<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
import { getSiteConfig } from "@/api/system/config"

export default {
  name: "App",
  computed: {
    routeName() {
      return this.$route.path
    },
  },
  mounted() {
    this.getSiteConfig()

    window.setInterval(() => {
      if (
        this.routeName == "/map/map_mng/imageCommon" ||
        this.routeName == "/design/online/tool"
      ) {
        window.document.oncontextmenu = function() {
          return false
        }
      } else {
        window.document.oncontextmenu = function() {
          return true
        }
      }
    }, 1000)
  },
  provide() {
    return {
      reload: this.reload,
    }
  },

  data() {
    return {
      isRouterAlive: true,
    }
  },

  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function() {
        this.isRouterAlive = true
      })
    },
    async getSiteConfig() {
      let result = await getSiteConfig()
      this.$store.commit("SET_SITE_DATA", result.data)
    },
  },
}
</script>

<style>
html,
body,
#app {
  height: 100%;
  background-color: white;
  font-family: sans-serif;
}
body {
  margin: initial;
  min-width: 1280px;
}
#nprogress .bar {
  background: #ff6c02 !important;
}
</style>
