<template>
  <div class="body">
    <TopNav :showDetail="!showDetail"></TopNav>
    <Content></Content>
    <Service></Service>
    <Footer></Footer>
    <Feedback></Feedback>
  </div>
</template>

<script>
import TopNav from "../advantage/TopNav"
import Content from "./Content"
import Service from "../default2/Service"
import Footer from "../default2/Footer"
import Feedback from "../default2/Feedback"


export default {
  components: {
    TopNav,
    Service,
    Content,
    Footer,
    Feedback,
  },
  data() {
    return {
      showDetail: false,
    }
  },
  methods: {},
  mounted() {
    let _self = this
  },
}
</script>

<style>
@import "../../assets/css/default/top-nav.css";
@import "../../assets/css/default/main.css";
</style>
