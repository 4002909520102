<template>
  <div>
    <div class="flowBox">
      <div class="boxHeader">
        <h2>覆盖多元行业及品类</h2>
        <p>多行业品类覆盖，丰富的服务</p>
      </div>
      <div class="flowItem flowItem1">
        <img class="img" src="../../assets/images/home/image-box.jpg" />
        <div class="title">箱包</div>
      </div>
      <div class="flowItem flowItem2">
        <img class="img" src="../../assets/images/home/image-shirt.jpg" />
        <div class="title">服装</div>
      </div>
      <div class="flowItem flowItem3">
        <img class="img" src="../../assets/images/home/image-shoes.jpg" />
        <div class="title">鞋子</div>
      </div>
      <div class="flowItem flowItem4">
        <img class="img" src="../../assets/images/home/image-003.jpeg" />
        <div class="title">挎包</div>
      </div>
      <div class="flowItem flowItem5">
        <img class="img" src="../../assets/images/home/image-000.jpeg" />
        <div class="title">卫衣</div>
      </div>
      <div class="flowItem flowItem6">
        <img class="img" src="../../assets/images/home/image-002.jpeg" />
        <div class="title">肩包</div>
      </div>

      <div class="flowItem flowItem1" style="left: 26%;top: 583px;">
        <img class="img" src="../../assets/images/home/image-box1.jpg" />
        <div class="title">裙子</div>
      </div>
      <div class="flowItem flowItem2" style="left: 8%;top: 153px;">
        <img class="img" src="../../assets/images/home/image-box2.jpg" />
        <div class="title">裤子</div>
      </div>
      <div class="flowItem flowItem3" style="left: 40%;top: 223px;">
        <img class="img" src="../../assets/images/home/image-004.jpeg" />
        <div class="title">平底鞋</div>
      </div>
      <div class="flowItem flowItem4" style="left: 70%;top: 139px;">
        <img class="img" src="../../assets/images/home/image-box4.jpg" />
        <div class="title">靴子</div>
      </div>
      <div class="flowItem flowItem5" style="left: 53%;top: 589px;">
        <img class="img" src="../../assets/images/home/image-001.jpeg" />
        <div class="title">短裤</div>
      </div>
      <div class="flowItem flowItem6" style="left: 84%;top: 569px;">
        <img class="img" src="../../assets/images/home/image-005.jpeg" />
        <div class="title">抱枕</div>
      </div>
    </div>
    <div class="boxHeader">
      <h2>一站定制，货通全球</h2>
      <p>从定制设计至生产发货如此简单</p>
    </div>
    <div class="glo-list">
      <div class="glo-item" v-for="(item, index) in gloList" :key="index">
        <div class="index">{{ item.index }}</div>
        <div class="name">{{ item.name }}</div>
        <div class="desc">{{ item.desc }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      gloList: [
        {
          index: 1,
          name: "选品设计",
          desc:
            "通过信息的捕获设计不同的主题，根据图片主题快速选择商品，开启泛精品差异化运营第一步",
        },
        {
          index: 2,
          name: "授权刊登",
          desc:
            "用户只需将海外店铺与Onecust 1号进行授权绑定，并将设计的商品刊登到您的海外店铺进行销售",
        },
        {
          index: 3,
          name: "下单支付",
          desc:
            "授权绑定的店铺出单后即可自动同步，支持多种支付方式及全球主流币种结算",
        },
        {
          index: 4,
          name: "生产发货",
          desc:
            "支持订单状态可视化，一件即可下单，48小时内即可完成生产并向全球发货",
        },
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.flowBox {
  height: 850px;
  box-sizing: border-box;
  padding: 120px 0;
  text-align: center;
  background: url("../../assets/images/home/banner2.png") no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  margin-bottom: 90px;
}

.boxHeader {
  margin-bottom: 50px;
  text-align: center;
}

.boxHeader h2 {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #181b1c;
  margin-bottom: 10px;
}

.boxHeader p {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.flowBox .flowItem {
  width: 160px;
  height: 220px;
  background: #ffffff;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.6s ease 0s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 0px 13px 0 rgb(64 147 243 / 35%);
  }
}

.flowItem1 {
  left: 10%;
  top: 550px;
}

.flowItem2 {
  left: 23%;
  top: 253px;
}

.flowItem3 {
  left: 41%;
  top: 503px;
}

.flowItem4 {
  left: 55%;
  top: 229px;
}

.flowItem5 {
  left: 68%;
  top: 543px;
}

.flowItem6 {
  left: 85%;
  top: 208px;
}

.flowBox .img {
  width: 135px;
  height: 135px;
  margin-bottom: 20px;
}

.flowBox .title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #181b1c;
}

.global-title {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #181b1c;
  margin-bottom: 40px;
  margin-top: 60px;
}

.global-title-sub {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}

.glo-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  margin: 60px auto 30px;

  .glo-item {
    width: 250px;
    height: 340px;
    background: #ffffff;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    position: relative;
    transition: all 0.3s linear;
    
    &:hover {
      box-shadow: 0 0px 13px 0 rgb(64 147 243 / 35%);
      transform: translateY(-10px);
    }

    .index {
      position: absolute;
      background: url("../../assets/images/home/list-dot.png") no-repeat;
      background-size: cover;
      width: 87px;
      height: 87px;
      top: -43px;
      left: 32%;
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .name {
      width: 75%;
      text-align: center;
      margin: 80px auto 20px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #363839;
    }

    .desc {
      width: 75%;
      margin: 0 auto;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #363839;
      line-height: 30px;
    }
  }
}
</style>
