<template>
  <div style="position: relative">
    <div class="rno-2-event-panels" id="section-hot">
      <div
        class="rno-2-event-lattice-card rno-2-event-lattice-cols-4 rno-2-event-lattice-without-margin"
      >
        <div class="J-lattice-cell rno-2-event-lattice-cell">
          <a target="_blank" class="rno-2-event-panel1">
            <div class="rno-2-event-cont">
              <img class="adImage" :src="ad1" />
              <h3 class="rno-2-event-panel-title">0成本创业</h3>
              <p class="rno-2-event-panel-desc">专属于您的泛精品模式</p>
            </div>
          </a>
        </div>
        <div class="J-lattice-cell rno-2-event-lattice-cell">
          <a target="_blank" class="rno-2-event-panel1">
            <div class="rno-2-event-cont">
              <img class="adImage" :src="ad2" />
              <h3 class="rno-2-event-panel-title">0库存压力</h3>
              <p class="rno-2-event-panel-desc">解放资金压力</p>
            </div>
          </a>
        </div>
        <div class="J-lattice-cell rno-2-event-lattice-cell">
          <a target="_blank" class="rno-2-event-panel1">
            <div class="rno-2-event-cont">
              <img class="adImage" :src="ad3" />
              <h3 class="rno-2-event-panel-title">0后顾之忧</h3>
              <p class="rno-2-event-panel-desc">60天超长售后周期</p>
            </div>
          </a>
        </div>
        <div class="J-lattice-cell rno-2-event-lattice-cell">
          <a target="_blank" class="rno-2-event-panel1">
            <div class="rno-2-event-cont">
              <img class="adImage" :src="ad4" />
              <h3 class="rno-2-event-panel-title">0经验运营</h3>
              <p class="rno-2-event-panel-desc">一对一商家运营服务</p>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div @click="handleJump('/design/online/tool')" class="website-text">
      <div class="text-button">
        <img :src="designButton" />
      </div>
    </div>
  </div>
</template>

<script>
import ad1 from "@/assets/images/default/ad1.png"
import ad2 from "@/assets/images/default/ad2.png"
import ad3 from "@/assets/images/default/ad3.png"
import ad4 from "@/assets/images/default/ad4.png"
import designButton from "@/assets/images/default/design_button.png"

export default {
  data() {
    return {
      ad1: ad1,
      ad2: ad2,
      ad3: ad3,
      ad4: ad4,
      designButton: designButton,
    }
  },
}
</script>

<style lang="scss" scoped>
#section-hot {
  margin: 30px auto 89px auto;
}
.rno-2-event-cont {
  text-align: center;
}
.adImage {
  width: 200px;
  margin: 0 0 16px 0;
}
.rno-2-event-panel-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
}
.rno-2-event-panel-desc {
  color: #333333;
}
.website-text {
  width: 500px;
  text-align: left;
  position: absolute;
  left: 15%;
  top: 200px;
  .text-button {
    margin: 0 0 0 -10px;
    img {
      height: 50px;
      cursor: pointer;
    }
  }
}
</style>
