import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import permission from "./directive/permission"
import echarts from "echarts"
import axios from "axios"
import "xe-utils"
import VXETable from "vxe-table"
import "vxe-table/lib/style.css"
import "font-awesome/css/font-awesome.css"
import "./assets/css/common.css"

import "./assets/icons" // icon
import "./permission" // permission control
import {
  parseTime,
  resetForm,
  addDateRange,
  handleTree,
  selectDictLabel,
} from "@/utils/jn"
import {
  encrypt,
  decrypt,
  encryptUnicodeLong,
  decryptUnicodeLong,
} from "@/utils/jsencrypt"
import Pagination from "@/components/Pagination"
import { getDicts } from "@/api/system/dict/data"
import { Loading } from "element-ui"
import _ from "lodash"
import mixin from "./mixin/index.js"
// import VueLazyload from 'vue-lazyload'

// Vue.use(VueLazyload, {
//   preload: 1.3, // 表示lazyload元素距离底部距离百分比
// })

let loading
// 挂载全局方法
Vue.prototype.loading = Loading
Vue.prototype.getDicts = getDicts
Vue.prototype.handleTree = handleTree
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.$echarts = echarts
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.encrypt = encrypt
Vue.prototype.decrypt = decrypt
Vue.prototype.encryptUnicodeLong = encryptUnicodeLong
Vue.prototype.decryptUnicodeLong = decryptUnicodeLong
Vue.prototype._ = _
Vue.prototype.events = new Vue()

// 给 vue 实例挂载内部对象，例如：
Vue.prototype.$XModal = VXETable.modal
Vue.prototype.$XPrint = VXETable.print
Vue.prototype.$XSaveFile = VXETable.saveFile
Vue.prototype.$XReadFile = VXETable.readFile
Vue.prototype.msgSuccess = function(msg, duration) {
  this.$message({
    showClose: true,
    message: msg,
    type: "success",
    duration: duration ? duration : 1000,
  })
}
Vue.prototype.msgError = function(msg) {
  this.$message({ showClose: true, message: msg, type: "error" })
}
Vue.prototype.msgWarning = function(msg) {
  this.$message({ showClose: true, message: msg, type: "warning" })
}
Vue.prototype.startLoading = function() {
  //使用Element loading-start 方法
  loading = Loading.service({
    lock: true,
    text: "加载中……",
    background: "rgba(0, 0, 0, 0.7)",
  })
}

Vue.prototype.endLoading = function() {
  //使用Element loading-close 方法
  loading.close()
}
window.startLoading = function() {
  loading = Loading.service({
    lock: true,
    text: "加载中……",
    background: "rgba(0, 0, 0, 0.7)",
  })
}
window.endLoading = function() {
  loading.close()
}
import ElementUI from "element-ui"
import "./assets/css/element.css"

Vue.use(ElementUI)
Vue.use(permission)
Vue.use(VXETable)
Vue.mixin(mixin)

// 全局组件挂载
Vue.component("Pagination", Pagination)

Vue.config.productionTip = false

window.$vue = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
