import request from '@/utils/request'

export function collData(url){
    return request({
        url: '/product/collData',
        method: 'post',
        timeout:50000,
        params: {'url':url}
    })
}

// 加载表格数据
export function loadData(params){
    return request({
        url: '/product/comfun/list',
        method: 'get',
        params: params
    })
}

// 通过用户名和平台编码获取店铺集合
export function getShopByPlatCode(code){
    return request({
        url: '/plat/ShopList/'+ code,
        method: 'get',
    })
}

//认领产品到平台
export function claimToPlat(params){
    return request({
        url: '/product/comfun/claimToPlatfrom',
        method: 'post',
        data: params,
    })
}

//修改数据采集箱
export function updateColl(data) {
    return request({
        url: '/product/comfun/update',
        method: 'put',
        data: data
    })
}

//批量删除指定产品
export function deleteData(params) {
    return request({
        url: '/product/comfun/delete',
        method: 'post',
        data: params
    })
}
//单个删除指定产品
export function deleteById(collId) {
    return request({
        url: '/product/comfun/delete/'+collId,
        method: 'delete',
    })
}

//根据采集id获取指定产品
export function selectById(collId) {
    return request({
        url: '/product/comfun/get/'+collId,
        method: 'get',
    })
}

export function uploadImg(data) {
    return request({
        url:'/product/comfun/uploadImg',
        method:'post',
        data:data
    })
}

// 保存产品编辑页面数据
export function saveDataCollEdit(data) {
    return request({
        url:'/product/comfun/saveDataCollEdit',
        method:'post',
        data:data
    })
}

//获取授权平台数据
export function getAuthPlatform(userId) {
    return request({
        url: '/erp/authPlatform/'+userId,
        method: 'get',
    });
}

//获取当前登录账号相关用户（主账号和所有子账号）
export function manageList() {
    return request({
        url:'/product/comFun/mainAndChild',
        method:'get'
    });
}

//公用的平台上传图片方法
export function publicUploadImg(data) {
    return request({
        url:'/product/comfun/publicUploadImg',
        method:'post',
        data:data
    })
}

//公用的获取图片方法
export function getPicListBypId(params) {
    return request({
        url:'/product/comfun/getPicListBypId',
        method:'get',
        params:params
    })
}

//商品管理上传图片
export function goodsUploadImg(data) {
    return request({
        url:'/product/comfun/goodsUploadImg',
        method:'post',
        data:data
    })
}

//获取店铺下的所有用户
export function getAllShopUser() {
    return request({
        url:'/product/comfun/getAllShopUser',
        method:'get',
    })
}

//获取用户ID
export function getUserId() {
    return request({
        url:'/product/comfun/getUserId',
        method:'get',
    })
}

export function saveAndClaim(params) {
    return request({
        url:'/product/comfun/saveAndClaim',
        method:'post',
        data:params
    });
}

//获取采集的商品数量和未认领商品的数量
export function getTotalAndUnClaimedNum() {
    return request({
        url:'/product/comfun/getTotalAndUnClaimedNum',
        method:'get'
    });
}


