<template>
  <div class="rno-2-event-panels" id="section-hot">
    <div
      class="rno-2-event-lattice-card rno-2-event-lattice-cols-4 rno-2-event-lattice-without-margin"
    >
      <div class="J-lattice-cell rno-2-event-lattice-cell">
        <a
          target="_blank"
          class="rno-2-event-panel"
        >
          <div class="rno-2-event-cont">
            <h3 class="rno-2-event-panel-title">0成本创业</h3>
            <p class="rno-2-event-panel-desc">专属于您的泛精品模式</p>
            <img
              src="../../assets/images/home/pay.png"
              class="rno-2-event-panel-icon J-panel-icon"
            />
          </div>
        </a>
      </div>
      <div class="J-lattice-cell rno-2-event-lattice-cell">
        <a
          target="_blank"
          class="rno-2-event-panel"
        >
          <div class="rno-2-event-cont">
            <h3 class="rno-2-event-panel-title">0库存压力</h3>
            <p class="rno-2-event-panel-desc">解放资金压力</p>
            <img
              src="../../assets/images/home/box.png"
              class="rno-2-event-panel-icon J-panel-icon"
            />
          </div>
        </a>
      </div>
      <div class="J-lattice-cell rno-2-event-lattice-cell">
        <a
          target="_blank"
          class="rno-2-event-panel"
        >
          <div class="rno-2-event-cont">
            <h3 class="rno-2-event-panel-title">0后顾之忧</h3>
            <p class="rno-2-event-panel-desc">60天超长售后周期</p>
            <img
              src="../../assets/images/home/service.png"
              alt="人工智能"
              class="rno-2-event-panel-icon J-panel-icon"
            />
          </div>
        </a>
      </div>
      <div class="J-lattice-cell rno-2-event-lattice-cell">
        <a
          target="_blank"
          class="rno-2-event-panel"
        >
          <div class="rno-2-event-cont">
            <h3 class="rno-2-event-panel-title">0经验运营</h3>
            <p class="rno-2-event-panel-desc">一对一商家运营服务</p>
            <img
              src="../../assets/images/home/circle.png"
              alt="金融行业方案"
              class="rno-2-event-panel-icon J-panel-icon"
            />
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
