var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"rno-2-section-inner"},[_c('div',{staticClass:"rno-2-section-hd"},[_vm._v(" 热卖单品 ")]),_c('h5',{staticClass:"rno-2-section-desc",staticStyle:{"margin-bottom":"30px"}},[_vm._v(" 新品持续更新 紧跟潮流热卖 ")]),_c('div',{staticClass:"rno-2-section-bd"},[_c('div',{staticClass:"rno-2-tabs"},[(false)?_c('div',{staticClass:"rno-2-tabs-bar"},[_c('ul',{staticClass:"rno-2-tabs-list"},[_c('li',{class:[
                'rno-2-tabs-item',
                _vm.active === 'hot' ? 'rno-2-tabs-active' : '',
                'J-homeProductTab',
              ],attrs:{"data-target":"hot"},on:{"click":function($event){return _vm.changeActive('hot')}}},[_c('a',{staticClass:"rno-2-tabs-item-con",attrs:{"href":"javascript:;"}},[_vm._v(" 近期热卖 ")])]),_c('li',{class:[
                'rno-2-tabs-item',
                _vm.active === 'essential' ? 'rno-2-tabs-active' : '',
                'J-homeProductTab',
              ],attrs:{"data-target":"essential"},on:{"click":function($event){return _vm.changeActive('essential')}}},[_c('a',{staticClass:"rno-2-tabs-item-con",attrs:{"href":"javascript:;"}},[_vm._v(" 新品速递 ")])])])]):_vm._e(),_c('div',{staticClass:"rno-2-tabs-content"},[_c('div',{staticClass:"flex-row flex-wrap index-goods-list flex-x-sbetween"},_vm._l((_vm.productList),function(record,index){return _c('div',{key:index,staticClass:"good-item",on:{"mouseout":function($event){return _vm.handleMouseOut(record)},"mouseover":function($event){return _vm.handleMouseOver(record)}}},[_c('a',{staticStyle:{"display":"none"},attrs:{"href":"/frontend/create/index?blankProId=Y9I9U8"}}),_c('div',{staticClass:"index-goods-vip"}),_c('a',{on:{"click":function($event){return _vm.goProduct(record)}}},[_c('img',{staticClass:"evenflow_scale index-goods-img",attrs:{"src":record.picUrl}})]),_c('div',{staticStyle:{"height":"101px"}},[_c('div',{staticClass:"index-goods-detail pt13"},[_c('div',{staticClass:"index-goods-tit shop_tit_style"},[_vm._v(" "+_vm._s(record.proName)+" ")]),_c('div',{staticClass:"index-goods-price"},[_c('span',{staticClass:"zc"},[_vm._v("￥"+_vm._s(record.price))]),_vm._v(" 起 ")]),_c('div',{staticClass:"mt5 shop_newTime_style"},[_vm._v(" 上新时间："+_vm._s(record.createTime)+" ")])]),_c('div',{staticClass:"index-goods-order"},[_c('div',{staticClass:"index-goods-active"},[_c('div',{staticClass:"text-ellipsis",attrs:{"title":record.pdMaterial}},[_vm._v(" 材质："+_vm._s(record.pdMaterial)+" ")]),_c('div',[_vm._v("重量："+_vm._s(record.weight)+"g")]),_c('div',{staticClass:"shop_factory_style",staticStyle:{"height":"2px"},attrs:{"title":""}})])])])])}),0)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }