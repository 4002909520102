const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  nickName: state => state.user.nickName,
  user: state => state.user,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  permissions: state => state.user.permissions,
  auth_platform:state => state.user.authPlatform,
  platCode:state => state.user.platCode,
  shopList:state => state.user.shopList,
  lazadaTotal:state => state.product.lazada.total,
  amazonTotal:state => state.product.amazon.total,
  permission_routes: state => state.permission.routes,
  orderStatus:state => state.order.orderStatus,
  nginx:state =>state.setting.nginx,
  dictData:state => state.setting.dictData,
  dictMap:state => state.setting.dictMap,
  stat: state => state.user.stat,
  siteData: state => state.setting.siteData,
  syncResult: state => state.product.syncResult,
}
export default getters
