import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"

import Index from "../views/index/index.vue"
import IndexAdmin from "../views/indexAdmin/index.vue"
import IndexAdminWelcome from "../views/indexAdminDefault/index.vue"
import Rank from "../views/rank.vue"
import loginNew from "../views/login/index.vue"
import vip from "../views/vip/index.vue"
import help from "../views/help/index.vue"
import policy from "../views/policy/index.vue"
import terms from "../views/terms/index.vue"
import advantage from "../views/advantage/index.vue"
import contact from "../views/contact/index.vue"
import productClass from "../views/productClass/index.vue"
import productContent from "../views/productContent/index.vue"
import registerNew from "../views/register/index.vue"
import registerApp from "../views/registerApp/index.vue"
import loginApp from "../views/loginApp/index.vue"
import Default from "../views/default/index.vue"
import ad from "../views/ad/index.vue"
import DefaultIndex from "../views/index/index.vue"
import Reset from "../views/reset/index.vue"
import siteRouter from "../views/siteRouter/index.vue"
import DefaultPH from "../views/default2/index.vue"


Vue.use(VueRouter)

export const constantRoutes = [
  {
    path: "/index",
    name: "Home",
    component: Home,
    redirect: "/",
    meta: { requireAuth: true },
    children: [
      {
        path: "/index", //首页
        name: "首页",
        component: DefaultIndex,
        meta: { title: "首页" },
      },
      //   ...Product, // 产品
      //   ...Repository, //仓库
      //   ...user,
      //   ...Order,  //订单
      //   ...Procurement, //采购
      //   ...AuxiliaryFunction  //辅助功能
    ],
  },
  {
    path: "/index_admin",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/index_admin", //首页
        name: "首页",
        component: IndexAdmin,
        meta: { title: "首页" },
      },
    ],
  },
  {
    path: "/",
    name: "default",
    meta: { requireAuth: false },
    component: DefaultPH,
  },
  {
    path: "/welcome",
    name: "Home",
    component: Home,
    redirect: "/",
    meta: { requireAuth: true },
    children: [
      {
        path: "/welcome", //首页
        name: "默认页面",
        component: IndexAdminWelcome,
        meta: { title: "默认页面" },
      },
      //   ...Product, // 产品
      //   ...Repository, //仓库
      //   ...user,
      //   ...Order,  //订单
      //   ...Procurement, //采购
      //   ...AuxiliaryFunction  //辅助功能
    ],
  },
  {
    path: "/index1",
    name: "DefaultIndex",
    meta: { requireAuth: false },
    component: siteRouter,
  },
  {
    path: "/index2",
    name: "DefaultPH",
    meta: { requireAuth: false },
    component: DefaultPH,
  },
  {
    path: "/vip",
    name: "vip",
    meta: { requireAuth: false },
    component: vip,
  },
  {
    path: "/help",
    name: "help",
    meta: { requireAuth: false },
    component: help,
  },
  {
    path: "/policy",
    name: "policy",
    meta: { requireAuth: false },
    component: policy,
  },
  {
    path: "/terms",
    name: "terms",
    meta: { requireAuth: false },
    component: terms,
  },





  {
    path: "/rank",
    name: "activity",
    meta: { requireAuth: false },
    component: Rank,
  },
  {
    path: "/advantage",
    name: "advantage",
    meta: { requireAuth: false },
    component: advantage,
  },
  {
    path: "/contact",
    name: "contact",
    meta: { requireAuth: false },
    component: contact,
  },
  {
    path: "/login",
    name: "login",
    meta: { requireAuth: false },
    component: loginNew,
  },
  {
    path: "/login_admin",
    name: "login_admin",
    meta: { requireAuth: false },
    component: loginNew,
  },
  {
    path: "/register",
    name: "register",
    meta: { requireAuth: false },
    component: registerNew,
  },
  {
    path: "/reset",
    name: "reset",
    meta: { requireAuth: false },
    component: Reset,
  },
  {
    path: "/register_new",
    name: "register_new",
    meta: { requireAuth: false },
    component: registerNew,
  },
  {
    path: "/erp",
    name: "erp",
    meta: { requireAuth: false },
    component: DefaultIndex,
  },
  {
    path: "/app_register",
    name: "app_register",
    meta: { requireAuth: false },
    component: registerApp,
  },
  {
    path: "/app_login",
    name: "app_login",
    meta: { requireAuth: false },
    component: loginApp,
  },
  {
    path: "/ad",
    name: "ad",
    meta: { requireAuth: false },
    component: ad,
  },
  {
    path: "/login_new",
    name: "login_new",
    meta: { requireAuth: false },
    component: loginNew,
  },
  {
    path: "/404",
    name: "404",
    component: (resolve) => require(["@/views/error/404"], resolve),
    hidden: true,
  },

  {
    path: "/401",
    name: "401",
    component: (resolve) => require(["@/views/error/401"], resolve),
    hidden: true,
  },
  {
    path: "/product_type",
    name: "productClass",
    meta: { requireAuth: false },
    component: productClass,
  },
  {
    path: "/product",
    name: "Product",
    meta: { requireAuth: false },
    component: productContent,
  },
  {
    path: "/temu",
    name: "temudiy",
    meta: { requireAuth: false },
    component:  (resolve) => require(["@/views/mobile/temu"], resolve),
  },
]

const router = new VueRouter({
  routes: constantRoutes,
  base: "",
  mode: "history",
  scrollBehavior: () => ({ y: 0 }),
})

//处理路由跳转，导航重复时报错
const originalPush = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalPush.call(this, location).catch((err) => err)
}

export default router
