import request from '@/utils/request'

// 加载表格数据
export function getShopList(params){
    return request({
        url: '/server/shop/select',
        method: 'get',
        params: params
    })
}
// 加载表格数据
export function getLazada(params){
    return request({
        url: '/server/shop/select',
        method: 'get',
        params: params
    })
}

// 加载表格数据
export function getLazadaCategory(shopId){
    return request({
        url: '/server/shop/getLazadaCategory/' + shopId,
        method: 'get',
    })
}

// 加载表格数据
export function syncLazada(data){
    return request({
        url: '/server/universal/template/syncLazada',
        method: 'post',
        data: data
    })
}


// 加载表格数据
export function getChannelList(data){
    return request({
        url: '/server/order/channelList',
        method: 'GET',
        params: data
    })
}


// 加载表格数据
export function syncShopee(data){
    return request({
        url: '/server/universal/template/syncShopee',
        method: 'post',
        data: data
    })
}


// 加载表格数据
export function syncTiktok(data){
    return request({
        url: '/server/universal/template/syncTiktok',
        method: 'post',
        data: data
    })
}


// 加载表格数据
export function getCategoryAttribute(data){
    return request({
        url: '/server/lazada/product/getCategoryAttribute',
        method: 'get',
        params: data
    })
}



// 加载表格数据
export function getBrandByPages(data){
    return request({
        url: '/server/lazada/product/getBrandByPages',
        method: 'get',
        params: data
    })
}



export function templateTiming(data) {
    return request({
      url: "/server/universal/template/timing",
      method: "post",
      data: data
    })
  }

export function getShopSiteList(data) {
    return request({
      url: "/product/shienApi/getShopSiteList",
      method: "get",
      params: data
    })
  }

  // 发布shein产品
export function sendProduct(data) {
    return request({
      url: "/product/shienApi/sendProduct",
      method: "post",
      data: data,
    })
  }
  // 保存shein产品
export function setProduct(data) {
    return request({
      url: "/product/shienApi/setProduct",
      method: "post",
      data: data,
    })
  }
  // 获取shein产品列表
export function getProductList(data) {
    return request({
      url: "/product/shienApi/list",
      method: "get",
      params: data,
    })
  }
  // 获取shein产品详情
export function getProductDetail(data) {
    return request({
      url: "/product/shienApi/"+data.pId,
      method: "get",
    //   params: data,
    })
  }
    // 批量删除shein产品
export function removeSheinProduct(data) {
  return request({
    url: "/product/shienApi/remove",
    method: "post",
    data: data,
  })
}
    // 定时发布shein产品
export function timingSheinProduct(data) {
  return request({
    url: "/product/shienApi/timing",
    method: "post",
    data: data,
  })
}
    // 取消shein定时发布
export function cancelTiming(data) {
  return request({
    url: "/product/shienApi/cancelTiming",
    method: "post",
    data: data,
  })
}