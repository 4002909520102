<template>
  <div class="index">
    <SideBar></SideBar>
    <Main></Main>
  </div>
</template>

<script>
import SideBar from "./SideBar"
import Main from "./Main"

export default {
  components: {
    SideBar,
    Main
  },
  data() {
    return {}
  },
  
}
</script>

<style>
.index {
  width: 100%;
  height: 100%;
}

</style>
