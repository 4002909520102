var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"portalFooter"},[_vm._m(0),_c('div',{staticClass:"bd"},[_vm._m(1),_c('div',{staticClass:"contact1"},[_c('div',{staticClass:"tel"},[_vm._v("135 5939 7618")]),_c('div',{staticClass:"qq"},[_c('div',{staticStyle:{"float":"left","margin":"0 0 0 0px"}},[_c('img',{attrs:{"src":_vm.imageWechart}}),_vm._v(" "),_c('br'),_c('span',{staticStyle:{"padding":"0 0 0 8px"}},[_vm._v("扫码添加微信")])]),_c('div',{staticStyle:{"float":"left","margin":"0 0 0 29px"}},[_c('img',{attrs:{"src":_vm.imageQQ}}),_vm._v(" "),_c('br'),_c('span',{staticStyle:{"padding":"0 0 0 8px"}},[_vm._v("扫码添加QQ")])])])]),_vm._m(2),_vm._m(3),_vm._m(4)]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hdWrap__PortalLayout-16BGU8"},[_c('div',{staticClass:"hd"},[_c('div',[_vm._v("零库存")]),_c('div',[_vm._v("无忧售后")]),_c('div',[_vm._v("一件代发")]),_c('div',[_vm._v("高净利润")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qrcode"},[_c('div',{staticClass:"logo"}),_c('div',{staticClass:"contact"},[_c('div',[_vm._v("联系客服 service@onecust.com")]),_c('div',[_vm._v("商务合作 bd@onecust.com")]),_c('div',[_vm._v("技术支持 support@onecust.com")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"linkCol__PortalLayout-1EV_-b"},[_c('h3',[_vm._v("新手指南")]),_c('a',{attrs:{"target":"_blank","rel":"noopener noreferrer"}},[_vm._v("DIY铺货基础学习")]),_c('a',{attrs:{"target":"_blank","rel":"noopener noreferrer"}},[_vm._v("选择产品/开发产品")]),_c('a',{attrs:{"target":"_blank","rel":"noopener noreferrer"}},[_vm._v("如何设计")]),_c('a',{attrs:{"target":"_blank","rel":"noopener noreferrer"}},[_vm._v("成品发布")]),_c('a',{attrs:{"target":"_blank","rel":"noopener noreferrer"}},[_vm._v("如何下单")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"linkCol__PortalLayout-1EV_-b"},[_c('h3',[_vm._v("合作专区")]),_c('a',[_vm._v("会员服务")]),_c('a',[_vm._v("生产商合作")]),_c('a',[_vm._v("服务商合作")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"linkCol__PortalLayout-1EV_-b"},[_c('h3',[_vm._v("关于我们")]),_c('a',[_vm._v("关于我们")]),_c('a',{attrs:{"target":"_blank","rel":"noopener noreferrer"}},[_vm._v("售后说明")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"copyright__PortalLayout-3W9Nm3"},[_vm._v(" Copyright @平台定制"),_c('a',{staticStyle:{"color":"rgb(255, 255, 255)","margin":"0px 10px"},attrs:{"href":"https://beian.miit.gov.cn/#/Integrated/index","target":"blank"}},[_vm._v("闽ICP备2021011432号-1")]),_c('a',{staticStyle:{"color":"rgb(255, 255, 255)","margin":"0px 10px"},attrs:{"href":"https://beian.miit.gov.cn/#/Integrated/index","target":"blank"}},[_vm._v("闽ICP备2021011432号-2")]),_c('a',{staticStyle:{"color":"rgb(255, 255, 255)","margin":"0px 10px"},attrs:{"href":"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35020302035083","target":"blank"}},[_vm._v("闽公网安备35020302035083号")]),_vm._v(" All Rights Reserved. ")])
}]

export { render, staticRenderFns }