<template>
  <div style="height:auto;" class="body">
    <div
      :style="{
        background: 'url(' + banner + ')   center top no-repeat',
      }"
      class="top-banner"
    ></div>
    <div class="advantage-wrap">
      <div class="list">
        <div class="top">
          <div class="item" v-for="(item, index) in header" :key="index">
            <i :class="['icon-' + index]"></i>
            <div class="title">{{ item.tip }}</div>
            <div class="name">
              {{ item.name }}
            </div>
            <div class="name">{{ item.en }}</div>
          </div>
        </div>
      </div>
      <div class="middle-title">
        <a>&nbsp;</a>
      </div>
      <div class="classify-list">
        <div class="item" v-for="(item, index) in classifyList" :key="index">
          <i :class="['image', 'image-' + index]"></i>
          <div class="name">{{ item.name }}</div>
          <div class="en">{{ item.en }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import banner from "@/assets/images/advantage/banner.png"

export default {
  components: {},
  data() {
    return {
      header: [
        {
          tip: "店群铺货",
          name: "批量设计无限SKU",
          en: "一键同步店群铺货",
        },
        {
          tip: "ERP管理",
          name: "多账户/多平台统一管理",
          en: "销售/生产/财务/物流全流程管理",
        },
        {
          tip: "本土生产",
          name: "供应链覆盖全国地区",
          en: "大部分订单可在24h内完成生产",
        },
        {
          tip: "本土发货",
          name: "本地仓储配合官方/第三方物流",
          en: "可满足绝大部分订单的时效需求",
        },
      ],
      banner: banner,
      classifyList: [
        {
          name: "印花",
          en: "Print On Demand",
        },
        {
          name: "潮鞋",
          en: "Sneakers",
        },
        {
          name: "快速生产",
          en: "Fast Production",
        },
        {
          name: "独品开发",
          en: "Develop Unique Product",
        },
        {
          name: "批量设计",
          en: "Bulk Create",
        },
        {
          name: "零库存",
          en: "No Stock",
        },
        {
          name: "无起订量",
          en: "No Order Minimums",
        },
        {
          name: "高品质",
          en: "High Quality",
        },
        {
          name: "品牌定制",
          en: "Custom Branding",
        },
        {
          name: "多平台",
          en: "Multi Platform",
        },
        {
          name: "本地生产",
          en: "Local production",
        },
        {
          name: "本地物流",
          en: "Local Drop-shipping",
        },
      ],
      advantageList: [
        {
          name: "鞋靴",
          en: "footwear",
        },
        {
          name: "服装内衣",
          en: "clothing underwear",
        },
        {
          name: "箱包",
          en: "luggage",
        },
        {
          name: "家居用品",
          en: "household products",
        },
        {
          name: "母婴玩具",
          en: "baby toys",
        },
      ],
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.advantage-wrap {
  .banner {
    width: 100%;

    .title {
      font-size: 48px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      line-height: 38px;
      margin-bottom: 20px;
    }

    .sub {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      line-height: 38px;
    }
  }
}

.list {
  width: 1200px;
  margin: 50px auto 20px;

  .top {
    height: 280px;
    display: flex;

    .item {
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      &:hover .icon-0 {
        opacity: 0.5;
      }
      &:hover .icon-1 {
        opacity: 0.5;
      }
      &:hover .icon-2 {
        opacity: 0.5;
      }
      &:hover .icon-3 {
        opacity: 0.5;
      }

      .icon-0 {
        width: 72px;
        height: 72px;
        background: url("../../assets/images/advantage/icon-1.png") no-repeat;
        background-size: 100% 100%;
      }

      .icon-1 {
        width: 72px;
        height: 72px;
        background: url("../../assets/images/advantage/icon-2.png") no-repeat;
        background-size: 100% 100%;
      }

      .icon-2 {
        width: 72px;
        height: 72px;
        background: url("../../assets/images/advantage/icon-3.png") no-repeat;
        background-size: 100% 100%;
      }

      .icon-3 {
        width: 72px;
        height: 72px;
        background: url("../../assets/images/advantage/icon-4.png") no-repeat;
        background-size: 100% 100%;
      }

      .title {
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        margin-bottom: 30px;
        margin-top: 20px;
      }

      .name {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #555555;
        margin-bottom: 10px;
        width: 200px;
        text-align: center;
      }
      .desc {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #555555;
        margin-bottom: 30px;
        width: 200px;
        text-align: center;
      }

      .en {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #555555;
        text-transform: uppercase;
      }
    }
  }
}

.middle-title {
  text-align: center;
  border-bottom: 1px solid #e4e4e4;
  margin: 80px auto 30px;
  width: 1200px;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    width: 100px;
    height: 2px;
    background: rgb(73, 52, 178);
    left: 553px;
    top: 0px;
  }

  .zh {
    font-size: 44px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    margin-bottom: 20px;
  }

  .en {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    text-transform: uppercase;
  }
}

.tip {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
  width: 680px;
  border-top: 1px solid #e4e4e4;
  margin: 0 auto 100px;
  padding: 40px;
  text-align: center;
}

.classify-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  margin: 60px auto 30px;
  flex-wrap: wrap;

  .item {
    width: 270px;
    height: 160px;
    background: #ffffff;
    border: 1px solid #edeffa;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;

    transition: all 0.3s linear;

    &:hover {
      box-shadow: 0 0px 13px 0 rgb(64 147 243 / 35%);
      transform: translateY(-10px);
    }

    .image {
      width: 60px;
      height: 60px;
      margin-bottom: 20px;

      &.image-0 {
        background: url("../../assets/images/advantage/classify-0.png")
          no-repeat;
        background-size: 100% 100%;
      }
      &.image-1 {
        background: url("../../assets/images/advantage/classify-1.png")
          no-repeat;
        background-size: 100% 100%;
      }
      &.image-2 {
        background: url("../../assets/images/advantage/classify-2.png")
          no-repeat;
        background-size: 100% 100%;
      }
      &.image-3 {
        background: url("../../assets/images/advantage/classify-3.png")
          no-repeat;
        background-size: 100% 100%;
      }
      &.image-4 {
        background: url("../../assets/images/advantage/classify-4.png")
          no-repeat;
        background-size: 100% 100%;
      }
      &.image-5 {
        background: url("../../assets/images/advantage/classify-5.png")
          no-repeat;
        background-size: 100% 100%;
      }
      &.image-6 {
        background: url("../../assets/images/advantage/classify-6.png")
          no-repeat;
        background-size: 100% 100%;
      }
      &.image-7 {
        background: url("../../assets/images/advantage/classify-7.png")
          no-repeat;
        background-size: 100% 100%;
      }
      &.image-8 {
        background: url("../../assets/images/advantage/classify-8.png")
          no-repeat;
        background-size: 100% 100%;
      }
      &.image-9 {
        background: url("../../assets/images/advantage/classify-9.png")
          no-repeat;
        background-size: 100% 100%;
      }
      &.image-10 {
        background: url("../../assets/images/advantage/classify-10.png")
          no-repeat;
        background-size: 100% 100%;
      }
      &.image-11 {
        background: url("../../assets/images/advantage/classify-11.png")
          no-repeat;
        background-size: 100% 100%;
      }
    }

    .name {
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #181b1c;
    }

    .en {
      width: 100%;
      text-align: center;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
    }
  }
}

.advantage-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  margin: 60px auto 30px;

  .item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 220px;
    height: 300px;
    position: relative;
    margin-bottom: 60px;
    cursor: pointer;

    .image-0 {
      width: 220px;
      height: 220px;
      background: url("../../assets/images/advantage/image-1.png") no-repeat;
      background-size: 100% 100%;
      transition: all 0.3s linear;

      &:hover {
        opacity: 0.7;
      }
    }

    .image-1 {
      width: 220px;
      height: 220px;
      background: url("../../assets/images/advantage/image-2.png") no-repeat;
      background-size: 100% 100%;
      transition: all 0.3s linear;

      &:hover {
        opacity: 0.7;
      }
    }

    .image-2 {
      width: 220px;
      height: 220px;
      background: url("../../assets/images/advantage/image-3.png") no-repeat;
      background-size: 100% 100%;
      transition: all 0.3s linear;

      &:hover {
        opacity: 0.7;
      }
    }

    .image-3 {
      width: 220px;
      height: 220px;
      background: url("../../assets/images/advantage/image-4.png") no-repeat;
      background-size: 100% 100%;
      transition: all 0.3s linear;

      &:hover {
        opacity: 0.7;
      }
    }

    .image-4 {
      width: 220px;
      height: 220px;
      background: url("../../assets/images/advantage/image-5.png") no-repeat;
      background-size: 100% 100%;
      transition: all 0.3s linear;

      &:hover {
        opacity: 0.7;
      }
    }

    .name {
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      margin-bottom: 10px;
      margin-top: 20px;
      width: 100%;
    }

    .en {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #999999;
      text-transform: uppercase;
      width: 100%;
    }
  }
}
.top-banner {
  width: 100%;
  height: 30vw;
  background-size: cover !important;
}
</style>
