var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flowBox"},[_vm._m(0),_c('div',{staticStyle:{"display":"flex","width":"100%","margin":"0 0 7vh 0","padding":"0 5%","box-sizing":"border-box"}},_vm._l((_vm.productList1),function(record,index){return _c('div',{key:index,staticClass:"flexItem"},[_c('div',{staticClass:"flowItem",on:{"mouseout":function($event){return _vm.handleMouseOut(record)},"mouseover":function($event){return _vm.handleMouseOver(record)}}},[_c('img',{staticClass:"img",attrs:{"src":record.picUrl}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(record.name))])])])}),0),_c('div',{staticStyle:{"display":"flex","width":"100%","margin":"0 0 7vh 0","padding":"0 5%","box-sizing":"border-box"}},_vm._l((_vm.productList2),function(record,index){return _c('div',{key:index,staticClass:"flexItem"},[_c('div',{staticClass:"flowItem",on:{"mouseout":function($event){return _vm.handleMouseOut(record)},"mouseover":function($event){return _vm.handleMouseOver(record)}}},[_c('img',{staticClass:"img",attrs:{"src":record.picUrl}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(record.name))])])])}),0),_c('div',{staticStyle:{"display":"flex","width":"100%","padding":"0 5%","box-sizing":"border-box"}},_vm._l((_vm.productList3),function(record,index){return _c('div',{key:index,staticClass:"flexItem"},[_c('div',{staticClass:"flowItem",on:{"mouseout":function($event){return _vm.handleMouseOut(record)},"mouseover":function($event){return _vm.handleMouseOver(record)}}},[_c('img',{staticClass:"img",attrs:{"src":record.picUrl}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(record.name))])])])}),0)]),_vm._m(1),_c('div',{staticClass:"glo-list"},_vm._l((_vm.gloList),function(item,index){return _c('div',{key:index,staticClass:"glo-item",class:{
        'glo-item1': index == 0,
        'glo-item2': index == 1,
        'glo-item3': index == 2,
        'glo-item4': index == 3,
      }},[_c('div',{staticClass:"name",style:({ color: item.color })},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(item.desc))])])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"boxHeader"},[_c('h2',[_vm._v("覆盖多元行业及品类")]),_c('p',[_vm._v("多行业品类覆盖，丰富的服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"boxHeader"},[_c('h2',[_vm._v("一站定制，货通全球")]),_c('p',[_vm._v("从定制设计至生产发货如此简单")])])
}]

export { render, staticRenderFns }