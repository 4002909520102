<template>
  <div class="block">
    <el-carousel class="carousel" style="height: 526px;">
      <el-carousel-item v-for="(item, index) in imageList" :key="index">
        <img
          class="image"
          :style="{ background:'url(' +item.url + ')   center top no-repeat'}"
        />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import bg1 from "@/assets/images/advantage/banner.png"

import { mapGetters } from "vuex"

export default {
  data() {
    return {
      url: bg1,
      imageList: [
        {
          url: bg1,
        }
      ],
    }
  },
  computed: {
    ...mapGetters(["user", "token"]),
  },
  methods: {
    handleJump(path) {
      this.$router.push({
        path: path,
      })
    },
    goProduct() {
      if (this.token) {
        let routeUrl = this.$router.resolve({
          path: "/select/selection/list",
        })
        window.open(routeUrl.href, "_blank")
      } else {
        let routeUrl = this.$router.resolve({
          path: "/product_type",
        })
        window.open(routeUrl.href, "_blank")
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.block {
  margin-top: 4px;
  width: 100%;
  position: relative;
}
.image {
  width: 100%;
  height: 526px;
  background-size: cover !important;
}
.title1 {
  height: 54px;
  font-size: 39px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #181b1c;
  position: absolute;
  left: 19vw;
  top: 100px;
}
.title2 {
  height: 29px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #181b1c;
  left: 19vw;
  top: 170px;
  position: absolute;
}
.title3 {
  height: 54px;
  font-size: 20px;
  font-family: PingFang SC;
  color: #999999;
  position: absolute;
  left: 19vw;
  top: 226px;
}
.buttons {
  height: 54px;
  font-size: 20px;
  font-family: PingFang SC;
  color: #999999;
  position: absolute;
  left: 14vw;
  top: 278px;

  .button {
    padding: 12px 0 0 0;
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
  }
}
.design {
  width: 140px;
  height: 45px;
  background: #f2f4ff;
  border-radius: 6px;
  display: inline-block;
  text-align: center;
  margin-right: 30px;
  color: #146ff4;
  box-sizing: border-box;
  &:hover{
    opacity: 0.9;
  }
}
.sales {
  width: 140px;
  height: 45px;
  background: linear-gradient(270deg, #146ff4, #4892ff);
  border-radius: 6px;
  display: inline-block;
  text-align: center;
  color: #ffffff;
  box-sizing: border-box;
  &:hover{
    background: #4934b2;
    border-color: #8071c9;
  }
}

.carousel ::v-deep .el-carousel__container {
  height: 485px;

}

.carousel ::v-deep .el-carousel__item {
  left: -1px;
  width: 100vw;
}

.carousel ::v-deep .el-carousel__indicators {
  display: none;
}

</style>
