<template>
  <div
    class="common-topbar-user-info-dropdown"
    :class="{ 'common-topbar-white-style': custom === 'white' }"
  >
    <div>
      <div class="top">
        <div class="top-line">
          <a class="user-uid">{{ userExt.userName }}</a>
          <div style="line-height:1.5;display:inline-block;">
            <button class="account-button">
              {{ accountName }}
            </button>
          </div>
        </div>
      </div>
      <div class="middle">
        <div class="middle-line">
          <a @click="handleJump('/system/account/userInfo')"
            ><span class="left-text"><i class="el-icon-money"></i>余额</span
            ><span id="topbar-prepay-money" class="right-text"
              >￥{{ userExt.balance }}</span
            ></a
          ><a class="charge-btn" @click="handleJump('/system/account/recharge')"
            >充值</a
          >
        </div>
        <div class="break-line"></div>
        <a class="middle-line" @click="handleJump('/map/map_mng/imageMe')"
          ><i class="el-icon-bangzhu"></i
          ><span class="left-text">我的图库</span></a
        ><a class="middle-line" @click="handleJump('/select/selection/list')"
          ><i class="el-icon-shopping-bag-1"></i
          ><span class="left-text">定制产品</span
          ><span
            id="topbar-instance-count"
            class="right-text circular topbar-instance-count"
          ></span
        ></a>
        <a class="middle-line" @click="handleJump('/order/myOrder/order')"
          ><i class="el-icon-document"></i
          ><span class="left-text">订单列表</span
          ><span
            id="topbar-unpayed-order-count"
            class="right-text circular"
          ></span
        ></a>
        <a class="middle-line" @click="handleJump('/system/account/recharge')"
          ><i class="el-icon-chat-line-round"></i
          ><span class="left-text">未读消息</span
          ><span id="topbar-message-count" class="right-text circular"
            >0</span
          ></a
        >
      </div>
      <div class="bottom-line">
        <!-- <div class="wo-management">
          <a href="https://workorder.console.aliyun.com/#/ticket/list/"
            >工单管理</a
          >
        </div> -->
        <div class="logout-btn">
          <a @click.prevent="handleLogOut">退出</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { getUserProfile } from "@/api/system/user"

export default {
  data() {
    return {
      userExt: {},
    }
  },
  props: {
    custom: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["user"]),
    accountName() {
      if (this.user.userType == "00") {
        return "管理员"
      } else if (this.user.userType == "01") {
        return "主账号"
      } else {
        return "子账号"
      }
    },
  },
  methods: {
    handleLogOut() {
      this.$confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store.dispatch("LogOut").then(() => {
          location.href = "index"
        })
      })
    },
    handleJump(path) {
      this.$router.push({ path: path })
    },
    getUserProfile() {
      getUserProfile().then((res) => {
        this.userExt = res.user
      })
    },
  },
  mounted() {
    this.getUserProfile()
  },
}
</script>

<style lang="scss" scoped>
.common-topbar-user-info-dropdown {
  position: absolute;
  right: -10px;
  top: 50px;
  width: 240px;
  background: #ffffff;
  color: rgb(73, 52, 178);
  display: none;
  z-index: 99999;
  border-left: 1px solid #aaaaaa;
  border-right: 1px solid #aaaaaa;
  border-bottom: 1px solid #aaaaaa;
}

.common-topbar-user-info-dropdown a {
  display: block;
  text-align: left;
  color: rgb(73, 52, 178);
}

.common-topbar-user-info-dropdown .top {
  background: #ffffff;
  padding: 12px 16px;
}

.common-topbar-user-info-dropdown .top .ram-info .ram-alias,
.common-topbar-user-info-dropdown .top .ram-info .ram-title {
  width: 150px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
}

.common-topbar-user-info-dropdown .top .ram-info .ram-title {
  color: #fff;
}

.common-topbar-user-info-dropdown .top .ram-info .ram-alias {
  width: 200px;
  color: #73777a;
}

.common-topbar-user-info-dropdown .top .ram-info .ram-label {
  position: absolute;
  right: 16px;
  top: 12px;
  color: #fff;
  background: #1280aa;
  border-radius: 2px;
  padding: 2px 4px;
  font-size: 12px;
}

.common-topbar-user-info-dropdown .top .top-line {
  height: 24px;
  line-height: 24px;
}

.common-topbar-user-info-dropdown .top .top-line.short {
  height: 18px;
  line-height: 18px;
  margin-top: 4px;
}

.common-topbar-user-info-dropdown .top .user-uid {
  display: inline-block;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.common-topbar-user-info-dropdown .top .user-grade {
  float: right;
  display: block;
  width: 40px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  background: #373d41;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  color: rgb(73, 52, 178);
  transition: all 0.3s ease-in-out;
}

.common-topbar-user-info-dropdown .top .user-grade:hover {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  background: #41484c;
}

.common-topbar-user-info-dropdown .top .certified {
  color: #35b34a;
  font-size: 12px;
}

.common-topbar-user-info-dropdown .top .not-certified {
  color: rgb(73, 52, 178);
}

.common-topbar-user-info-dropdown .big-customer-info {
  height: 32px;
  line-height: 32px;
  color: #fff;
  background: rgb(73, 52, 178);
  font-size: 12px;
  padding-left: 16px;
}

.common-topbar-user-info-dropdown .big-customer-info .big-customer-info-link {
  display: inline-block;
}

.common-topbar-user-info-dropdown
  .big-customer-info
  .big-customer-info-link:active,
.common-topbar-user-info-dropdown
  .big-customer-info
  .big-customer-info-link:hover {
  color: #fff;
  text-decoration: underline;
}

.common-topbar-user-info-dropdown .big-customer-info img,
.common-topbar-user-info-dropdown .info-prefix-icon {
  width: 16px;
  vertical-align: text-bottom;
  margin-right: 8px;
}

.common-topbar-user-info-dropdown .middle-line {
  height: 20px;
  line-height: 20px;
  padding: 0 16px;
  margin: 12px 0;
  font-size: 12px;
  position: relative;
  cursor: pointer;
}

.common-topbar-user-info-dropdown .middle-line .right-text {
  float: right;
  font-size: 10px;
  color: #fff;
}

.common-topbar-user-info-dropdown .middle-line .right-text.circular {
  background: #ff5029;
  border-radius: 10px;
  transform: scale(0.8);
  padding: 0 6px;
  color: #fff;
}

.common-topbar-user-info-dropdown .middle-line .charge-btn {
  box-sizing: content-box !important;
  display: inline-block;
  border: 1px solid #ffa133;
  width: 40px;
  height: 20px;
  line-height: 20px;
  color: #ffa133;
  text-align: center;
  position: absolute;
  top: -1px;
  left: 75px;
  cursor: pointer;
}

.common-topbar-user-info-dropdown .middle-line .charge-btn:hover {
  background: #ffa133;
  color: #202529;
}
.common-topbar-user-info-dropdown .middle-line i {
  margin: 0 4px 0 0;
}

.common-topbar-user-info-dropdown .break-line {
  height: 1px;
  margin: 0 16px;
  background: #373d41;
}

.common-topbar-user-info-dropdown .bottom-line {
  overflow: hidden;
  padding: 12px 16px 16px;
}

.common-topbar-user-info-dropdown .bottom-line .wo-management {
  font-size: 14px;
  letter-spacing: 0.58px;
  line-height: 24px;
  float: left;
}

.common-topbar-user-info-dropdown .bottom-line .logout-btn {
  box-sizing: content-box !important;
  font-size: 12px;
  width: 70px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  float: right;
  border: 1px solid #73777a;
  cursor: pointer;
}

.common-topbar-user-info-dropdown .bottom-line .logout-btn:hover {
  border: 1px solid #4934b2;
}

.common-topbar-user-info-dropdown .bottom-line .logout-btn a {
  text-align: center;
}

.common-topbar-user-info-dropdown .bottom-line .logout-btn.ram {
  color: #fff;
  border: none;
  height: 20px;
  line-height: 20px;
}

.common-topbar-user-info-dropdown .bottom-line .logout-btn.ram a {
  color: #fff;
}

.common-topbar-user-info-dropdown .bottom-line .logout-btn.ram a:hover {
  color: #00b7d3;
}

.common-topbar-user-info-dropdown .bottom-line a,
.common-topbar-user-info-dropdown .bottom-line a:link,
.common-topbar-user-info-dropdown .bottom-line a:visited {
  color: #73777a;
}

.common-topbar-user-info-dropdown .bottom-line a:hover {
  color: rgb(73, 52, 178);
}

.common-topbar-all-nav-dropdown {
  color: #fff;
  height: 400px;
  position: relative;
  display: none;
  min-width: 1000px;
  position: absolute;
  z-index: 999;
  top: 50px;
}

.common-topbar-all-nav-dropdown.active {
  display: block;
}

.common-topbar-all-nav-dropdown .inner-text {
  display: block;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  padding: 6px 20px;
  cursor: pointer;
}

.common-topbar-all-nav-dropdown .inner-text:first-child {
  padding-top: 0;
}

.common-topbar-all-nav-dropdown .inner-text:last-child {
  padding-bottom: 0;
}

.common-topbar-all-nav-dropdown .inner-text.active,
.common-topbar-all-nav-dropdown .inner-text:hover {
  color: rgb(73, 52, 178);
}

.common-topbar-all-nav-dropdown .inner-text .inner-text-img {
  width: 12px;
  height: 12px;
  right: 0;
  bottom: -1px;
}

.common-topbar-all-nav-dropdown .title-text {
  display: block;
  height: 24px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 600;
  padding: 0 20px 8px;
}

.common-topbar-all-nav-dropdown .higher-text {
  display: block;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  padding: 0 20px;
  cursor: pointer;
  position: relative;
}

.common-topbar-all-nav-dropdown .higher-text a {
  position: relative;
  display: block;
  z-index: 2;
  text-align: left !important;
}

.common-topbar-all-nav-dropdown .higher-text:after {
  content: "";
  position: absolute;
  top: 0;
  left: 10px;
  bottom: 0;
  right: 20px;
}

.common-topbar-all-nav-dropdown .higher-text:hover {
  color: rgb(73, 52, 178);
}

.common-topbar-all-nav-dropdown .higher-text:hover:after {
  background: hsla(0, 0%, 100%, 0.05);
}

.common-topbar-all-nav-dropdown .space-line {
  margin: 12px 20px;
  height: 1px;
  opacity: 0.2;
  background: #fff;
}

.common-topbar-all-nav-dropdown .icon-indicator {
  float: right;
  margin-top: 5px;
}

.common-topbar-all-nav-dropdown .level-inner {
  margin-right: -40px;
  padding-right: 40px;
  overflow-y: scroll;
  height: 100%;
}

.common-topbar-all-nav-dropdown .common-topbar-level1-nav {
  background: #272b2e;
  width: 200px;
  height: 100%;
  box-sizing: border-box;
  border-right: 1px solid hsla(0, 0%, 100%, 0.15);
  padding: 20px 0;
  position: absolute;
  z-index: 4;
  display: none;
  overflow: hidden;
}

.common-topbar-all-nav-dropdown .common-topbar-level1-nav ul {
  width: 200px;
}

.common-topbar-all-nav-dropdown .common-topbar-level1-nav ul li a {
  display: block;
  text-align: left !important;
}

.common-topbar-all-nav-dropdown .common-topbar-level1-nav ul li.active a {
  color: rgb(73, 52, 178);
}

.common-topbar-all-nav-dropdown .common-topbar-level1-nav.active {
  display: block;
}

.common-topbar-all-nav-dropdown .common-topbar-level1-nav.slide-in {
  animation: level1NavSlidein 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.common-topbar-all-nav-dropdown .common-topbar-level1-nav.slide-out {
  animation: level1NavSlideout 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.common-topbar-all-nav-dropdown .common-topbar-level1-nav .dock-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 18px;
  line-height: 18px;
  padding-top: 10px;
  padding-left: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  background: #272b2e;
}

.common-topbar-all-nav-dropdown .common-topbar-level1-content {
  display: none;
  width: 320px;
  height: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  background: #303538;
  position: absolute;
  left: 200px;
  z-index: 3;
  overflow: hidden;
}

.common-topbar-all-nav-dropdown
  .common-topbar-level1-content.common-topbar-level2-nav {
  background: #272b2e;
  width: 200px;
}

.common-topbar-all-nav-dropdown
  .common-topbar-level1-content.common-topbar-level2-nav.slide-in {
  animation: leve1ContentSlidein 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.common-topbar-all-nav-dropdown
  .common-topbar-level1-content.common-topbar-level2-nav.slide-out {
  animation: leve1ContentSlideout 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.common-topbar-all-nav-dropdown
  .common-topbar-level1-content.common-topbar-level2-nav
  ul {
  width: 200px;
}

.common-topbar-all-nav-dropdown .common-topbar-level1-content.active {
  display: block;
}

.common-topbar-all-nav-dropdown .common-topbar-level1-content.slide-in {
  animation: leve1ContentSlideinExpand 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.common-topbar-all-nav-dropdown .common-topbar-level1-content.slide-out {
  animation: leve1ContentSlideoutExpand 0.1s cubic-bezier(0, 0, 0.2, 1);
}

.common-topbar-all-nav-dropdown .common-topbar-leve2-content {
  display: none;
  position: absolute;
  left: 400px;
  height: 100%;
  overflow: hidden;
}

.common-topbar-all-nav-dropdown .common-topbar-leve2-content.slide-out {
  animation: level2ContentSlideout 0.1s cubic-bezier(0, 0, 0.2, 1);
}

.common-topbar-all-nav-dropdown .common-topbar-leve2-content.slide-in {
  animation: level2ContentSlidein 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.common-topbar-all-nav-dropdown .common-topbar-leve2-content.expand.slide-out {
  animation: level2ContentSlideoutExpand 0.1s cubic-bezier(0, 0, 0.2, 1);
}

.common-topbar-all-nav-dropdown .common-topbar-leve2-content.expand.slide-in {
  animation: level2ContentSlideinExpand 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.common-topbar-all-nav-dropdown .common-topbar-leve2-content.active {
  display: block;
}

.common-topbar-all-nav-dropdown
  .common-topbar-leve2-content.product-sidebar
  .level-inner {
  width: 1000px;
}

.common-topbar-all-nav-dropdown
  .common-topbar-leve2-content.product-sidebar
  .common-topbar-detail-list {
  width: 1000px;
  padding: 0;
}

.common-topbar-all-nav-dropdown
  .common-topbar-leve2-content
  .common-topbar-detail-list {
  background: #303538;
  width: 400px;
  height: 100%;
  box-sizing: border-box;
  border-right: 1px solid hsla(0, 0%, 100%, 0.15);
  float: left;
  padding: 20px 0;
  overflow: hidden;
}

.common-topbar-all-nav-dropdown
  .common-topbar-leve2-content
  .common-topbar-detail-list
  .bg-title-text {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
  margin-left: 20px;
  font-weight: 600;
}

.common-topbar-all-nav-dropdown
  .common-topbar-leve2-content
  .common-topbar-detail-list
  .content-wrapper {
  width: 370px;
  padding: 8px 10px;
  margin-left: 10px;
  margin-right: 20px;
  box-sizing: border-box;
}

.common-topbar-all-nav-dropdown
  .common-topbar-leve2-content
  .common-topbar-detail-list
  .content-wrapper
  .content-link {
  display: block;
  text-align: left !important;
}

.common-topbar-all-nav-dropdown
  .common-topbar-leve2-content
  .common-topbar-detail-list
  .content-wrapper
  .content-name {
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 20px;
}

.common-topbar-all-nav-dropdown
  .common-topbar-leve2-content
  .common-topbar-detail-list
  .content-wrapper
  .content-description {
  font-size: 12px;
  color: #9b9ea0;
  line-height: 20px;
}

.common-topbar-all-nav-dropdown
  .common-topbar-leve2-content
  .common-topbar-detail-list
  .content-wrapper
  .content-child {
  overflow: hidden;
}

.common-topbar-all-nav-dropdown
  .common-topbar-leve2-content
  .common-topbar-detail-list
  .content-wrapper
  .content-child
  a {
  display: block;
  width: 170px;
  height: 28px;
  padding-left: 12px;
  text-align: left;
  line-height: 28px;
  font-size: 12px;
  border: 1px solid #474f54;
  float: left;
  margin-right: 10px;
  margin-top: 4px;
  box-sizing: border-box;
}

.common-topbar-all-nav-dropdown
  .common-topbar-leve2-content
  .common-topbar-detail-list
  .content-wrapper
  .content-child
  a:nth-child(2n + 2) {
  margin-right: 0;
}

.common-topbar-all-nav-dropdown
  .common-topbar-leve2-content
  .common-topbar-detail-list
  .content-wrapper
  .content-child
  a:hover {
  border: 1px solid rgb(73, 52, 178);
  color: rgb(73, 52, 178);
  text-decoration: none;
}

.common-topbar-all-nav-dropdown
  .common-topbar-leve2-content
  .common-topbar-detail-list
  .content-wrapper:hover {
  background: hsla(0, 0%, 100%, 0.05);
}

.common-topbar-all-nav-dropdown
  .common-topbar-leve2-content
  .common-topbar-detail-list
  .content-wrapper:hover
  .content-name {
  color: rgb(73, 52, 178);
}

.common-topbar-all-nav-dropdown
  .common-topbar-leve2-content
  .common-topbar-detail-list
  .content-wrapper:hover
  .content-description {
  color: #9b9ea0;
}

.common-topbar-all-nav-dropdown
  .common-topbar-leve2-content
  .common-topbar-detail-list
  .sub-content-title {
  font-size: 12px;
  color: #fff;
  line-height: 20px;
  margin-left: 20px;
  margin-top: 16px;
  font-weight: 600;
}

.common-topbar-all-nav-dropdown
  .common-topbar-leve2-content
  .common-topbar-relevant-list {
  float: left;
  background-color: #303538;
  background-position-y: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  width: 280px;
  height: 100%;
  box-sizing: border-box;
  padding: 20px 0;
  overflow: hidden;
}

.common-topbar-all-nav-dropdown
  .common-topbar-leve2-content
  .common-topbar-relevant-list
  .relavant-title {
  font-size: 14px;
  color: #9b9ea0;
  padding: 0 20px;
}

.common-topbar-all-nav-dropdown
  .common-topbar-leve2-content
  .common-topbar-relevant-list
  a {
  display: block;
  font-size: 12px;
  line-height: 24px;
  padding: 4px 20px;
}

.common-topbar-all-nav-dropdown
  .common-topbar-leve2-content
  .common-topbar-relevant-list
  a:first-child {
  padding-top: 0;
}

.common-topbar-all-nav-dropdown
  .common-topbar-leve2-content
  .common-topbar-relevant-list
  a:last-child {
  padding-bottom: 20px;
}

.common-topbar-all-nav-dropdown
  .common-topbar-leve2-content
  .common-topbar-relevant-list
  a:hover {
  color: rgb(73, 52, 178);
}

.common-topbar-all-nav-dropdown .common-topbar-content {
  width: 1020px;
}

@media (max-width: 1124px) and (min-width: 1004px) {
  .common-topbar-dropdown .common-topbar-dropdown-category-container.column-5 {
    padding-left: 0 !important;
  }
}

@media (max-width: 1004px) {
  .common-topbar-dropdown .common-topbar-dropdown-category-container.column-5 {
    padding-left: 0 !important;
  }

  .common-topbar-dropdown
    .common-topbar-dropdown-category-container.column-5
    .common-topbar-dropdown-category-list {
    float: none;
  }

  .common-topbar-dropdown
    .common-topbar-dropdown-category-container.column-5
    .common-topbar-dropdown-category-list
    .category-item {
    width: 18.67219917% !important;
    margin-right: 1.65975104% !important;
  }

  .common-topbar-dropdown
    .common-topbar-dropdown-category-container.column-5
    .common-topbar-dropdown-category-list
    .category-item:last-child {
    margin-right: 0 !important;
  }
}

@media (max-width: 1320px) and (min-width: 1200px) {
  .common-topbar-dropdown .common-topbar-dropdown-category-container.column-6 {
    padding-left: 0 !important;
  }
}

@media (max-width: 1200px) {
  .common-topbar-dropdown .common-topbar-dropdown-category-container.column-6 {
    padding-left: 0 !important;
  }

  .common-topbar-dropdown
    .common-topbar-dropdown-category-container.column-6
    .common-topbar-dropdown-category-list {
    float: none;
  }

  .common-topbar-dropdown
    .common-topbar-dropdown-category-container.column-6
    .common-topbar-dropdown-category-list
    .category-item {
    width: 15.51724138% !important;
    margin-right: 1.37931034% !important;
  }

  .common-topbar-dropdown
    .common-topbar-dropdown-category-container.column-6
    .common-topbar-dropdown-category-list
    .category-item:last-child {
    margin-right: 0 !important;
  }
}
.common-topbar-body a,
.common-topbar-body a:link,
.common-topbar-body a:visited {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}
a:hover {
  color: #4934b2;
}
.common-topbar-white-style {
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgb(68 68 68 / 49%);
  font-size: 14px;
  .top {
    background: #ffffff;
    padding: 8px 0 0 14px;
    text-align: left;
  }
  a {
    color: rgb(89, 90, 90);
  }
  .middle-line {
    font-size: 12px;
    cursor: pointer;
  }
  .middle-line .right-text {
    color: rgb(89, 90, 90);
  }
  .middle-line i {
    margin: 0 4px 0 0;
  }

  .middle-line .charge-btn {
    box-sizing: content-box !important;
    display: inline-block;
    border: 1px solid #ffa133;
    width: 40px;
    height: 20px;
    line-height: 20px;
    color: #ffa133;
    text-align: center;
    position: absolute;
    top: -1px;
    left: 75px;
    cursor: pointer;
  }

  .middle-line .charge-btn:hover {
    background: #ffa133;
    color: #ffffff;
  }
}
.account-button {
  cursor: default;
  color: #ffffff;
  background-color: rgb(73, 52, 178);
  border: 1px solid var(--cb-color-border-accent, rgb(73, 52, 178));
  margin: -16px 8px 0px 8px;
  padding: 0px 8px;
  border-radius: 100px;
  line-height: 1.7em;
  font-size: 0.95em;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
  width: auto;
}
</style>
