import request from '@/utils/request'

// 查询公告管理列表
export function getHelpList(data) {
  return request({
      url: '/server/help/list',
      method: 'get',
      params: data
  })
}

// 导出公告管理列表
export function exportHelpList(data) {
  return request({
      url: '/server/help/export',
      method: 'get',
      params: data
  })
} 

// 获取公告管理详细信息
export function getHelp(data) {
  return request({
      url: '/server/help/getInfo/'+data,
      method: 'get',
      data: data
  });
}

// 新增公告管理
export function addHelp(data) {
  return request({
      url: '/server/help/add',
      method: 'post',
      data: data
  });
}

// 修改公告管理
export function updateHelp(data) {
  return request({
      url: '/server/help/edit',
      method: 'post',
      data: data
  });
}

// 删除公告管理
export function deleteHelp(data) {
  return request({
      url: '/server/help/delete/' + data,
      method: 'post',
      data: data
  });
}
