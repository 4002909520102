<template>
  <div class="rno-cert">
    <div class="rno-action-panel rno-action-panel-custom-bg">
      <div class="rno-action-panel-bgs rno-action-panel-bgs-responsive">
        <div
          class="rno-action-panel-bg-pc"
          style='background-image: url("//main.qcloudimg.com/raw/119a10c00081b7a999e2b264c289930a/bg2.jpg"); background-color: rgb(0, 137, 255)'
        ></div>
        <div
          class="rno-action-panel-bg-mobile"
          style='background-image: url("//main.qcloudimg.com/raw/0c9ccdd01a0868cc24454aee80cff190/bg2-mobile.jpg")'
        ></div>
      </div>
      <div class="rno-action-panel-inner">
        <h3 class="rno-action-panel-title">开始体验免费套餐</h3>
        <p class="rno-action-panel-desc">
          立即
          <a href="/register">注册</a>
          领取40+款产品的免费体验套餐，更有
          入门中心
          提供简明指导教程，伴您快速上手
        </p>
        <p class="rno-action-panel-desc mobile">
          <i>免费领取40+款产品的免费体验套餐</i>腾讯云为您提供无忧的上云体验机会
        </p>
        <div class="rno-action-panel-btns">
          <a
            hotrep="home.connect-us.free.btn"
            href="/register"
            class="rno-btn rno-btn-white-hole"
            ><span class="rno-btn-text">免费体验</span></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
