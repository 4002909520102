import request from "@/utils/request"
//采集器模块

// 加载表格数据
export function getCollList(params) {
  return request({
    url: "/server/coll/list",
    method: "get",
    params: params,
  })
}

// 认领采集数据
export function getColl(data) {
  return request({
    url: "/server/coll/getTemu",
    method: "post",
    data: data,
  })
}

// 加载表格数据
export function getCollInfo(params) {
  return request({
    url: "/server/coll/getInfo/" + params,
    method: "get",
  })
}

//保存数据采集
export function editColl(data) {
  return request({
    url: "/server/coll/edit",
    method: "POST",
    data: data,
  })
}
//保存采集数据并认领
export function editGetColl(data) {
  return request({
    url: "/server/coll/editAndGet",
    method: "POST",
    data: data,
  })
}
export function deleteColl(data) {
    return request({
      url: "/server/coll/delete/" + data,
      method: "POST",
    })
  }
//新增数据采集
export function startColl(data) {
  return request({
    url: "/server/coll/start",
    method: "POST",
    data: data,
  })
}
//新增数据采集
export function getImage(data) {
  return request({
    url: "/server/coll/getImage/" + data,
    method: "GET",
  })
}
//新增数据采集
export function queryKeyWords(data) {
  return request({
    url: "/keywords/queryKeyWords",
    method: "POST",
    data: data,
    params: {
      pageNum: data.pageNum,
      pageSize: data.pageSize
    }
  })
}
//获取采集图库
export function queryPicList(params) {
  return request({
    url: "/server/coll/picList",
    method: "GET",
    params: params
  })
}
//获取采集数据
export function queryListData(params) {
  return request({
    url: "/server/html/list",
    method: "GET",
    params: params
  })
}