<template>
  <div
    class="register-2017-body"
    data-spm-anchor-id="a2c44.11131956.0.i2.168a5455pOElMx"
  >
    <div class="register-width iframe-loaded" id="register">
      <div class="login-box">
        <div id="container" data-spm-anchor-id="0.0.0.i0.a10834dc9MfOkc">
          <div data-reactroot="" class="schema-form">
            <div class="title-box">
              <div class="head">用户登录</div>
            </div>

            <div class="title-box">
              <el-radio
                @change="handleChangeLoginType"
                v-model="loginType"
                label="1"
                >密码登录</el-radio
              >
              <el-radio
                @change="handleChangeLoginType"
                v-model="loginType"
                label="2"
                >短信登录</el-radio
              >
            </div>
            <div class="next-form next-form-left ver next-form-large">
              <div class="form-content">
                <el-form
                  ref="loginForm"
                  :model="loginForm"
                  :rules="loginRules"
                  label-width="0px"
                >
                  <el-form-item prop="username" label="">
                    <el-input
                      v-model="loginForm.username"
                      :placeholder="
                        loginType == '1' ? '会员名/手机号' : '手机号'
                      "
                    >
                      <template slot="prepend"
                        ><i class="el-icon-user"></i
                      ></template>
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    v-if="loginType == '1'"
                    prop="password"
                    label=""
                  >
                    <el-input
                      type="password"
                      v-model="loginForm.password"
                      placeholder="登录密码"
                      show-password
                    >
                      <template slot="prepend"
                        ><i class="el-icon-lock"></i
                      ></template>
                    </el-input>
                  </el-form-item>

                  <el-form-item prop="code" label="">
                    <el-input
                      style="width: 156px; float: left"
                      v-model="loginForm.code"
                      placeholder="验证码"
                      @keyup.enter.native="handleLogin"
                    ></el-input>
                    <img
                      :src="codeUrl"
                      @click="getCode"
                      style="float: left; height: 40px; margin-left: 8px"
                    />
                  </el-form-item>

                  <el-form-item v-if="loginType == '2'" prop="smsCode">
                    <el-input
                      v-model="loginForm.smsCode"
                      placeholder="请输入短信验证码"
                    >
                      <template slot="prepend"
                        ><i class="el-icon-edit"></i
                      ></template>
                      <template slot="append"
                        ><span
                          v-if="!isSend"
                          style="cursor: pointer"
                          @click="handleSendMessage"
                          >发送验证码</span
                        ><span v-if="isSend">{{ remain }} s</span></template
                      >
                    </el-input>
                  </el-form-item>

                  <el-form-item v-if="showMeta" prop="code" label="">
                    <el-checkbox v-model="checked"> </el-checkbox>
                    <span
                      ><a
                        href="https://sellercentral.amazon.com/mws/static/policy?documentType=AUP&locale=zh_CN"
                        target="_blank"
                        >亚马逊可接受使用政策</a
                      >和
                      <a
                        href="https://sellercentral.amazon.com/mws/static/policy?documentType=DPP&locale=zh_CN"
                        target="_blank"
                        >亚马逊数据保护政策</a
                      ></span
                    >
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      @click="handleLogin"
                      style="width: 100%;background-color:rgb(73,52,178);border:1px solid rgb(73,52,178)"
                      type="primary"
                      >立即登录</el-button
                    >
                  </el-form-item>
                </el-form>
                <div class="form-footer">
                  <a
                    href="javascript:void(0)"
                    @click="handleJump('/reset')"
                    style="margin-right: 24px;color: rgb(73,52,178);"
                    >忘记密码</a
                  ><a
                    href="javascript:void(0)"
                    @click="handleJump('/register')"
                    class=""
                    style="color: rgb(73,52,178);"
                    >免费注册</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCodeImg, sendSms } from "@/api/login"
import Cookies from "js-cookie"
import { encrypt, decrypt } from "@/utils/jsencrypt"
import { getDictData } from "@/api/system/dict/data"

export default {
  data() {
    var checkAliaName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入名称"))
      } else {
        let pattern = new RegExp("^[0-9]{11}$")
        if (!pattern.test(value) && this.loginType == "2") {
          return callback(new Error("请输入正确的手机号"))
        }
      }
      callback()
    }
    return {
      codeUrl: "",
      cookiePassword: "",
      loginForm: {
        smsCode: "",
        username: "",
        password: "",
        code: "",
        rememberMe: false,
        uuid: "",
        userType: "merchant",
      },
      loginRules: {
        username: [
          {
            required: true,
            trigger: "blur",
            validator: checkAliaName,
          },
        ],
        password: [
          { required: true, trigger: "blur", message: "密码不能为空" },
        ],
        code: [
          { required: true, trigger: "change", message: "验证码不能为空" },
        ],
      },
      loading: false,
      checked: false,
      loginType: "1",
      isSend: false,
      remain: 60,
    }
  },
  computed: {
    showMeta() {
      if (document.location.host == "chuanghe.onecust.com") {
        return true
      } else {
        return false
      }
    },
  },
  created() {
    this.getCode()
    this.getCookie()
    sessionStorage.clear()
    if (this.$route.path.indexOf("admin") > -1) {
      this.loginForm.userType = "admin"
    } else {
      this.loginForm.userType = "merchant"
    }
  },
  methods: {
    getCode() {
      getCodeImg().then((res) => {
        this.codeUrl = "data:image/gif;base64," + res.img
        this.loginForm.uuid = res.uuid
      })
    },
    getCookie() {
      const username = Cookies.get("username")
      const password = Cookies.get("password")
      const rememberMe = Cookies.get("rememberMe")
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password:
          password === undefined ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
      }
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.loginForm.rememberMe) {
            Cookies.set("username", this.loginForm.username, { expires: 365 })
            Cookies.set("password", encrypt(this.loginForm.password), {
              expires: 365,
            })
            Cookies.set("rememberMe", this.loginForm.rememberMe, {
              expires: 365,
            })
          } else {
            Cookies.remove("username")
            Cookies.remove("password")
            Cookies.remove("rememberMe")
          }
          this.loginForm.loginType = this.loginType
          this.$store
            .dispatch("Login", this.loginForm)
            .then((userType) => {
              console.log(userType)
              if (userType == "admin") {
                this.$router.push({ path: "/welcome" })
              } else {
                this.$router.push({ path: "/index" })
              }
              getDictData().then((result) => {
                this.$store.commit("SET_DICT_DATA", result.data)
              })
            })
            .catch(() => {
              this.loading = false
              if (this.loginForm.loginType == "1") {
                this.getCode()
              }
            })
        }
      })
    },
    handleChangeLoginType() {
      this.$refs.loginForm.clearValidate()
    },
    regist() {
      this.$message.error("还未实现此功能！")
    },
    handleJump(path) {
      this.$router.push({
        path: path,
      })
    },

    async handleSendMessage() {
      try {
        if (this.isSend) {
          return
        }
        let pattern = new RegExp("^[0-9]{11}$")
        if (!pattern.test(this.loginForm.username) && this.loginType == "2") {
          this.msgError("请输入正确的手机号")
          return
        }
        let _self = this
        let result = await sendSms({
          phoneNumber: this.loginForm.username,
          imgCode: this.loginForm.code,
          uuid: this.loginForm.uuid,
          type: "4",
        })
        if (result.code == 200) {
          let timer = window.setInterval(() => {
            _self.remain = Number(_self.remain) - 1
            if (this.remain == 0) {
              window.clearInterval(timer)
              _self.remain = 60
              this.isSend = false
            }
          }, 1000)
          this.isSend = true
          this.msgSuccess("短信发送成功")
        } else {
          this.getCode()
        }
      } catch (error) {}
    },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
#alibaba-register-box {
  background-color: #f5f5f6;
}
.register-2017-body {
  padding: 80px 0 0 0;
  text-align: center;
  margin: auto;
  width: 100%;
  border: none;
  height: 660px;
  background-image: url("~@/assets/images/index/login_bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 1600px) {
      background-image: url("~@/assets/images/index/login_bg_s.png");    
  }
  ::v-deep .el-input-group__prepend {
    padding: 0 12px;
    font-size: 18px;
  }  

}
#register {
  margin: auto;
  width: 1200px;
  border: none;
  background-color: #fff;
  margin-top: 60px;
}

.title-box {
  position: relative;
  margin: 0 auto;
}
.head {
  padding-top: 24px;
  font-size: 16px;
  color: white;
  letter-spacing: 1px;
  line-height: 36px;
  text-align: left;
  padding: 16px 8px 0 8px;
  margin: 0 0 2px 0;
}
.tips {
  position: absolute;
  top: 43px;
  right: 40px;
  font-size: 12px;
  color: #73777a;
  letter-spacing: 0.4px;
  line-height: 30px;
  text-align: left;
}
#container {
  position: relative;
  border: 1px solid transparent;
  background: rgba(0, 0, 0, 0.5);
}
.schema-form {
  width: 300px;
  margin: 0 auto;
}
.next-form {
  color: #333;
  font-size: 12px;
}
.next-form-item {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 18px;
}
.next-form .next-row {
  padding: 0;
}
.next-input {
  display: inline-table;
  border-collapse: separate;
  overflow: visible;
  border: 1px solid #ededed;
  width: 200px;
  border-spacing: 0;
  background-color: #fff;
  transition: all 0.3s ease-out;
}
.next-row .next-col-24 {
  width: 100%;
  max-width: 100%;
  -moz-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}
.next-input input,
.next-input textarea {
  width: 100%;
  border: none;
  outline: none;
  padding: 0;
  font-weight: 400;
  vertical-align: baseline;
  background-color: transparent;
}
.next-input-large input {
  height: 38px;
  line-height: 38px \0;
  margin: 0;
  padding: 0 12px;
  font-size: 12px;
}
.next-input.next-input-single {
  border-color: #ebeef5;
}
.next-form-item-control > .next-input,
.next-form-item-control > .next-input-group {
  width: 100%;
}
.next-form,
.next-form *,
.next-form :after,
.next-form :before {
  box-sizing: border-box;
}
.form-content {
  padding: 20px 12px 32px 12px;
}
.login-box {
  width: 310px;
  height: 100%;
  float: right;
  overflow: hidden;
  box-shadow: 0 2px 15px rgb(0 0 0 / 15%);
}
.form-footer {
  text-align: center;
}
.form-footer a {
  color: #4934b2;
  text-decoration: none;
}
.title-box ::v-deep .el-radio__input.is-checked+.el-radio__label{
  color: rgb(73,52,178);
}

.title-box ::v-deep  .el-radio__input.is-checked .el-radio__inner {
    border-color: rgb(73,52,178);
    background: rgb(73,52,178);
}
</style>
