// 系统支撑-分类管理
import request from '@/utils/request'

//查询产品分类信息列表
export function loadTypeData(data) {
    return request({
      url: '/server/cp/type/list',
      method: 'get',
      params: data
    });
}

//获取产品分类信息详细信息
export function getTypeInfo(data) {
  return request({
    url: '/server/cp/type/getInfo/'+ data.typeId,
    method: 'get',
    params: data
  });
}
  
//获取产品分类树
export function getTypeTree(data) {
  return request({
    url: '/server/cp/type/tree',
    method: 'get',
    params: data
  });
}

//获取产品分类树列表
export function getTypeTreeList(data) {
  return request({
    url: '/server/cp/type/listTree',
    method: 'get',
    params: data
  });
}
 
// 新增产品分类信息
export function addType(data) {
    return request({
        url: '/server/cp/type/add',
        method: 'post',
        data: data
    })
}
// 修改产品分类信息
export function updateType(data) {
    return request({
        url: '/server/cp/type/edit',
        method: 'post',
        data: data
    })
}
// 删除产品分类信息
export function deleteType(data) {
  return request({
      url: '/server/cp/type/delete/'+ data,
      method: 'post',
      data: data
  })
}

// 删除产品分类信息
export function getPlatPath(platId) {
  return request({
      url: '/server/cp/type/route/' + platId,
      method: 'get',
  })
}






