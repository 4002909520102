import request from "@/utils/request"

// 新增购物车
export function addCart(data) {
  return request({
    url: "/server/cart/add",
    method: "post",
    data: data,
  })
}

// 查询购物车列表
export function listCart() {
  return request({
    url: "/server/cart/list",
    method: "get",
  })
}

// 修改购物车sku
export function updateCart(data) {
  return request({
    url: "/server/cart/editSku",
    method: "post",
    data: data,
  })
}

// 修改购物车数量
export function updateCartCount(data) {
  return request({
    url: "/server/cart/editNum",
    method: "post",
    data: data,
  })
}

// 删除购物车
export function delCart(cartIds) {
  return request({
    url: `/server/cart/delete/${cartIds}`,
    method: "post",
  })
}

// 首页统计数据
export function getIndexStat() {
  return request({
    url: "/comm/index/stat",
    method: "GET",
  })
}

// 购物车选中与取消 单选
export function selectCart(data) {
  return request({
    url: "/server/cart/select",
    method: "POST",
    data: data,
  })
}
