const repository = {
    state:{
        treeSelectData:[],//订单状态集合（字典数据）
        repositoryList:[],//仓库数据集合
    },
    mutations:{
        setTreeSelectData:(state,data) =>{
            state.treeSelectData = data;
        },
        setRepositoryList:(state,data) => {
            state.repositoryList = data;
        }
    },
    actions:{

    }
}

export default repository
