const product = {
  state: {
    commFun: {
      totalNum: 0, //总数
      unClaimed: 0, //未认领数量
      claimed: 0, //已认领数量
    },
    lazada: {
      total: {
        releasing: 0,
        releaseFail: 0,
        TimeInterval: 0,
      },
      currentTime: null,
      currentTime2: null,
      currentTime3: null,
    },
    amazon: {
      total: {
        collBox: 0,
        waitToRelease: 0,
        releasing: 0,
        releaseFail: 0,
        timeInterval: 0,
      },
      ifReload: false, //是否重新加载数据
      shopList: [],
    },
    syncResult: {
      total: 0,
      success: 0,
      fail: 0,
      failList: []
    },
    syncStatus: true
  },
  mutations: {
    SET_COMMFUN_STATE_NUM(state, val) {
      state.commFun.total = val.total
      state.commFun.unClaimed = val.unClaimed
      state.commFun.claimed = val.claimed
    },
    SET_SYNC_RESULT(state, val) {
      state.syncResult.total = val.total
      state.syncResult.success = val.success
      state.syncResult.fail = val.fail
      state.syncResult.failList = val.failList
    },
    SET_SYNC_STATUS(state, val){
      state.syncStatus = val
    },
    SET_RELEASING_NUM(state, releasing) {
      state.lazada.total.releasing = releasing
    },
    SET_RELEASEFAILD_NUM(state, releaseFail) {
      state.lazada.total.releaseFail = releaseFail
    },
    SET_INTERVAL_NUM(state, TimeInterval) {
      state.lazada.total.TimeInterval = TimeInterval
    },
    SET_CURRENTTIME(state, currentTime) {
      state.lazada.currentTime = currentTime
    },

    SET_AMAZON_COLLBOX_NUM(state, collBox) {
      state.amazon.total.collBox = collBox
    },
    SET_AMAZON_RELEASING_NUM(state, releasing) {
      state.amazon.total.releasing = releasing
    },
    SET_AMAZON_RELEASEFAILD_NUM(state, releaseFail) {
      state.amazon.total.releaseFail = releaseFail
    },
    SET_AMAZON_INTERVAL_NUM(state, TimeInterval) {
      state.amazon.total.TimeInterval = TimeInterval
    },
    SET_AMAZON_IFRELOAD(state, ifReload) {
      state.amazon.ifReload = ifReload
    },
    SET_AMAZON_NUM(state, arr) {
      state.amazon.total.collBox = 0
      state.amazon.total.waitToRelease = 0
      state.amazon.total.releasing = 0
      state.amazon.total.releaseFail = 0
      state.amazon.total.timeInterval = 0
      arr.forEach((item) => {
        if (item.pubStatus == "01") {
          //采集箱中商品数量
          state.amazon.total.collBox = item.num
        } else if (item.pubStatus == "02") {
          //待发布
          state.amazon.total.waitToRelease = item.num
        } else if (item.pubStatus == "03") {
          //发布中
          state.amazon.total.releasing = item.num
        } else if (item.pubStatus == "04") {
          //发布失败商品数量
          state.amazon.total.releaseFail = item.num
        } else if (item.pubStatus == "08") {
          //定时发布商品数量
          state.amazon.total.timeInterval = item.num
        }
      })
    },

    SET_SHOP_LIST(state, shopList) {
      state.amazon.shopList = shopList
    },
  },

  actions: {
    setCommFunStateNum({ commit }, val) {
      commit("SET_COMMFUN_STATE_NUM", val)
    },

    setReleaseNum({ commit }, val) {
      commit("SET_RELEASING_NUM", val)
    },
    setReleaseFailNum({ commit }, val) {
      commit("SET_RELEASEFAILD_NUM", val)
    },
    setTimeIntervalNum({ commit }, val) {
      commit("SET_INTERVAL_NUM", val)
    },
    setIfReload({ commit }, val) {
      commit("SET_AMAZON_IFRELOAD", val)
    },

    setAmazonNum({ commit }, val) {
      commit("SET_AMAZON_NUM", val)
    },
    setAmazonShopList({ commit }, val) {
      commit("SET_SHOP_LIST", val)
    },
  },
}

export default product
