import { login, loginAdmin, logout, getInfo,loginSms } from "@/api/login"
import { getToken, setToken, removeToken } from "@/utils/auth"
import { getAuthPlatform } from "@/api/product/common"
import { getIndexStat } from "@/api/order/cart"

const user = {
  state: {
    token: getToken(),
    name: "",
    primAcc: "",
    nickName: "",
    avatar: "",
    roles: [],
    permissions: [],
    authPlatform: [], //授权平台
    platCode: [], //平台代码数据
    shopList: [], //用户所有店铺数据
    stat: {
      cartCount: 0,
      messageCount: 8,
    },
    loginDate: "",
    loginIp: "",
    dayLimitNum: "",
    dayProductNum: "",
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_USER_TYPE: (state, userType) => {
      state.userType = userType
    },
    SET_PRIM_ACC: (state, primAcc) => {
      state.primAcc = primAcc
    },
    SET_NICKNAME: (state, nickName) => {
      state.nickName = nickName
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_AUTHPLATFORM: (state, authPlatfrom) => {
      state.authPlatform = authPlatfrom
    },
    SET_PLAT_CODE: (state, platCode) => {
      state.platCode = platCode
    },
    SET_SHOP_LIST: (state, shopList) => {
      state.shopList = shopList
    },
    SET_INDEX_STAT: async (state, stat) => {
      state.stat = stat
      state.stat.messageCount = 8
    },

    SET_LOGIN_DATA: async (state, data) => {
      state.loginDate = data.loginDate
      state.loginIp = data.loginIp

      state.location = data.location
    },
    SET_GEAR: (state, data) => {
      state.gear = data
    },
    SET_LIMIT_NUM: (state, data) => {
      state.dayLimitNum = data
    },
    SET_PRODUCT_NUM: (state, data) => {
      state.dayProductNum = data
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      let password = userInfo.password
      const code = userInfo.code
      const uuid = userInfo.uuid
      const userType = userInfo.userType

      const loginType = userInfo.loginType
      const smsCode = userInfo.smsCode
      let loginMethod = login
      if (userType == "admin") {
        loginMethod = loginAdmin
      }else if(loginType == '2'){
        loginMethod = loginSms
        password = smsCode
      }
      return new Promise((resolve, reject) => {
        loginMethod(username, password, code, uuid)
          .then((res) => {
            setToken(res.token)
            commit("SET_TOKEN", res.token)
            resolve(userType)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo(state.token)
          .then((res) => {
            const user = res.user
            const avatar =
              user.avatar == ""
                ? require("@/assets/images/profile.jpg")
                : user.avatar
            // const avatar = user.avatar == "" ? require("@/assets/images/profile.jpg") : process.env.VUE_APP_BASE_API + user.avatar;
            if (res.roles && res.roles.length > 0) {
              // 验证返回的roles是否是一个非空数组
              commit("SET_ROLES", res.roles)
              commit("SET_PERMISSIONS", res.permissions)
            } else {
              commit("SET_ROLES", ["ROLE_DEFAULT"])
            }
            commit("SET_LIMIT_NUM", user.dayLimitNum)
            commit("SET_PRODUCT_NUM", user.dayProductNum)
            commit("SET_USER_TYPE", user.userType)
            commit("SET_NAME", user.userName)
            commit(
              "SET_PRIM_ACC",
              user.createBy == null ? user.userName : user.createBy
            )
            commit("SET_GEAR", user.gear)
            commit("SET_NICKNAME", user.nickName)
            commit("SET_AVATAR", avatar)
            commit("SET_ROLES", res.roles)
            commit("SET_LOGIN_DATA", {
              loginDate: user.loginDate,
              loginIp: user.loginIp,
              location: user.location
            })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 获取统计数据
    GetIndexStat({ commit, state }) {
      return new Promise((resolve, reject) => {
        getIndexStat()
          .then((res) => {
            commit("SET_INDEX_STAT", res.data)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            commit("SET_TOKEN", "")
            commit("SET_ROLES", [])
            commit("SET_PERMISSIONS", [])
            removeToken()
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit("SET_TOKEN", "")
        removeToken()
        resolve()
      })
    },

    //更新授权平台数据
    UpdateAuthPlatform({ commit, state }) {
      return new Promise((resolve, reject) => {
        getAuthPlatform(state.token)
          .then((res) => {
            commit("SET_AUTHPLATFORM", res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default user
