<template>
  <div style="position: relative;margin: 60px 0 0 0;">
    <div class="rno-2-section-hd">
      <h3 class="rno-2-section-title">一键开启东南亚市场</h3>
      <h5 class="rno-2-section-desc">
        从店铺管理到本土供应链支撑，一站式服务专业满足东南亚本土卖家需求
      </h5>
    </div>
    <div class="rno-2-event-panels" id="section-hot">
      <div
        class="rno-2-event-lattice-card rno-2-event-lattice-cols-4 rno-2-event-lattice-without-margin"
      >
        <div class="J-lattice-cell rno-2-event-lattice-cell">
          <a target="_blank" class="rno-2-event-panel1">
            <div class="rno-2-event-cont">
              <img class="adImage" :src="ad1" />
              <h3 class="rno-2-event-panel-title">选品设计</h3>
              <p class="rno-2-event-panel-desc">使用先进的在线定制器</p>
              <p class="rno-2-event-panel-desc">
                批量设计制作海量的『商品』SKU
              </p>
            </div>
          </a>
        </div>
     
        <div class="J-lattice-cell rno-2-event-lattice-cell">
          <a target="_blank" class="rno-2-event-panel1">
            <div class="rno-2-event-cont">
              <img class="adImage" :src="ad2" />
              <h3 class="rno-2-event-panel-title">发布销售</h3>
              <p class="rno-2-event-panel-desc">多平台/多账号统一管理</p>
              <p class="rno-2-event-panel-desc">店群铺货一键刊登</p>
            </div>
          </a>
        </div>
           <div class="J-lattice-cell rno-2-event-lattice-cell">
          <a target="_blank" class="rno-2-event-panel1">
            <div class="rno-2-event-cont">
              <img class="adImage" :src="ad3" />
              <h3 class="rno-2-event-panel-title">本地生产</h3>
              <p class="rno-2-event-panel-desc">供应链覆盖菲律宾/泰国/印尼/马来...</p>
              <p class="rno-2-event-panel-desc">本地生产保障卖家的发货时效</p>
            </div>
          </a>
        </div>
        <div class="J-lattice-cell rno-2-event-lattice-cell">
          <a target="_blank" class="rno-2-event-panel1">
            <div class="rno-2-event-cont">
              <img class="adImage" :src="ad4" />
              <h3 class="rno-2-event-panel-title">本地仓储</h3>
              <p class="rno-2-event-panel-desc">快至24h内完成一件代发</p>
              <p class="rno-2-event-panel-desc">协同卖家完善售后服务</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ad1 from "@/assets/images/default2/ad1.png"
import ad2 from "@/assets/images/default2/ad2.png"
import ad3 from "@/assets/images/default2/ad3.png"
import ad4 from "@/assets/images/default2/ad4.png"
import designButton from "@/assets/images/default/design_button.png"

export default {
  data() {
    return {
      ad1: ad1,
      ad2: ad2,
      ad3: ad3,
      ad4: ad4,
      designButton: designButton,
    }
  },
}
</script>

<style lang="scss" scoped>
#section-hot {
  margin: 30px auto 89px auto;
}
.rno-2-event-cont {
  text-align: center;
}
.adImage {
  width: 150px;
  margin: 0 0 16px 0;
}
.rno-2-event-panel-title {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #181b1c;
  margin: 0 0 20px 0;
}
.rno-2-event-panel-desc {
  color: #7e7e7e;
}
.website-text {
  width: 500px;
  text-align: left;
  position: absolute;
  left: 15%;
  top: 200px;
  .text-button {
    margin: 0 0 0 -10px;
    img {
      height: 50px;
      cursor: pointer;
    }
  }
}
.rno-2-section-hd {
}
.rno-2-section-desc {
  font-size: 16px;
}
</style>
