import axios from "axios"
import { Notification, MessageBox, Message, Loading } from "element-ui"
import store from "@/store"
import { getToken } from "@/utils/auth"
import errorCode from "@/utils/errorCode"

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8"
// 创建axios实例
let baseURL = process.env.VUE_APP_BASE_API
  ? process.env.VUE_APP_BASE_API
  : "/prod-api"
window.baseURL = baseURL

const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: baseURL,
  // 超时
  timeout: 60 * 2 * 1000,
})
// request拦截器
service.interceptors.request.use(
  (config) => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
      config.headers["Authorization"] = "Bearer " + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config
  },
  (error) => {
    console.log(error)
    Promise.reject(error)
  }
)



// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200
    // 获取错误信息
    let msg = errorCode[code] || res.data.msg || errorCode["default"]

    if (code == 401) {
      MessageBox.alert(
        "系统发布更新中，请稍后重新登录",
        "系统提示",{
          showConfirmButton: false,
          showClose: false
        }
      )
      Loading.service({ fullscreen: true })
      window.setTimeout(() => {
        location.href = "/login"
      }, 5000)
    } else if (code == 500) {
      let keys = []
      for (let key in store.getters.dictMap["errorInfo"]) {
        keys.push(key)
      }

      for (let record of keys) {
        if (msg.toLowerCase().indexOf(record.toLowerCase()) > -1) {
          msg = store.getters.dictMap["errorInfo"][record]
          Message({
            message: msg,
            type: "error",
            duration: 3 * 1000,
          })
          if (window.endLoading) {
            window.endLoading()
          }
          return Promise.reject(new Error(msg))
        }
      }

      Message({
        message: msg,
        type: "error",
        duration: 3 * 1000,
      })
      if (window.endLoading) {
        window.endLoading()
      }
      return Promise.reject(new Error(msg))
    } else if (code !== 200) {
      Notification.error({
        title: msg,
      })
      if (window.endLoading) {
        window.endLoading()
      }
      return Promise.reject("error")
    } else {
      return res.data
    }
    if (window.endLoading) {
      window.endLoading()
    }
  },
  (error) => {
    let { message } = error
    if (message == "Network Error") {
      message = "后端接口连接异常"
    } else if (message.includes("timeout")) {
      message = store.getters.dictMap["errorInfo"]["timeout"]
    } else if (message.includes("Request failed with status code")) {
      // MessageBox.alert(
      //   "系统发布更新中，请稍后重新登录",
      //   "系统提示",{
      //     showConfirmButton: false,
      //     showClose: false
      //   }
      // )
      // Loading.service({ fullscreen: true })
      // window.setTimeout(() => {
      //   location.href = "/login"
      // }, 5000)
      // return Promise.reject(error)
    }

    Message({
      message: message,
      type: "error",
      duration: 3 * 1000,
    })
    if (window.endLoading) {
      window.endLoading()
    }
    return Promise.reject(error)
  }
)

export default service
