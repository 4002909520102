import request from '@/utils/request'
let platformCode =  process.env.VUE_APP_PLATFORM_CODE ?  process.env.VUE_APP_PLATFORM_CODE : "1"

// 获取路由
export const getRouters = () => {
  let code = platformCode
  return request({
    url: '/getRouters/'+code,
    method: 'get'
  })
}
