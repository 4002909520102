<template>
  <section class="rno-2-action-panel" id="section-free">
    <div class="rno-2-action-panel-inner">
      <div class="label1">
        <div style="display: flex; margin: 0 0 39px 0">
          <div class="title1">年包裹出口总量</div>
          <div class="title2">内容600万+</div>
        </div>
        <div style="display: flex; margin: 0 0 39px 0">
          <div class="title1">覆盖国家和地区</div>
          <div class="title2">200+</div>
        </div>
        <div style="display: flex">
          <div class="title1">年度活跃客户</div>
          <div class="title2">6000+</div>
        </div>
      </div>
      <div class="label2">
        <div style="display: flex; margin: 0 0 39px 0">
          <div class="title1">年交易总额</div>
          <div class="title2">3亿+</div>
        </div>
        <div style="display: flex; margin: 0 0 39px 0">
          <div class="title1">支持全球站点</div>
          <div class="title2">60+</div>
        </div>
        <div style="display: flex">
          <div class="title1">支持全球电商平台</div>
          <div class="title2">20+</div>
        </div>
        <div style="margin: 10vh 0 0 -10px;">
          <img style="height:50px;" :src="HowToUse" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import companyStats from "@/assets/images/default/company_stats.png"
import HowToUse from "@/assets/images/default/howtouse.png"

export default {
  data() {
    return {
      active: "hot",
      productList: [],
      dataRecent: [],
      dataHSales: [],
      companyStats: companyStats,
      HowToUse: HowToUse,
    }
  },
}
</script>

<style lang="scss" scoped>
.rno-2-action-panel {
  background: none;
  background-image: url("../../assets/images/default/company_stats.png") !important;
  background-size: cover;
  background-position: center center;
  height: calc(100vw * 0.534);
  position: relative;
}
.rno-2-action-panel-inner {
  display: flex;
  margin: 0 auto;
  width: 100%;
  padding: 24px 10px;
  box-sizing: border-box;
  position: relative;
  height: calc(100vw * 0.6416);
}
.label1 {
  width: 500px;
  text-align: left;
  position: absolute;
  left: 15%;
  top: 30%;
}
.label2 {
  width: 500px;
  text-align: left;
  position: absolute;
  right: 5%;
  top: 30%;
}
.title1 {
  font-size: 17px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 10px;
  flex: 1;
  color: #333333;
}
.title2 {
  font-size: 17px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fefefe;
  flex: 1;
  color: #0053ff;
  font-weight: bold;
}
</style>
