import { getNginx } from "@/api/setting"
const setting = {
  state: {
    nginx: null, //订单状态集合（字典数据）
    dictData: {
      lazada_color: [
        { label: "Blush Pink", value: "Blush Pink" },
        { label: "Bronze", value: "Bronze" },
        { label: "Brown", value: "Brown" },
        { label: "Burgundy", value: "Burgundy" },
        { label: "Cacao", value: "Cacao" },
        { label: "Camel", value: "Camel" },
        { label: "Champagne", value: "Champagne" },
        { label: "Champagne Pink", value: "Champagne Pink" },
        { label: "Charcoal", value: "Charcoal" },
        { label: "Cherry", value: "Cherry" },
        { label: "Chestnut", value: "Chestnut" },
        { label: "Chili Red", value: "Chili Red" },
        { label: "Chocolate", value: "Chocolate" },
        { label: "Cinnamon", value: "Cinnamon" },
        { label: "Clear", value: "Clear" },
        { label: "Coffee", value: "Coffee" },
        { label: "Cream", value: "Cream" },
        { label: "Dark Ash", value: "Dark Ash" },
        { label: "Dark Brown", value: "Dark Brown" },
        { label: "Dark Grey", value: "Dark Grey" },
        { label: "Dark blue", value: "Dark blue" },
        { label: "Deep Black", value: "Deep Black" },
        { label: "Deep Blue", value: "Deep Blue" },
        { label: "Deep Gray", value: "Deep Gray" },
        { label: "Deep green", value: "Deep green" },
        { label: "Emerald Green", value: "Emerald Green" },
        { label: "Floral", value: "Floral" },
        { label: "Fluorescent Green", value: "Fluorescent Green" },
        { label: "Red", value: "Red" },
      ],
      lazada_Ventures: [
        { remark: null, label: "SG", value: "SG" },
        { remark: null, label: "TH", value: "TH" },
        { remark: null, label: "PH", value: "PH" },
        { remark: null, label: "ID", value: "ID" },
        { remark: null, label: "VN", value: "VN" },
      ],
      syncStatus: [
        { label: "待发布", value: "01" },
        { label: "已发布", value: "02" },
        { label: "发布失败", value: "03" },
        { label: "发布中", value: "04" },
      ],
      amazon_site: [
        { label: "美国", value: "US" },
        { label: "加拿大", value: "CA" },
        { label: "墨西哥", value: "MX" },
        { label: "中国", value: "CN" },
        { label: "巴西", value: "BR" },
        { label: "西班牙", value: "ES" },
        { label: "英国", value: "GB" },
        { label: "德国", value: "DE" },
      ],

      lazada_Express: [
        { label: "Yes", value: "1" },
        { label: "No", value: "0" },
      ],
      lazada_size: [
        { label: "EU:33.5", value: "100" },
        { label: "EU:34", value: "101" },
        { label: "EU:34.5", value: "102" },
        { label: "EU:26", value: "103" },
        { label: "EU:27", value: "104" },
        { label: "EU:26.5", value: "105" },
        { label: "EU:27.5", value: "106" },
        { label: "EU:28", value: "107" },
        { label: "EU:28.5", value: "108" },
        { label: "EU:29", value: "109" },
        { label: "EU:29.5", value: "110" },
        { label: "EU:30", value: "111" },
        { label: "EU:27-28", value: "112" },
        { label: "EU:28-29", value: "113" },
        { label: "EU:29-30", value: "114" },
        { label: "EU:29.5-30", value: "115" },
        { label: "EU:30-30.5", value: "116" },
        { label: "EU:31", value: "117" },
        { label: "EU:31.5", value: "118" },
        { label: "EU:32", value: "119" },
        { label: "EU:32.5", value: "120" },
        { label: "EU:33", value: "121" },
        { label: "EU:33.5", value: "122" },
        { label: "EU:34", value: "123" },
        { label: "EU:33-34", value: "124" },
        { label: "EU:33-33.5", value: "125" },
        { label: "EU:34.5", value: "126" },
        { label: "EU:35", value: "127" },
        { label: "EU:35.5", value: "128" },
        { label: "EU:36", value: "129" },
        { label: "EU:33-34.5", value: "130" },
        { label: "EU:34.5-35", value: "131" },
        { label: "EU:35-36", value: "132" },
        { label: "EU:36.5", value: "133" },
        { label: "EU:36.5-37", value: "134" },
        { label: "EU:37", value: "135" },
        { label: "EU:37.5-38", value: "136" },
        { label: "EU:38.5", value: "137" },
        { label: "EU:38.5-39", value: "138" },
        { label: "EU:39", value: "139" },
        { label: "EU:39.5", value: "140" },
        { label: "EU:40", value: "141" },
        { label: "EU:41", value: "142" },
        { label: "EU:40-41", value: "143" },
      ],
      sys_show_hide: [
        { label: "显示", value: "0" },
        { label: "隐藏", value: "1" },
      ],
      language: [
        { label: "白色", value: "White" },
        { label: "黑色", value: "Black" },
        { label: "棕色", value: "Brown" },
        { label: "米色", value: "Beige" },
        { label: "蓝色", value: "Blue" },
        { label: "绿色", value: "Green" },
        { label: "红色", value: "Red" },
        { label: "透明色", value: "Transparent" },
      ],
      notice_type: [
        { label: "新品发布", value: "01" },
        { label: "物流公告", value: "02" },
        { label: "网站公告", value: "03" },
        { label: "功能更新", value: "04" },
      ],
      eanFlagCode: [
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
      ],
      order_status: [
        { label: "待付货款", value: "01" },
        { label: "已付货款", value: "02" },
        { label: "排单中", value: "03" },
        { label: "生产中", value: "04" },
        { label: "已发货", value: "05" },
        { label: "已取消", value: "06" },
        { label: "审核不通过", value: "07" },
        { label: "待编辑", value: "09" },
      ],
      user_type: [
        { label: "系统用户", value: "00" },
        { label: "商家用户", value: "01" },
        { label: "商家子用户", value: "02" },
      ],
      dz_order_status: [
        { label: "待付款", value: "01" },
        { label: "已付款", value: "02" },
        { label: "排单中", value: "03" },
        { label: "生产中", value: "04" },
        { label: "已发货", value: "05" },
        { label: "已取消", value: "06" },
        { label: "已删除", value: "07" },
      ],
      shopee_style: [
        { label: "夹克", value: "0" },
        { label: "短袖", value: "1" },
      ],
      shopee_style2: [
        { label: "夏季款", value: "0" },
        { label: "冬季款", value: "1" },
      ],
      auth_status: [
        { label: "未授权", value: "01" },
        { label: "已授权", value: "02" },
      ],
      sys_app_platform: [
        { label: "管理平台", value: "0" },
        { label: "电商平台", value: "1" },
      ],
      lazada_DangerousGoods: [
        { label: "Battery", value: "1" },
        { label: "Flammable", value: "2" },
        { label: "Liquid", value: "3" },
        { label: "None", value: "4" },
      ],
      currency_mark: [
        { label: "₱", value: "PHP" },
        { label: "RM", value: "MYR" },
        { label: "Rp", value: "IDR" },
        { label: "฿", value: "THB" },
        { label: "₫", value: "VND" },
        { label: "S$", value: "SGD" },
        { label: "$", value: "USD" },
        { label: "£", value: "GBP" },
        { label: "C$", value: "CAD" },
        { label: "¥", value: "CNY" },
        { label: "€", value: "EUR" },
        { label: "C$", value: "CDN" },
        { label: "JPY¥", value: "JPY" },
      ],
      lazada_fa_create_year: [
        { label: "2012", value: "1" },
        { label: "2013", value: "2" },
        { label: "2014", value: "3" },
        { label: "2015", value: "4" },
        { label: "2016", value: "5" },
        { label: "2017", value: "6" },
        { label: "2018", value: "7" },
        { label: "2019", value: "8" },
        { label: "2020", value: "9" },
      ],
      tiktok_Warranty: [
        { label: "4 weeks", value: "1" },
        { label: "2 months", value: "2" },
        { label: "3 months", value: "3" },
        { label: "4 months", value: "4" },
        { label: "5 months", value: "5" },
        { label: "6 months", value: "6" },
        { label: "7 months", value: "7" },
        { label: "8 months", value: "8" },
        { label: "9 months", value: "9" },
        { label: "10 months", value: "10" },
        { label: "11 months", value: "11" },
        { label: "12 months", value: "12" },
        { label: "2 years", value: "13" },
        { label: "3 years", value: "14" },
        { label: "1 week", value: "15" },
        { label: "2 weeks", value: "16" },
        { label: "18 months", value: "17" },
        { label: "4 years", value: "18" },
        { label: "5 years", value: "19" },
        { label: "10 years", value: "20" },
        { label: "lifetime warranty", value: "21" },
      ],
      shop_status: [
        { label: "正常", value: "01" },
        { label: "禁用", value: "02" },
      ],
      sys_job_status: [
        { label: "正常", value: "0" },
        { label: "暂停", value: "1" },
      ],
      lazada_pattern: [
        { label: "赤坎刺绣", value: "500" },
        { label: "阿丽刺绣", value: "501" },
        { label: "克什米尔刺绣", value: "502" },
      ],
      prod_days: [
        { label: "1~3天", value: "01" },
        { label: "3~5天", value: "02" },
        { label: "5~7天", value: "03" },
        { label: "大于7天", value: "04" },
      ],
      pub_status: [
        { label: "采集箱", value: "01" },
        { label: "待发布", value: "02" },
        { label: "已发布", value: "03" },
      ],
      map_shape: [
        { label: "似方形", value: "01" },
        { label: "横向形", value: "02" },
        { label: "竖向形", value: "03" },
        { label: "超横形", value: "04" },
        { label: "超竖形", value: "05" },
      ],
      shopee_material: [
        { label: "棉布", value: "0" },
        { label: "麻布", value: "1" },
      ],
      useFlag: [
        { label: "未使用", value: "01" },
        { label: "已使用", value: "02" },
      ],
      s_plat_code: [
        { label: "1688", value: "01" },
        { label: "速卖通", value: "02" },
        { label: "Lazada", value: "03" },
        { label: "Amazon", value: "04" },
        { label: "Shopee", value: "05" },
        { label: "淘宝", value: "06" },
      ],
      map_format: [
        { label: "PNG", value: "01" },
        { label: "JPG/JPEG", value: "02" },
        { label: "矢量图", value: "03" },
      ],
      gear: [
        { label: "最低档位", value: "1" },
        { label: "第二档位", value: "2" },
        { label: "第三档位", value: "3" },
        { label: "第四档位", value: "4" },
        { label: "最高档位", value: "5" },
        { label: "最低价", value: "A" },
        { label: "零售价", value: "B" },
        { label: "成本价", value: "C" },
      ],
      withdraw_mode: [
        { label: "支付宝", value: "01" },
        { label: "银行卡", value: "02" },
      ],
      plat_no: [
        { label: "1688", value: "01" },
        { label: "tiktok", value: "10" },
        { label: "shein", value: "03" },
        { label: "亚马逊", value: "04" },
        { label: "Temu", value: "05" },
        { label: "其他", value: "09" },
      ],
      color: [
        { label: "#FFFFFF", value: "白色" },
        { label: "#000000", value: "黑色" },
        { label: "#A52A2A", value: "棕色" },
        { label: "#F5F5DC", value: "米色" },
        { label: "#0000FF", value: "蓝色" },
        { label: "#008000", value: "绿色" },
        { label: "#FF0000", value: "红色" },
        { label: "#FFFFFF", value: "透明色" },
        { label: "#FFFFFF", value: "White" },
        { label: "#000000", value: "Black" },
        { label: "#A52A2A", value: "Brown" },
        { label: "#F5F5DC", value: "Beige" },
        { label: "#0000FF", value: "Blue" },
        { label: "#008000", value: "Green" },
        { label: "#FF0000", value: "Red" },
        { label: "#FFFFFF", value: "Transparent" },
      ],
      fund_type: [
        { label: "收入", value: "01" },
        { label: "支出", value: "02" },
      ],
      sale_form: [
        { label: "单品", value: "01" },
        { label: "多变种", value: "02" },
      ],
      import_status: [
        { label: "处理中", value: "01" },
        { label: "成功", value: "02" },
        { label: "失败", value: "03" },
      ],
      question_status: [
        { label: "未处理", value: "01" },
        { label: "已处理", value: "02" },
      ],
      remarkType: [
        { label: "请发送一个空包", value: "01" },
        { label: "尺寸修改为3XL", value: "02" },
        { label: "其他", value: "99" },
      ],
      yes_or_no: [
        { label: "是", value: "1" },
        { label: "否", value: "0" },
      ],
      sys_user_sex: [
        { label: "男", value: "0" },
        { label: "女", value: "1" },
        { label: "未知", value: "2" },
      ],
      productCodeType: [
        { label: "EAN", value: "EAN" },
        { label: "UPC", value: "UPC" },
      ],
      sys_oper_type: [
        { label: "新增", value: "1" },
        { label: "修改", value: "2" },
        { label: "删除", value: "3" },
        { label: "授权", value: "4" },
        { label: "导出", value: "5" },
        { label: "导入", value: "6" },
        { label: "强退", value: "7" },
        { label: "生成代码", value: "8" },
        { label: "清空数据", value: "9" },
      ],
      lazada_season: [
        { label: "Spring", value: "1" },
        { label: "Summer", value: "2" },
        { label: "Autumn", value: "3" },
        { label: "Winter", value: "4" },
      ],
      match_type: [
        { label: "未关联", value: "01" },
        { label: "自动关联", value: "02" },
        { label: "手动关联", value: "03" },
        { label: "自建订单", value: "04" },
      ],
      currency: [
        { label: "美元(USD)", value: "USD" },
        { label: "英镑(GBP)", value: "GBP" },
        { label: "加元(CAD)", value: "CAD" },
        { label: "人民币(CNY)", value: "CNY" },
        { label: "欧元(EUR)", value: "EUR" },
        { label: "加元(CDN)", value: "CDN" },
        { label: "日元(JPY)", value: "JPY" },
      ],
      lazada_tax: [
        { label: "11%", value: "1" },
        { label: "16%", value: "2" },
        { label: "17%", value: "3" },
      ],
      sys_tree_node_type: [
        { label: "是", value: "1" },
        { label: "否", value: "0" },
      ],
      shopee_attr_size: [
        { label: "XXS", value: "0" },
        { label: "XS", value: "1" },
        { label: "S", value: "2" },
        { label: "M", value: "3" },
        { label: "L", value: "4" },
        { label: "XL", value: "5" },
        { label: "XXL", value: "6" },
        { label: "XXXL", value: "7" },
        { label: "XXXXL", value: "8" },
        { label: "XXXXXL", value: "9" },
      ],
      upcFlagCode: [
        { label: "1", value: "1" },
        { label: "3", value: "3" },
        { label: "6", value: "6" },
        { label: "8", value: "8" },
      ],
      sys_job_group: [
        { label: "默认", value: "DEFAULT" },
        { label: "系统", value: "SYSTEM" },
      ],
      question_type: [
        { label: "新手指导", value: "01" },
        { label: "常见问题", value: "02" },
        { label: "选品", value: "03" },
        { label: "设计", value: "04" },
        { label: "刊登", value: "05" },
        { label: "订单", value: "06" },
        { label: "账户", value: "07" },
        { label: "其他帮助", value: "09" },
      ],
      sys_yes_no: [
        { label: "是", value: "Y" },
        { label: "否", value: "N" },
      ],
      recharge_mode: [
        { label: "线上充值", value: "01" },
        { label: "银行转账", value: "02" },
      ],
      map_source: [
        { label: "收费图库", value: "01" },
        { label: "我的图库", value: "02" },
      ],
      lazada_warranty: [
        { label: "1周", value: "1014" },
        { label: "10日", value: "1010" },
        { label: "1个月", value: "1009" },
        { label: "10个月", value: "1013" },
        { label: "1年", value: "1008" },
        { label: "5年", value: "1017" },
        { label: "10年", value: "1011" },
        { label: "20年", value: "1012" },
      ],
      lazada_DeliveryOptionEconomy: [
        { label: "Yes", value: "1" },
        { label: "No", value: "0" },
      ],
      lazada_station: [
        { label: "马来西亚", value: "01" },
        { label: "新加坡", value: "02" },
        { label: "泰国", value: "03" },
        { label: "印度尼西亚", value: "04" },
        { label: "菲律宾", value: "05" },
        { label: "越南", value: "06" },
      ],
      check_status: [
        { label: "待审核", value: "01" },
        { label: "审核通过", value: "02" },
        { label: "审核不通过", value: "03" },
      ],
      lazada_qa_type: [
        { label: "International Manufacturer Warranty", value: "1" },
        { label: "International Seller Warranty", value: "2" },
        { label: "Local Manufacturer Warranty", value: "3" },
        { label: "Local Supplier Warranty", value: "4" },
        { label: "No Warranty", value: "5" },
      ],
      lazada_material: [
        { label: "尼龙", value: "1" },
        { label: "帆布", value: "2" },
        { label: "亚麻", value: "3" },
        { label: "棉", value: "4" },
        { label: "塑料", value: "5" },
      ],
      amazon_order_status: [
        { label: "PendingAvailability", value: "PendingAvailability" },
        { label: "Pending", value: "Pending" },
        { label: "Unshipped", value: "Unshipped" },
        { label: "PartiallyShipped", value: "PartiallyShipped" },
        { label: "Shipped", value: "Shipped" },
        { label: "InvoiceUnconfirmed", value: "InvoiceUnconfirmed" },
        { label: "Canceled", value: "Canceled" },
        { label: "Unfulfillable", value: "Unfulfillable" },
      ],
      dz_status: [
        { label: "已上架", value: "01" },
        { label: "已下架", value: "02" },
        { label: "待发布", value: "03" },
      ],
      product_type: [
        { label: "定制产品", value: "01" },
        { label: "优选产品", value: "02" },
      ],
      sys_common_status: [
        { label: "成功", value: "0" },
        { label: "失败", value: "1" },
      ],
      data_type: [
        { label: "文本", value: "01" },
        { label: "单选", value: "02" },
        { label: "多选", value: "03" },
        { label: "文本域", value: "04" },
        { label: "枚举值单选", value: "05" },
        { label: "枚举值多选", value: "06" },
        { label: "时间", value: "07" },
      ],
      order_source: [
        { label: "导入", value: "01" },
        { label: "同步", value: "02" },
        { label: "自建", value: "03" },
      ],
      sys_notice_status: [
        { label: "正常", value: "1" },
        { label: "停用", value: "0" },
      ],
      sys_normal_disable: [
        { label: "正常", value: "1" },
        { label: "停用", value: "0" },
      ],
      shopee_brand: [
        { label: "自用品牌", value: "0" },
        { label: "阿玛尼", value: "1" },
      ],
      WARRANTY_TIME: [
        { label: "7 Days", value: "01" },
        { label: "14 Days", value: "02" },
        { label: "1 Month", value: "03" },
        { label: "2 Months", value: "04" },
      ],
      WARRANTY_TYPE: [
        { label: "No Warranty", value: "01" },
        { label: "Lazada Return and Refund Guarantee", value: "02" },
        { label: "Local Supplier Refund Warranty", value: "03" },
        { label: "Local Supplier Warranty", value: "04" },
        { label: "International Manufacturer Warranty", value: "05" },
        { label: "Local Manufacturer Warranty", value: "06" },
        { label: "International Seller Warranty", value: "07" },
      ],
      shopeeCategory: [
        { label: "100240 - Men Clothes/Pants/Pants", value: "100240" },
        {
          label: "100827 - Food & Beverages/Beverages/Energy & Isotonic Drinks",
          value: "100827",
        },
        {
          label: "101104 - Cameras & Drones/Security Cameras & Systems/Others",
          value: "101104",
        },
        {
          label:
            "101385 - Hobbies & Collections/Collectible Items/Action Figurines",
          value: "101385",
        },
        {
          label: "100441 - Health/Personal Care/Oral Care/Dental Floss",
          value: "100441",
        },
        {
          label:
            "101746 - Baby & Kids Fashion/Baby & Kids Accessories/Bags & Luggage/Luggage & Trolley Bags",
          value: "101746",
        },
        { label: "100241 - Men Clothes/Pants/Others", value: "100241" },
        { label: "100828 - Food & Beverages/Beverages/Water", value: "100828" },
        {
          label:
            "101105 - Cameras & Drones/Lens Accessories/Lens Mount & Adaptors",
          value: "101105",
        },
        {
          label:
            "101386 - Hobbies & Collections/Collectible Items/Statues & Sculptures",
          value: "101386",
        },
        {
          label: "100442 - Health/Personal Care/Oral Care/Mouth Wash",
          value: "100442",
        },
        {
          label:
            "101747 - Baby & Kids Fashion/Baby & Kids Accessories/Bags & Luggage/Wallets",
          value: "101747",
        },
        { label: "100242 - Men Clothes/Tops/Shirts", value: "100242" },
        {
          label: "100829 - Food & Beverages/Beverages/Juice & Juice Vinegar",
          value: "100829",
        },
        {
          label: "101106 - Cameras & Drones/Lens Accessories/Lens Caps",
          value: "101106",
        },
        {
          label:
            "101387 - Hobbies & Collections/Collectible Items/Mecha Models & Diecast",
          value: "101387",
        },
        {
          label: "100443 - Health/Personal Care/Oral Care/Denture Care",
          value: "100443",
        },
        {
          label:
            "101748 - Baby & Kids Fashion/Baby & Kids Accessories/Bags & Luggage/Others",
          value: "101748",
        },
        { label: "100243 - Men Clothes/Tops/Polo Shirts", value: "100243" },
        {
          label: "100830 - Food & Beverages/Beverages/Cordial & Syrups",
          value: "100830",
        },
        {
          label: "101107 - Cameras & Drones/Lens Accessories/Filters",
          value: "101107",
        },
        {
          label:
            "101388 - Hobbies & Collections/Collectible Items/Vehicle Models & Diecast",
          value: "101388",
        },
        {
          label: "100444 - Health/Personal Care/Oral Care/Teeth Whitening",
          value: "100444",
        },
        {
          label:
            "101749 - Baby & Kids Fashion/Baby & Kids Accessories/Jewelry/Bracelets",
          value: "101749",
        },
        { label: "100244 - Men Clothes/Tops/T-Shirts", value: "100244" },
        {
          label:
            "100831 - Food & Beverages/Beverages/Carbonated Drinks & Tonics",
          value: "100831",
        },
        {
          label: "101108 - Cameras & Drones/Lens Accessories/Lens Hoods",
          value: "101108",
        },
        {
          label:
            "101389 - Hobbies & Collections/Collectible Items/Stones & Minerals",
          value: "101389",
        },
        {
          label:
            "100445 - Health/Personal Care/Oral Care/Orthodontic Accessories",
          value: "100445",
        },
        {
          label:
            "101750 - Baby & Kids Fashion/Baby & Kids Accessories/Jewelry/Earrings",
          value: "101750",
        },
        { label: "100245 - Men Clothes/Tops/Tanks", value: "100245" },
        {
          label: "100832 - Food & Beverages/Beverages/Powdered Drink Mixes",
          value: "100832",
        },
        {
          label: "101109 - Cameras & Drones/Lens Accessories/Others",
          value: "101109",
        },
        {
          label:
            "101390 - Hobbies & Collections/Collectible Items/Idol Collectibles",
          value: "101390",
        },
        {
          label: "100446 - Health/Personal Care/Oral Care/Others",
          value: "100446",
        },
        {
          label:
            "101751 - Baby & Kids Fashion/Baby & Kids Accessories/Jewelry/Necklaces",
          value: "101751",
        },
        { label: "100246 - Men Clothes/Tops/Others", value: "100246" },
        {
          label: "100833 - Food & Beverages/Beverages/Dessert Drink",
          value: "100833",
        },
        {
          label: "101110 - Cameras & Drones/Camera Accessories/Flashes",
          value: "101110",
        },
        {
          label:
            "101391 - Hobbies & Collections/Collectible Items/Sports Collectibles",
          value: "101391",
        },
        {
          label:
            "100447 - Health/Personal Care/Feminine Care/Sanitary Napkins & Panty Liners",
          value: "100447",
        },
        {
          label:
            "101752 - Baby & Kids Fashion/Baby & Kids Accessories/Jewelry/Rings",
          value: "101752",
        },
        {
          label: "100247 - Men Clothes/Innerwear & Underwear/Underwear",
          value: "100247",
        },
        {
          label:
            "100834 - Food & Beverages/Beverages/Traditional & Herbal Drinks",
          value: "100834",
        },
        {
          label:
            "101112 - Cameras & Drones/Camera Accessories/Gimbals & Stabilizers",
          value: "101112",
        },
        {
          label:
            "101392 - Hobbies & Collections/Collectible Items/Anime & Manga Collectibles",
          value: "101392",
        },
        {
          label: "100448 - Health/Personal Care/Feminine Care/Tampons",
          value: "100448",
        },
        {
          label:
            "101753 - Baby & Kids Fashion/Baby & Kids Accessories/Jewelry/Others",
          value: "101753",
        },
        {
          label: "100248 - Men Clothes/Innerwear & Underwear/Undershirts",
          value: "100248",
        },
        {
          label: "100835 - Food & Beverages/Beverages/Drink Toppings",
          value: "100835",
        },
        {
          label:
            "101113 - Cameras & Drones/Camera Accessories/Lighting & Studio Equipments",
          value: "101113",
        },
        {
          label: "101393 - Hobbies & Collections/Collectible Items/Coins",
          value: "101393",
        },
        {
          label:
            "100449 - Health/Personal Care/Feminine Care/Pregnancy & Fertility Tests",
          value: "100449",
        },
        {
          label:
            "101754 - Baby & Kids Fashion/Baby & Kids Accessories/Rain Gear/Raincoats",
          value: "101754",
        },
        {
          label: "100249 - Men Clothes/Innerwear & Underwear/Thermal Innerwear",
          value: "100249",
        },
        {
          label: "100836 - Food & Beverages/Beverages/Non-dairy Milk",
          value: "100836",
        },
        {
          label:
            "101114 - Cameras & Drones/Camera Accessories/Photo Films & Papers",
          value: "101114",
        },
        {
          label: "101394 - Hobbies & Collections/Collectible Items/Others",
          value: "101394",
        },
        {
          label: "100450 - Health/Personal Care/Feminine Care/Menstrual Cup",
          value: "100450",
        },
        {
          label:
            "101755 - Baby & Kids Fashion/Baby & Kids Accessories/Rain Gear/Rain Boots",
          value: "101755",
        },
        {
          label: "100250 - Men Clothes/Innerwear & Underwear/Others",
          value: "100250",
        },
        {
          label: "100837 - Food & Beverages/Beverages/Others",
          value: "100837",
        },
        {
          label: "101115 - Cameras & Drones/Camera Accessories/Photo Printers",
          value: "101115",
        },
        {
          label: "101395 - Hobbies & Collections/Souvenirs/Hand Fans",
          value: "101395",
        },
        {
          label: "100451 - Health/Personal Care/Feminine Care/Feminine Wash",
          value: "100451",
        },
        {
          label:
            "101756 - Baby & Kids Fashion/Baby & Kids Accessories/Rain Gear/Others",
          value: "101756",
        },
        {
          label: "100251 - Men Clothes/Traditional Wear/Tops",
          value: "100251",
        },
        {
          label:
            "100839 - Food & Beverages/Dairy & Eggs/Yogurt & Cultured Milk",
          value: "100839",
        },
        {
          label:
            "101116 - Cameras & Drones/Camera Accessories/Camera Cases & Bags",
          value: "101116",
        },
        {
          label: "101396 - Hobbies & Collections/Souvenirs/Keychains",
          value: "101396",
        },
        {
          label: "100452 - Health/Personal Care/Feminine Care/Vaginal Cream",
          value: "100452",
        },
        {
          label: "101757 - Baby & Kids Fashion/Boy Clothes/Tops/T-shirts",
          value: "101757",
        },
        {
          label: "100252 - Men Clothes/Traditional Wear/Bottoms",
          value: "100252",
        },
        {
          label: "100845 - Food & Beverages/Dairy & Eggs/Beancurd",
          value: "100845",
        },
        {
          label:
            "101117 - Cameras & Drones/Camera Accessories/Battery Chargers",
          value: "101117",
        },
        {
          label: "101397 - Hobbies & Collections/Souvenirs/Coin Banks",
          value: "101397",
        },
        {
          label: "100453 - Health/Personal Care/Feminine Care/Others",
          value: "100453",
        },
        {
          label: "101758 - Baby & Kids Fashion/Boy Clothes/Tops/Polo Shirts",
          value: "101758",
        },
        {
          label: "100253 - Men Clothes/Traditional Wear/Sets",
          value: "100253",
        },
        {
          label: "100850 - Food & Beverages/Fresh & Frozen Food/Others",
          value: "100850",
        },
        {
          label:
            "101118 - Cameras & Drones/Camera Accessories/Batteries & Battery Grips",
          value: "101118",
        },
        {
          label: "101398 - Hobbies & Collections/Souvenirs/Fridge Magnets",
          value: "101398",
        },
        {
          label:
            "100454 - Home Appliances/Small Household Appliances/Telephones/Corded Phones",
          value: "100454",
        },
        {
          label: "101759 - Baby & Kids Fashion/Boy Clothes/Tops/Shirts",
          value: "101759",
        },
        {
          label: "100254 - Men Clothes/Traditional Wear/Others",
          value: "100254",
        },
        {
          label:
            "102071 - Food & Beverages/Fresh & Frozen Food/Vegetables/Cabbage & Brussel Sprouts",
          value: "102071",
        },
        {
          label:
            "101119 - Cameras & Drones/Camera Accessories/Tripods, Monopods, & Accessories",
          value: "101119",
        },
        {
          label: "101399 - Hobbies & Collections/Souvenirs/Others",
          value: "101399",
        },
        {
          label:
            "100455 - Home Appliances/Small Household Appliances/Telephones/Cordless Phones",
          value: "100455",
        },
        {
          label: "101760 - Baby & Kids Fashion/Boy Clothes/Tops/Others",
          value: "101760",
        },
        { label: "100255 - Men Shoes/Boots/Fashion Boots", value: "100255" },
        {
          label:
            "102072 - Food & Beverages/Fresh & Frozen Food/Vegetables/Fresh Herbs",
          value: "102072",
        },
        {
          label: "101120 - Cameras & Drones/Camera Accessories/Others",
          value: "101120",
        },
        {
          label:
            "101400 - Hobbies & Collections/Toys & Games/Dice, Board & Card Games",
          value: "101400",
        },
        {
          label:
            "100456 - Home Appliances/Small Household Appliances/Telephones/Fax Machines",
          value: "100456",
        },
        {
          label:
            "101761 - Baby & Kids Fashion/Boy Clothes/Outerwear/Regular Jackets & Coats",
          value: "101761",
        },
        { label: "100256 - Men Shoes/Boots/Rain Boots", value: "100256" },
        {
          label:
            "102073 - Food & Beverages/Fresh & Frozen Food/Vegetables/Leafy Vegetables",
          value: "102073",
        },
        {
          label: "101121 - Cameras & Drones/Camera Care/Dry Boxes & Cabinets",
          value: "101121",
        },
        {
          label: "101401 - Hobbies & Collections/Toys & Games/Magic Toys",
          value: "101401",
        },
        {
          label:
            "100457 - Home Appliances/Small Household Appliances/Telephones/Others",
          value: "100457",
        },
        {
          label:
            "101762 - Baby & Kids Fashion/Boy Clothes/Outerwear/Winter Outerwear",
          value: "101762",
        },
        { label: "100257 - Men Shoes/Boots/Safety Boots", value: "100257" },
        {
          label:
            "102074 - Food & Beverages/Fresh & Frozen Food/Vegetables/Brinjals, Gourds & Squashes",
          value: "102074",
        },
        {
          label: "101122 - Cameras & Drones/Camera Care/Cleaning Kit",
          value: "101122",
        },
        {
          label: "101402 - Hobbies & Collections/Toys & Games/Prank Toys",
          value: "101402",
        },
        {
          label:
            "100458 - Home Appliances/Small Household Appliances/Air Treatment/Purifiers",
          value: "100458",
        },
        {
          label: "101763 - Baby & Kids Fashion/Boy Clothes/Outerwear/Vests",
          value: "101763",
        },
        { label: "100258 - Men Shoes/Boots/Others", value: "100258" },
        {
          label:
            "102075 - Food & Beverages/Fresh & Frozen Food/Vegetables/Broccoli & Cauliflowers",
          value: "102075",
        },
        {
          label: "101123 - Cameras & Drones/Camera Care/Silica Gel",
          value: "101123",
        },
        {
          label: "101403 - Hobbies & Collections/Toys & Games/Rubik's Cubes",
          value: "101403",
        },
        {
          label:
            "100459 - Home Appliances/Small Household Appliances/Air Treatment/Dehumidifiers",
          value: "100459",
        },
        {
          label:
            "101764 - Baby & Kids Fashion/Boy Clothes/Outerwear/Sweaters & Cardigans",
          value: "101764",
        },
        {
          label: "100259 - Men Shoes/Sandals & Flip Flops/Flip Flops",
          value: "100259",
        },
        {
          label:
            "102076 - Food & Beverages/Fresh & Frozen Food/Vegetables/Chilli & Capsicum",
          value: "102076",
        },
        {
          label: "101124 - Cameras & Drones/Camera Care/Blowers",
          value: "101124",
        },
        {
          label: "101404 - Hobbies & Collections/Toys & Games/Spinning Tops",
          value: "101404",
        },
        {
          label:
            "100460 - Home Appliances/Small Household Appliances/Air Treatment/Others",
          value: "100460",
        },
        {
          label: "101765 - Baby & Kids Fashion/Boy Clothes/Outerwear/Blazers",
          value: "101765",
        },
        {
          label: "100260 - Men Shoes/Sandals & Flip Flops/Sandals",
          value: "100260",
        },
        {
          label:
            "102077 - Food & Beverages/Fresh & Frozen Food/Vegetables/Garlic, Ginger & Onion ",
          value: "102077",
        },
        {
          label: "101125 - Cameras & Drones/Camera Care/Lenspens & Brushes",
          value: "101125",
        },
        {
          label: "101405 - Hobbies & Collections/Toys & Games/Kendamas",
          value: "101405",
        },
        {
          label:
            "100461 - Home Appliances/Large Household Appliances/Washing Machines & Dryers/Washing Machines",
          value: "100461",
        },
        {
          label: "101766 - Baby & Kids Fashion/Boy Clothes/Outerwear/Hoodies",
          value: "101766",
        },
        {
          label: "100261 - Men Shoes/Sandals & Flip Flops/Indoor Slippers",
          value: "100261",
        },
        {
          label:
            "102078 - Food & Beverages/Fresh & Frozen Food/Vegetables/Peas, Beans, Nuts & Corn",
          value: "102078",
        },
        {
          label: "101126 - Cameras & Drones/Camera Care/Others",
          value: "101126",
        },
        {
          label: "101406 - Hobbies & Collections/Toys & Games/Yo-yos",
          value: "101406",
        },
        {
          label:
            "100462 - Home Appliances/Large Household Appliances/Washing Machines & Dryers/Laundry Dryers",
          value: "100462",
        },
        {
          label: "101767 - Baby & Kids Fashion/Boy Clothes/Outerwear/Others",
          value: "101767",
        },
        {
          label: "100262 - Men Shoes/Sandals & Flip Flops/Health Slippers",
          value: "100262",
        },
        {
          label:
            "102079 - Food & Beverages/Fresh & Frozen Food/Vegetables/Root & Stem Vegetables",
          value: "102079",
        },
        {
          label:
            "101127 - Home & Living/Home Fragrance & Aromatherapy/Air Fresheners & Home Fragrance",
          value: "101127",
        },
        {
          label:
            "101407 - Hobbies & Collections/Toys & Games/Remote Control Toys & Accessories",
          value: "101407",
        },
        {
          label:
            "100463 - Home Appliances/Large Household Appliances/Washing Machines & Dryers/Others",
          value: "100463",
        },
        {
          label: "101768 - Baby & Kids Fashion/Boy Clothes/Bottoms/Jeans",
          value: "101768",
        },
        {
          label: "100263 - Men Shoes/Sandals & Flip Flops/Others",
          value: "100263",
        },
        {
          label:
            "102080 - Food & Beverages/Fresh & Frozen Food/Vegetables/Sprouts",
          value: "102080",
        },
        {
          label:
            "101128 - Home & Living/Home Fragrance & Aromatherapy/Essential Oils",
          value: "101128",
        },
        {
          label: "101408 - Hobbies & Collections/Toys & Games/Capsule Toys",
          value: "101408",
        },
        {
          label:
            "100464 - Home Appliances/Large Household Appliances/Cooling/Air-Conditioners",
          value: "100464",
        },
        {
          label: "101769 - Baby & Kids Fashion/Boy Clothes/Bottoms/Pants",
          value: "101769",
        },
        {
          label:
            "100264 - Men Shoes/Shoe Care & Accessories/Shoe Care & Cleaning Tools",
          value: "100264",
        },
        {
          label:
            "102081 - Food & Beverages/Fresh & Frozen Food/Vegetables/Salads Vegetables ",
          value: "102081",
        },
        {
          label:
            "101129 - Home & Living/Home Fragrance & Aromatherapy/Diffusers, Humidifiers & Oil Burners",
          value: "101129",
        },
        {
          label: "101409 - Hobbies & Collections/Toys & Games/Others",
          value: "101409",
        },
        {
          label:
            "100465 - Home Appliances/Large Household Appliances/Cooling/Air Coolers & Portable Aircons",
          value: "100465",
        },
        {
          label: "101770 - Baby & Kids Fashion/Boy Clothes/Bottoms/Shorts",
          value: "101770",
        },
        {
          label: "100265 - Men Shoes/Shoe Care & Accessories/Shoe Deodorizers",
          value: "100265",
        },
        {
          label:
            "102082 - Food & Beverages/Fresh & Frozen Food/Vegetables/Pre-cut & Assorted Vegetable Set",
          value: "102082",
        },
        {
          label: "101130 - Home & Living/Home Fragrance & Aromatherapy/Others",
          value: "101130",
        },
        {
          label:
            "101410 - Hobbies & Collections/Musical Instruments & Accessories/Keyboards & Pianos",
          value: "101410",
        },
        {
          label:
            "100466 - Home Appliances/Large Household Appliances/Cooling/Fans",
          value: "100466",
        },
        {
          label: "101771 - Baby & Kids Fashion/Boy Clothes/Bottoms/Overalls",
          value: "101771",
        },
        {
          label: "100266 - Men Shoes/Shoe Care & Accessories/Shoe Laces",
          value: "100266",
        },
        {
          label:
            "102083 - Food & Beverages/Fresh & Frozen Food/Vegetables/Others",
          value: "102083",
        },
        {
          label:
            "101131 - Home & Living/Bathrooms/Toilet Bowls, Seats & Covers",
          value: "101131",
        },
        {
          label:
            "101411 - Hobbies & Collections/Musical Instruments & Accessories/Percussion Instruments",
          value: "101411",
        },
        {
          label:
            "100467 - Home Appliances/Large Household Appliances/Cooling/Others",
          value: "100467",
        },
        {
          label: "101772 - Baby & Kids Fashion/Boy Clothes/Bottoms/Others",
          value: "101772",
        },
        {
          label:
            "100267 - Men Shoes/Shoe Care & Accessories/Shoe Horns & Trees",
          value: "100267",
        },
        {
          label:
            "102084 - Food & Beverages/Fresh & Frozen Food/Fruits/Apples & Pears",
          value: "102084",
        },
        {
          label:
            "101132 - Home & Living/Bathrooms/Toothbrush Holders & Toothpaste Dispensers",
          value: "101132",
        },
        {
          label:
            "101412 - Hobbies & Collections/Musical Instruments & Accessories/Wind Instruments",
          value: "101412",
        },
        {
          label:
            "100468 - Home Appliances/Kitchen Appliances/Specialty Cookware/Waffle & Crepe Makers",
          value: "100468",
        },
        {
          label: "101773 - Baby & Kids Fashion/Girl Clothes/Tops/T-shirts",
          value: "101773",
        },
        {
          label: "100268 - Men Shoes/Shoe Care & Accessories/Shoe Insoles",
          value: "100268",
        },
        {
          label: "102085 - Food & Beverages/Fresh & Frozen Food/Fruits/Bananas",
          value: "102085",
        },
        {
          label:
            "101133 - Home & Living/Bathrooms/Soap Dispensers, Holders & Boxes",
          value: "101133",
        },
        {
          label:
            "101413 - Hobbies & Collections/Musical Instruments & Accessories/Music Accessories",
          value: "101413",
        },
        {
          label:
            "100469 - Home Appliances/Kitchen Appliances/Specialty Cookware/Egg Boilers",
          value: "100469",
        },
        {
          label: "101774 - Baby & Kids Fashion/Girl Clothes/Tops/Polo Shirts",
          value: "101774",
        },
        {
          label: "100269 - Men Shoes/Shoe Care & Accessories/Others",
          value: "100269",
        },
        {
          label: "102086 - Food & Beverages/Fresh & Frozen Food/Fruits/Berries",
          value: "102086",
        },
        {
          label: "101134 - Home & Living/Bathrooms/Bathroom Racks & Cabinets",
          value: "101134",
        },
        {
          label:
            "101414 - Hobbies & Collections/Musical Instruments & Accessories/Others",
          value: "101414",
        },
        {
          label:
            "100470 - Home Appliances/Kitchen Appliances/Specialty Cookware/Bread Makers",
          value: "100470",
        },
        {
          label:
            "101775 - Baby & Kids Fashion/Girl Clothes/Tops/Shirts & Blouses",
          value: "101775",
        },
        {
          label:
            "100270 - Mobile & Gadgets/Wearable Devices/Smartwatches & Fitness Trackers",
          value: "100270",
        },
        {
          label: "102087 - Food & Beverages/Fresh & Frozen Food/Fruits/Citrus",
          value: "102087",
        },
        { label: "101135 - Home & Living/Bathrooms/Bathtubs", value: "101135" },
        {
          label:
            "101415 - Automobiles/Automobile Interior Accessories/Navigation & AV Receivers",
          value: "101415",
        },
        {
          label:
            "100471 - Home Appliances/Kitchen Appliances/Specialty Cookware/Takoyaki Makers",
          value: "100471",
        },
        {
          label: "101776 - Baby & Kids Fashion/Girl Clothes/Tops/Others",
          value: "101776",
        },
        {
          label: "100271 - Mobile & Gadgets/Wearable Devices/VR Devices",
          value: "100271",
        },
        {
          label: "102088 - Food & Beverages/Fresh & Frozen Food/Fruits/Grapes",
          value: "102088",
        },
        {
          label: "101137 - Home & Living/Bathrooms/Showerheads & Bidet Sprays",
          value: "101137",
        },
        {
          label:
            "101416 - Automobiles/Automobile Interior Accessories/Amplifiers, Speakers & Subwoofers",
          value: "101416",
        },
        {
          label:
            "100472 - Home Appliances/Kitchen Appliances/Specialty Cookware/Dessert Machine",
          value: "100472",
        },
        {
          label:
            "101777 - Baby & Kids Fashion/Girl Clothes/Outerwear/Regular Jackets & Coats",
          value: "101777",
        },
        {
          label: "100272 - Mobile & Gadgets/Wearable Devices/GPS Trackers",
          value: "100272",
        },
        {
          label: "102089 - Food & Beverages/Fresh & Frozen Food/Fruits/Melons",
          value: "102089",
        },
        {
          label: "101138 - Home & Living/Bathrooms/Bath Brushes & Loofahs",
          value: "101138",
        },
        {
          label:
            "101417 - Automobiles/Automobile Interior Accessories/Organizers & Compartments",
          value: "101417",
        },
        {
          label:
            "100473 - Home Appliances/Kitchen Appliances/Specialty Cookware/Soda Makers",
          value: "100473",
        },
        {
          label:
            "101778 - Baby & Kids Fashion/Girl Clothes/Outerwear/Winter Outerwear",
          value: "101778",
        },
        { label: "100008 - Health/Others", value: "100008" },
        {
          label: "100273 - Mobile & Gadgets/Wearable Devices/Others",
          value: "100273",
        },
        {
          label:
            "102090 - Food & Beverages/Fresh & Frozen Food/Fruits/Stonefruits",
          value: "102090",
        },
        {
          label: "101139 - Home & Living/Bathrooms/Shower Curtains",
          value: "101139",
        },
        {
          label:
            "101418 - Automobiles/Automobile Interior Accessories/Perfumes, Air Fresheners & Purifiers",
          value: "101418",
        },
        {
          label:
            "100474 - Home Appliances/Kitchen Appliances/Specialty Cookware/Others",
          value: "100474",
        },
        {
          label: "101779 - Baby & Kids Fashion/Girl Clothes/Outerwear/Vests",
          value: "101779",
        },
        { label: "100021 - Fashion Accessories/Rings", value: "100021" },
        {
          label: "100275 - Mobile & Gadgets/Accessories/Mobile Lens",
          value: "100275",
        },
        {
          label:
            "102091 - Food & Beverages/Fresh & Frozen Food/Fruits/Tropical & Exotic Fruits",
          value: "102091",
        },
        {
          label: "101140 - Home & Living/Bathrooms/Shower Seats & Commodes",
          value: "101140",
        },
        {
          label:
            "101419 - Automobiles/Automobile Interior Accessories/Carpets & Mats",
          value: "101419",
        },
        {
          label:
            "100475 - Mobile & Gadgets/Accessories/Selfie Accessories/Selfie Sticks",
          value: "100475",
        },
        {
          label:
            "101780 - Baby & Kids Fashion/Girl Clothes/Outerwear/Sweaters & Cardigans",
          value: "101780",
        },
        { label: "100022 - Fashion Accessories/Earrings", value: "100022" },
        {
          label:
            "100276 - Mobile & Gadgets/Accessories/Mobile Flashes & Selfie Lights",
          value: "100276",
        },
        {
          label:
            "102092 - Food & Beverages/Fresh & Frozen Food/Fruits/Pre-cut & Assorted Fruit Set",
          value: "102092",
        },
        {
          label: "101141 - Home & Living/Bathrooms/Safety Handles",
          value: "101141",
        },
        {
          label:
            "101420 - Automobiles/Automobile Interior Accessories/Seat Headrests & Back Supports",
          value: "101420",
        },
        {
          label:
            "100476 - Mobile & Gadgets/Accessories/Selfie Accessories/Lazypods",
          value: "100476",
        },
        {
          label: "101781 - Baby & Kids Fashion/Girl Clothes/Outerwear/Blazers",
          value: "101781",
        },
        {
          label: "100023 - Fashion Accessories/Scarves & Shawls",
          value: "100023",
        },
        {
          label: "100277 - Mobile & Gadgets/Accessories/USB & Mobile Fans",
          value: "100277",
        },
        {
          label: "102093 - Food & Beverages/Fresh & Frozen Food/Fruits/Others",
          value: "102093",
        },
        {
          label: "101142 - Home & Living/Bathrooms/Shower Caps",
          value: "101142",
        },
        {
          label:
            "101421 - Automobiles/Automobile Interior Accessories/Car Mattresses",
          value: "101421",
        },
        {
          label:
            "100477 - Mobile & Gadgets/Accessories/Selfie Accessories/Remote Shutters",
          value: "100477",
        },
        {
          label: "101782 - Baby & Kids Fashion/Girl Clothes/Outerwear/Hoodies",
          value: "101782",
        },
        { label: "100024 - Fashion Accessories/Gloves", value: "100024" },
        {
          label: "100278 - Mobile & Gadgets/Accessories/Stylus",
          value: "100278",
        },
        {
          label: "100853 - Food & Beverages/Fresh & Frozen Food/Mushroom",
          value: "100853",
        },
        { label: "101143 - Home & Living/Bathrooms/Others", value: "101143" },
        {
          label:
            "101422 - Automobiles/Automobile Interior Accessories/Steering Wheels & Covers",
          value: "101422",
        },
        {
          label:
            "100478 - Mobile & Gadgets/Accessories/Selfie Accessories/Others",
          value: "100478",
        },
        {
          label: "101783 - Baby & Kids Fashion/Girl Clothes/Outerwear/Others",
          value: "101783",
        },
        {
          label: "100026 - Fashion Accessories/Bracelets & Bangles",
          value: "100026",
        },
        {
          label: "100279 - Mobile & Gadgets/Accessories/Phone Grips",
          value: "100279",
        },
        { label: "100856 - Food & Beverages/Bakery/Breads", value: "100856" },
        {
          label: "101144 - Home & Living/Bedding/Cooling Mats",
          value: "101144",
        },
        {
          label:
            "101423 - Automobiles/Automobile Interior Accessories/Seats & Seat Covers",
          value: "101423",
        },
        {
          label:
            "100479 - Mobile & Gadgets/Accessories/Cables, Chargers & Converters/Cable Cases, Protectors, & Winders",
          value: "100479",
        },
        {
          label: "101784 - Baby & Kids Fashion/Girl Clothes/Bottoms/Jeans",
          value: "101784",
        },
        { label: "100027 - Fashion Accessories/Anklets", value: "100027" },
        {
          label:
            "100280 - Mobile & Gadgets/Accessories/Phone Straps & Keychains",
          value: "100280",
        },
        {
          label: "100857 - Food & Beverages/Bakery/Cakes & Pies",
          value: "100857",
        },
        {
          label: "101145 - Home & Living/Bedding/Mattress Protectors & Toppers",
          value: "101145",
        },
        {
          label:
            "101424 - Automobiles/Automobile Interior Accessories/Phone Holders",
          value: "101424",
        },
        {
          label:
            "100480 - Mobile & Gadgets/Accessories/Cables, Chargers & Converters/Travel Adaptors",
          value: "100480",
        },
        {
          label: "101785 - Baby & Kids Fashion/Girl Clothes/Bottoms/Pants",
          value: "101785",
        },
        { label: "100028 - Fashion Accessories/Hats & Caps", value: "100028" },
        {
          label: "100281 - Mobile & Gadgets/Accessories/Memory Cards",
          value: "100281",
        },
        { label: "100858 - Food & Beverages/Bakery/Pastry", value: "100858" },
        {
          label: "101146 - Home & Living/Bedding/Blankets, Comforters & Quilts",
          value: "101146",
        },
        {
          label:
            "101425 - Automobiles/Automobile Interior Accessories/USB Chargers, FM & Bluetooth Transmitters",
          value: "101425",
        },
        {
          label:
            "100481 - Mobile & Gadgets/Accessories/Cables, Chargers & Converters/Mobile Cables & Converters",
          value: "100481",
        },
        {
          label: "101786 - Baby & Kids Fashion/Girl Clothes/Bottoms/Shorts",
          value: "101786",
        },
        { label: "100029 - Fashion Accessories/Necklaces", value: "100029" },
        {
          label: "100282 - Mobile & Gadgets/Accessories/Casting Devices",
          value: "100282",
        },
        { label: "100859 - Food & Beverages/Bakery/Others", value: "100859" },
        { label: "101147 - Home & Living/Bedding/Pillows", value: "101147" },
        {
          label:
            "101426 - Automobiles/Automobile Interior Accessories/Pedals & Gear Sticks",
          value: "101426",
        },
        {
          label:
            "100482 - Mobile & Gadgets/Accessories/Cables, Chargers & Converters/Chargers",
          value: "100482",
        },
        {
          label: "101787 - Baby & Kids Fashion/Girl Clothes/Bottoms/Skirts",
          value: "101787",
        },
        { label: "100032 - Fashion Accessories/Belts", value: "100032" },
        {
          label: "100283 - Mobile & Gadgets/Accessories/Mobile Pouches",
          value: "100283",
        },
        {
          label: "100868 - Beauty/Hand, Foot & Nail Care/Others",
          value: "100868",
        },
        {
          label:
            "101148 - Home & Living/Bedding/Bedsheets, Pillowcases & Bolster Cases",
          value: "101148",
        },
        {
          label:
            "101427 - Automobiles/Automobile Interior Accessories/Sun Shields & Dash Covers",
          value: "101427",
        },
        {
          label:
            "100483 - Mobile & Gadgets/Accessories/Cables, Chargers & Converters/Docks & Stands",
          value: "100483",
        },
        {
          label: "101788 - Baby & Kids Fashion/Girl Clothes/Bottoms/Leggings",
          value: "101788",
        },
        {
          label: "100033 - Fashion Accessories/Neckties, Bow Ties & Cravats",
          value: "100033",
        },
        {
          label: "100285 - Mobile & Gadgets/Accessories/USB & Mobile Lights",
          value: "100285",
        },
        { label: "100869 - Beauty/Hair Care/Shampoo", value: "100869" },
        { label: "101149 - Home & Living/Bedding/Mattresses", value: "101149" },
        {
          label:
            "101428 - Automobiles/Automobile Interior Accessories/Locks & Security",
          value: "101428",
        },
        {
          label:
            "100484 - Mobile & Gadgets/Accessories/Cables, Chargers & Converters/Others",
          value: "100484",
        },
        {
          label: "101789 - Baby & Kids Fashion/Girl Clothes/Bottoms/Others",
          value: "101789",
        },
        {
          label: "100035 - Fashion Accessories/Accessories Sets & Packages",
          value: "100035",
        },
        {
          label: "100286 - Mobile & Gadgets/Accessories/Pocket Wifi",
          value: "100286",
        },
        { label: "100870 - Beauty/Hair Care/Hair Colour", value: "100870" },
        {
          label: "101150 - Home & Living/Bedding/Mosquito Nets",
          value: "101150",
        },
        {
          label:
            "101429 - Automobiles/Automobile Interior Accessories/Camcorders & Parking Cameras",
          value: "101429",
        },
        {
          label:
            "100486 - Mobile & Gadgets/Accessories/Powerbanks & Batteries/Powerbanks",
          value: "100486",
        },
        {
          label:
            "102021 - Cameras & Drones/Camera Accessories/Flash Accessories/Flash Triggers",
          value: "102021",
        },
        { label: "100036 - Fashion Accessories/Others", value: "100036" },
        {
          label: "100288 - Mobile & Gadgets/Accessories/Wearable Accessories",
          value: "100288",
        },
        { label: "100871 - Beauty/Hair Care/Hair Treatment", value: "100871" },
        { label: "101151 - Home & Living/Bedding/Bolsters", value: "101151" },
        {
          label:
            "101430 - Automobiles/Automobile Interior Accessories/HUD, Speedometers & Gauges",
          value: "101430",
        },
        {
          label:
            "100487 - Mobile & Gadgets/Accessories/Powerbanks & Batteries/Powercases",
          value: "100487",
        },
        {
          label:
            "102022 - Cameras & Drones/Camera Accessories/Flash Accessories/Flash Diffusers",
          value: "102022",
        },
        { label: "100045 - Home Appliances/Remote Controls", value: "100045" },
        {
          label: "100289 - Mobile & Gadgets/Accessories/Screen Protectors",
          value: "100289",
        },
        {
          label: "100872 - Beauty/Hair Care/Hair and Scalp Conditioner",
          value: "100872",
        },
        { label: "101152 - Home & Living/Bedding/Others", value: "101152" },
        {
          label: "101431 - Automobiles/Automobile Interior Accessories/Others",
          value: "101431",
        },
        {
          label:
            "100488 - Mobile & Gadgets/Accessories/Powerbanks & Batteries/Others",
          value: "100488",
        },
        {
          label:
            "102023 - Cameras & Drones/Camera Accessories/Flash Accessories/Others",
          value: "102023",
        },
        { label: "100046 - Home Appliances/Others", value: "100046" },
        {
          label: "100291 - Mobile & Gadgets/Accessories/Others",
          value: "100291",
        },
        { label: "100873 - Beauty/Hair Care/Hair Styling", value: "100873" },
        { label: "101153 - Home & Living/Decoration/Flowers", value: "101153" },
        {
          label: "101432 - Automobiles/Automobile Exterior Accessories/Garnish",
          value: "101432",
        },
        {
          label:
            "100489 - Mobile & Gadgets/Accessories/Cases, Covers, & Skins/Tablet Cases, Covers, & Skins",
          value: "100489",
        },
        {
          label:
            "101790 - Home & Living/Bathrooms/Towels & Bathrobes/Bath Towels",
          value: "101790",
        },
        { label: "100047 - Men Clothes/Jeans", value: "100047" },
        {
          label:
            "100294 - Muslim Fashion/Women Muslim Wear/Muslimah Sports Apparels",
          value: "100294",
        },
        { label: "100874 - Beauty/Hair Care/Others", value: "100874" },
        {
          label:
            "101154 - Home & Living/Decoration/Furniture & Appliance Covers",
          value: "101154",
        },
        {
          label:
            "101433 - Automobiles/Automobile Exterior Accessories/Antennas",
          value: "101433",
        },
        {
          label:
            "100490 - Mobile & Gadgets/Accessories/Cases, Covers, & Skins/Phone Cases, Covers, & Skins",
          value: "100490",
        },
        {
          label:
            "101791 - Home & Living/Bathrooms/Towels & Bathrobes/Face & Hand Towels",
          value: "101791",
        },
        { label: "100049 - Men Clothes/Sweaters & Cardigans", value: "100049" },
        {
          label: "100295 - Muslim Fashion/Women Muslim Wear/Muslimah Swimwear",
          value: "100295",
        },
        {
          label: "100875 - Beauty/Men's Care/Bath & Body Care",
          value: "100875",
        },
        {
          label: "101155 - Home & Living/Decoration/Curtains & Blinds",
          value: "101155",
        },
        {
          label: "101434 - Automobiles/Automobile Exterior Accessories/Covers",
          value: "101434",
        },
        {
          label:
            "100491 - Mobile & Gadgets/Accessories/Cases, Covers, & Skins/Others",
          value: "100491",
        },
        {
          label:
            "101792 - Home & Living/Bathrooms/Towels & Bathrobes/Bath Robes",
          value: "101792",
        },
        { label: "100053 - Men Clothes/Shorts", value: "100053" },
        {
          label: "100299 - Muslim Fashion/Women Muslim Wear/Others",
          value: "100299",
        },
        { label: "100878 - Beauty/Men's Care/Hair Care", value: "100878" },
        {
          label:
            "101156 - Home & Living/Decoration/Photo Frames & Wall Decoration",
          value: "101156",
        },
        {
          label:
            "101435 - Automobiles/Automobile Exterior Accessories/Stickers, Logos & Emblems",
          value: "101435",
        },
        {
          label: "100492 - Muslim Fashion/Women Muslim Wear/Veils/Khimar",
          value: "100492",
        },
        {
          label: "101793 - Home & Living/Bathrooms/Towels & Bathrobes/Others",
          value: "101793",
        },
        { label: "100056 - Men Clothes/Sleepwear", value: "100056" },
        {
          label:
            "100300 - Muslim Fashion/Prayer Attire & Equipment/Prayer Sets",
          value: "100300",
        },
        { label: "100879 - Beauty/Men's Care/Others", value: "100879" },
        {
          label: "101157 - Home & Living/Decoration/Wallpapers & Wall Stickers",
          value: "101157",
        },
        {
          label:
            "101436 - Automobiles/Automobile Exterior Accessories/Mud Flaps & Splash Guards",
          value: "101436",
        },
        {
          label:
            "100493 - Muslim Fashion/Women Muslim Wear/Veils/Instant Hijab",
          value: "100493",
        },
        {
          label:
            "101794 - Home & Living/Tools & Home Improvement/Tools/Tool Boxes",
          value: "101794",
        },
        { label: "100057 - Men Clothes/Sets", value: "100057" },
        {
          label:
            "100301 - Muslim Fashion/Prayer Attire & Equipment/Prayer Mats",
          value: "100301",
        },
        { label: "100880 - Beauty/Makeup/Others", value: "100880" },
        { label: "101158 - Home & Living/Decoration/Clocks", value: "101158" },
        {
          label:
            "101437 - Automobiles/Automobile Exterior Accessories/Sill Plates",
          value: "101437",
        },
        {
          label: "100494 - Muslim Fashion/Women Muslim Wear/Veils/Square Hijab",
          value: "100494",
        },
        {
          label:
            "101795 - Home & Living/Tools & Home Improvement/Tools/Measuring Tapes",
          value: "101795",
        },
        { label: "100059 - Men Clothes/Costumes", value: "100059" },
        {
          label:
            "100302 - Muslim Fashion/Prayer Attire & Equipment/Peci, Songkok & Kopiah",
          value: "100302",
        },
        { label: "100884 - Beauty/Makeup/Makeup Removers", value: "100884" },
        {
          label: "101159 - Home & Living/Decoration/Floor Mats",
          value: "101159",
        },
        {
          label: "101438 - Automobiles/Automobile Exterior Accessories/Gutters",
          value: "101438",
        },
        {
          label: "100495 - Muslim Fashion/Women Muslim Wear/Veils/Pashmina",
          value: "100495",
        },
        {
          label:
            "101796 - Home & Living/Tools & Home Improvement/Tools/Spanner Sets",
          value: "101796",
        },
        { label: "100060 - Men Clothes/Occupational Attire", value: "100060" },
        {
          label: "100303 - Muslim Fashion/Prayer Attire & Equipment/Telekung",
          value: "100303",
        },
        {
          label: "100887 - Beauty/Beauty Tools/Body Slimming Tools",
          value: "100887",
        },
        {
          label: "101160 - Home & Living/Decoration/Carpets & Rugs",
          value: "101160",
        },
        {
          label:
            "101439 - Automobiles/Automobile Exterior Accessories/Horns & Accessories",
          value: "101439",
        },
        {
          label: "100497 - Muslim Fashion/Women Muslim Wear/Veils/Others",
          value: "100497",
        },
        {
          label:
            "101797 - Home & Living/Tools & Home Improvement/Tools/Hammers",
          value: "101797",
        },
        { label: "100061 - Men Clothes/Others", value: "100061" },
        {
          label:
            "100304 - Muslim Fashion/Prayer Attire & Equipment/Telekung Travel",
          value: "100304",
        },
        {
          label: "100888 - Beauty/Beauty Tools/Hair Removal Tools",
          value: "100888",
        },
        {
          label: "101161 - Home & Living/Decoration/Vases & Vessels",
          value: "101161",
        },
        {
          label:
            "101440 - Automobiles/Automobile Exterior Accessories/Mirrors & Accessories",
          value: "101440",
        },
        {
          label: "100498 - Muslim Fashion/Women Muslim Wear/Accessories/Socks",
          value: "100498",
        },
        {
          label: "101798 - Home & Living/Tools & Home Improvement/Tools/Pliers",
          value: "101798",
        },
        { label: "100062 - Men Clothes/Socks", value: "100062" },
        {
          label: "100305 - Muslim Fashion/Prayer Attire & Equipment/Others",
          value: "100305",
        },
        { label: "100890 - Beauty/Beauty Tools/Others", value: "100890" },
        {
          label: "101162 - Home & Living/Decoration/Candles & Candleholders",
          value: "101162",
        },
        {
          label:
            "101441 - Automobiles/Automobile Exterior Accessories/License Plate Accessories",
          value: "101441",
        },
        {
          label:
            "100499 - Muslim Fashion/Women Muslim Wear/Accessories/Handsocks",
          value: "100499",
        },
        {
          label:
            "101799 - Home & Living/Tools & Home Improvement/Tools/Nails, Screws & Fasteners",
          value: "101799",
        },
        { label: "100064 - Men Shoes/Sneakers", value: "100064" },
        {
          label: "100306 - Muslim Fashion/Men Muslim Wear/Tops",
          value: "100306",
        },
        { label: "100891 - Beauty/Skincare/Facial Cleanser", value: "100891" },
        { label: "101163 - Home & Living/Decoration/Mirrors", value: "101163" },
        {
          label: "101442 - Automobiles/Automobile Exterior Accessories/Others",
          value: "101442",
        },
        {
          label: "100500 - Muslim Fashion/Women Muslim Wear/Accessories/Others",
          value: "100500",
        },
        {
          label:
            "101800 - Home & Living/Tools & Home Improvement/Tools/Drills, Screwdrivers & Accessories",
          value: "101800",
        },
        { label: "100065 - Men Shoes/Slip Ons & Mules", value: "100065" },
        {
          label: "100307 - Muslim Fashion/Men Muslim Wear/Robe & Jubah",
          value: "100307",
        },
        { label: "100892 - Beauty/Skincare/Toner", value: "100892" },
        {
          label: "101164 - Home & Living/Decoration/Table Cloths",
          value: "101164",
        },
        {
          label:
            "101443 - Automobiles/Automobile Spare Parts/Body, Frame & Bumpers",
          value: "101443",
        },
        {
          label: "100501 - Muslim Fashion/Women Muslim Wear/Top/Tunic",
          value: "100501",
        },
        {
          label:
            "101801 - Home & Living/Tools & Home Improvement/Tools/Saws, Cut-off Machines & Grinders",
          value: "101801",
        },
        { label: "100066 - Men Shoes/Loafers & Boat Shoes", value: "100066" },
        {
          label: "100308 - Muslim Fashion/Men Muslim Wear/Pants",
          value: "100308",
        },
        {
          label: "100893 - Beauty/Skincare/Facial Moisturizer",
          value: "100893",
        },
        { label: "101165 - Home & Living/Decoration/Others", value: "101165" },
        {
          label:
            "101444 - Automobiles/Automobile Spare Parts/Windshield Wipers & Washers",
          value: "101444",
        },
        {
          label:
            "100502 - Muslim Fashion/Women Muslim Wear/Top/Shirts, Blouses & Inner Blouses",
          value: "100502",
        },
        {
          label:
            "101802 - Home & Living/Tools & Home Improvement/Tools/Pressure Washers",
          value: "101802",
        },
        { label: "100067 - Men Shoes/Oxfords & Lace-Ups", value: "100067" },
        {
          label: "100309 - Muslim Fashion/Men Muslim Wear/Sarong",
          value: "100309",
        },
        { label: "100894 - Beauty/Skincare/Facial Oil", value: "100894" },
        { label: "101166 - Home & Living/Furniture/Cushions", value: "101166" },
        {
          label:
            "101445 - Automobiles/Automobile Spare Parts/Exhaust & Emissions",
          value: "101445",
        },
        {
          label: "100503 - Muslim Fashion/Women Muslim Wear/Top/Nursing Blouse",
          value: "100503",
        },
        {
          label:
            "101803 - Home & Living/Tools & Home Improvement/Tools/Power Generators",
          value: "101803",
        },
        { label: "100070 - Men Shoes/Others", value: "100070" },
        {
          label: "100310 - Muslim Fashion/Men Muslim Wear/Baju Melayu",
          value: "100310",
        },
        { label: "100895 - Beauty/Skincare/Facial Mist", value: "100895" },
        {
          label: "101167 - Home & Living/Furniture/Doorstoppers",
          value: "101167",
        },
        {
          label:
            "101446 - Automobiles/Automobile Spare Parts/Wheels, Rims & Accessories",
          value: "101446",
        },
        {
          label: "100504 - Muslim Fashion/Women Muslim Wear/Top/Others",
          value: "100504",
        },
        {
          label:
            "101804 - Home & Living/Tools & Home Improvement/Tools/Electrical Testers & Multimeters",
          value: "101804",
        },
        { label: "100071 - Mobile & Gadgets/Sim Cards", value: "100071" },
        {
          label: "100311 - Muslim Fashion/Men Muslim Wear/Others",
          value: "100311",
        },
        {
          label: "100896 - Beauty/Skincare/Facial Serum & Essence",
          value: "100896",
        },
        {
          label: "101168 - Home & Living/Furniture/Bed Frames & Headboards",
          value: "101168",
        },
        {
          label:
            "101447 - Automobiles/Automobile Spare Parts/Tires & Accessories",
          value: "101447",
        },
        {
          label:
            "100505 - Muslim Fashion/Women Muslim Wear/Dresses/Robe & Jubah",
          value: "100505",
        },
        {
          label:
            "101805 - Home & Living/Tools & Home Improvement/Tools/Levels & Measuring Wheels",
          value: "101805",
        },
        { label: "100072 - Mobile & Gadgets/Tablets", value: "100072" },
        {
          label: "100313 - Muslim Fashion/Kid Muslim Wear/Boy's Muslim Attire",
          value: "100313",
        },
        {
          label: "100897 - Beauty/Skincare/Face Scrub & Peel",
          value: "100897",
        },
        {
          label: "101169 - Home & Living/Furniture/Desks & Tables",
          value: "101169",
        },
        {
          label:
            "101448 - Automobiles/Automobile Spare Parts/Shocks, Struts & Suspension",
          value: "101448",
        },
        {
          label:
            "100506 - Muslim Fashion/Women Muslim Wear/Dresses/Abaya Cloak",
          value: "100506",
        },
        {
          label:
            "101806 - Home & Living/Tools & Home Improvement/Tools/Rangefinders",
          value: "101806",
        },
        { label: "100073 - Mobile & Gadgets/Mobile Phones", value: "100073" },
        {
          label: "100314 - Muslim Fashion/Kid Muslim Wear/Others",
          value: "100314",
        },
        {
          label: "100898 - Beauty/Skincare/Face Mask & Packs",
          value: "100898",
        },
        {
          label: "101170 - Home & Living/Furniture/Wardrobes",
          value: "101170",
        },
        {
          label:
            "101449 - Automobiles/Automobile Spare Parts/Radiators, Engine Cooling & Climate Control",
          value: "101449",
        },
        {
          label:
            "100507 - Muslim Fashion/Women Muslim Wear/Dresses/Nursing Jubah",
          value: "100507",
        },
        {
          label:
            "101807 - Home & Living/Tools & Home Improvement/Tools/Air Compressors",
          value: "101807",
        },
        { label: "100076 - Mobile & Gadgets/Walkie Talkies", value: "100076" },
        { label: "100315 - Muslim Fashion/Outerwear/Vests", value: "100315" },
        { label: "100901 - Beauty/Skincare/Face Sunscreen", value: "100901" },
        {
          label: "101171 - Home & Living/Furniture/Benches, Chairs & Stools",
          value: "101171",
        },
        {
          label:
            "101450 - Automobiles/Automobile Spare Parts/Drivetrain, Transmission & Clutches",
          value: "101450",
        },
        {
          label: "100508 - Muslim Fashion/Women Muslim Wear/Dresses/Kaftan",
          value: "100508",
        },
        {
          label:
            "101808 - Home & Living/Tools & Home Improvement/Tools/Sandpaper, Power Sanders & Accessories",
          value: "101808",
        },
        { label: "100077 - Mobile & Gadgets/Others", value: "100077" },
        { label: "100316 - Muslim Fashion/Outerwear/Jackets", value: "100316" },
        {
          label: "100902 - Beauty/Skincare/After Sun Face Care",
          value: "100902",
        },
        { label: "101172 - Home & Living/Furniture/Sofas", value: "101172" },
        {
          label: "101451 - Automobiles/Automobile Spare Parts/Bearing & Seals",
          value: "101451",
        },
        {
          label:
            "100509 - Muslim Fashion/Women Muslim Wear/Dresses/Baju Kelawar",
          value: "100509",
        },
        {
          label:
            "101809 - Home & Living/Tools & Home Improvement/Tools/Power Welding Tools",
          value: "101809",
        },
        { label: "100083 - Muslim Fashion/Sets", value: "100083" },
        { label: "100317 - Muslim Fashion/Outerwear/Coats", value: "100317" },
        { label: "100903 - Beauty/Skincare/Blotting Paper", value: "100903" },
        {
          label: "101173 - Home & Living/Furniture/Cupboards & Cabinets",
          value: "101173",
        },
        {
          label: "101453 - Automobiles/Automobile Spare Parts/Brake System",
          value: "101453",
        },
        {
          label:
            "100510 - Muslim Fashion/Women Muslim Wear/Dresses/Baju Kurung",
          value: "100510",
        },
        {
          label:
            "101810 - Home & Living/Tools & Home Improvement/Tools/Blowers",
          value: "101810",
        },
        { label: "100084 - Muslim Fashion/Others", value: "100084" },
        {
          label: "100318 - Muslim Fashion/Outerwear/Cardigans",
          value: "100318",
        },
        { label: "100904 - Beauty/Skincare/Acne Treatment", value: "100904" },
        {
          label: "101174 - Home & Living/Furniture/Shelves & Racks",
          value: "101174",
        },
        {
          label:
            "101454 - Automobiles/Automobile Spare Parts/Belts, Hoses & Pulleys",
          value: "101454",
        },
        {
          label: "100511 - Muslim Fashion/Women Muslim Wear/Dresses/Set Warda",
          value: "100511",
        },
        {
          label: "101811 - Home & Living/Tools & Home Improvement/Tools/Others",
          value: "101811",
        },
        { label: "100085 - Travel & Luggage/Luggage", value: "100085" },
        { label: "100319 - Muslim Fashion/Outerwear/Others", value: "100319" },
        { label: "100905 - Beauty/Skincare/Others", value: "100905" },
        { label: "101175 - Home & Living/Furniture/Others", value: "101175" },
        {
          label: "101456 - Automobiles/Automobile Spare Parts/Fuel System",
          value: "101456",
        },
        {
          label: "100512 - Muslim Fashion/Women Muslim Wear/Dresses/Jumpsuits",
          value: "100512",
        },
        {
          label:
            "101812 - Home & Living/Home Care Supplies/Laundry Care/Fabric Fragrances",
          value: "101812",
        },
        { label: "100088 - Travel & Luggage/Others", value: "100088" },
        {
          label:
            "100320 - Travel & Luggage/Travel Bags/Duffel & Weekender Bags",
          value: "100320",
        },
        {
          label: "102003 - Beauty/Bath & Body Care/Body Wash & Soap",
          value: "102003",
        },
        { label: "101176 - Home & Living/Gardening/Plants", value: "101176" },
        {
          label: "101457 - Automobiles/Automobile Spare Parts/Others",
          value: "101457",
        },
        {
          label: "100513 - Muslim Fashion/Women Muslim Wear/Dresses/Others",
          value: "100513",
        },
        {
          label:
            "101813 - Home & Living/Home Care Supplies/Laundry Care/Fabric Conditioners & Softeners",
          value: "101813",
        },
        { label: "100089 - Women Bags/Backpacks", value: "100089" },
        {
          label: "100321 - Travel & Luggage/Travel Bags/Foldable Bags",
          value: "100321",
        },
        {
          label: "102004 - Beauty/Bath & Body Care/Body Scrub & Peel",
          value: "102004",
        },
        {
          label: "101177 - Home & Living/Gardening/Garden Decorations",
          value: "101177",
        },
        {
          label:
            "101458 - Automobiles/Automotive Tools/Test, Diagnostic & Repair Tools",
          value: "101458",
        },
        {
          label: "100514 - Muslim Fashion/Women Muslim Wear/Bottoms/Skirt",
          value: "100514",
        },
        {
          label:
            "101814 - Home & Living/Home Care Supplies/Laundry Care/Detergents",
          value: "101814",
        },
        { label: "100091 - Women Bags/Clutches & Wristlets", value: "100091" },
        {
          label: "100322 - Travel & Luggage/Travel Bags/Drawstring Bags",
          value: "100322",
        },
        {
          label: "102005 - Beauty/Bath & Body Care/Body Masks",
          value: "102005",
        },
        {
          label:
            "101178 - Home & Living/Gardening/Garden Soils & Growing Media",
          value: "101178",
        },
        {
          label:
            "101459 - Automobiles/Automotive Tools/Tire Pressure Detectors",
          value: "101459",
        },
        {
          label: "100515 - Muslim Fashion/Women Muslim Wear/Bottoms/Pants",
          value: "100515",
        },
        {
          label:
            "101815 - Home & Living/Home Care Supplies/Laundry Care/Others",
          value: "101815",
        },
        {
          label: "100092 - Women Bags/Waist Bags & Chest Bags",
          value: "100092",
        },
        {
          label: "100323 - Travel & Luggage/Travel Bags/Others",
          value: "100323",
        },
        { label: "102006 - Beauty/Bath & Body Care/Body Oil", value: "102006" },
        {
          label: "101179 - Home & Living/Gardening/Fertilizer",
          value: "101179",
        },
        {
          label: "101460 - Automobiles/Automotive Tools/Others",
          value: "101460",
        },
        {
          label: "100516 - Muslim Fashion/Women Muslim Wear/Bottoms/Leggings",
          value: "100516",
        },
        {
          label:
            "101816 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fishing/Fishing Hooks",
          value: "101816",
        },
        { label: "100093 - Women Bags/Tote Bags", value: "100093" },
        {
          label:
            "100324 - Travel & Luggage/Travel Accessories/Passport Holders & Covers",
          value: "100324",
        },
        {
          label: "102007 - Beauty/Bath & Body Care/Body Cream, Lotion & Butter",
          value: "102007",
        },
        {
          label: "101180 - Home & Living/Gardening/Seeds & Bulbs",
          value: "101180",
        },
        {
          label: "101461 - Automobiles/Automotive Care/Wash & Waxes",
          value: "101461",
        },
        {
          label: "100517 - Muslim Fashion/Women Muslim Wear/Bottoms/Palazzo",
          value: "100517",
        },
        {
          label:
            "101817 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fishing/Fishing Rods & Reels",
          value: "101817",
        },
        { label: "100094 - Women Bags/Top-handle Bags", value: "100094" },
        {
          label:
            "100325 - Travel & Luggage/Travel Accessories/Travel Organizers",
          value: "100325",
        },
        {
          label: "102008 - Beauty/Bath & Body Care/Body Deodorants",
          value: "102008",
        },
        {
          label: "101181 - Home & Living/Gardening/Pots & Planters",
          value: "101181",
        },
        {
          label:
            "101462 - Automobiles/Automotive Care/Glass Care & Water Repellents",
          value: "101462",
        },
        {
          label: "100518 - Muslim Fashion/Women Muslim Wear/Bottoms/Others",
          value: "100518",
        },
        {
          label:
            "101818 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fishing/Fish Finders",
          value: "101818",
        },
        {
          label: "100095 - Women Bags/Crossbody & Shoulder Bags",
          value: "100095",
        },
        {
          label:
            "100326 - Travel & Luggage/Travel Accessories/Luggage Protectors & Covers",
          value: "100326",
        },
        {
          label: "102009 - Beauty/Bath & Body Care/Massage Oil",
          value: "102009",
        },
        {
          label: "101182 - Home & Living/Gardening/Irrigation Systems",
          value: "101182",
        },
        {
          label: "101463 - Automobiles/Automotive Care/Interior Care",
          value: "101463",
        },
        {
          label:
            "100519 - Muslim Fashion/Kid Muslim Wear/Girl's Muslim Attire/Veils",
          value: "100519",
        },
        {
          label:
            "101819 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fishing/Fishing Lines",
          value: "101819",
        },
        { label: "100098 - Women Bags/Others", value: "100098" },
        {
          label: "100327 - Travel & Luggage/Travel Accessories/Luggage Tags",
          value: "100327",
        },
        {
          label: "102010 - Beauty/Bath & Body Care/Hair Removal Cream & Wax",
          value: "102010",
        },
        {
          label: "101183 - Home & Living/Gardening/Gardening Tools",
          value: "101183",
        },
        {
          label: "101464 - Automobiles/Automotive Care/Tire & Wheel Care",
          value: "101464",
        },
        {
          label:
            "100520 - Muslim Fashion/Kid Muslim Wear/Girl's Muslim Attire/Telekung",
          value: "100520",
        },
        {
          label:
            "101820 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fishing/Lures & Baits",
          value: "101820",
        },
        { label: "100102 - Women Clothes/Skirts", value: "100102" },
        {
          label: "100328 - Travel & Luggage/Travel Accessories/Luggage Straps",
          value: "100328",
        },
        {
          label: "102012 - Beauty/Bath & Body Care/Breast Care",
          value: "102012",
        },
        { label: "101184 - Home & Living/Gardening/Others", value: "101184" },
        {
          label:
            "101465 - Automobiles/Automotive Care/Polish, Coating & Sealants",
          value: "101465",
        },
        {
          label:
            "100521 - Muslim Fashion/Kid Muslim Wear/Girl's Muslim Attire/Attire Piece & Sets",
          value: "100521",
        },
        {
          label:
            "101821 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fishing/Fishing Bags",
          value: "101821",
        },
        { label: "100103 - Women Clothes/Jeans", value: "100103" },
        {
          label: "100329 - Travel & Luggage/Travel Accessories/Luggage Locks",
          value: "100329",
        },
        { label: "102013 - Beauty/Bath & Body Care/Others", value: "102013" },
        {
          label: "101185 - Home & Living/Gardening/Lawn Mowers",
          value: "101185",
        },
        {
          label: "101466 - Automobiles/Automotive Care/Others",
          value: "101466",
        },
        {
          label:
            "100522 - Muslim Fashion/Kid Muslim Wear/Girl's Muslim Attire/Others",
          value: "100522",
        },
        {
          label:
            "101822 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fishing/Fishing Nets",
          value: "101822",
        },
        { label: "100104 - Women Clothes/Dresses", value: "100104" },
        {
          label: "100330 - Travel & Luggage/Travel Accessories/Luggage Scales",
          value: "100330",
        },
        { label: "100906 - Pets/Pet Food/Dog Food", value: "100906" },
        {
          label:
            "101186 - Home & Living/Tools & Home Improvement/Industrial Adhesives & Tapes",
          value: "101186",
        },
        {
          label: "101472 - Automobiles/Automotive Oils & Lubes/Others",
          value: "101472",
        },
        {
          label:
            "100523 - Women Clothes/Lingerie & Underwear/Bra Accessories/Straps",
          value: "100523",
        },
        {
          label:
            "101823 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fishing/Others",
          value: "101823",
        },
        { label: "100105 - Women Clothes/Wedding Dresses", value: "100105" },
        {
          label:
            "100331 - Travel & Luggage/Travel Accessories/Travel Pillows & Eye Covers",
          value: "100331",
        },
        { label: "100907 - Pets/Pet Food/Dog Treats", value: "100907" },
        {
          label:
            "101187 - Home & Living/Tools & Home Improvement/Protective Gloves, Goggles & Masks",
          value: "101187",
        },
        {
          label: "101473 - Motorcycles/Motorcycle Accessories/Carpets",
          value: "101473",
        },
        {
          label:
            "100524 - Women Clothes/Lingerie & Underwear/Bra Accessories/Paddings",
          value: "100524",
        },
        {
          label:
            "101824 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Cycling/Bicycles",
          value: "101824",
        },
        {
          label: "100108 - Women Clothes/Sweaters & Cardigans",
          value: "100108",
        },
        {
          label:
            "100332 - Travel & Luggage/Travel Accessories/Travel Size Bottles & Containers",
          value: "100332",
        },
        { label: "100908 - Pets/Pet Food/Cat Food", value: "100908" },
        {
          label:
            "101188 - Home & Living/Tools & Home Improvement/Sinks & Water Taps",
          value: "101188",
        },
        {
          label:
            "101474 - Motorcycles/Motorcycle Accessories/Speedometers, Odometers & Gauges",
          value: "101474",
        },
        {
          label:
            "100525 - Women Clothes/Lingerie & Underwear/Bra Accessories/Nipple Stickers",
          value: "100525",
        },
        {
          label:
            "101825 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Cycling/Bicycle Parts & Accessories",
          value: "101825",
        },
        { label: "100115 - Women Clothes/Costumes", value: "100115" },
        {
          label: "100333 - Travel & Luggage/Travel Accessories/Others",
          value: "100333",
        },
        { label: "100909 - Pets/Pet Food/Cat Treats", value: "100909" },
        {
          label:
            "101189 - Home & Living/Tools & Home Improvement/Roofing & Flooring",
          value: "101189",
        },
        {
          label: "101475 - Motorcycles/Motorcycle Accessories/Covers",
          value: "101475",
        },
        {
          label:
            "100526 - Women Clothes/Lingerie & Underwear/Bra Accessories/Others",
          value: "100526",
        },
        {
          label:
            "101826 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Cycling/Cycling Helmets",
          value: "101826",
        },
        { label: "100116 - Women Clothes/Others", value: "100116" },
        {
          label: "100334 - Women Bags/Laptop Bags/Laptop Bags & Cases",
          value: "100334",
        },
        { label: "100910 - Pets/Pet Food/Small Pet Food", value: "100910" },
        {
          label:
            "101190 - Home & Living/Tools & Home Improvement/Wall Paints & Coatings",
          value: "101190",
        },
        {
          label:
            "101476 - Motorcycles/Motorcycle Accessories/Stickers, Logos & Emblems",
          value: "101476",
        },
        {
          label:
            "101574 - Food & Beverages/Snacks/Dried Snacks/Meat Jerky and Bakkwa",
          value: "101574",
        },
        {
          label:
            "101827 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Cycling/Others",
          value: "101827",
        },
        { label: "100557 - Women Shoes/Sneakers", value: "100557" },
        {
          label: "100335 - Women Bags/Laptop Bags/Laptop Sleeves",
          value: "100335",
        },
        { label: "100911 - Pets/Pet Food/Small Pet Treats", value: "100911" },
        {
          label:
            "101192 - Home & Living/Tools & Home Improvement/Water Pumps, Parts & Accessories",
          value: "101192",
        },
        {
          label:
            "101477 - Motorcycles/Motorcycle Accessories/Seats & Seat Covers",
          value: "101477",
        },
        {
          label: "101575 - Food & Beverages/Snacks/Dried Snacks/Dried Fruits",
          value: "101575",
        },
        {
          label:
            "101828 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Camping & Hiking/Camping Cookware",
          value: "101828",
        },
        { label: "100559 - Women Shoes/Heels", value: "100559" },
        {
          label: "100336 - Women Bags/Laptop Bags/Laptop Backpacks",
          value: "100336",
        },
        { label: "100912 - Pets/Pet Food/Aquarium Pet Food", value: "100912" },
        {
          label:
            "101193 - Home & Living/Tools & Home Improvement/Air Pumps, Parts & Accessories",
          value: "101193",
        },
        {
          label:
            "101478 - Motorcycles/Motorcycle Accessories/Mirrors & Accessories",
          value: "101478",
        },
        {
          label: "101576 - Food & Beverages/Snacks/Dried Snacks/Meat Floss",
          value: "101576",
        },
        {
          label:
            "101829 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Camping & Hiking/Camping Lamps & Flashlights",
          value: "101829",
        },
        { label: "100560 - Women Shoes/Wedges", value: "100560" },
        { label: "100337 - Women Bags/Laptop Bags/Others", value: "100337" },
        { label: "100913 - Pets/Pet Food/Bird Feed", value: "100913" },
        {
          label: "101194 - Home & Living/Tools & Home Improvement/Ladders",
          value: "101194",
        },
        {
          label: "101479 - Motorcycles/Motorcycle Accessories/Locks & Security",
          value: "101479",
        },
        {
          label:
            "101577 - Food & Beverages/Snacks/Dried Snacks/Dried Seafood Snacks",
          value: "101577",
        },
        {
          label:
            "101830 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Camping & Hiking/Tents & Tent Accessories",
          value: "101830",
        },
        { label: "100563 - Women Shoes/Others", value: "100563" },
        { label: "100338 - Women Bags/Wallets/Card Holders", value: "100338" },
        { label: "100914 - Pets/Pet Food/Reptile Food", value: "100914" },
        {
          label: "101195 - Home & Living/Tools & Home Improvement/Trollies",
          value: "101195",
        },
        {
          label: "101480 - Motorcycles/Motorcycle Accessories/Boxes & Cases",
          value: "101480",
        },
        {
          label: "101578 - Food & Beverages/Snacks/Dried Snacks/Others",
          value: "101578",
        },
        {
          label:
            "101831 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Camping & Hiking/Sleeping Bags",
          value: "101831",
        },
        { label: "100564 - Men Bags/Backpacks", value: "100564" },
        {
          label: "100339 - Women Bags/Wallets/Coin Holders & Purses",
          value: "100339",
        },
        { label: "100915 - Pets/Pet Food/Others", value: "100915" },
        {
          label:
            "101196 - Home & Living/Tools & Home Improvement/Shades, Awnings & Tarpaulins",
          value: "101196",
        },
        {
          label: "101481 - Motorcycles/Motorcycle Accessories/Phone Holders",
          value: "101481",
        },
        {
          label:
            "101579 - Food & Beverages/Food Staples/Dried Goods/Beans & Grains",
          value: "101579",
        },
        {
          label:
            "101832 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Camping & Hiking/Binoculars, Monoculars & Telescopes",
          value: "101832",
        },
        { label: "100566 - Men Bags/Tote Bags", value: "100566" },
        {
          label: "100340 - Women Bags/Wallets/Phone & Key Wallets",
          value: "100340",
        },
        {
          label: "100916 - Pets/Pet Accessories/Bowls & Feeders",
          value: "100916",
        },
        {
          label:
            "101197 - Home & Living/Tools & Home Improvement/Construction Materials",
          value: "101197",
        },
        {
          label:
            "101482 - Motorcycles/Motorcycle Accessories/Mud Flaps & Splash Guards",
          value: "101482",
        },
        {
          label:
            "101580 - Food & Beverages/Food Staples/Dried Goods/Dried Seafood",
          value: "101580",
        },
        {
          label:
            "101833 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Camping & Hiking/Compasses",
          value: "101833",
        },
        { label: "100567 - Men Bags/Briefcases", value: "100567" },
        {
          label: "100341 - Women Bags/Wallets/Bifold & Trifold Wallets",
          value: "100341",
        },
        {
          label: "100917 - Pets/Pet Accessories/Travel Essentials",
          value: "100917",
        },
        {
          label:
            "101198 - Home & Living/Tools & Home Improvement/Doors & Windows",
          value: "101198",
        },
        {
          label: "101483 - Motorcycles/Motorcycle Accessories/Others",
          value: "101483",
        },
        {
          label:
            "101581 - Food & Beverages/Food Staples/Dried Goods/Traditional Herbs",
          value: "101581",
        },
        {
          label:
            "101834 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Camping & Hiking/Camping Knives & Survival Kits",
          value: "101834",
        },
        { label: "100568 - Men Bags/Clutches", value: "100568" },
        { label: "100342 - Women Bags/Wallets/Long Wallets", value: "100342" },
        {
          label:
            "100918 - Pets/Pet Accessories/Leashes, Collars, Harnesses & Muzzles",
          value: "100918",
        },
        {
          label: "101199 - Home & Living/Tools & Home Improvement/Others",
          value: "101199",
        },
        {
          label: "101485 - Motorcycles/Motorcycle Spare Parts/Fuel System",
          value: "101485",
        },
        {
          label: "101582 - Food & Beverages/Food Staples/Dried Goods/Others",
          value: "101582",
        },
        {
          label:
            "101835 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Camping & Hiking/Hammocks",
          value: "101835",
        },
        { label: "100569 - Men Bags/Waist Bags & Chest Bags", value: "100569" },
        { label: "100343 - Women Bags/Wallets/Others", value: "100343" },
        {
          label: "100921 - Pets/Pet Accessories/Aquarium Needs",
          value: "100921",
        },
        {
          label:
            "101200 - Home & Living/Home Care Supplies/Clotheslines & Drying Racks",
          value: "101200",
        },
        {
          label: "101486 - Motorcycles/Motorcycle Spare Parts/Brake System",
          value: "101486",
        },
        {
          label:
            "101583 - Food & Beverages/Food Staples/Canned Food/Canned Fruits",
          value: "101583",
        },
        {
          label:
            "101836 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Camping & Hiking/Hiking Sticks",
          value: "101836",
        },
        {
          label: "100570 - Men Bags/Crossbody & Shoulder Bags",
          value: "100570",
        },
        {
          label: "100344 - Women Bags/Bag Accessories/Bag Straps",
          value: "100344",
        },
        { label: "100922 - Pets/Pet Accessories/Others", value: "100922" },
        {
          label: "101201 - Home & Living/Home Care Supplies/Cleaning Brushes",
          value: "101201",
        },
        {
          label:
            "101487 - Motorcycles/Motorcycle Spare Parts/Shocks, Struts & Suspension",
          value: "101487",
        },
        {
          label:
            "102024 - Food & Beverages/Food Staples/Canned Food/Canned Meat",
          value: "102024",
        },
        {
          label:
            "101837 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Camping & Hiking/Picnic Mats & Baskets",
          value: "101837",
        },
        { label: "100572 - Men Bags/Others", value: "100572" },
        {
          label: "100345 - Women Bags/Bag Accessories/Bag Holders",
          value: "100345",
        },
        {
          label: "100923 - Pets/Litter & Toilet/Cat Litter & Boxes",
          value: "100923",
        },
        {
          label: "101202 - Home & Living/Home Care Supplies/Brooms",
          value: "101202",
        },
        {
          label:
            "101489 - Motorcycles/Motorcycle Spare Parts/Batteries & Accessories",
          value: "101489",
        },
        {
          label:
            "102025 - Food & Beverages/Food Staples/Canned Food/Canned Seafood",
          value: "102025",
        },
        {
          label:
            "101838 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Camping & Hiking/Others",
          value: "101838",
        },
        { label: "100573 - Watches/Women Watches", value: "100573" },
        {
          label: "100346 - Women Bags/Bag Accessories/Charms & Twillies",
          value: "100346",
        },
        {
          label: "100924 - Pets/Litter & Toilet/Small Pet Bedding & Litter",
          value: "100924",
        },
        {
          label: "101203 - Home & Living/Home Care Supplies/Dusters",
          value: "101203",
        },
        {
          label:
            "101490 - Motorcycles/Motorcycle Spare Parts/Horns & Accessories",
          value: "101490",
        },
        {
          label:
            "102026 - Food & Beverages/Food Staples/Canned Food/Canned Vegetables",
          value: "102026",
        },
        {
          label:
            "101839 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Boardsports/Skateboards & Roller Blades",
          value: "101839",
        },
        { label: "100574 - Watches/Men Watches", value: "100574" },
        {
          label: "100347 - Women Bags/Bag Accessories/Bag Organizers",
          value: "100347",
        },
        { label: "100925 - Pets/Litter & Toilet/Diapers", value: "100925" },
        {
          label: "101204 - Home & Living/Home Care Supplies/Mops",
          value: "101204",
        },
        {
          label: "101491 - Motorcycles/Motorcycle Spare Parts/Cables & Tubes",
          value: "101491",
        },
        {
          label:
            "102027 - Food & Beverages/Food Staples/Canned Food/Canned Soup",
          value: "102027",
        },
        {
          label:
            "101840 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Boardsports/Scooters & Unicycles",
          value: "101840",
        },
        { label: "100575 - Watches/Set & Couple Watches", value: "100575" },
        {
          label:
            "100348 - Women Bags/Bag Accessories/Cleaning & Care Equipment",
          value: "100348",
        },
        {
          label: "100926 - Pets/Litter & Toilet/Dog Training Pads & Trays",
          value: "100926",
        },
        {
          label:
            "101205 - Home & Living/Home Care Supplies/Basins, Buckets & Water Dippers",
          value: "101205",
        },
        {
          label: "101492 - Motorcycles/Motorcycle Spare Parts/Body & Frame",
          value: "101492",
        },
        {
          label: "102028 - Food & Beverages/Food Staples/Canned Food/Others",
          value: "102028",
        },
        {
          label:
            "101841 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Boardsports/Segways & Hoverboards",
          value: "101841",
        },
        { label: "100577 - Watches/Others", value: "100577" },
        {
          label: "100349 - Women Bags/Bag Accessories/Others",
          value: "100349",
        },
        {
          label: "100927 - Pets/Litter & Toilet/Poop Bags & Scoopers",
          value: "100927",
        },
        {
          label:
            "101206 - Home & Living/Home Care Supplies/Sponges & Scouring Pads",
          value: "101206",
        },
        {
          label:
            "101493 - Motorcycles/Motorcycle Spare Parts/Exhaust & Emissions",
          value: "101493",
        },
        {
          label:
            "101584 - Food & Beverages/Cooking Essentials/Seasonings & Condiments/Pepper",
          value: "101584",
        },
        {
          label:
            "101842 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Boardsports/Helmets & Protective Gears",
          value: "101842",
        },
        {
          label: "100578 - Audio/Earphones, Headphones & Headsets",
          value: "100578",
        },
        {
          label: "100350 - Women Clothes/Tops/Tanks & Camisoles",
          value: "100350",
        },
        { label: "100928 - Pets/Litter & Toilet/Others", value: "100928" },
        {
          label:
            "101207 - Home & Living/Home Care Supplies/Trash & Recycling Bins",
          value: "101207",
        },
        {
          label:
            "101494 - Motorcycles/Motorcycle Spare Parts/Wheels, Rims & Accessories",
          value: "101494",
        },
        {
          label:
            "101585 - Food & Beverages/Cooking Essentials/Seasonings & Condiments/Salt",
          value: "101585",
        },
        {
          label:
            "101843 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Boardsports/Others",
          value: "101843",
        },
        { label: "100580 - Audio/Microphones", value: "100580" },
        { label: "100351 - Women Clothes/Tops/Tube Tops", value: "100351" },
        { label: "100929 - Pets/Pet Grooming/Hair Care", value: "100929" },
        {
          label:
            "101208 - Home & Living/Home Care Supplies/Plastic Bags & Trash Bags",
          value: "101208",
        },
        {
          label:
            "101495 - Motorcycles/Motorcycle Spare Parts/Tires & Accessories",
          value: "101495",
        },
        {
          label:
            "101586 - Food & Beverages/Cooking Essentials/Seasonings & Condiments/Herbs & Spices",
          value: "101586",
        },
        {
          label:
            "101844 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Archery/Bows",
          value: "101844",
        },
        { label: "100581 - Audio/Amplifiers & Mixers", value: "100581" },
        { label: "100352 - Women Clothes/Tops/T-shirts", value: "100352" },
        { label: "100930 - Pets/Pet Grooming/Oral Care", value: "100930" },
        {
          label: "101209 - Home & Living/Home Care Supplies/Cleaning Cloths",
          value: "101209",
        },
        {
          label: "101496 - Motorcycles/Motorcycle Spare Parts/Lighting",
          value: "101496",
        },
        {
          label:
            "101587 - Food & Beverages/Cooking Essentials/Seasonings & Condiments/Sauce",
          value: "101587",
        },
        {
          label:
            "101845 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Archery/Arrows",
          value: "101845",
        },
        {
          label: "100583 - Audio/Audio & Video Cables & Converters",
          value: "100583",
        },
        {
          label: "100353 - Women Clothes/Tops/Shirts & Blouses",
          value: "100353",
        },
        { label: "100931 - Pets/Pet Grooming/Claw Care", value: "100931" },
        {
          label:
            "101210 - Home & Living/Home Care Supplies/Pest & Weed Control",
          value: "101210",
        },
        {
          label: "101497 - Motorcycles/Motorcycle Spare Parts/Others",
          value: "101497",
        },
        {
          label:
            "101588 - Food & Beverages/Cooking Essentials/Seasonings & Condiments/Chilli & Sambal",
          value: "101588",
        },
        {
          label:
            "101846 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Archery/Others",
          value: "101846",
        },
        { label: "100584 - Audio/Others", value: "100584" },
        { label: "100354 - Women Clothes/Tops/Polo Shirts", value: "100354" },
        { label: "100932 - Pets/Pet Grooming/Others", value: "100932" },
        {
          label:
            "101211 - Home & Living/Home Care Supplies/Tissue & Paper Towels",
          value: "101211",
        },
        {
          label:
            "101498 - Tickets, Vouchers & Services/Events & Attractions/Theme Parks & Attractions",
          value: "101498",
        },
        {
          label:
            "101589 - Food & Beverages/Cooking Essentials/Seasonings & Condiments/Vinegar",
          value: "101589",
        },
        {
          label:
            "101847 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Soccer, Futsal & Sepak Takraw/Balls",
          value: "101847",
        },
        {
          label: "100656 - Food & Beverages/Gift Set & Hampers",
          value: "100656",
        },
        { label: "100355 - Women Clothes/Tops/Bodysuits", value: "100355" },
        {
          label: "100933 - Pets/Pet Clothing & Accessories/Pet Clothing",
          value: "100933",
        },
        {
          label: "101212 - Home & Living/Home Care Supplies/Toilet Paper",
          value: "101212",
        },
        {
          label:
            "101499 - Tickets, Vouchers & Services/Events & Attractions/Events, Fairs & Conferences",
          value: "101499",
        },
        {
          label:
            "101590 - Food & Beverages/Cooking Essentials/Seasonings & Condiments/Cooking Wine",
          value: "101590",
        },
        {
          label:
            "101848 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Soccer, Futsal & Sepak Takraw/Goalkeeper Gloves & Shin Guards",
          value: "101848",
        },
        { label: "100657 - Food & Beverages/Others", value: "100657" },
        { label: "100356 - Women Clothes/Tops/Others", value: "100356" },
        {
          label: "100934 - Pets/Pet Clothing & Accessories/Wet Weather Gear",
          value: "100934",
        },
        {
          label: "101213 - Home & Living/Home Care Supplies/Cleaning Agents",
          value: "101213",
        },
        {
          label:
            "101500 - Tickets, Vouchers & Services/Events & Attractions/Movies",
          value: "101500",
        },
        {
          label:
            "101591 - Food & Beverages/Cooking Essentials/Seasonings & Condiments/Dressing",
          value: "101591",
        },
        {
          label:
            "101849 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Soccer, Futsal & Sepak Takraw/Goal Posts & Nets",
          value: "101849",
        },
        { label: "100661 - Beauty/Perfumes & Fragrances", value: "100661" },
        {
          label: "100357 - Women Clothes/Pants & Leggings/Leggings & Treggings",
          value: "100357",
        },
        {
          label:
            "100935 - Pets/Pet Clothing & Accessories/Boots, Socks & Paw Protectors",
          value: "100935",
        },
        {
          label: "101215 - Home & Living/Home Care Supplies/Others",
          value: "101215",
        },
        {
          label:
            "101501 - Tickets, Vouchers & Services/Events & Attractions/Concerts & Exhibitions",
          value: "101501",
        },
        {
          label:
            "101592 - Food & Beverages/Cooking Essentials/Seasonings & Condiments/Others",
          value: "101592",
        },
        {
          label:
            "101850 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Soccer, Futsal & Sepak Takraw/Others",
          value: "101850",
        },
        { label: "100665 - Beauty/Beauty Sets & Packages", value: "100665" },
        {
          label: "100358 - Women Clothes/Pants & Leggings/Pants",
          value: "100358",
        },
        {
          label: "100936 - Pets/Pet Clothing & Accessories/Neck Accessories",
          value: "100936",
        },
        {
          label: "101216 - Home & Living/Kitchenware/Grills & Accessories",
          value: "101216",
        },
        {
          label:
            "101502 - Tickets, Vouchers & Services/Events & Attractions/Others",
          value: "101502",
        },
        {
          label: "101594 - Food & Beverages/Dairy & Eggs/Milk/UHT Milk",
          value: "101594",
        },
        {
          label:
            "101851 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Basketball/Basket Balls",
          value: "101851",
        },
        { label: "100666 - Beauty/Others", value: "100666" },
        {
          label: "100359 - Women Clothes/Pants & Leggings/Others",
          value: "100359",
        },
        {
          label: "100937 - Pets/Pet Clothing & Accessories/Eyewear",
          value: "100937",
        },
        {
          label: "101217 - Home & Living/Kitchenware/Bakewares & Decorations",
          value: "101217",
        },
        {
          label: "101503 - Tickets, Vouchers & Services/F&B/Dine-in & Takeaway",
          value: "101503",
        },
        {
          label:
            "101595 - Food & Beverages/Dairy & Eggs/Milk/Condensed & Evaporated Milk",
          value: "101595",
        },
        {
          label:
            "101852 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Basketball/Basketball Rings",
          value: "101852",
        },
        { label: "100673 - Pets/Others", value: "100673" },
        { label: "100360 - Women Clothes/Shorts/Shorts", value: "100360" },
        {
          label: "100938 - Pets/Pet Clothing & Accessories/Hair Accessories",
          value: "100938",
        },
        { label: "101218 - Home & Living/Kitchenware/Pans", value: "101218" },
        {
          label: "101504 - Tickets, Vouchers & Services/F&B/Food Delivery",
          value: "101504",
        },
        {
          label: "101596 - Food & Beverages/Dairy & Eggs/Milk/Powdered Milk",
          value: "101596",
        },
        {
          label:
            "101853 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Basketball/Others",
          value: "101853",
        },
        { label: "100685 - Mom & Baby/Gift Sets & Packages", value: "100685" },
        { label: "100361 - Women Clothes/Shorts/Skorts", value: "100361" },
        {
          label: "100939 - Pets/Pet Clothing & Accessories/Hats",
          value: "100939",
        },
        { label: "101219 - Home & Living/Kitchenware/Pots", value: "101219" },
        {
          label: "101505 - Tickets, Vouchers & Services/F&B/Others",
          value: "101505",
        },
        {
          label: "101597 - Food & Beverages/Dairy & Eggs/Milk/Others",
          value: "101597",
        },
        {
          label:
            "101854 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Volleyball/Volley Balls",
          value: "101854",
        },
        { label: "100686 - Mom & Baby/Others", value: "100686" },
        { label: "100362 - Women Clothes/Shorts/Others", value: "100362" },
        {
          label: "100940 - Pets/Pet Clothing & Accessories/Others",
          value: "100940",
        },
        {
          label: "101220 - Home & Living/Kitchenware/Food Storage",
          value: "101220",
        },
        {
          label:
            "101506 - Tickets, Vouchers & Services/Shopping/Malls & Department Stores",
          value: "101506",
        },
        {
          label: "101607 - Beauty/Hand, Foot & Nail Care/Hand Care/Hand Masks",
          value: "101607",
        },
        {
          label:
            "101855 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Volleyball/Volley Nets",
          value: "101855",
        },
        {
          label: "100688 - Baby & Kids Fashion/Baby Mittens & Footwear",
          value: "100688",
        },
        {
          label:
            "100363 - Women Clothes/Jumpsuits, Playsuits & Overalls/Jumpsuits",
          value: "100363",
        },
        {
          label: "100941 - Pets/Pet Healthcare/Anti Fleas & Ticks",
          value: "100941",
        },
        {
          label: "101221 - Home & Living/Kitchenware/Cling Wrap",
          value: "101221",
        },
        {
          label: "101507 - Tickets, Vouchers & Services/Shopping/Supermarkets",
          value: "101507",
        },
        {
          label:
            "101608 - Beauty/Hand, Foot & Nail Care/Hand Care/Lotion, Cream & Scrubs",
          value: "101608",
        },
        {
          label:
            "101856 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Volleyball/Others",
          value: "101856",
        },
        { label: "100694 - Baby & Kids Fashion/Others", value: "100694" },
        {
          label:
            "100364 - Women Clothes/Jumpsuits, Playsuits & Overalls/Playsuits",
          value: "100364",
        },
        { label: "100942 - Pets/Pet Healthcare/Medication", value: "100942" },
        {
          label: "101222 - Home & Living/Kitchenware/Aluminium Foil",
          value: "101222",
        },
        {
          label: "101508 - Tickets, Vouchers & Services/Shopping/Others",
          value: "101508",
        },
        {
          label: "101609 - Beauty/Hand, Foot & Nail Care/Hand Care/Hand Washes",
          value: "101609",
        },
        {
          label:
            "101857 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Badminton/Badminton Rackets",
          value: "101857",
        },
        {
          label: "100696 - Gaming & Consoles/Console Accessories",
          value: "100696",
        },
        {
          label:
            "100365 - Women Clothes/Jumpsuits, Playsuits & Overalls/Overalls",
          value: "100365",
        },
        { label: "100943 - Pets/Pet Healthcare/Others", value: "100943" },
        {
          label:
            "101223 - Home & Living/Kitchenware/Tea, Coffee & Bartending Equipments",
          value: "101223",
        },
        {
          label: "101509 - Tickets, Vouchers & Services/Services/Housekeeping",
          value: "101509",
        },
        {
          label: "101610 - Beauty/Hand, Foot & Nail Care/Hand Care/Others",
          value: "101610",
        },
        {
          label:
            "101858 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Badminton/Shuttlecocks",
          value: "101858",
        },
        { label: "100698 - Gaming & Consoles/Others", value: "100698" },
        {
          label:
            "100366 - Women Clothes/Jumpsuits, Playsuits & Overalls/Others",
          value: "100366",
        },
        {
          label: "100944 - Pets/Pet Healthcare/Vitamins & Supplements",
          value: "100944",
        },
        {
          label: "101224 - Home & Living/Kitchenware/Kitchen Racks",
          value: "101224",
        },
        {
          label:
            "101510 - Tickets, Vouchers & Services/Services/Delivery & Moving",
          value: "101510",
        },
        {
          label:
            "101611 - Beauty/Hand, Foot & Nail Care/Foot Care/Foot Deodorant",
          value: "101611",
        },
        {
          label:
            "101859 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Badminton/Badminton Nets",
          value: "101859",
        },
        { label: "100701 - Cameras & Drones/Lenses", value: "100701" },
        {
          label:
            "100367 - Women Clothes/Jackets, Coats & Vests/Winter Jackets & Coats",
          value: "100367",
        },
        {
          label: "100945 - Mom & Baby/Baby Travel Essentials/Baby Carrier",
          value: "100945",
        },
        {
          label: "101225 - Home & Living/Kitchenware/Aprons & Kitchen Gloves",
          value: "101225",
        },
        {
          label:
            "101512 - Tickets, Vouchers & Services/Services/Air-Conditioning Servicing",
          value: "101512",
        },
        {
          label: "101612 - Beauty/Hand, Foot & Nail Care/Foot Care/Foot Masks",
          value: "101612",
        },
        {
          label:
            "101860 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Badminton/Others",
          value: "101860",
        },
        { label: "100705 - Cameras & Drones/Drones", value: "100705" },
        {
          label: "100368 - Women Clothes/Jackets, Coats & Vests/Capes",
          value: "100368",
        },
        {
          label:
            "100946 - Mom & Baby/Baby Travel Essentials/Strollers & Travel Systems",
          value: "100946",
        },
        {
          label:
            "101226 - Home & Living/Kitchenware/Spatulas & Cooking Tweezers",
          value: "101226",
        },
        {
          label:
            "101513 - Tickets, Vouchers & Services/Services/Photography & Printing",
          value: "101513",
        },
        {
          label:
            "101613 - Beauty/Hand, Foot & Nail Care/Foot Care/Lotion, Cream & Scrubs",
          value: "101613",
        },
        {
          label:
            "101861 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Tennis/Tennis Rackets",
          value: "101861",
        },
        {
          label: "100706 - Cameras & Drones/Drone Accessories",
          value: "100706",
        },
        {
          label: "100369 - Women Clothes/Jackets, Coats & Vests/Blazers",
          value: "100369",
        },
        {
          label:
            "100947 - Mom & Baby/Baby Travel Essentials/Stroller Accessories",
          value: "100947",
        },
        {
          label: "101227 - Home & Living/Kitchenware/Chopping Boards",
          value: "101227",
        },
        {
          label: "101514 - Tickets, Vouchers & Services/Services/Others",
          value: "101514",
        },
        {
          label: "101614 - Beauty/Hand, Foot & Nail Care/Foot Care/Others",
          value: "101614",
        },
        {
          label:
            "101862 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Tennis/Tennis Balls",
          value: "101862",
        },
        { label: "100707 - Cameras & Drones/Others", value: "100707" },
        {
          label: "100370 - Women Clothes/Jackets, Coats & Vests/Jackets",
          value: "100370",
        },
        {
          label:
            "100948 - Mom & Baby/Baby Travel Essentials/Car & Motorbike Seats",
          value: "100948",
        },
        {
          label: "101228 - Home & Living/Kitchenware/Knives & Kitchen Scissors",
          value: "101228",
        },
        {
          label: "102036 - Tickets, Vouchers & Services/Services/Design",
          value: "102036",
        },
        {
          label:
            "101615 - Beauty/Hand, Foot & Nail Care/Nail Care/Base & Top Coat",
          value: "101615",
        },
        {
          label:
            "101863 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Tennis/Tennis Nets",
          value: "101863",
        },
        {
          label:
            "100712 - Home & Living/Hand Warmers, Hot Water Bags & Ice Bags",
          value: "100712",
        },
        {
          label: "100371 - Women Clothes/Jackets, Coats & Vests/Vests",
          value: "100371",
        },
        {
          label:
            "100949 - Mom & Baby/Baby Travel Essentials/Car & Motorbike Seats Accessories",
          value: "100949",
        },
        {
          label: "101229 - Home & Living/Kitchenware/Whisks & Beaters",
          value: "101229",
        },
        {
          label:
            "101515 - Tickets, Vouchers & Services/Beauty & Wellness/Health",
          value: "101515",
        },
        {
          label: "102029 - Beauty/Hand, Foot & Nail Care/Nail Care/Nail Polish",
          value: "102029",
        },
        {
          label:
            "101864 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Tennis/Others",
          value: "101864",
        },
        { label: "100719 - Home & Living/Lighting", value: "100719" },
        {
          label: "100372 - Women Clothes/Jackets, Coats & Vests/Others",
          value: "100372",
        },
        {
          label: "100950 - Mom & Baby/Baby Travel Essentials/Diaper Bags",
          value: "100950",
        },
        {
          label: "101230 - Home & Living/Kitchenware/Can & Bottle Openers",
          value: "101230",
        },
        {
          label:
            "101516 - Tickets, Vouchers & Services/Beauty & Wellness/Dental",
          value: "101516",
        },
        {
          label:
            "102030 - Beauty/Hand, Foot & Nail Care/Nail Care/Nail Polish Remover",
          value: "102030",
        },
        {
          label:
            "101866 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Table Tennis/Table Tennis Bats",
          value: "101866",
        },
        {
          label: "100723 - Home & Living/Fengshui & Religious Supplies",
          value: "100723",
        },
        {
          label: "100373 - Women Clothes/Hoodies & Sweatshirts/Sweatshirts",
          value: "100373",
        },
        {
          label:
            "100951 - Mom & Baby/Baby Travel Essentials/Child Harnesses & Leashes",
          value: "100951",
        },
        {
          label:
            "101231 - Home & Living/Kitchenware/Measuring Glassess & Spoons",
          value: "101231",
        },
        {
          label:
            "101517 - Tickets, Vouchers & Services/Beauty & Wellness/Fitness",
          value: "101517",
        },
        {
          label:
            "102031 - Beauty/Hand, Foot & Nail Care/Nail Care/Nail Treatment",
          value: "102031",
        },
        {
          label:
            "101867 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Table Tennis/Table Tennis Nets",
          value: "101867",
        },
        { label: "100724 - Home & Living/Others", value: "100724" },
        {
          label: "100374 - Women Clothes/Hoodies & Sweatshirts/Hoodies",
          value: "100374",
        },
        {
          label: "100952 - Mom & Baby/Baby Travel Essentials/Others",
          value: "100952",
        },
        {
          label: "101232 - Home & Living/Kitchenware/Strainers",
          value: "101232",
        },
        {
          label: "101518 - Tickets, Vouchers & Services/Beauty & Wellness/Hair",
          value: "101518",
        },
        {
          label:
            "102032 - Beauty/Hand, Foot & Nail Care/Nail Care/Artificial Nail",
          value: "102032",
        },
        {
          label:
            "101868 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Table Tennis/Others",
          value: "101868",
        },
        { label: "100729 - Sports & Outdoors/Others", value: "100729" },
        {
          label: "100375 - Women Clothes/Hoodies & Sweatshirts/Others",
          value: "100375",
        },
        {
          label:
            "100955 - Mom & Baby/Feeding Essentials/Highchairs & Booster Seats",
          value: "100955",
        },
        {
          label:
            "101233 - Home & Living/Kitchenware/Graters, Peelers & Cutters",
          value: "101233",
        },
        {
          label:
            "101519 - Tickets, Vouchers & Services/Beauty & Wellness/Spa & Massage",
          value: "101519",
        },
        {
          label:
            "102033 - Beauty/Hand, Foot & Nail Care/Nail Care/Nail Art & Sticker",
          value: "102033",
        },
        {
          label:
            "101869 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Boxing & Martial Arts/Punching Bags & Paddings",
          value: "101869",
        },
        { label: "100736 - Stationery/Others", value: "100736" },
        { label: "100376 - Women Clothes/Sets/Couple Sets", value: "100376" },
        {
          label: "100957 - Mom & Baby/Feeding Essentials/Bibs",
          value: "100957",
        },
        {
          label: "101234 - Home & Living/Kitchenware/Kitchen Weighing Scales",
          value: "101234",
        },
        {
          label:
            "101520 - Tickets, Vouchers & Services/Beauty & Wellness/Others",
          value: "101520",
        },
        {
          label:
            "102034 - Beauty/Hand, Foot & Nail Care/Nail Care/Manicure Tools & Devices",
          value: "102034",
        },
        {
          label:
            "101870 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Boxing & Martial Arts/Martial Arts Belts",
          value: "101870",
        },
        {
          label: "100740 - Hobbies & Collections/CD, DVD & Bluray",
          value: "100740",
        },
        { label: "100377 - Women Clothes/Sets/Family Sets", value: "100377" },
        {
          label: "100958 - Mom & Baby/Feeding Essentials/Pacifiers",
          value: "100958",
        },
        {
          label: "101235 - Home & Living/Kitchenware/Sealers",
          value: "101235",
        },
        {
          label:
            "101521 - Tickets, Vouchers & Services/Telco/Internet & Cable TV",
          value: "101521",
        },
        {
          label: "102035 - Beauty/Hand, Foot & Nail Care/Nail Care/Others",
          value: "102035",
        },
        {
          label:
            "101871 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Boxing & Martial Arts/Gloves, Wraps & Helmets",
          value: "101871",
        },
        {
          label: "100742 - Hobbies & Collections/Vinyl Records",
          value: "100742",
        },
        {
          label: "100378 - Women Clothes/Sets/Individual Sets",
          value: "100378",
        },
        {
          label: "100959 - Mom & Baby/Feeding Essentials/Food Processors",
          value: "100959",
        },
        { label: "101237 - Home & Living/Kitchenware/Others", value: "101237" },
        {
          label: "101522 - Tickets, Vouchers & Services/Telco/Mobile Data",
          value: "101522",
        },
        {
          label: "101616 - Beauty/Men's Care/Skincare/Facial Cleanser",
          value: "101616",
        },
        {
          label:
            "101872 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Boxing & Martial Arts/Others",
          value: "101872",
        },
        {
          label: "100743 - Hobbies & Collections/Photo Albums",
          value: "100743",
        },
        { label: "100379 - Women Clothes/Sets/Others", value: "100379" },
        {
          label: "100960 - Mom & Baby/Feeding Essentials/Others",
          value: "100960",
        },
        {
          label:
            "101238 - Home & Living/Dinnerware/Jugs, Pitchers & Accessories",
          value: "101238",
        },
        {
          label:
            "101523 - Tickets, Vouchers & Services/Telco/Mobile Top-ups & Pre-paid",
          value: "101523",
        },
        {
          label: "101617 - Beauty/Men's Care/Skincare/Moisturizer & Treatment",
          value: "101617",
        },
        {
          label:
            "101873 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Golf/Golf Balls",
          value: "101873",
        },
        { label: "100744 - Hobbies & Collections/Needlework", value: "100744" },
        {
          label: "100380 - Women Clothes/Lingerie & Underwear/Sets",
          value: "100380",
        },
        {
          label: "100961 - Mom & Baby/Maternity Accessories/Supporting Belts",
          value: "100961",
        },
        {
          label: "101239 - Home & Living/Dinnerware/Tea Pots & Sets",
          value: "101239",
        },
        {
          label: "101524 - Tickets, Vouchers & Services/Telco/Others",
          value: "101524",
        },
        {
          label: "101618 - Beauty/Men's Care/Skincare/Others",
          value: "101618",
        },
        {
          label:
            "101874 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Golf/Golf Clubs",
          value: "101874",
        },
        { label: "100745 - Hobbies & Collections/Others", value: "100745" },
        {
          label: "100381 - Women Clothes/Lingerie & Underwear/Bras",
          value: "100381",
        },
        {
          label: "100962 - Mom & Baby/Maternity Accessories/Maternity Pillows",
          value: "100962",
        },
        {
          label: "101240 - Home & Living/Dinnerware/Cups, Mugs & Glasses",
          value: "101240",
        },
        {
          label: "101525 - Tickets, Vouchers & Services/Travel/Accommodation",
          value: "101525",
        },
        {
          label: "101619 - Beauty/Men's Care/Shaving & Grooming/Aftershave",
          value: "101619",
        },
        {
          label:
            "101875 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Golf/Gears &Training Equipment",
          value: "101875",
        },
        {
          label: "100753 - Automobiles/Automotive Keychains & Key Covers",
          value: "100753",
        },
        {
          label: "100382 - Women Clothes/Lingerie & Underwear/Panties",
          value: "100382",
        },
        {
          label: "100963 - Mom & Baby/Maternity Accessories/Others",
          value: "100963",
        },
        {
          label:
            "101241 - Home & Living/Dinnerware/Water Bottles & Accessories",
          value: "101241",
        },
        {
          label: "101526 - Tickets, Vouchers & Services/Travel/Hot Springs",
          value: "101526",
        },
        {
          label:
            "101620 - Beauty/Men's Care/Shaving & Grooming/Creams, Foams & Gels",
          value: "101620",
        },
        {
          label:
            "101876 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Golf/Golf Bags & Organizers",
          value: "101876",
        },
        { label: "100754 - Automobiles/Others", value: "100754" },
        {
          label:
            "100383 - Women Clothes/Lingerie & Underwear/Thermal Innerwear",
          value: "100383",
        },
        {
          label: "100964 - Mom & Baby/Maternity Healthcare/Maternity Milk",
          value: "100964",
        },
        { label: "101242 - Home & Living/Dinnerware/Bowls", value: "101242" },
        {
          label: "101527 - Tickets, Vouchers & Services/Travel/Flights",
          value: "101527",
        },
        {
          label:
            "101621 - Beauty/Men's Care/Shaving & Grooming/Razors & Blades",
          value: "101621",
        },
        {
          label:
            "101877 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Golf/Others",
          value: "101877",
        },
        { label: "100755 - Motorcycles/Motorcycles", value: "100755" },
        {
          label: "100385 - Women Clothes/Lingerie & Underwear/Shapewear",
          value: "100385",
        },
        {
          label:
            "100965 - Mom & Baby/Maternity Healthcare/Maternity Vitamins & Supplement",
          value: "100965",
        },
        { label: "101243 - Home & Living/Dinnerware/Plates", value: "101243" },
        {
          label: "101528 - Tickets, Vouchers & Services/Travel/Trains",
          value: "101528",
        },
        {
          label: "101622 - Beauty/Men's Care/Shaving & Grooming/Shavers",
          value: "101622",
        },
        {
          label:
            "101878 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Swimming & Diving/Swimming Caps",
          value: "101878",
        },
        {
          label: "100758 - Motorcycles/Motorcycle Helmets & Accessories",
          value: "100758",
        },
        {
          label: "100386 - Women Clothes/Lingerie & Underwear/Safety Pants",
          value: "100386",
        },
        {
          label:
            "100966 - Mom & Baby/Maternity Healthcare/Moisturizers & Creams",
          value: "100966",
        },
        {
          label: "101244 - Home & Living/Dinnerware/Cutleries",
          value: "101244",
        },
        {
          label: "101529 - Tickets, Vouchers & Services/Travel/Buses",
          value: "101529",
        },
        {
          label:
            "101623 - Beauty/Men's Care/Shaving & Grooming/Shaving Brushes",
          value: "101623",
        },
        {
          label:
            "101879 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Swimming & Diving/Swimming Goggles",
          value: "101879",
        },
        { label: "100759 - Motorcycles/Others", value: "100759" },
        {
          label: "100387 - Women Clothes/Lingerie & Underwear/Sexy Lingerie",
          value: "100387",
        },
        {
          label: "100967 - Mom & Baby/Maternity Healthcare/Others",
          value: "100967",
        },
        { label: "101245 - Home & Living/Dinnerware/Straws", value: "101245" },
        {
          label: "101530 - Tickets, Vouchers & Services/Travel/Car Rentals",
          value: "101530",
        },
        {
          label:
            "101624 - Beauty/Men's Care/Shaving & Grooming/Trimmers, Clippers & Multi-functional Groomers",
          value: "101624",
        },
        {
          label:
            "101880 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Swimming & Diving/Swimming Boards",
          value: "101880",
        },
        {
          label: "100763 - Tickets, Vouchers & Services/Utilities",
          value: "100763",
        },
        {
          label: "100388 - Women Clothes/Lingerie & Underwear/Others",
          value: "100388",
        },
        {
          label: "100968 - Mom & Baby/Bath & Body Care/Bathing Tubs & Seats",
          value: "100968",
        },
        {
          label: "101246 - Home & Living/Dinnerware/Food Covers",
          value: "101246",
        },
        {
          label: "101531 - Tickets, Vouchers & Services/Travel/Tour & Packages",
          value: "101531",
        },
        {
          label:
            "101625 - Beauty/Men's Care/Shaving & Grooming/Shaving Accessories",
          value: "101625",
        },
        {
          label:
            "101881 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Swimming & Diving/Diving Flashlights",
          value: "101881",
        },
        {
          label: "100766 - Tickets, Vouchers & Services/Transport",
          value: "100766",
        },
        {
          label: "100389 - Women Clothes/Sleepwear & Pajamas/Pajamas",
          value: "100389",
        },
        {
          label:
            "100969 - Mom & Baby/Bath & Body Care/Bath Robes, Towels & Wash Cloths",
          value: "100969",
        },
        {
          label: "101247 - Home & Living/Dinnerware/Placemats & Coasters",
          value: "101247",
        },
        {
          label: "101532 - Tickets, Vouchers & Services/Travel/Others",
          value: "101532",
        },
        {
          label: "101626 - Beauty/Men's Care/Shaving & Grooming/Others",
          value: "101626",
        },
        {
          label:
            "101882 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Swimming & Diving/Diving Masks",
          value: "101882",
        },
        {
          label: "100767 - Tickets, Vouchers & Services/Lessons & Workshops",
          value: "100767",
        },
        {
          label: "100390 - Women Clothes/Sleepwear & Pajamas/Night Dresses",
          value: "100390",
        },
        {
          label: "100970 - Mom & Baby/Bath & Body Care/Shower Caps",
          value: "100970",
        },
        { label: "101248 - Home & Living/Dinnerware/Others", value: "101248" },
        {
          label: "101533 - Tickets, Vouchers & Services/Shopee/Shopee Official",
          value: "101533",
        },
        {
          label: "101627 - Beauty/Makeup/Face/Makeup Base & Primer",
          value: "101627",
        },
        {
          label:
            "101883 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Swimming & Diving/Flippers",
          value: "101883",
        },
        {
          label: "100772 - Tickets, Vouchers & Services/Gaming & App Stores",
          value: "100772",
        },
        {
          label: "100391 - Women Clothes/Sleepwear & Pajamas/Kimonos & Robes",
          value: "100391",
        },
        {
          label:
            "100971 - Mom & Baby/Bath & Body Care/Bathing Tools & Accessories",
          value: "100971",
        },
        {
          label: "101249 - Home & Living/Safety & Security/Safes",
          value: "101249",
        },
        {
          label:
            "101534 - Tickets, Vouchers & Services/Shopee/Shopee Paid Ads Credits",
          value: "101534",
        },
        { label: "101628 - Beauty/Makeup/Face/Foundation", value: "101628" },
        {
          label:
            "101884 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Swimming & Diving/Life Jackets",
          value: "101884",
        },
        {
          label: "100775 - Tickets, Vouchers & Services/Others",
          value: "100775",
        },
        {
          label: "100392 - Women Clothes/Sleepwear & Pajamas/Others",
          value: "100392",
        },
        {
          label: "100972 - Mom & Baby/Bath & Body Care/Hair Care & Body Wash",
          value: "100972",
        },
        {
          label:
            "101250 - Home & Living/Safety & Security/Fire Fighting Equipments",
          value: "101250",
        },
        {
          label: "101535 - Tickets, Vouchers & Services/Shopee/Others",
          value: "101535",
        },
        { label: "101629 - Beauty/Makeup/Face/BB & CC Cream", value: "101629" },
        {
          label:
            "101885 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Swimming & Diving/Others",
          value: "101885",
        },
        { label: "100778 - Books & Magazines/E-Books", value: "100778" },
        {
          label: "100393 - Women Clothes/Maternity Wear/Nursing Bras",
          value: "100393",
        },
        {
          label:
            "100973 - Mom & Baby/Bath & Body Care/Baby Colognes & Fragrances",
          value: "100973",
        },
        {
          label:
            "101251 - Home & Living/Safety & Security/Door Hardware & Locks",
          value: "101251",
        },
        {
          label: "101536 - Books & Magazines/Magazines & Newspaper/Business",
          value: "101536",
        },
        { label: "101630 - Beauty/Makeup/Face/Powder", value: "101630" },
        {
          label:
            "101886 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Yoga & Pilates/Yoga Mats",
          value: "101886",
        },
        { label: "100779 - Books & Magazines/Others", value: "100779" },
        {
          label: "100394 - Women Clothes/Maternity Wear/Maternity Dresses",
          value: "100394",
        },
        {
          label: "100974 - Mom & Baby/Bath & Body Care/Baby Grooming Tools",
          value: "100974",
        },
        {
          label: "101252 - Home & Living/Safety & Security/Others",
          value: "101252",
        },
        {
          label: "101537 - Books & Magazines/Magazines & Newspaper/Lifestyle",
          value: "101537",
        },
        {
          label: "101631 - Beauty/Makeup/Face/Concealer & Corrector",
          value: "101631",
        },
        {
          label:
            "101887 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Yoga & Pilates/Yoga Blocks, Rings & Foam Rollers",
          value: "101887",
        },
        { label: "101933 - Computers & Accessories/Monitors", value: "101933" },
        {
          label: "100395 - Women Clothes/Maternity Wear/Maternity Tops",
          value: "100395",
        },
        {
          label: "100975 - Mom & Baby/Bath & Body Care/Wipes",
          value: "100975",
        },
        {
          label: "101253 - Home & Living/Home Organizers/Hangers & Pegs",
          value: "101253",
        },
        {
          label: "101538 - Books & Magazines/Magazines & Newspaper/Teenager",
          value: "101538",
        },
        {
          label: "101632 - Beauty/Makeup/Face/Bronzer,Contour & Highlighter",
          value: "101632",
        },
        {
          label:
            "101888 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Yoga & Pilates/Resistance Bands",
          value: "101888",
        },
        {
          label: "101937 - Computers & Accessories/Softwares",
          value: "101937",
        },
        {
          label: "100396 - Women Clothes/Maternity Wear/Breastfeeding Wear",
          value: "100396",
        },
        {
          label: "100976 - Mom & Baby/Bath & Body Care/Baby Laundry Detergent",
          value: "100976",
        },
        {
          label:
            "101254 - Home & Living/Home Organizers/Storage Boxes, Bags & Baskets",
          value: "101254",
        },
        {
          label: "101539 - Books & Magazines/Magazines & Newspaper/Others",
          value: "101539",
        },
        {
          label: "101633 - Beauty/Makeup/Face/Setting & Finishing Spray",
          value: "101633",
        },
        {
          label:
            "101889 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Yoga & Pilates/Others",
          value: "101889",
        },
        { label: "101942 - Computers & Accessories/Laptops", value: "101942" },
        {
          label: "100397 - Women Clothes/Maternity Wear/Maternity Sets",
          value: "100397",
        },
        {
          label: "100977 - Mom & Baby/Bath & Body Care/Others",
          value: "100977",
        },
        {
          label: "101255 - Home & Living/Home Organizers/Shoe Storage Boxes",
          value: "101255",
        },
        {
          label: "101540 - Books & Magazines/Books/Comics & Manga",
          value: "101540",
        },
        { label: "101634 - Beauty/Makeup/Face/Blush", value: "101634" },
        {
          label:
            "101890 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fitness Equipment/Weight Training",
          value: "101890",
        },
        { label: "101943 - Computers & Accessories/Others", value: "101943" },
        {
          label: "100398 - Women Clothes/Maternity Wear/Maternity Bottoms",
          value: "100398",
        },
        {
          label: "100978 - Mom & Baby/Nursery/Cribs & Cradles & Beds",
          value: "100978",
        },
        {
          label: "101256 - Home & Living/Home Organizers/Hooks",
          value: "101256",
        },
        {
          label: "101541 - Books & Magazines/Books/Children's Books",
          value: "101541",
        },
        { label: "101635 - Beauty/Makeup/Face/Others", value: "101635" },
        {
          label:
            "101891 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fitness Equipment/Skipping Ropes",
          value: "101891",
        },
        {
          label: "100003 - Health/Food Supplement/Weight Management",
          value: "100003",
        },
        {
          label: "100399 - Women Clothes/Maternity Wear/Others",
          value: "100399",
        },
        {
          label: "100979 - Mom & Baby/Nursery/Bouncers, Rockers & Jumpers",
          value: "100979",
        },
        {
          label:
            "101257 - Home & Living/Home Organizers/Laundry Bags & Baskets",
          value: "101257",
        },
        {
          label:
            "101542 - Books & Magazines/Books/Agriculture, Forestry & Fishery",
          value: "101542",
        },
        { label: "101636 - Beauty/Makeup/Eyes/Eyeshadow", value: "101636" },
        {
          label:
            "101892 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fitness Equipment/Gym Balls",
          value: "101892",
        },
        {
          label: "100004 - Health/Food Supplement/Beauty Supplements",
          value: "100004",
        },
        {
          label: "100400 - Women Clothes/Traditional Wear/Tops",
          value: "100400",
        },
        { label: "100980 - Mom & Baby/Nursery/Walkers", value: "100980" },
        {
          label: "101258 - Home & Living/Home Organizers/Desk Organizers",
          value: "101258",
        },
        {
          label: "101544 - Books & Magazines/Books/Arts, Design & Photography",
          value: "101544",
        },
        { label: "101637 - Beauty/Makeup/Eyes/Eye Primer", value: "101637" },
        {
          label:
            "101893 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fitness Equipment/Fitness Machines",
          value: "101893",
        },
        { label: "100005 - Health/Food Supplement/Fitness", value: "100005" },
        {
          label: "100401 - Women Clothes/Traditional Wear/Bottoms",
          value: "100401",
        },
        {
          label: "100982 - Mom & Baby/Nursery/Storage & Organization",
          value: "100982",
        },
        {
          label: "101259 - Home & Living/Home Organizers/Wardrobe Organizers",
          value: "101259",
        },
        {
          label: "101545 - Books & Magazines/Books/Recipes & Cooking",
          value: "101545",
        },
        { label: "101638 - Beauty/Makeup/Eyes/Eyeliner", value: "101638" },
        {
          label:
            "101894 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fitness Equipment/Ab Rollers",
          value: "101894",
        },
        {
          label: "100006 - Health/Food Supplement/Well Being",
          value: "100006",
        },
        {
          label: "100402 - Women Clothes/Traditional Wear/Sets",
          value: "100402",
        },
        { label: "100983 - Mom & Baby/Nursery/Others", value: "100983" },
        {
          label: "101260 - Home & Living/Home Organizers/Jewelry Organizers",
          value: "101260",
        },
        {
          label: "101546 - Books & Magazines/Books/Business & Investment",
          value: "101546",
        },
        { label: "101639 - Beauty/Makeup/Eyes/Mascara", value: "101639" },
        {
          label:
            "101895 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fitness Equipment/Pull Up & Push Up Bars",
          value: "101895",
        },
        { label: "100007 - Health/Food Supplement/Others", value: "100007" },
        {
          label: "100403 - Women Clothes/Traditional Wear/Dresses",
          value: "100403",
        },
        { label: "100984 - Mom & Baby/Baby Safety/Monitors", value: "100984" },
        {
          label: "101261 - Home & Living/Home Organizers/Tissue Holders",
          value: "101261",
        },
        {
          label:
            "101547 - Books & Magazines/Books/Politics, Law & Social Sciences",
          value: "101547",
        },
        { label: "101640 - Beauty/Makeup/Eyes/Eyebrows", value: "101640" },
        {
          label:
            "101896 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fitness Equipment/Others",
          value: "101896",
        },
        {
          label: "100119 - Health/Medical Supplies/Over-the-counter Medicine",
          value: "100119",
        },
        {
          label: "100404 - Women Clothes/Traditional Wear/Others",
          value: "100404",
        },
        {
          label: "100985 - Mom & Baby/Baby Safety/Mosquito Netting",
          value: "100985",
        },
        {
          label: "101262 - Home & Living/Home Organizers/Others",
          value: "101262",
        },
        {
          label: "101548 - Books & Magazines/Books/Computers & Technology",
          value: "101548",
        },
        { label: "101641 - Beauty/Makeup/Eyes/Others", value: "101641" },
        {
          label:
            "101897 - Sports & Outdoors/Sports & Outdoor Apparels/Swimming Attire/Swimming Trunks",
          value: "101897",
        },
        {
          label: "100120 - Health/Medical Supplies/Traditional Medicine",
          value: "100120",
        },
        { label: "100405 - Women Clothes/Fabric/Batik", value: "100405" },
        {
          label: "100986 - Mom & Baby/Baby Safety/Bumpers, Rails & Guards",
          value: "100986",
        },
        {
          label: "101263 - Home & Living/Party Supplies/Balloons",
          value: "101263",
        },
        {
          label: "101549 - Books & Magazines/Books/Health, Fitness & Dieting",
          value: "101549",
        },
        { label: "101642 - Beauty/Makeup/Lips/Lipstick", value: "101642" },
        {
          label:
            "101898 - Sports & Outdoors/Sports & Outdoor Apparels/Swimming Attire/Bikini Sets",
          value: "101898",
        },
        {
          label: "100122 - Health/Medical Supplies/Scale & Body Fat Analyzers",
          value: "100122",
        },
        { label: "100406 - Women Clothes/Fabric/Kebaya", value: "100406" },
        {
          label: "100987 - Mom & Baby/Baby Safety/Edge & Corner Guards",
          value: "100987",
        },
        {
          label: "101264 - Home & Living/Party Supplies/Wooden Clips",
          value: "101264",
        },
        {
          label: "101550 - Books & Magazines/Books/Travel & Tourism",
          value: "101550",
        },
        { label: "101643 - Beauty/Makeup/Lips/Lip Gloss", value: "101643" },
        {
          label:
            "101899 - Sports & Outdoors/Sports & Outdoor Apparels/Swimming Attire/One-Piece Swimsuits",
          value: "101899",
        },
        {
          label: "100123 - Health/Medical Supplies/Nasal Care",
          value: "100123",
        },
        { label: "100407 - Women Clothes/Fabric/Cotton", value: "100407" },
        {
          label: "100988 - Mom & Baby/Baby Safety/Baby Gates & Doorways",
          value: "100988",
        },
        {
          label: "101265 - Home & Living/Party Supplies/Backdrops & Banners",
          value: "101265",
        },
        {
          label:
            "101551 - Books & Magazines/Books/Language Learning & Dictionaries",
          value: "101551",
        },
        { label: "101644 - Beauty/Makeup/Lips/Lip Liner", value: "101644" },
        {
          label:
            "101900 - Sports & Outdoors/Sports & Outdoor Apparels/Swimming Attire/Rash Guards",
          value: "101900",
        },
        {
          label: "100125 - Health/Medical Supplies/Stethoscopes",
          value: "100125",
        },
        { label: "100408 - Women Clothes/Fabric/Wool", value: "100408" },
        {
          label: "100989 - Mom & Baby/Baby Safety/Safety Locks & Straps",
          value: "100989",
        },
        {
          label: "101266 - Home & Living/Party Supplies/Cards",
          value: "101266",
        },
        { label: "101552 - Books & Magazines/Books/Hobbies", value: "101552" },
        {
          label: "101645 - Beauty/Makeup/Lips/Lip Tint & Stain",
          value: "101645",
        },
        {
          label:
            "101901 - Sports & Outdoors/Sports & Outdoor Apparels/Swimming Attire/Others",
          value: "101901",
        },
        {
          label: "100126 - Health/Medical Supplies/Pain Relievers",
          value: "100126",
        },
        {
          label: "100409 - Women Clothes/Fabric/Velvet, Silk & Satin",
          value: "100409",
        },
        { label: "100990 - Mom & Baby/Baby Safety/Others", value: "100990" },
        {
          label: "101267 - Home & Living/Party Supplies/Disposable Tableware",
          value: "101267",
        },
        {
          label: "101555 - Books & Magazines/Books/Classic Literature",
          value: "101555",
        },
        { label: "101646 - Beauty/Makeup/Lips/Lip Plumper", value: "101646" },
        {
          label:
            "102018 - Hobbies & Collections/Musical Instruments & Accessories/String Instruments/Ukuleles",
          value: "102018",
        },
        {
          label: "100127 - Health/Medical Supplies/Laboratory Tools",
          value: "100127",
        },
        { label: "100410 - Women Clothes/Fabric/Leather", value: "100410" },
        {
          label: "100991 - Mom & Baby/Milk Formula & Baby Food/Milk Formula",
          value: "100991",
        },
        {
          label: "101268 - Home & Living/Party Supplies/Party Hats & Masks",
          value: "101268",
        },
        { label: "101556 - Books & Magazines/Books/Fantasy", value: "101556" },
        { label: "101647 - Beauty/Makeup/Lips/Others", value: "101647" },
        {
          label:
            "102019 - Hobbies & Collections/Musical Instruments & Accessories/String Instruments/Guitars & Bass Guitars",
          value: "102019",
        },
        {
          label: "100128 - Health/Medical Supplies/Medical Gloves & Masks",
          value: "100128",
        },
        {
          label: "100411 - Women Clothes/Fabric/Vinyl & Nylon",
          value: "100411",
        },
        {
          label:
            "100992 - Mom & Baby/Milk Formula & Baby Food/Baby Porridge, Puree & Cereal",
          value: "100992",
        },
        {
          label: "101269 - Home & Living/Party Supplies/Sashes",
          value: "101269",
        },
        {
          label: "101557 - Books & Magazines/Books/Action, Crime & Thrillers",
          value: "101557",
        },
        {
          label: "101648 - Beauty/Beauty Tools/Makeup Accessories/Others",
          value: "101648",
        },
        {
          label:
            "102020 - Hobbies & Collections/Musical Instruments & Accessories/String Instruments/Others",
          value: "102020",
        },
        { label: "100130 - Health/Medical Supplies/Others", value: "100130" },
        { label: "100412 - Women Clothes/Fabric/Denim", value: "100412" },
        {
          label: "100993 - Mom & Baby/Milk Formula & Baby Food/Baby Snack",
          value: "100993",
        },
        {
          label: "101270 - Home & Living/Party Supplies/Others",
          value: "101270",
        },
        { label: "101558 - Books & Magazines/Books/Romance", value: "101558" },
        {
          label:
            "101649 - Beauty/Beauty Tools/Makeup Accessories/Cotton Pads and Buds",
          value: "101649",
        },
        {
          label:
            "101902 - Automobiles/Automobile Spare Parts/Engine Parts/Pistons",
          value: "101902",
        },
        {
          label: "100131 - Health/Personal Care/Hand Sanitizers",
          value: "100131",
        },
        { label: "100413 - Women Clothes/Fabric/Canvas", value: "100413" },
        {
          label: "100994 - Mom & Baby/Milk Formula & Baby Food/Others",
          value: "100994",
        },
        {
          label:
            "101274 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Rock Climbing",
          value: "101274",
        },
        {
          label: "101559 - Books & Magazines/Books/Biography & Memoirs",
          value: "101559",
        },
        {
          label:
            "101650 - Beauty/Beauty Tools/Makeup Accessories/Makeup Bags & Organizers",
          value: "101650",
        },
        {
          label:
            "101903 - Automobiles/Automobile Spare Parts/Engine Parts/Air Filters",
          value: "101903",
        },
        { label: "100133 - Health/Personal Care/Ear Care", value: "100133" },
        { label: "100414 - Women Clothes/Fabric/Kain Pasang", value: "100414" },
        {
          label:
            "100995 - Mom & Baby/Baby Healthcare/Baby Vitamins & Supplements",
          value: "100995",
        },
        {
          label:
            "101285 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Baseball & Softball",
          value: "101285",
        },
        {
          label: "101560 - Books & Magazines/Books/Science & Maths",
          value: "101560",
        },
        {
          label: "101651 - Beauty/Beauty Tools/Makeup Accessories/Mirrors",
          value: "101651",
        },
        {
          label:
            "101904 - Automobiles/Automobile Spare Parts/Engine Parts/Oil Filters",
          value: "101904",
        },
        {
          label: "100135 - Health/Personal Care/Adult Diapers & Incontinence",
          value: "100135",
        },
        { label: "100415 - Women Clothes/Fabric/Songket", value: "100415" },
        {
          label: "100996 - Mom & Baby/Baby Healthcare/Nasal Care",
          value: "100996",
        },
        {
          label:
            "101286 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Squash",
          value: "101286",
        },
        { label: "101561 - Books & Magazines/Books/Medical", value: "101561" },
        {
          label:
            "101652 - Beauty/Beauty Tools/Makeup Accessories/Makeup Brush Cleaners",
          value: "101652",
        },
        {
          label:
            "101905 - Automobiles/Automobile Spare Parts/Engine Parts/Others",
          value: "101905",
        },
        {
          label: "100137 - Health/Personal Care/Massage & Therapy Devices",
          value: "100137",
        },
        { label: "100416 - Women Clothes/Fabric/Others", value: "100416" },
        {
          label: "100998 - Mom & Baby/Baby Healthcare/Baby Oral Care",
          value: "100998",
        },
        {
          label:
            "101288 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Rugby",
          value: "101288",
        },
        {
          label: "101562 - Books & Magazines/Books/Parenting & Family",
          value: "101562",
        },
        {
          label:
            "101653 - Beauty/Beauty Tools/Makeup Accessories/Makeup Brushes",
          value: "101653",
        },
        {
          label:
            "101907 - Automobiles/Automobile Spare Parts/Electronics/Batteries & Accessories",
          value: "101907",
        },
        {
          label: "100138 - Health/Personal Care/Insect Repellents",
          value: "100138",
        },
        {
          label: "100417 - Women Clothes/Socks & Stockings/Socks",
          value: "100417",
        },
        {
          label: "100999 - Mom & Baby/Baby Healthcare/Sun Care",
          value: "100999",
        },
        {
          label:
            "101289 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Billiards",
          value: "101289",
        },
        {
          label: "101563 - Books & Magazines/Books/Psychology & Relationships",
          value: "101563",
        },
        {
          label:
            "101654 - Beauty/Beauty Tools/Makeup Accessories/Sponges & Applicators",
          value: "101654",
        },
        {
          label:
            "101908 - Automobiles/Automobile Spare Parts/Electronics/Lighting",
          value: "101908",
        },
        { label: "100139 - Health/Personal Care/Others", value: "100139" },
        {
          label: "100418 - Women Clothes/Socks & Stockings/Pantyhose",
          value: "100418",
        },
        {
          label: "101000 - Mom & Baby/Baby Healthcare/Others",
          value: "101000",
        },
        {
          label:
            "101290 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Surfing & Wakeboarding",
          value: "101290",
        },
        {
          label: "101564 - Books & Magazines/Books/Religion & Philosophy",
          value: "101564",
        },
        {
          label:
            "101655 - Beauty/Beauty Tools/Makeup Accessories/Eyelash Curlers",
          value: "101655",
        },
        {
          label:
            "101909 - Automobiles/Automobile Spare Parts/Electronics/Others",
          value: "101909",
        },
        { label: "100001 - Health", value: "100001" },
        {
          label: "100419 - Women Clothes/Socks & Stockings/Others",
          value: "100419",
        },
        {
          label: "101001 - Mom & Baby/Diapering & Potty/Changing Pads & Kits",
          value: "101001",
        },
        {
          label:
            "101291 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Ice Skating & Winter Sports",
          value: "101291",
        },
        {
          label: "101565 - Books & Magazines/Books/History & Cultures",
          value: "101565",
        },
        {
          label:
            "101656 - Beauty/Beauty Tools/Makeup Accessories/Eyebrow Templates",
          value: "101656",
        },
        {
          label:
            "101912 - Automobiles/Automotive Oils & Lubes/Automotive Fluids/Others",
          value: "101912",
        },
        { label: "100001 - Health", value: "100001" },
        { label: "100585 - Women Shoes/Boots/Rain Boots", value: "100585" },
        {
          label:
            "101002 - Mom & Baby/Diapering & Potty/Potty Training & Commode Chairs",
          value: "101002",
        },
        {
          label:
            "101293 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Boating",
          value: "101293",
        },
        {
          label:
            "101566 - Books & Magazines/Books/Careers, Self Help & Personal Development",
          value: "101566",
        },
        {
          label:
            "101657 - Beauty/Beauty Tools/Makeup Accessories/False Eyelashes",
          value: "101657",
        },
        {
          label:
            "101913 - Motorcycles/Motorcycle Spare Parts/Ignition & Engine Parts/Radiators",
          value: "101913",
        },
        { label: "100001 - Health", value: "100001" },
        { label: "100586 - Women Shoes/Boots/Fashion Boots", value: "100586" },
        {
          label: "101003 - Mom & Baby/Diapering & Potty/Disposable Diapers",
          value: "101003",
        },
        {
          label:
            "101297 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Others",
          value: "101297",
        },
        {
          label: "101567 - Books & Magazines/Books/Horoscopes",
          value: "101567",
        },
        {
          label:
            "101658 - Beauty/Beauty Tools/Makeup Accessories/Eyelid Tape & Glue",
          value: "101658",
        },
        {
          label:
            "101914 - Motorcycles/Motorcycle Spare Parts/Ignition & Engine Parts/Pistons",
          value: "101914",
        },
        {
          label: "100145 - Fashion Accessories/Hair Accessories/Headbands",
          value: "100145",
        },
        { label: "100587 - Women Shoes/Boots/Others", value: "100587" },
        {
          label:
            "101004 - Mom & Baby/Diapering & Potty/Cloth Diapers & Accessories",
          value: "101004",
        },
        {
          label: "101298 - Sports & Outdoors/Sports Footwear/Basketball Shoes",
          value: "101298",
        },
        {
          label: "101568 - Books & Magazines/Books/Education & School",
          value: "101568",
        },
        {
          label:
            "101659 - Beauty/Beauty Tools/Makeup Accessories/Pencil Sharpeners",
          value: "101659",
        },
        {
          label:
            "101915 - Motorcycles/Motorcycle Spare Parts/Ignition & Engine Parts/CDI & ECU",
          value: "101915",
        },
        {
          label:
            "100146 - Fashion Accessories/Hair Accessories/Hair Ties, Ribbons & Scrunchies",
          value: "100146",
        },
        { label: "100588 - Women Shoes/Flats/Ballet Flats", value: "100588" },
        {
          label: "101005 - Mom & Baby/Diapering & Potty/Others",
          value: "101005",
        },
        {
          label: "101299 - Sports & Outdoors/Sports Footwear/Running Shoes",
          value: "101299",
        },
        {
          label: "101569 - Books & Magazines/Books/Sticker & Colouring Books",
          value: "101569",
        },
        {
          label:
            "101660 - Beauty/Beauty Tools/Facial Care Tools/Facial Steamers",
          value: "101660",
        },
        {
          label:
            "101916 - Motorcycles/Motorcycle Spare Parts/Ignition & Engine Parts/Spark Plugs",
          value: "101916",
        },
        {
          label:
            "100147 - Fashion Accessories/Hair Accessories/Hair Clips & Hair Pins",
          value: "100147",
        },
        {
          label: "100589 - Women Shoes/Flats/Loafers & Boat Shoes",
          value: "100589",
        },
        { label: "101007 - Mom & Baby/Toys/Block Toys", value: "101007" },
        {
          label: "101300 - Sports & Outdoors/Sports Footwear/Training Shoes",
          value: "101300",
        },
        { label: "101570 - Books & Magazines/Books/Music", value: "101570" },
        {
          label:
            "101661 - Beauty/Beauty Tools/Facial Care Tools/Facial Slimming & Massage Tools",
          value: "101661",
        },
        {
          label:
            "101917 - Motorcycles/Motorcycle Spare Parts/Ignition & Engine Parts/Ignition Coils",
          value: "101917",
        },
        {
          label:
            "100148 - Fashion Accessories/Hair Accessories/Wigs & Extensions",
          value: "100148",
        },
        {
          label: "100590 - Women Shoes/Flats/Oxfords & Lace-Ups",
          value: "100590",
        },
        { label: "101009 - Mom & Baby/Toys/Pretend Play", value: "101009" },
        {
          label: "101301 - Sports & Outdoors/Sports Footwear/Tennis Shoes",
          value: "101301",
        },
        {
          label: "101571 - Books & Magazines/Books/Baby & Soft Books",
          value: "101571",
        },
        {
          label:
            "101662 - Beauty/Beauty Tools/Facial Care Tools/Facial Cleansing Tools",
          value: "101662",
        },
        {
          label:
            "101918 - Motorcycles/Motorcycle Spare Parts/Ignition & Engine Parts/V-Belts",
          value: "101918",
        },
        {
          label:
            "100149 - Fashion Accessories/Hair Accessories/Headpieces, Tiaras & Flower Crowns",
          value: "100149",
        },
        {
          label: "100591 - Women Shoes/Flats/Slip Ons, Mary Janes & Mules",
          value: "100591",
        },
        { label: "101010 - Mom & Baby/Toys/Toy Vehicles", value: "101010" },
        {
          label: "101303 - Sports & Outdoors/Sports Footwear/Badminton Shoes",
          value: "101303",
        },
        {
          label: "101572 - Books & Magazines/Books/Audio Books",
          value: "101572",
        },
        {
          label: "101663 - Beauty/Beauty Tools/Facial Care Tools/Others",
          value: "101663",
        },
        {
          label:
            "101919 - Motorcycles/Motorcycle Spare Parts/Ignition & Engine Parts/Air Filters",
          value: "101919",
        },
        {
          label: "100150 - Fashion Accessories/Hair Accessories/Others",
          value: "100150",
        },
        { label: "100592 - Women Shoes/Flats/Others", value: "100592" },
        { label: "101013 - Mom & Baby/Toys/Robot Toys", value: "101013" },
        {
          label: "101304 - Sports & Outdoors/Sports Footwear/Futsal Shoes",
          value: "101304",
        },
        { label: "101573 - Books & Magazines/Books/Others", value: "101573" },
        {
          label: "101664 - Beauty/Beauty Tools/Hair Tools/Brushes & Combs",
          value: "101664",
        },
        {
          label:
            "101920 - Motorcycles/Motorcycle Spare Parts/Ignition & Engine Parts/Others",
          value: "101920",
        },
        {
          label: "100151 - Fashion Accessories/Eyewear/Sunglasses",
          value: "100151",
        },
        {
          label: "100593 - Women Shoes/Flat Sandals & Flip Flops/Flat Sandals",
          value: "100593",
        },
        {
          label: "101014 - Mom & Baby/Toys/Slime & Squishy Toys",
          value: "101014",
        },
        {
          label: "101305 - Sports & Outdoors/Sports Footwear/Hiking Shoes",
          value: "101305",
        },
        {
          label:
            "101944 - Computers & Accessories/Desktop Computers/Desktop PC",
          value: "101944",
        },
        {
          label: "101665 - Beauty/Beauty Tools/Hair Tools/Hair Dryers",
          value: "101665",
        },
        {
          label:
            "101921 - Motorcycles/Motorcycle Spare Parts/Drivetrain, Transmission & Clutches/Chains & Gears",
          value: "101921",
        },
        {
          label: "100152 - Fashion Accessories/Eyewear/Frames & Glasses",
          value: "100152",
        },
        {
          label: "100594 - Women Shoes/Flat Sandals & Flip Flops/Flip Flops",
          value: "100594",
        },
        { label: "101015 - Mom & Baby/Toys/Others", value: "101015" },
        {
          label: "101306 - Sports & Outdoors/Sports Footwear/Soccer Shoes",
          value: "101306",
        },
        {
          label: "101945 - Computers & Accessories/Desktop Computers/Mini PC",
          value: "101945",
        },
        {
          label:
            "101666 - Beauty/Beauty Tools/Hair Tools/Hair Styling Appliances",
          value: "101666",
        },
        {
          label:
            "101922 - Motorcycles/Motorcycle Spare Parts/Drivetrain, Transmission & Clutches/Clutches",
          value: "101922",
        },
        {
          label:
            "100153 - Fashion Accessories/Eyewear/Eyewear Cases & Accessories",
          value: "100153",
        },
        {
          label:
            "100595 - Women Shoes/Flat Sandals & Flip Flops/Health Slippers",
          value: "100595",
        },
        {
          label: "101016 - Baby & Kids Fashion/Baby Clothes/Regular Outerwear",
          value: "101016",
        },
        {
          label: "101307 - Sports & Outdoors/Sports Footwear/Kid's Sport Shoes",
          value: "101307",
        },
        {
          label: "101946 - Computers & Accessories/Desktop Computers/Server PC",
          value: "101946",
        },
        {
          label:
            "101667 - Beauty/Beauty Tools/Hair Tools/Heatless Styling Tools",
          value: "101667",
        },
        {
          label:
            "101923 - Motorcycles/Motorcycle Spare Parts/Drivetrain, Transmission & Clutches/Bearings",
          value: "101923",
        },
        {
          label: "100154 - Fashion Accessories/Eyewear/Others",
          value: "100154",
        },
        {
          label:
            "100596 - Women Shoes/Flat Sandals & Flip Flops/Indoor Slippers",
          value: "100596",
        },
        {
          label: "101017 - Baby & Kids Fashion/Baby Clothes/Winter Outerwear",
          value: "101017",
        },
        {
          label: "101308 - Sports & Outdoors/Sports Footwear/Others",
          value: "101308",
        },
        {
          label:
            "101947 - Computers & Accessories/Desktop Computers/All-in-One Desktops",
          value: "101947",
        },
        {
          label: "101668 - Beauty/Beauty Tools/Hair Tools/Others",
          value: "101668",
        },
        {
          label:
            "101924 - Motorcycles/Motorcycle Spare Parts/Drivetrain, Transmission & Clutches/Others",
          value: "101924",
        },
        {
          label:
            "100155 - Fashion Accessories/Investment Precious Metals/Platinum & K Gold",
          value: "100155",
        },
        {
          label: "100597 - Women Shoes/Flat Sandals & Flip Flops/Others",
          value: "100597",
        },
        {
          label: "101018 - Baby & Kids Fashion/Baby Clothes/Dresses",
          value: "101018",
        },
        {
          label: "101309 - Sports & Outdoors/Sports & Outdoor Apparels/Sets",
          value: "101309",
        },
        {
          label: "101948 - Computers & Accessories/Desktop Computers/Others",
          value: "101948",
        },
        {
          label: "101669 - Beauty/Skincare/Eye Treatment/Eye Cream",
          value: "101669",
        },
        {
          label:
            "100527 - Muslim Fashion/Women Muslim Wear/Veils/Hijab Accessories/Inner Hijab",
          value: "100527",
        },
        {
          label:
            "100156 - Fashion Accessories/Investment Precious Metals/Silver",
          value: "100156",
        },
        {
          label:
            "100598 - Women Shoes/Shoe Care & Accessories/Shoe Deodorizers",
          value: "100598",
        },
        {
          label: "101020 - Baby & Kids Fashion/Baby Clothes/Sleepwear",
          value: "101020",
        },
        {
          label: "101310 - Sports & Outdoors/Sports & Outdoor Apparels/Jackets",
          value: "101310",
        },
        {
          label:
            "101949 - Computers & Accessories/Desktop & Laptop Components/Fans & Heatsinks",
          value: "101949",
        },
        {
          label: "101670 - Beauty/Skincare/Eye Treatment/Eye Mask & Packs",
          value: "101670",
        },
        {
          label:
            "100528 - Muslim Fashion/Women Muslim Wear/Veils/Hijab Accessories/Hijab Pin",
          value: "100528",
        },
        {
          label:
            "100157 - Fashion Accessories/Investment Precious Metals/Diamond",
          value: "100157",
        },
        {
          label:
            "100599 - Women Shoes/Shoe Care & Accessories/Insoles & Heel Liners",
          value: "100599",
        },
        {
          label: "101021 - Baby & Kids Fashion/Baby Clothes/Tops",
          value: "101021",
        },
        {
          label:
            "101311 - Sports & Outdoors/Sports & Outdoor Apparels/T-shirts",
          value: "101311",
        },
        {
          label:
            "101950 - Computers & Accessories/Desktop & Laptop Components/Processors",
          value: "101950",
        },
        {
          label:
            "101671 - Beauty/Skincare/Eye Treatment/Eyebrow & Eyelash Serum",
          value: "101671",
        },
        {
          label:
            "100529 - Muslim Fashion/Women Muslim Wear/Veils/Hijab Accessories/Others",
          value: "100529",
        },
        {
          label: "100158 - Fashion Accessories/Investment Precious Metals/Jade",
          value: "100158",
        },
        {
          label:
            "100600 - Women Shoes/Shoe Care & Accessories/Shoe Horns & Trees",
          value: "100600",
        },
        {
          label:
            "101022 - Baby & Kids Fashion/Baby Clothes/Bodysuits & Jumpsuits",
          value: "101022",
        },
        {
          label: "101312 - Sports & Outdoors/Sports & Outdoor Apparels/Jerseys",
          value: "101312",
        },
        {
          label:
            "101951 - Computers & Accessories/Desktop & Laptop Components/Motherboards",
          value: "101951",
        },
        {
          label: "101672 - Beauty/Skincare/Eye Treatment/Others",
          value: "101672",
        },
        {
          label:
            "101925 - Pets/Pet Accessories/Toys/Dog & Cat Toys/Sticks & Chasers",
          value: "101925",
        },
        {
          label:
            "100159 - Fashion Accessories/Investment Precious Metals/Others",
          value: "100159",
        },
        {
          label:
            "100601 - Women Shoes/Shoe Care & Accessories/Shoe Care & Cleaning Tools",
          value: "100601",
        },
        {
          label: "101023 - Baby & Kids Fashion/Baby Clothes/Sets",
          value: "101023",
        },
        {
          label: "101313 - Sports & Outdoors/Sports & Outdoor Apparels/Bottoms",
          value: "101313",
        },
        {
          label:
            "101952 - Computers & Accessories/Desktop & Laptop Components/Graphics Cards",
          value: "101952",
        },
        {
          label: "101673 - Beauty/Skincare/Lip Treatment/Lip Balm",
          value: "101673",
        },
        {
          label:
            "101926 - Pets/Pet Accessories/Toys/Dog & Cat Toys/Chew, Bones & Balls",
          value: "101926",
        },
        {
          label:
            "100160 - Fashion Accessories/Additional Accessories/Brooches & Pins",
          value: "100160",
        },
        {
          label: "100602 - Women Shoes/Shoe Care & Accessories/Shoe Laces",
          value: "100602",
        },
        {
          label: "101024 - Baby & Kids Fashion/Baby Clothes/Swimwear",
          value: "101024",
        },
        {
          label:
            "101315 - Sports & Outdoors/Sports & Outdoor Apparels/Sports Bras",
          value: "101315",
        },
        {
          label:
            "101953 - Computers & Accessories/Desktop & Laptop Components/Thermal Paste",
          value: "101953",
        },
        {
          label:
            "101674 - Beauty/Skincare/Lip Treatment/Lip Scrub & Exfoliator",
          value: "101674",
        },
        {
          label: "101927 - Pets/Pet Accessories/Toys/Dog & Cat Toys/Frisbees",
          value: "101927",
        },
        {
          label: "100161 - Fashion Accessories/Additional Accessories/Patches",
          value: "100161",
        },
        {
          label: "100603 - Women Shoes/Shoe Care & Accessories/Others",
          value: "100603",
        },
        {
          label: "101025 - Baby & Kids Fashion/Baby Clothes/Others",
          value: "101025",
        },
        {
          label:
            "101316 - Sports & Outdoors/Sports & Outdoor Apparels/Kid's Sports Apparel",
          value: "101316",
        },
        {
          label:
            "101954 - Computers & Accessories/Desktop & Laptop Components/Power Supply Units",
          value: "101954",
        },
        {
          label: "101675 - Beauty/Skincare/Lip Treatment/Lip Mask",
          value: "101675",
        },
        {
          label: "101928 - Pets/Pet Accessories/Toys/Dog & Cat Toys/Others",
          value: "101928",
        },
        {
          label:
            "100162 - Fashion Accessories/Additional Accessories/Charms, Pendants & Ornaments",
          value: "100162",
        },
        {
          label: "100604 - Men Bags/Laptop Bags/Laptop Bags & Cases",
          value: "100604",
        },
        {
          label:
            "101027 - Baby & Kids Fashion/Baby & Kids Accessories/Hats & Caps",
          value: "101027",
        },
        {
          label: "101317 - Sports & Outdoors/Sports & Outdoor Apparels/Others",
          value: "101317",
        },
        {
          label:
            "101955 - Computers & Accessories/Desktop & Laptop Components/RAM",
          value: "101955",
        },
        {
          label: "101676 - Beauty/Skincare/Lip Treatment/Others",
          value: "101676",
        },
        {
          label:
            "101929 - Automobiles/Automobile Spare Parts/Electronics/Ignition/Ignition Coils",
          value: "101929",
        },
        {
          label:
            "100163 - Fashion Accessories/Additional Accessories/Cufflinks",
          value: "100163",
        },
        {
          label: "100605 - Men Bags/Laptop Bags/Laptop Sleeves",
          value: "100605",
        },
        {
          label: "101028 - Baby & Kids Fashion/Baby & Kids Accessories/Eyewear",
          value: "101028",
        },
        {
          label:
            "101318 - Sports & Outdoors/Sports & Outdoor Accessories/Stopwatches & Pedometers",
          value: "101318",
        },
        {
          label:
            "101956 - Computers & Accessories/Desktop & Laptop Components/UPS & Stabilizers",
          value: "101956",
        },
        {
          label:
            "102014 - Beauty/Bath & Body Care/Sun Care/Body Sunscreen & After Sun",
          value: "102014",
        },
        {
          label:
            "101930 - Automobiles/Automobile Spare Parts/Electronics/Ignition/Spark Plugs",
          value: "101930",
        },
        {
          label: "100164 - Fashion Accessories/Additional Accessories/Tattoos",
          value: "100164",
        },
        {
          label: "100606 - Men Bags/Laptop Bags/Laptop Backpacks",
          value: "100606",
        },
        {
          label:
            "101029 - Baby & Kids Fashion/Baby & Kids Accessories/Hair Accessories",
          value: "101029",
        },
        {
          label:
            "101319 - Sports & Outdoors/Sports & Outdoor Accessories/Shoe Bags",
          value: "101319",
        },
        {
          label:
            "101957 - Computers & Accessories/Desktop & Laptop Components/PC Cases",
          value: "101957",
        },
        {
          label:
            "102015 - Beauty/Bath & Body Care/Sun Care/Tanning Oil & Self Tanners",
          value: "102015",
        },
        {
          label:
            "101931 - Automobiles/Automobile Spare Parts/Electronics/Ignition/Others",
          value: "101931",
        },
        {
          label: "100165 - Fashion Accessories/Additional Accessories/Masks",
          value: "100165",
        },
        { label: "100607 - Men Bags/Laptop Bags/Others", value: "100607" },
        {
          label: "101030 - Baby & Kids Fashion/Baby & Kids Accessories/Gloves",
          value: "101030",
        },
        {
          label:
            "101320 - Sports & Outdoors/Sports & Outdoor Accessories/Sports Wristbands",
          value: "101320",
        },
        {
          label:
            "101958 - Computers & Accessories/Desktop & Laptop Components/Optical Drives",
          value: "101958",
        },
        {
          label: "102016 - Beauty/Bath & Body Care/Sun Care/Others",
          value: "102016",
        },
        {
          label:
            "100166 - Fashion Accessories/Additional Accessories/Handkerchiefs",
          value: "100166",
        },
        { label: "100608 - Men Bags/Wallets/Card Holders", value: "100608" },
        {
          label: "101031 - Baby & Kids Fashion/Baby & Kids Accessories/Belts",
          value: "101031",
        },
        {
          label:
            "101321 - Sports & Outdoors/Sports & Outdoor Accessories/Sports Headbands",
          value: "101321",
        },
        {
          label:
            "101959 - Computers & Accessories/Desktop & Laptop Components/Sound Cards",
          value: "101959",
        },
        {
          label: "101678 - Pets/Pet Accessories/Toys/Small Pet Toys",
          value: "101678",
        },
        {
          label: "100167 - Fashion Accessories/Additional Accessories/Others",
          value: "100167",
        },
        {
          label: "100609 - Men Bags/Wallets/Coin Holders & Purses",
          value: "100609",
        },
        {
          label: "101032 - Baby & Kids Fashion/Baby & Kids Accessories/Socks",
          value: "101032",
        },
        {
          label:
            "101322 - Sports & Outdoors/Sports & Outdoor Accessories/Sports & Outdoor Hats",
          value: "101322",
        },
        {
          label:
            "101960 - Computers & Accessories/Desktop & Laptop Components/Others",
          value: "101960",
        },
        {
          label: "101679 - Pets/Pet Accessories/Toys/Bird Toys",
          value: "101679",
        },
        {
          label:
            "100168 - Home Appliances/Projectors & Accessories/Projectors & Projector Screens",
          value: "100168",
        },
        {
          label: "100610 - Men Bags/Wallets/Phone & Key Wallets",
          value: "100610",
        },
        {
          label: "101033 - Baby & Kids Fashion/Baby & Kids Accessories/Scarves",
          value: "101033",
        },
        {
          label:
            "101323 - Sports & Outdoors/Sports & Outdoor Accessories/Dry Bags",
          value: "101323",
        },
        {
          label: "101961 - Computers & Accessories/Data Storage/Hard Drives",
          value: "101961",
        },
        { label: "101680 - Pets/Pet Accessories/Toys/Others", value: "101680" },
        {
          label: "100169 - Home Appliances/Projectors & Accessories/Pointers",
          value: "100169",
        },
        {
          label: "100611 - Men Bags/Wallets/Bifold & Trifold Wallets",
          value: "100611",
        },
        {
          label: "101034 - Baby & Kids Fashion/Baby & Kids Accessories/Watches",
          value: "101034",
        },
        {
          label:
            "101324 - Sports & Outdoors/Sports & Outdoor Accessories/Rain Coats",
          value: "101324",
        },
        {
          label: "101962 - Computers & Accessories/Data Storage/SSD",
          value: "101962",
        },
        {
          label: "101681 - Pets/Pet Accessories/Pet Furniture/Beds & Mats",
          value: "101681",
        },
        {
          label: "100170 - Home Appliances/Projectors & Accessories/Others",
          value: "100170",
        },
        { label: "100612 - Men Bags/Wallets/Long Wallets", value: "100612" },
        {
          label:
            "101037 - Baby & Kids Fashion/Baby & Kids Accessories/Earmuffs",
          value: "101037",
        },
        {
          label:
            "101325 - Sports & Outdoors/Sports & Outdoor Accessories/Umbrellas",
          value: "101325",
        },
        {
          label:
            "101963 - Computers & Accessories/Data Storage/Network Attached Storage (NAS)",
          value: "101963",
        },
        {
          label: "101682 - Pets/Pet Accessories/Pet Furniture/Houses",
          value: "101682",
        },
        {
          label:
            "100171 - Home Appliances/Small Household Appliances/Foot Baths & Spas",
          value: "100171",
        },
        { label: "100613 - Men Bags/Wallets/Others", value: "100613" },
        {
          label: "101038 - Baby & Kids Fashion/Baby & Kids Accessories/Others",
          value: "101038",
        },
        {
          label:
            "101326 - Sports & Outdoors/Sports & Outdoor Accessories/Mouthguards & Sport Tapes",
          value: "101326",
        },
        {
          label:
            "101964 - Computers & Accessories/Data Storage/Flash Drives & OTG",
          value: "101964",
        },
        {
          label:
            "101683 - Pets/Pet Accessories/Pet Furniture/Habitats & Accessories",
          value: "101683",
        },
        {
          label:
            "100172 - Home Appliances/Small Household Appliances/Water Flossers",
          value: "100172",
        },
        {
          label: "100614 - Watches/Watches Accessories/Straps",
          value: "100614",
        },
        {
          label: "101039 - Baby & Kids Fashion/Boy Clothes/Costumes",
          value: "101039",
        },
        {
          label:
            "101327 - Sports & Outdoors/Sports & Outdoor Accessories/Training Equipments",
          value: "101327",
        },
        {
          label:
            "101965 - Computers & Accessories/Data Storage/Hard Disk Casings & Dockings",
          value: "101965",
        },
        {
          label: "101684 - Pets/Pet Accessories/Pet Furniture/Cages & Crates",
          value: "101684",
        },
        {
          label:
            "100173 - Home Appliances/Small Household Appliances/Sewing Machines & Accessories",
          value: "100173",
        },
        {
          label: "100615 - Watches/Watches Accessories/Service Tools",
          value: "100615",
        },
        {
          label:
            "101040 - Baby & Kids Fashion/Boy Clothes/Underwear & Innerwear",
          value: "101040",
        },
        {
          label:
            "101328 - Sports & Outdoors/Sports & Outdoor Accessories/Gym Protective Gears",
          value: "101328",
        },
        {
          label: "101966 - Computers & Accessories/Data Storage/Compact Discs",
          value: "101966",
        },
        {
          label:
            "101685 - Pets/Pet Accessories/Pet Furniture/Scratching Pads & Posts",
          value: "101685",
        },
        {
          label:
            "100175 - Home Appliances/Small Household Appliances/Irons & Steamers",
          value: "100175",
        },
        {
          label: "100616 - Watches/Watches Accessories/Buckles",
          value: "100616",
        },
        {
          label: "101041 - Baby & Kids Fashion/Boy Clothes/Sleepwear",
          value: "101041",
        },
        {
          label:
            "101329 - Sports & Outdoors/Sports & Outdoor Accessories/Others",
          value: "101329",
        },
        {
          label: "101967 - Computers & Accessories/Data Storage/Others",
          value: "101967",
        },
        {
          label: "101686 - Pets/Pet Accessories/Pet Furniture/Others",
          value: "101686",
        },
        {
          label:
            "100177 - Home Appliances/Small Household Appliances/Vacuum Cleaners & Floor Care Appliances",
          value: "100177",
        },
        {
          label: "100617 - Watches/Watches Accessories/Batteries",
          value: "100617",
        },
        {
          label: "101042 - Baby & Kids Fashion/Boy Clothes/Swimwear",
          value: "101042",
        },
        {
          label: "101330 - Stationery/Gift & Wrapping/Gift Wrappers",
          value: "101330",
        },
        {
          label:
            "101968 - Computers & Accessories/Network Components/Modems & Wireless Routers",
          value: "101968",
        },
        {
          label:
            "101691 - Mom & Baby/Feeding Essentials/Bottle-feeding/Bottle Cooler Bag",
          value: "101691",
        },
        {
          label: "100178 - Home Appliances/Small Household Appliances/Others",
          value: "100178",
        },
        {
          label: "100618 - Watches/Watches Accessories/Boxes",
          value: "100618",
        },
        {
          label: "101046 - Baby & Kids Fashion/Boy Clothes/Suits & Sets",
          value: "101046",
        },
        {
          label: "101331 - Stationery/Gift & Wrapping/Gift Boxes",
          value: "101331",
        },
        {
          label:
            "101969 - Computers & Accessories/Network Components/Repeaters",
          value: "101969",
        },
        {
          label:
            "101692 - Mom & Baby/Feeding Essentials/Bottle-feeding/Bottles & Bottle accessories",
          value: "101692",
        },
        {
          label:
            "100180 - Home Appliances/Large Household Appliances/Water Heaters",
          value: "100180",
        },
        {
          label: "100619 - Watches/Watches Accessories/Others",
          value: "100619",
        },
        {
          label: "101047 - Baby & Kids Fashion/Boy Clothes/Others",
          value: "101047",
        },
        {
          label: "101332 - Stationery/Gift & Wrapping/Gift Bags",
          value: "101332",
        },
        {
          label:
            "101970 - Computers & Accessories/Network Components/Wireless Adapters & Network Cards",
          value: "101970",
        },
        {
          label:
            "101693 - Mom & Baby/Feeding Essentials/Bottle-feeding/Bottle Cleansing",
          value: "101693",
        },
        {
          label:
            "100182 - Home Appliances/Large Household Appliances/Futon & Shoe Dryers",
          value: "100182",
        },
        {
          label: "100620 - Audio/Media Players/MP3 & MP4 Players",
          value: "100620",
        },
        {
          label: "101048 - Baby & Kids Fashion/Girl Clothes/Costumes",
          value: "101048",
        },
        {
          label: "101333 - Stationery/Gift & Wrapping/Ribbons",
          value: "101333",
        },
        {
          label:
            "101971 - Computers & Accessories/Network Components/Powerline Adapters",
          value: "101971",
        },
        {
          label:
            "101694 - Mom & Baby/Feeding Essentials/Bottle-feeding/Warmers",
          value: "101694",
        },
        {
          label: "100183 - Home Appliances/Large Household Appliances/Heaters",
          value: "100183",
        },
        {
          label: "100621 - Audio/Media Players/CD, DVD, & Blu-ray Players",
          value: "100621",
        },
        {
          label:
            "101049 - Baby & Kids Fashion/Girl Clothes/Underwear & Innerwear",
          value: "101049",
        },
        {
          label: "101334 - Stationery/Gift & Wrapping/Bubble Wraps",
          value: "101334",
        },
        {
          label:
            "101972 - Computers & Accessories/Network Components/Network Switches & PoE",
          value: "101972",
        },
        {
          label:
            "101695 - Mom & Baby/Feeding Essentials/Bottle-feeding/Sterilizers",
          value: "101695",
        },
        {
          label: "100184 - Home Appliances/Large Household Appliances/Others",
          value: "100184",
        },
        {
          label: "100622 - Audio/Media Players/Voice Recorders",
          value: "100622",
        },
        {
          label: "101050 - Baby & Kids Fashion/Girl Clothes/Sleepwear",
          value: "101050",
        },
        {
          label: "101335 - Stationery/Gift & Wrapping/Carton Boxes",
          value: "101335",
        },
        {
          label:
            "101973 - Computers & Accessories/Network Components/Network Cables & Connectors",
          value: "101973",
        },
        {
          label: "101696 - Mom & Baby/Feeding Essentials/Bottle-feeding/Others",
          value: "101696",
        },
        {
          label: "100185 - Home Appliances/TVs & Accessories/TVs",
          value: "100185",
        },
        {
          label: "100623 - Audio/Media Players/Radio & Cassette Players",
          value: "100623",
        },
        {
          label: "101051 - Baby & Kids Fashion/Girl Clothes/Swimwear",
          value: "101051",
        },
        {
          label: "101336 - Stationery/Gift & Wrapping/Others",
          value: "101336",
        },
        {
          label:
            "101974 - Computers & Accessories/Network Components/KVM Switches",
          value: "101974",
        },
        {
          label:
            "101697 - Mom & Baby/Feeding Essentials/Breastfeeding/Breast Pump & Accessories",
          value: "101697",
        },
        {
          label: "100186 - Home Appliances/TVs & Accessories/TV Antennas",
          value: "100186",
        },
        { label: "100624 - Audio/Media Players/Others", value: "100624" },
        {
          label:
            "101055 - Baby & Kids Fashion/Girl Clothes/Rompers, Jumpsuits & Overalls",
          value: "101055",
        },
        {
          label: "101337 - Stationery/Writing & Correction/Pens & Inks",
          value: "101337",
        },
        {
          label:
            "101975 - Computers & Accessories/Network Components/Print Servers",
          value: "101975",
        },
        {
          label:
            "101698 - Mom & Baby/Feeding Essentials/Breastfeeding/Breast Pads, Shells & Shields",
          value: "101698",
        },
        {
          label:
            "100187 - Home Appliances/TVs & Accessories/TV Boxes & Receivers",
          value: "100187",
        },
        {
          label: "100625 - Audio/Home Audio & Speakers/Speakers",
          value: "100625",
        },
        {
          label: "101056 - Baby & Kids Fashion/Girl Clothes/Dresses",
          value: "101056",
        },
        {
          label: "101338 - Stationery/Writing & Correction/Pencils",
          value: "101338",
        },
        {
          label: "101976 - Computers & Accessories/Network Components/Others",
          value: "101976",
        },
        {
          label:
            "101699 - Mom & Baby/Feeding Essentials/Breastfeeding/Nursing Covers",
          value: "101699",
        },
        {
          label: "100188 - Home Appliances/TVs & Accessories/TV Brackets",
          value: "100188",
        },
        {
          label: "100626 - Audio/Home Audio & Speakers/Home Theater Systems",
          value: "100626",
        },
        {
          label: "101057 - Baby & Kids Fashion/Girl Clothes/Suits & Sets",
          value: "101057",
        },
        {
          label:
            "101339 - Stationery/Writing & Correction/Eraser & Correction Supplies",
          value: "101339",
        },
        {
          label:
            "101977 - Computers & Accessories/Office Equipment/Typewriters",
          value: "101977",
        },
        {
          label:
            "101700 - Mom & Baby/Feeding Essentials/Breastfeeding/Breastmilk Storage Bags",
          value: "101700",
        },
        {
          label: "100189 - Home Appliances/TVs & Accessories/Others",
          value: "100189",
        },
        {
          label: "100627 - Audio/Home Audio & Speakers/AV Receivers",
          value: "100627",
        },
        {
          label: "101058 - Baby & Kids Fashion/Girl Clothes/Others",
          value: "101058",
        },
        {
          label: "101340 - Stationery/Writing & Correction/Markers",
          value: "101340",
        },
        {
          label:
            "101978 - Computers & Accessories/Office Equipment/Absence Machines",
          value: "101978",
        },
        {
          label: "101701 - Mom & Baby/Feeding Essentials/Breastfeeding/Others",
          value: "101701",
        },
        {
          label:
            "100190 - Home Appliances/Kitchen Appliances/Water Filters, Coolers & Dispensers",
          value: "100190",
        },
        {
          label: "100628 - Audio/Home Audio & Speakers/Others",
          value: "100628",
        },
        {
          label: "101059 - Baby & Kids Fashion/Boy Shoes/Boots",
          value: "101059",
        },
        {
          label: "101341 - Stationery/Writing & Correction/Highlighters",
          value: "101341",
        },
        {
          label:
            "101979 - Computers & Accessories/Office Equipment/Paper Shredders",
          value: "101979",
        },
        {
          label: "101702 - Mom & Baby/Feeding Essentials/Utensils/Baby Cups",
          value: "101702",
        },
        {
          label: "100191 - Home Appliances/Kitchen Appliances/Kettles",
          value: "100191",
        },
        {
          label:
            "100780 - Food & Beverages/Convenience / Ready-to-eat/Cooked Food",
          value: "100780",
        },
        {
          label: "101060 - Baby & Kids Fashion/Boy Shoes/Sandals",
          value: "101060",
        },
        {
          label: "101342 - Stationery/Writing & Correction/Others",
          value: "101342",
        },
        {
          label:
            "101980 - Computers & Accessories/Office Equipment/Money Counters",
          value: "101980",
        },
        {
          label:
            "101703 - Mom & Baby/Feeding Essentials/Utensils/Baby Tableware",
          value: "101703",
        },
        {
          label: "100192 - Home Appliances/Kitchen Appliances/Wine Fridges",
          value: "100192",
        },
        {
          label: "100781 - Food & Beverages/Convenience / Ready-to-eat/Others",
          value: "100781",
        },
        {
          label: "101061 - Baby & Kids Fashion/Boy Shoes/Sneakers",
          value: "101061",
        },
        {
          label:
            "101343 - Stationery/School & Office Equipment/Writing Boards & Board Stands",
          value: "101343",
        },
        {
          label: "101981 - Computers & Accessories/Office Equipment/Others",
          value: "101981",
        },
        {
          label:
            "101704 - Mom & Baby/Feeding Essentials/Utensils/Baby Food Containers",
          value: "101704",
        },
        {
          label:
            "100193 - Home Appliances/Kitchen Appliances/Juicers, Blenders & Soya Bean Machines",
          value: "100193",
        },
        {
          label:
            "100782 - Food & Beverages/Convenience / Ready-to-eat/Instant Rice & Porridge",
          value: "100782",
        },
        {
          label: "101062 - Baby & Kids Fashion/Boy Shoes/Flip Flops",
          value: "101062",
        },
        {
          label: "101344 - Stationery/School & Office Equipment/Calculators",
          value: "101344",
        },
        {
          label:
            "101982 - Computers & Accessories/Printers & Scanners/Printers, Scanners & Photocopy Machines",
          value: "101982",
        },
        {
          label: "101705 - Mom & Baby/Feeding Essentials/Utensils/Others",
          value: "101705",
        },
        {
          label:
            "100194 - Home Appliances/Kitchen Appliances/Coffee Machines & Accessories",
          value: "100194",
        },
        {
          label:
            "100783 - Food & Beverages/Convenience / Ready-to-eat/Instant Hotpot",
          value: "100783",
        },
        {
          label: "101063 - Baby & Kids Fashion/Boy Shoes/Formal Shoes",
          value: "101063",
        },
        {
          label:
            "101345 - Stationery/School & Office Equipment/Pen Knives & Paper Cutters",
          value: "101345",
        },
        {
          label:
            "101983 - Computers & Accessories/Printers & Scanners/Thermal & Barcode Printers",
          value: "101983",
        },
        {
          label:
            "101706 - Mom & Baby/Nursery/Mattresses & Bedding/Blankets & Wrappers",
          value: "101706",
        },
        {
          label: "100195 - Home Appliances/Kitchen Appliances/Mixers",
          value: "100195",
        },
        {
          label:
            "100784 - Food & Beverages/Convenience / Ready-to-eat/Instant Noodles",
          value: "100784",
        },
        {
          label: "101064 - Baby & Kids Fashion/Boy Shoes/Loafers",
          value: "101064",
        },
        {
          label:
            "101346 - Stationery/School & Office Equipment/Strings & Tapes",
          value: "101346",
        },
        {
          label:
            "101984 - Computers & Accessories/Printers & Scanners/Inks & Toners",
          value: "101984",
        },
        {
          label:
            "101707 - Mom & Baby/Nursery/Mattresses & Bedding/Pillows & Bolsters",
          value: "101707",
        },
        {
          label: "100196 - Home Appliances/Kitchen Appliances/Dishwashers",
          value: "100196",
        },
        {
          label: "100785 - Food & Beverages/Snacks/Sweets & Candy",
          value: "100785",
        },
        {
          label: "101065 - Baby & Kids Fashion/Boy Shoes/Others",
          value: "101065",
        },
        {
          label: "101347 - Stationery/School & Office Equipment/Glues",
          value: "101347",
        },
        {
          label:
            "101985 - Computers & Accessories/Printers & Scanners/3D Printers",
          value: "101985",
        },
        {
          label: "101708 - Mom & Baby/Nursery/Mattresses & Bedding/Bedsheets",
          value: "101708",
        },
        {
          label:
            "100197 - Home Appliances/Kitchen Appliances/Stoves, Hobs & Gas Regulators",
          value: "100197",
        },
        {
          label: "100786 - Food & Beverages/Snacks/Chocolate",
          value: "100786",
        },
        {
          label: "101066 - Baby & Kids Fashion/Girl Shoes/Boots",
          value: "101066",
        },
        {
          label: "101348 - Stationery/School & Office Equipment/Label Printers",
          value: "101348",
        },
        {
          label: "101986 - Computers & Accessories/Printers & Scanners/Others",
          value: "101986",
        },
        {
          label: "101709 - Mom & Baby/Nursery/Mattresses & Bedding/Others",
          value: "101709",
        },
        {
          label: "100198 - Home Appliances/Kitchen Appliances/Air Fryers",
          value: "100198",
        },
        {
          label: "100787 - Food & Beverages/Snacks/Biscuits, Cookies & Wafers",
          value: "100787",
        },
        {
          label: "101067 - Baby & Kids Fashion/Girl Shoes/Sandals",
          value: "101067",
        },
        {
          label:
            "101349 - Stationery/School & Office Equipment/Lanyards & Name Tags",
          value: "101349",
        },
        {
          label:
            "101987 - Computers & Accessories/Peripherals & Accessories/USB Hubs & Card Readers",
          value: "101987",
        },
        {
          label:
            "101710 - Mom & Baby/Baby Healthcare/Baby Skincare/Lotion & Creams",
          value: "101710",
        },
        {
          label: "100199 - Home Appliances/Kitchen Appliances/Deep Fryers",
          value: "100199",
        },
        {
          label: "100788 - Food & Beverages/Snacks/Chips & Crisps",
          value: "100788",
        },
        {
          label: "101068 - Baby & Kids Fashion/Girl Shoes/Sneakers",
          value: "101068",
        },
        {
          label:
            "101350 - Stationery/School & Office Equipment/Clips, Pins & Tacks",
          value: "101350",
        },
        {
          label:
            "101988 - Computers & Accessories/Peripherals & Accessories/Webcams",
          value: "101988",
        },
        {
          label: "101711 - Mom & Baby/Baby Healthcare/Baby Skincare/Oils",
          value: "101711",
        },
        {
          label: "100200 - Home Appliances/Kitchen Appliances/Microwaves",
          value: "100200",
        },
        { label: "100789 - Food & Beverages/Snacks/Seeds", value: "100789" },
        {
          label: "101069 - Baby & Kids Fashion/Girl Shoes/Loafers",
          value: "101069",
        },
        {
          label: "101351 - Stationery/School & Office Equipment/Hole Punchers",
          value: "101351",
        },
        {
          label:
            "101989 - Computers & Accessories/Peripherals & Accessories/Laptop Skins & Covers",
          value: "101989",
        },
        {
          label: "101712 - Mom & Baby/Baby Healthcare/Baby Skincare/Powders",
          value: "101712",
        },
        {
          label: "100201 - Home Appliances/Kitchen Appliances/Ovens",
          value: "100201",
        },
        { label: "100790 - Food & Beverages/Snacks/Popcorn", value: "100790" },
        {
          label: "101070 - Baby & Kids Fashion/Girl Shoes/Flip Flops",
          value: "101070",
        },
        {
          label: "101352 - Stationery/School & Office Equipment/Scissors",
          value: "101352",
        },
        {
          label:
            "101990 - Computers & Accessories/Peripherals & Accessories/Cooling Pads",
          value: "101990",
        },
        {
          label: "101713 - Mom & Baby/Baby Healthcare/Baby Skincare/Others",
          value: "101713",
        },
        {
          label: "100202 - Home Appliances/Kitchen Appliances/Toasters",
          value: "100202",
        },
        { label: "100791 - Food & Beverages/Snacks/Seaweed", value: "100791" },
        {
          label: "101071 - Baby & Kids Fashion/Girl Shoes/Flats",
          value: "101071",
        },
        {
          label:
            "101353 - Stationery/School & Office Equipment/Ink Stamps & Pads",
          value: "101353",
        },
        {
          label:
            "101991 - Computers & Accessories/Peripherals & Accessories/Laptop Stands & Foldable Laptop Desks",
          value: "101991",
        },
        {
          label:
            "101714 - Mom & Baby/Toys/Baby & Toddler Toys/Playgym & Playmats",
          value: "101714",
        },
        {
          label:
            "100203 - Home Appliances/Kitchen Appliances/Food Processors & Meat Grinders",
          value: "100203",
        },
        { label: "100792 - Food & Beverages/Snacks/Nuts", value: "100792" },
        {
          label: "101072 - Baby & Kids Fashion/Girl Shoes/Others",
          value: "101072",
        },
        {
          label:
            "101354 - Stationery/School & Office Equipment/Staplers & Staples",
          value: "101354",
        },
        {
          label:
            "101992 - Computers & Accessories/Peripherals & Accessories/Keyboard & Trackpad Covers",
          value: "101992",
        },
        {
          label:
            "101715 - Mom & Baby/Toys/Baby & Toddler Toys/Playards & Playpens",
          value: "101715",
        },
        {
          label:
            "100204 - Home Appliances/Kitchen Appliances/Multi-function Cookers",
          value: "100204",
        },
        { label: "100793 - Food & Beverages/Snacks/Others", value: "100793" },
        {
          label: "101073 - Gaming & Consoles/Console Machines/Playstation",
          value: "101073",
        },
        {
          label: "101355 - Stationery/School & Office Equipment/Calendars",
          value: "101355",
        },
        {
          label:
            "101993 - Computers & Accessories/Peripherals & Accessories/Laptop Batteries",
          value: "101993",
        },
        {
          label: "101716 - Mom & Baby/Toys/Baby & Toddler Toys/Bath Toys",
          value: "101716",
        },
        {
          label: "100205 - Home Appliances/Kitchen Appliances/Pressure Cookers",
          value: "100205",
        },
        {
          label:
            "100794 - Food & Beverages/Snacks/Pudding, Jellies & Marshmallow",
          value: "100794",
        },
        {
          label: "101074 - Gaming & Consoles/Console Machines/Xbox",
          value: "101074",
        },
        {
          label:
            "101356 - Stationery/School & Office Equipment/Folders, Paper Organizers & Accessories",
          value: "101356",
        },
        {
          label:
            "101994 - Computers & Accessories/Peripherals & Accessories/Laptop Chargers & Adaptors",
          value: "101994",
        },
        {
          label:
            "101717 - Mom & Baby/Toys/Baby & Toddler Toys/Crib Mobiles & Rattles",
          value: "101717",
        },
        {
          label:
            "100206 - Home Appliances/Kitchen Appliances/Slow Cookers & Sous Vide Machines",
          value: "100206",
        },
        {
          label: "100797 - Food & Beverages/Food Staples/Noodles",
          value: "100797",
        },
        {
          label: "101075 - Gaming & Consoles/Console Machines/Wii",
          value: "101075",
        },
        {
          label:
            "101357 - Stationery/School & Office Equipment/Rulers, Protractors & Stencils",
          value: "101357",
        },
        {
          label:
            "101995 - Computers & Accessories/Peripherals & Accessories/Video Conference Devices",
          value: "101995",
        },
        {
          label: "101718 - Mom & Baby/Toys/Baby & Toddler Toys/Teethers",
          value: "101718",
        },
        {
          label: "100207 - Home Appliances/Kitchen Appliances/Rice Cookers",
          value: "100207",
        },
        {
          label: "100798 - Food & Beverages/Food Staples/Rice",
          value: "100798",
        },
        {
          label: "101076 - Gaming & Consoles/Console Machines/Nintendo DS",
          value: "101076",
        },
        {
          label: "101358 - Stationery/School & Office Equipment/Sharpeners",
          value: "101358",
        },
        {
          label:
            "101996 - Computers & Accessories/Peripherals & Accessories/Mouse Pads",
          value: "101996",
        },
        {
          label: "101719 - Mom & Baby/Toys/Baby & Toddler Toys/Others",
          value: "101719",
        },
        {
          label: "100209 - Home Appliances/Kitchen Appliances/Refrigerators",
          value: "100209",
        },
        {
          label: "100799 - Food & Beverages/Food Staples/Pasta",
          value: "100799",
        },
        {
          label: "101077 - Gaming & Consoles/Console Machines/Gameboy",
          value: "101077",
        },
        {
          label: "101359 - Stationery/School & Office Equipment/Pencil Cases",
          value: "101359",
        },
        {
          label:
            "101997 - Computers & Accessories/Peripherals & Accessories/Others",
          value: "101997",
        },
        {
          label:
            "101720 - Mom & Baby/Toys/Dolls & Stuffed Toys/Dolls & Accessories",
          value: "101720",
        },
        {
          label: "100210 - Home Appliances/Kitchen Appliances/Freezers",
          value: "100210",
        },
        {
          label: "100800 - Food & Beverages/Food Staples/Others",
          value: "100800",
        },
        {
          label: "101078 - Gaming & Consoles/Console Machines/Switch",
          value: "101078",
        },
        {
          label: "101360 - Stationery/School & Office Equipment/Others",
          value: "101360",
        },
        {
          label: "101998 - Computers & Accessories/Keyboards & Mice/Mice",
          value: "101998",
        },
        {
          label:
            "101721 - Mom & Baby/Toys/Dolls & Stuffed Toys/Doll Houses & Accessories",
          value: "101721",
        },
        {
          label: "100211 - Home Appliances/Kitchen Appliances/Hoods",
          value: "100211",
        },
        {
          label: "100802 - Food & Beverages/Food Staples/Preserved Vegetables",
          value: "100802",
        },
        {
          label: "101079 - Gaming & Consoles/Console Machines/PS Vita",
          value: "101079",
        },
        {
          label: "101361 - Stationery/Art Supplies/Color Pencils",
          value: "101361",
        },
        {
          label: "101999 - Computers & Accessories/Keyboards & Mice/Keyboards",
          value: "101999",
        },
        {
          label: "101722 - Mom & Baby/Toys/Dolls & Stuffed Toys/Stuffed Toys",
          value: "101722",
        },
        {
          label: "100212 - Home Appliances/Kitchen Appliances/Others",
          value: "100212",
        },
        {
          label: "100803 - Food & Beverages/Cooking Essentials/Oil",
          value: "100803",
        },
        {
          label: "101080 - Gaming & Consoles/Console Machines/PSP",
          value: "101080",
        },
        {
          label: "101362 - Stationery/Art Supplies/Crayons & Pastels",
          value: "101362",
        },
        {
          label:
            "102000 - Computers & Accessories/Keyboards & Mice/Drawing Tablets",
          value: "102000",
        },
        {
          label: "101723 - Mom & Baby/Toys/Dolls & Stuffed Toys/Others",
          value: "101723",
        },
        {
          label:
            "100213 - Home Appliances/Electrical Circuitry & Parts/Electric Sockets & Extension Cords",
          value: "100213",
        },
        {
          label: "100805 - Food & Beverages/Cooking Essentials/Sugar",
          value: "100805",
        },
        {
          label: "101081 - Gaming & Consoles/Console Machines/Others",
          value: "101081",
        },
        {
          label: "101363 - Stationery/Art Supplies/Water & Poster Colours",
          value: "101363",
        },
        {
          label: "102001 - Computers & Accessories/Keyboards & Mice/Others",
          value: "102001",
        },
        {
          label:
            "101724 - Mom & Baby/Toys/Sports & Outdoor Play/Bicycles, Scooters & Ride-ons",
          value: "101724",
        },
        {
          label:
            "100214 - Home Appliances/Electrical Circuitry & Parts/Electrical Safety",
          value: "100214",
        },
        {
          label: "100806 - Food & Beverages/Cooking Essentials/Sweetener",
          value: "100806",
        },
        {
          label: "101082 - Gaming & Consoles/Video Games/Playstation",
          value: "101082",
        },
        {
          label: "101364 - Stationery/Art Supplies/Oil Paint",
          value: "101364",
        },
        {
          label:
            "100420 - Health/Medical Supplies/Health Monitors & Tests/Blood Pressure Monitors",
          value: "100420",
        },
        {
          label:
            "101725 - Mom & Baby/Toys/Sports & Outdoor Play/Inflatables & Slides",
          value: "101725",
        },
        {
          label:
            "100215 - Home Appliances/Electrical Circuitry & Parts/Electricity Savers",
          value: "100215",
        },
        {
          label:
            "100807 - Food & Beverages/Cooking Essentials/Stock, Gravy & Instant Soup",
          value: "100807",
        },
        {
          label: "101083 - Gaming & Consoles/Video Games/Xbox",
          value: "101083",
        },
        {
          label: "101365 - Stationery/Art Supplies/Acrylic Paint",
          value: "101365",
        },
        {
          label:
            "100421 - Health/Medical Supplies/Health Monitors & Tests/Blood Glucose Monitors",
          value: "100421",
        },
        {
          label:
            "101726 - Mom & Baby/Toys/Sports & Outdoor Play/Play Tents, Tunnels & Ball Pits",
          value: "101726",
        },
        {
          label:
            "100216 - Home Appliances/Electrical Circuitry & Parts/Doorbells",
          value: "100216",
        },
        {
          label:
            "100808 - Food & Beverages/Cooking Essentials/Cooking Paste & Kit",
          value: "100808",
        },
        {
          label: "101084 - Gaming & Consoles/Video Games/Wii",
          value: "101084",
        },
        {
          label: "101366 - Stationery/Art Supplies/Paint Brushes",
          value: "101366",
        },
        {
          label:
            "100422 - Health/Medical Supplies/Health Monitors & Tests/Pulse Oximeters",
          value: "100422",
        },
        {
          label:
            "101727 - Mom & Baby/Toys/Sports & Outdoor Play/Pool, Water & Sand Toys",
          value: "101727",
        },
        {
          label:
            "100217 - Home Appliances/Electrical Circuitry & Parts/Switches",
          value: "100217",
        },
        {
          label:
            "100809 - Food & Beverages/Cooking Essentials/Flavour Enhancers",
          value: "100809",
        },
        {
          label: "101085 - Gaming & Consoles/Video Games/Nintendo DS",
          value: "101085",
        },
        {
          label: "101367 - Stationery/Art Supplies/Paint Palettes",
          value: "101367",
        },
        {
          label:
            "100423 - Health/Medical Supplies/Health Monitors & Tests/Thermometers",
          value: "100423",
        },
        {
          label:
            "101728 - Mom & Baby/Toys/Sports & Outdoor Play/Blasters & Toy Guns",
          value: "101728",
        },
        {
          label:
            "100218 - Home Appliances/Electrical Circuitry & Parts/House Alarms",
          value: "100218",
        },
        {
          label: "100810 - Food & Beverages/Cooking Essentials/Flour Coating",
          value: "100810",
        },
        {
          label: "101086 - Gaming & Consoles/Video Games/Gameboy",
          value: "101086",
        },
        {
          label: "101368 - Stationery/Art Supplies/Canvases & Easels",
          value: "101368",
        },
        {
          label:
            "100424 - Health/Medical Supplies/Health Monitors & Tests/Others",
          value: "100424",
        },
        {
          label: "101729 - Mom & Baby/Toys/Sports & Outdoor Play/Sports Toys",
          value: "101729",
        },
        {
          label:
            "100219 - Home Appliances/Electrical Circuitry & Parts/Lightning Protection",
          value: "100219",
        },
        {
          label: "100811 - Food & Beverages/Cooking Essentials/Others",
          value: "100811",
        },
        {
          label: "101087 - Gaming & Consoles/Video Games/Switch",
          value: "101087",
        },
        {
          label: "101369 - Stationery/Art Supplies/Sketch Books",
          value: "101369",
        },
        {
          label:
            "100425 - Health/Medical Supplies/First Aid Supplies/Plasters & Bandages",
          value: "100425",
        },
        {
          label:
            "101730 - Mom & Baby/Toys/Sports & Outdoor Play/Flying Toys, Kites & Wind Spinners",
          value: "101730",
        },
        {
          label: "100220 - Home Appliances/Electrical Circuitry & Parts/Others",
          value: "100220",
        },
        {
          label: "100812 - Food & Beverages/Baking Needs/Baking Flavoring",
          value: "100812",
        },
        {
          label: "101088 - Gaming & Consoles/Video Games/PS Vita",
          value: "101088",
        },
        { label: "101370 - Stationery/Art Supplies/Others", value: "101370" },
        {
          label:
            "100426 - Health/Medical Supplies/First Aid Supplies/First Aid Accessories",
          value: "100426",
        },
        {
          label: "101731 - Mom & Baby/Toys/Sports & Outdoor Play/Others",
          value: "101731",
        },
        {
          label: "100226 - Men Clothes/Hoodies & Sweatshirts/Hoodies",
          value: "100226",
        },
        {
          label: "100813 - Food & Beverages/Baking Needs/Baking Powder & Soda",
          value: "100813",
        },
        {
          label: "101089 - Gaming & Consoles/Video Games/PSP",
          value: "101089",
        },
        {
          label: "101371 - Stationery/Notebooks & Papers/Bookmarks",
          value: "101371",
        },
        {
          label:
            "100427 - Health/Medical Supplies/First Aid Supplies/Ointments & Creams",
          value: "100427",
        },
        {
          label: "101732 - Mom & Baby/Toys/Educational Toys/Arts & Crafts",
          value: "101732",
        },
        {
          label: "100227 - Men Clothes/Hoodies & Sweatshirts/Sweatshirts",
          value: "100227",
        },
        {
          label: "100814 - Food & Beverages/Baking Needs/Baking Premix Flour",
          value: "100814",
        },
        {
          label: "101090 - Gaming & Consoles/Video Games/PC Game",
          value: "101090",
        },
        {
          label: "101372 - Stationery/Notebooks & Papers/Book Covers",
          value: "101372",
        },
        {
          label:
            "100428 - Health/Medical Supplies/First Aid Supplies/Medical Antiseptics & Disinfectants",
          value: "100428",
        },
        {
          label: "101733 - Mom & Baby/Toys/Educational Toys/Math Toys",
          value: "101733",
        },
        {
          label: "100228 - Men Clothes/Hoodies & Sweatshirts/Others",
          value: "100228",
        },
        {
          label: "100815 - Food & Beverages/Baking Needs/Flour",
          value: "100815",
        },
        {
          label: "101091 - Gaming & Consoles/Video Games/Others",
          value: "101091",
        },
        {
          label:
            "101373 - Stationery/Notebooks & Papers/Thermal Paper & Continuous Paper",
          value: "101373",
        },
        {
          label: "100429 - Health/Medical Supplies/First Aid Supplies/Others",
          value: "100429",
        },
        {
          label:
            "101734 - Mom & Baby/Toys/Educational Toys/Science & Tech Toys",
          value: "101734",
        },
        {
          label:
            "100229 - Men Clothes/Jackets, Coats & Vests/Winter Jackets & Coats",
          value: "100229",
        },
        {
          label: "100816 - Food & Beverages/Baking Needs/Food Coloring",
          value: "100816",
        },
        {
          label: "101092 - Cameras & Drones/Cameras/Point & Shoot",
          value: "101092",
        },
        {
          label:
            "101374 - Stationery/Notebooks & Papers/Printing & Photocopy Paper",
          value: "101374",
        },
        {
          label:
            "100430 - Health/Medical Supplies/Injury & Disability Support/Walking Aids",
          value: "100430",
        },
        {
          label: "101735 - Mom & Baby/Toys/Educational Toys/Shape Sorters",
          value: "101735",
        },
        {
          label: "100230 - Men Clothes/Jackets, Coats & Vests/Jackets",
          value: "100230",
        },
        {
          label: "100817 - Food & Beverages/Baking Needs/Baking decoration",
          value: "100817",
        },
        {
          label: "101093 - Cameras & Drones/Cameras/Mirrorless Cameras",
          value: "101093",
        },
        {
          label: "101375 - Stationery/Notebooks & Papers/Loose Leaf",
          value: "101375",
        },
        {
          label:
            "100431 - Health/Medical Supplies/Injury & Disability Support/Wheelchairs",
          value: "100431",
        },
        {
          label: "101736 - Mom & Baby/Toys/Educational Toys/Puzzles",
          value: "101736",
        },
        {
          label: "100231 - Men Clothes/Jackets, Coats & Vests/Vests",
          value: "100231",
        },
        {
          label: "100818 - Food & Beverages/Baking Needs/Others",
          value: "100818",
        },
        {
          label: "101094 - Cameras & Drones/Cameras/Action Cameras",
          value: "101094",
        },
        {
          label: "101376 - Stationery/Notebooks & Papers/Memo & Sticky Notes",
          value: "101376",
        },
        {
          label:
            "100432 - Health/Medical Supplies/Injury & Disability Support/Braces & Supports",
          value: "100432",
        },
        {
          label: "101737 - Mom & Baby/Toys/Educational Toys/Musical Toys",
          value: "101737",
        },
        {
          label: "100232 - Men Clothes/Jackets, Coats & Vests/Others",
          value: "100232",
        },
        {
          label:
            "100819 - Food & Beverages/Breakfast Cereals & Spread/Honey & Maple Syrups",
          value: "100819",
        },
        {
          label: "101095 - Cameras & Drones/Cameras/Video Camcorders",
          value: "101095",
        },
        {
          label: "101377 - Stationery/Notebooks & Papers/Art Paper & Boards",
          value: "101377",
        },
        {
          label:
            "100433 - Health/Medical Supplies/Injury & Disability Support/Hearing Aids",
          value: "100433",
        },
        {
          label:
            "101738 - Mom & Baby/Toys/Educational Toys/Toy Tablets & Computers",
          value: "101738",
        },
        { label: "100233 - Men Clothes/Suits/Suit Sets", value: "100233" },
        {
          label:
            "100820 - Food & Beverages/Breakfast Cereals & Spread/Jam & Spread",
          value: "100820",
        },
        {
          label: "101096 - Cameras & Drones/Cameras/Instant Cameras",
          value: "101096",
        },
        {
          label: "101378 - Stationery/Notebooks & Papers/Notebooks & Notepads",
          value: "101378",
        },
        {
          label:
            "100434 - Health/Medical Supplies/Injury & Disability Support/Others",
          value: "100434",
        },
        {
          label: "101739 - Mom & Baby/Toys/Educational Toys/Others",
          value: "101739",
        },
        {
          label: "100234 - Men Clothes/Suits/Suit Jackets & Blazers",
          value: "100234",
        },
        {
          label:
            "100821 - Food & Beverages/Breakfast Cereals & Spread/Cereal, Granola & Oats",
          value: "100821",
        },
        {
          label: "101097 - Cameras & Drones/Cameras/Analog Cameras",
          value: "101097",
        },
        {
          label: "101379 - Stationery/Notebooks & Papers/Labels & Stickers",
          value: "101379",
        },
        {
          label: "100435 - Health/Personal Care/Eye Care/Contact lens",
          value: "100435",
        },
        {
          label:
            "101740 - Baby & Kids Fashion/Baby Clothes/Bottoms/Pants & Leggings",
          value: "101740",
        },
        { label: "100235 - Men Clothes/Suits/Suit Pants", value: "100235" },
        {
          label:
            "100822 - Food & Beverages/Breakfast Cereals & Spread/Breakfast Bar",
          value: "100822",
        },
        { label: "101098 - Cameras & Drones/Cameras/DSLRs", value: "101098" },
        {
          label: "101380 - Stationery/Notebooks & Papers/Others",
          value: "101380",
        },
        {
          label:
            "100436 - Health/Personal Care/Eye Care/Lens Solutions & Eyedrops",
          value: "100436",
        },
        {
          label: "101741 - Baby & Kids Fashion/Baby Clothes/Bottoms/Shorts",
          value: "101741",
        },
        {
          label: "100236 - Men Clothes/Suits/Suit Vests & Waistcoats",
          value: "100236",
        },
        {
          label: "100823 - Food & Beverages/Breakfast Cereals & Spread/Others",
          value: "100823",
        },
        { label: "101099 - Cameras & Drones/Cameras/Others", value: "101099" },
        {
          label: "101381 - Stationery/Letters & Envelopes/Envelopes & Angpao",
          value: "101381",
        },
        {
          label: "100437 - Health/Personal Care/Eye Care/Others",
          value: "100437",
        },
        {
          label: "101742 - Baby & Kids Fashion/Baby Clothes/Bottoms/Skirts",
          value: "101742",
        },
        { label: "100237 - Men Clothes/Suits/Others", value: "100237" },
        {
          label: "100824 - Food & Beverages/Beverages/Coffee",
          value: "100824",
        },
        {
          label:
            "101100 - Cameras & Drones/Security Cameras & Systems/CCTV Security Cameras",
          value: "101100",
        },
        {
          label: "101382 - Stationery/Letters & Envelopes/Post Cards",
          value: "101382",
        },
        {
          label: "100438 - Health/Personal Care/Oral Care/Manual Toothbrushes",
          value: "100438",
        },
        {
          label: "101743 - Baby & Kids Fashion/Baby Clothes/Bottoms/Others",
          value: "101743",
        },
        { label: "100238 - Men Clothes/Pants/Cargo", value: "100238" },
        {
          label: "100825 - Food & Beverages/Beverages/Tea & Tea Bags",
          value: "100825",
        },
        {
          label: "101101 - Cameras & Drones/Security Cameras & Systems/DVRs",
          value: "101101",
        },
        {
          label:
            "101383 - Stationery/Letters & Envelopes/Postage Stamps & Duty Stamps",
          value: "101383",
        },
        {
          label:
            "100439 - Health/Personal Care/Oral Care/Electric Toothbrushes & Accessories",
          value: "100439",
        },
        {
          label:
            "101744 - Baby & Kids Fashion/Baby & Kids Accessories/Bags & Luggage/Backpacks",
          value: "101744",
        },
        { label: "100239 - Men Clothes/Pants/Joggers", value: "100239" },
        {
          label: "100826 - Food & Beverages/Beverages/Chocolate Drinks",
          value: "100826",
        },
        {
          label:
            "101102 - Cameras & Drones/Security Cameras & Systems/Dummy Cameras",
          value: "101102",
        },
        {
          label: "101384 - Stationery/Letters & Envelopes/Others",
          value: "101384",
        },
        {
          label: "100440 - Health/Personal Care/Oral Care/Toothpastes",
          value: "100440",
        },
        {
          label:
            "101745 - Baby & Kids Fashion/Baby & Kids Accessories/Bags & Luggage/Crossbody & Shoulder Bags",
          value: "101745",
        },
      ],
      errorInfo: [{ label: "系统业务繁忙，请稍后在试！", value: "timeout" }],
      country_site: [
        { label: "跨境仓", value: "WW" },
        { label: "泰国仓", value: "TH" },
        { label: "马来仓", value: "MY" },
        { label: "越南仓", value: "VN" },
        { label: "菲律宾仓", value: "PH" },
        { label: "印尼仓", value: "ID" },
        { label: "新加坡仓", value: "SG" },
      ],
      MANUFACTURER: [
        { label: "泉州工厂", value: "01" },
        { label: "莆田工厂", value: "04" },
        { label: "菲律宾仓1", value: "02" },
        { label: "菲律宾仓2", value: "07" },
        { label: "菲律宾仓3", value: "08" },
        { label: "泰国仓1", value: "03" },
        { label: "印尼仓1", value: "05" },
        { label: "马来仓1", value: "06" },
        { label: "泰国仓2", value: "09" },
        { label: "菲律宾仓4", value: "10" },
        { label: "泰国仓2", value: "11" },
        { label: "印尼仓2", value: "12" },
        { label: "马来仓2", value: "13" },
        { label: "新加坡仓1", value: "14" },
      ],
      tiktok_status: [
        { label: "UNPAID", value: "100" },
        { label: "AWAITING_SHIPMENT", value: "111" },
        { label: "AWAITING_COLLECTION", value: "112" },
        { label: " PARTIALLY_SHIPPING", value: "114" },
        { label: "IN_TRANSIT", value: "121" },
        { label: "DELIVERED", value: "122" },
        { label: "COMPLETED", value: "130" },
        { label: "CANCELLED", value: "140" },
      ],
    },
    dictMap: {
      tiktok_status: {
        "100": "UNPAID",
        "111": "AWAITING_SHIPMENT",
        "112": "AWAITING_COLLECTION",
        "114": " PARTIALLY_SHIPPING",
        "121": "IN_TRANSIT",
        "122": "DELIVERED",
        "130": "COMPLETED",
        "140": "CANCELLED",
      },
      country_site: {
        WW: "跨境仓",
        TH: "泰国仓",
        MY: "马来仓",
        VN: "越南仓",
        PH: "菲律宾仓",
        ID: "印尼仓",
        SG: "新加坡仓",
      },
      lazada_color: {
        "Blush Pink": "Blush Pink",
        Bronze: "Bronze",
        Brown: "Brown",
        Burgundy: "Burgundy",
        Cacao: "Cacao",
        Camel: "Camel",
        Champagne: "Champagne",
        "Champagne Pink": "Champagne Pink",
        Charcoal: "Charcoal",
        Cherry: "Cherry",
        Chestnut: "Chestnut",
        "Chili Red": "Chili Red",
        Chocolate: "Chocolate",
        Cinnamon: "Cinnamon",
        Clear: "Clear",
        Coffee: "Coffee",
        Cream: "Cream",
        "Dark Ash": "Dark Ash",
        "Dark Brown": "Dark Brown",
        "Dark Grey": "Dark Grey",
        "Dark blue": "Dark blue",
        "Deep Black": "Deep Black",
        "Deep Blue": "Deep Blue",
        "Deep Gray": "Deep Gray",
        "Deep green": "Deep green",
        "Emerald Green": "Emerald Green",
        Floral: "Floral",
        "Fluorescent Green": "Fluorescent Green",
        Red: "Red",
      },
      amazon_site: {
        US: "美国",
        CA: "加拿大",
        MX: "墨西哥",
        CN: "中国",
        BR: "巴西",
        ES: "西班牙",
        GB: "英国",
      },
      tiktok_Warranty: {
        "1": "4 weeks",
        "2": "2 months",
        "3": "3 months",
        "4": "4 months",
        "5": "5 months",
        "6": "6 months",
        "7": "7 months",
        "8": "8 months",
        "9": "9 months",
        "10": "10 months",
        "11": "11 months",
        "12": "12 months",
        "13": "2 years",
        "14": "3 years",
        "15": "1 week",
        "16": "2 weeks",
        "17": "18 months",
        "18": "4 years",
        "19": "5 years",
        "20": "10 years",
        "21": "lifetime warranty",
      },
      coupon_code: { "01": "购买套餐" },
      coupon_type: { "01": "满减券", "02": "折扣券" },
      coupon_status: { "01": "未使用", "02": "已使用", "03": "已过期" },
      lazada_Express: { "0": "No", "1": "Yes" },
      lazada_size: {
        "100": "EU:33.5",
        "101": "EU:34",
        "102": "EU:34.5",
        "103": "EU:26",
        "104": "EU:27",
        "105": "EU:26.5",
        "106": "EU:27.5",
        "107": "EU:28",
        "108": "EU:28.5",
        "109": "EU:29",
        "110": "EU:29.5",
        "111": "EU:30",
        "112": "EU:27-28",
        "113": "EU:28-29",
        "114": "EU:29-30",
        "115": "EU:29.5-30",
        "116": "EU:30-30.5",
        "117": "EU:31",
        "118": "EU:31.5",
        "119": "EU:32",
        "120": "EU:32.5",
        "121": "EU:33",
        "122": "EU:33.5",
        "123": "EU:34",
        "124": "EU:33-34",
        "125": "EU:33-33.5",
        "126": "EU:34.5",
        "127": "EU:35",
        "128": "EU:35.5",
        "129": "EU:36",
        "130": "EU:33-34.5",
        "131": "EU:34.5-35",
        "132": "EU:35-36",
        "133": "EU:36.5",
        "134": "EU:36.5-37",
        "135": "EU:37",
        "136": "EU:37.5-38",
        "137": "EU:38.5",
        "138": "EU:38.5-39",
        "139": "EU:39",
        "140": "EU:39.5",
        "141": "EU:40",
        "142": "EU:41",
        "143": "EU:40-41",
      },
      sys_show_hide: { "0": "显示", "1": "隐藏" },
      language: {
        White: "白色",
        Black: "黑色",
        Brown: "棕色",
        Beige: "米色",
        Blue: "蓝色",
        Green: "绿色",
        Red: "红色",
        Transparent: "透明色",
      },
      notice_type: {
        "01": "新品发布",
        "02": "物流公告",
        "03": "网站公告",
        "04": "功能更新",
      },
      order_status: {
        "01": "待付货款",
        "02": "已付货款",
        "03": "排单中",
        "04": "生产中",
        "05": "已发货",
        "06": "已取消",
        "07": "审核不通过",
        "09": "待编辑",
      },
      user_type: { "00": "系统用户", "01": "商家用户", "02": "商家子用户" },
      dz_order_status: {
        "01": "待付款",
        "02": "已付款",
        "03": "排单中",
        "04": "生产中",
        "05": "已发货",
        "06": "已取消",
        "07": "已删除",
      },
      coupon_code: [{ label: "购买套餐", value: "01" }],
      coupon_status: [
        { label: "未使用", value: "01" },
        { label: "已使用", value: "02" },
        { label: "已过期", value: "03" },
      ],
      coupon_type: [
        { label: "满减券", value: "01" },
        { label: "折扣券", value: "02" },
      ],
      shopee_style: { "0": "夹克", "1": "短袖" },
      shopee_style2: { "0": "夏季款", "1": "冬季款" },
      auth_status: { "01": "未授权", "02": "已授权" },
      sys_app_platform: { "0": "管理平台", "1": "电商平台" },
      lazada_DangerousGoods: {
        "1": "Battery",
        "2": "Flammable",
        "3": "Liquid",
        "4": "None",
      },
      currency_mark: {
        PHP: "₱",
        MYR: "RM",
        IDR: "Rp",
        THB: "฿",
        VND: "₫",
        SGD: "S$",
        USD: "$",
        GBP: "£",
        CAD: "C$",
        CNY: "¥",
        EUR: "€",
        CDN: "C$",
        JPY: "JPY¥",
      },
      lazada_fa_create_year: {
        "1": "2012",
        "2": "2013",
        "3": "2014",
        "4": "2015",
        "5": "2016",
        "6": "2017",
        "7": "2018",
        "8": "2019",
        "9": "2020",
      },
      shop_status: { "01": "正常", "02": "禁用" },
      sys_job_status: { "0": "正常", "1": "暂停" },
      lazada_pattern: {
        "500": "赤坎刺绣",
        "501": "阿丽刺绣",
        "502": "克什米尔刺绣",
      },
      prod_days: {
        "01": "1~3天",
        "02": "3~5天",
        "03": "5~7天",
        "04": "大于7天",
      },
      pub_status: { "01": "采集箱", "02": "待发布", "03": "已发布" },
      map_shape: {
        "01": "似方形",
        "02": "横向形",
        "03": "竖向形",
        "04": "超横形",
        "05": "超竖形",
      },
      shopee_material: { "0": "棉布", "1": "麻布" },
      s_plat_code: {
        "01": "1688",
        "02": "速卖通",
        "03": "Lazada",
        "04": "Amazon",
        "05": "Shopee",
        "06": "淘宝",
      },
      map_format: { "01": "PNG", "02": "JPG/JPEG", "03": "矢量图" },
      gear: {
        "1": "最低档位",
        "2": "第二档位",
        "3": "第三档位",
        "4": "第四档位",
        "5": "最高档位",
        A: "最低价",
        B: "零售价",
        C: "成本价",
      },
      withdraw_mode: { "01": "支付宝", "02": "银行卡" },
      plat_no: {
        "01": "1688",
        "10": "tiktok",
        "03": "shein",
        "04": "亚马逊",
        "05": "Temu",
        "09": "其他",
      },
      color: {
        白色: "#FFFFFF",
        黑色: "#000000",
        棕色: "#A52A2A",
        米色: "#F5F5DC",
        蓝色: "#0000FF",
        绿色: "#008000",
        红色: "#FF0000",
        透明色: "#FFFFFF",
        White: "#FFFFFF",
        Black: "#000000",
        Brown: "#A52A2A",
        Beige: "#F5F5DC",
        Blue: "#0000FF",
        Green: "#008000",
        Red: "#FF0000",
        Transparent: "#FFFFFF",
      },
      fund_type: { "01": "收入", "02": "支出" },
      sale_form: { "01": "单品", "02": "多变种" },
      import_status: { "01": "处理中", "02": "成功", "03": "失败" },
      question_status: { "01": "未处理", "02": "已处理" },
      yes_or_no: { "0": "否", "1": "是" },
      sys_user_sex: { "0": "男", "1": "女", "2": "未知" },
      sys_oper_type: {
        "1": "新增",
        "2": "修改",
        "3": "删除",
        "4": "授权",
        "5": "导出",
        "6": "导入",
        "7": "强退",
        "8": "生成代码",
        "9": "清空数据",
      },
      remarkType: {
        "99": "其他",
        "01": "请发送一个空包",
        "02": "尺寸修改为3XL",
      },
      lazada_season: {
        "1": "Spring",
        "2": "Summer",
        "3": "Autumn",
        "4": "Winter",
      },
      match_type: {
        "01": "未关联",
        "02": "自动关联",
        "03": "手动关联",
        "04": "自建订单",
      },
      currency: { USD: "美元(USD)", GBP: "英镑(GBP)", CAD: "加元(CAD)" },
      lazada_tax: { "1": "11%", "2": "16%", "3": "17%" },
      sys_tree_node_type: { "0": "否", "1": "是" },
      shopee_attr_size: {
        "0": "XXS",
        "1": "XS",
        "2": "S",
        "3": "M",
        "4": "L",
        "5": "XL",
        "6": "XXL",
        "7": "XXXL",
        "8": "XXXXL",
        "9": "XXXXXL",
      },
      syncStatus: { "01": "待发布", "02": "已发布", "03": "发布失败", "04": "发布中" },
      sys_job_group: { DEFAULT: "默认", SYSTEM: "系统" },
      question_type: {
        "01": "新手指导",
        "02": "常见问题",
        "03": "选品",
        "04": "设计",
        "05": "刊登",
        "06": "订单",
        "07": "账户",
        "09": "其他帮助",
      },
      sys_yes_no: { Y: "是", N: "否" },
      recharge_mode: { "01": "线上充值", "02": "银行转账" },
      map_source: { "01": "收费图库", "02": "我的图库" },
      lazada_warranty: {
        "1008": "1年",
        "1009": "1个月",
        "1010": "10日",
        "1011": "10年",
        "1012": "20年",
        "1013": "10个月",
        "1014": "1周",
        "1017": "5年",
      },
      lazada_DeliveryOptionEconomy: { "0": "No", "1": "Yes" },
      lazada_station: {
        "01": "马来西亚",
        "02": "新加坡",
        "03": "泰国",
        "04": "印度尼西亚",
        "05": "菲律宾",
        "06": "越南",
      },
      check_status: { "01": "待审核", "02": "审核通过", "03": "审核不通过" },
      lazada_qa_type: {
        "1": "International Manufacturer Warranty",
        "2": "International Seller Warranty",
        "3": "Local Manufacturer Warranty",
        "4": "Local Supplier Warranty",
        "5": "No Warranty",
      },
      lazada_material: {
        "1": "尼龙",
        "2": "帆布",
        "3": "亚麻",
        "4": "棉",
        "5": "塑料",
      },
      amazon_order_status: {
        PendingAvailability: "PendingAvailability",
        Pending: "Pending",
        Unshipped: "Unshipped",
        PartiallyShipped: "PartiallyShipped",
        Shipped: "Shipped",
        InvoiceUnconfirmed: "InvoiceUnconfirmed",
        Canceled: "Canceled",
        Unfulfillable: "Unfulfillable",
      },
      dz_status: { "01": "已上架", "02": "已下架", "03": "待发布" },
      product_type: { "01": "定制产品", "02": "优选产品" },
      sys_common_status: { "0": "成功", "1": "失败" },
      data_type: {
        "01": "文本",
        "02": "单选",
        "03": "多选",
        "04": "文本域",
        "05": "枚举值单选",
        "06": "枚举值多选",
        "07": "时间",
      },
      order_source: { "01": "导入", "02": "同步", "03": "自建" },
      sys_notice_status: { "0": "停用", "1": "正常" },
      sys_normal_disable: { "0": "停用", "1": "正常" },
      shopee_brand: { "0": "自用品牌", "1": "阿玛尼" },
      WARRANTY_TIME: {
        "01": "7 Days",
        "02": "14 Days",
        "03": "1 Month",
        "04": "2 Months",
      },
      MANUFACTURER: {
        "10": "菲律宾仓4",
        "11": "泰国仓2",
        "12": "印尼仓2",
        "13": "马来仓2",
        "14": "新加坡仓1",
        "01": "泉州工厂",
        "04": "莆田工厂",
        "02": "菲律宾仓1",
        "07": "菲律宾仓2",
        "08": "菲律宾仓3",
        "03": "泰国仓1",
        "05": "印尼仓1",
        "06": "马来仓1",
        "09": "泰国仓2",
      },
      WARRANTY_TYPE: {
        "01": "No Warranty",
        "02": "Lazada Return and Refund Guarantee",
        "03": "Local Supplier Refund Warranty",
        "04": "Local Supplier Warranty",
        "05": "International Manufacturer Warranty",
        "06": "Local Manufacturer Warranty",
        "07": "International Seller Warranty",
      },
      shopeeCategory: {
        "100001": "100001 - Health",
        "100003": "100003 - Health/Food Supplement/Weight Management",
        "100004": "100004 - Health/Food Supplement/Beauty Supplements",
        "100005": "100005 - Health/Food Supplement/Fitness",
        "100006": "100006 - Health/Food Supplement/Well Being",
        "100007": "100007 - Health/Food Supplement/Others",
        "100008": "100008 - Health/Others",
        "100021": "100021 - Fashion Accessories/Rings",
        "100022": "100022 - Fashion Accessories/Earrings",
        "100023": "100023 - Fashion Accessories/Scarves & Shawls",
        "100024": "100024 - Fashion Accessories/Gloves",
        "100026": "100026 - Fashion Accessories/Bracelets & Bangles",
        "100027": "100027 - Fashion Accessories/Anklets",
        "100028": "100028 - Fashion Accessories/Hats & Caps",
        "100029": "100029 - Fashion Accessories/Necklaces",
        "100032": "100032 - Fashion Accessories/Belts",
        "100033": "100033 - Fashion Accessories/Neckties, Bow Ties & Cravats",
        "100035": "100035 - Fashion Accessories/Accessories Sets & Packages",
        "100036": "100036 - Fashion Accessories/Others",
        "100045": "100045 - Home Appliances/Remote Controls",
        "100046": "100046 - Home Appliances/Others",
        "100047": "100047 - Men Clothes/Jeans",
        "100049": "100049 - Men Clothes/Sweaters & Cardigans",
        "100053": "100053 - Men Clothes/Shorts",
        "100056": "100056 - Men Clothes/Sleepwear",
        "100057": "100057 - Men Clothes/Sets",
        "100059": "100059 - Men Clothes/Costumes",
        "100060": "100060 - Men Clothes/Occupational Attire",
        "100061": "100061 - Men Clothes/Others",
        "100062": "100062 - Men Clothes/Socks",
        "100064": "100064 - Men Shoes/Sneakers",
        "100065": "100065 - Men Shoes/Slip Ons & Mules",
        "100066": "100066 - Men Shoes/Loafers & Boat Shoes",
        "100067": "100067 - Men Shoes/Oxfords & Lace-Ups",
        "100070": "100070 - Men Shoes/Others",
        "100071": "100071 - Mobile & Gadgets/Sim Cards",
        "100072": "100072 - Mobile & Gadgets/Tablets",
        "100073": "100073 - Mobile & Gadgets/Mobile Phones",
        "100076": "100076 - Mobile & Gadgets/Walkie Talkies",
        "100077": "100077 - Mobile & Gadgets/Others",
        "100083": "100083 - Muslim Fashion/Sets",
        "100084": "100084 - Muslim Fashion/Others",
        "100085": "100085 - Travel & Luggage/Luggage",
        "100088": "100088 - Travel & Luggage/Others",
        "100089": "100089 - Women Bags/Backpacks",
        "100091": "100091 - Women Bags/Clutches & Wristlets",
        "100092": "100092 - Women Bags/Waist Bags & Chest Bags",
        "100093": "100093 - Women Bags/Tote Bags",
        "100094": "100094 - Women Bags/Top-handle Bags",
        "100095": "100095 - Women Bags/Crossbody & Shoulder Bags",
        "100098": "100098 - Women Bags/Others",
        "100102": "100102 - Women Clothes/Skirts",
        "100103": "100103 - Women Clothes/Jeans",
        "100104": "100104 - Women Clothes/Dresses",
        "100105": "100105 - Women Clothes/Wedding Dresses",
        "100108": "100108 - Women Clothes/Sweaters & Cardigans",
        "100115": "100115 - Women Clothes/Costumes",
        "100116": "100116 - Women Clothes/Others",
        "100119": "100119 - Health/Medical Supplies/Over-the-counter Medicine",
        "100120": "100120 - Health/Medical Supplies/Traditional Medicine",
        "100122": "100122 - Health/Medical Supplies/Scale & Body Fat Analyzers",
        "100123": "100123 - Health/Medical Supplies/Nasal Care",
        "100125": "100125 - Health/Medical Supplies/Stethoscopes",
        "100126": "100126 - Health/Medical Supplies/Pain Relievers",
        "100127": "100127 - Health/Medical Supplies/Laboratory Tools",
        "100128": "100128 - Health/Medical Supplies/Medical Gloves & Masks",
        "100130": "100130 - Health/Medical Supplies/Others",
        "100131": "100131 - Health/Personal Care/Hand Sanitizers",
        "100133": "100133 - Health/Personal Care/Ear Care",
        "100135": "100135 - Health/Personal Care/Adult Diapers & Incontinence",
        "100137": "100137 - Health/Personal Care/Massage & Therapy Devices",
        "100138": "100138 - Health/Personal Care/Insect Repellents",
        "100139": "100139 - Health/Personal Care/Others",
        "100145": "100145 - Fashion Accessories/Hair Accessories/Headbands",
        "100146":
          "100146 - Fashion Accessories/Hair Accessories/Hair Ties, Ribbons & Scrunchies",
        "100147":
          "100147 - Fashion Accessories/Hair Accessories/Hair Clips & Hair Pins",
        "100148":
          "100148 - Fashion Accessories/Hair Accessories/Wigs & Extensions",
        "100149":
          "100149 - Fashion Accessories/Hair Accessories/Headpieces, Tiaras & Flower Crowns",
        "100150": "100150 - Fashion Accessories/Hair Accessories/Others",
        "100151": "100151 - Fashion Accessories/Eyewear/Sunglasses",
        "100152": "100152 - Fashion Accessories/Eyewear/Frames & Glasses",
        "100153":
          "100153 - Fashion Accessories/Eyewear/Eyewear Cases & Accessories",
        "100154": "100154 - Fashion Accessories/Eyewear/Others",
        "100155":
          "100155 - Fashion Accessories/Investment Precious Metals/Platinum & K Gold",
        "100156":
          "100156 - Fashion Accessories/Investment Precious Metals/Silver",
        "100157":
          "100157 - Fashion Accessories/Investment Precious Metals/Diamond",
        "100158":
          "100158 - Fashion Accessories/Investment Precious Metals/Jade",
        "100159":
          "100159 - Fashion Accessories/Investment Precious Metals/Others",
        "100160":
          "100160 - Fashion Accessories/Additional Accessories/Brooches & Pins",
        "100161": "100161 - Fashion Accessories/Additional Accessories/Patches",
        "100162":
          "100162 - Fashion Accessories/Additional Accessories/Charms, Pendants & Ornaments",
        "100163":
          "100163 - Fashion Accessories/Additional Accessories/Cufflinks",
        "100164": "100164 - Fashion Accessories/Additional Accessories/Tattoos",
        "100165": "100165 - Fashion Accessories/Additional Accessories/Masks",
        "100166":
          "100166 - Fashion Accessories/Additional Accessories/Handkerchiefs",
        "100167": "100167 - Fashion Accessories/Additional Accessories/Others",
        "100168":
          "100168 - Home Appliances/Projectors & Accessories/Projectors & Projector Screens",
        "100169": "100169 - Home Appliances/Projectors & Accessories/Pointers",
        "100170": "100170 - Home Appliances/Projectors & Accessories/Others",
        "100171":
          "100171 - Home Appliances/Small Household Appliances/Foot Baths & Spas",
        "100172":
          "100172 - Home Appliances/Small Household Appliances/Water Flossers",
        "100173":
          "100173 - Home Appliances/Small Household Appliances/Sewing Machines & Accessories",
        "100175":
          "100175 - Home Appliances/Small Household Appliances/Irons & Steamers",
        "100177":
          "100177 - Home Appliances/Small Household Appliances/Vacuum Cleaners & Floor Care Appliances",
        "100178": "100178 - Home Appliances/Small Household Appliances/Others",
        "100180":
          "100180 - Home Appliances/Large Household Appliances/Water Heaters",
        "100182":
          "100182 - Home Appliances/Large Household Appliances/Futon & Shoe Dryers",
        "100183": "100183 - Home Appliances/Large Household Appliances/Heaters",
        "100184": "100184 - Home Appliances/Large Household Appliances/Others",
        "100185": "100185 - Home Appliances/TVs & Accessories/TVs",
        "100186": "100186 - Home Appliances/TVs & Accessories/TV Antennas",
        "100187":
          "100187 - Home Appliances/TVs & Accessories/TV Boxes & Receivers",
        "100188": "100188 - Home Appliances/TVs & Accessories/TV Brackets",
        "100189": "100189 - Home Appliances/TVs & Accessories/Others",
        "100190":
          "100190 - Home Appliances/Kitchen Appliances/Water Filters, Coolers & Dispensers",
        "100191": "100191 - Home Appliances/Kitchen Appliances/Kettles",
        "100192": "100192 - Home Appliances/Kitchen Appliances/Wine Fridges",
        "100193":
          "100193 - Home Appliances/Kitchen Appliances/Juicers, Blenders & Soya Bean Machines",
        "100194":
          "100194 - Home Appliances/Kitchen Appliances/Coffee Machines & Accessories",
        "100195": "100195 - Home Appliances/Kitchen Appliances/Mixers",
        "100196": "100196 - Home Appliances/Kitchen Appliances/Dishwashers",
        "100197":
          "100197 - Home Appliances/Kitchen Appliances/Stoves, Hobs & Gas Regulators",
        "100198": "100198 - Home Appliances/Kitchen Appliances/Air Fryers",
        "100199": "100199 - Home Appliances/Kitchen Appliances/Deep Fryers",
        "100200": "100200 - Home Appliances/Kitchen Appliances/Microwaves",
        "100201": "100201 - Home Appliances/Kitchen Appliances/Ovens",
        "100202": "100202 - Home Appliances/Kitchen Appliances/Toasters",
        "100203":
          "100203 - Home Appliances/Kitchen Appliances/Food Processors & Meat Grinders",
        "100204":
          "100204 - Home Appliances/Kitchen Appliances/Multi-function Cookers",
        "100205":
          "100205 - Home Appliances/Kitchen Appliances/Pressure Cookers",
        "100206":
          "100206 - Home Appliances/Kitchen Appliances/Slow Cookers & Sous Vide Machines",
        "100207": "100207 - Home Appliances/Kitchen Appliances/Rice Cookers",
        "100209": "100209 - Home Appliances/Kitchen Appliances/Refrigerators",
        "100210": "100210 - Home Appliances/Kitchen Appliances/Freezers",
        "100211": "100211 - Home Appliances/Kitchen Appliances/Hoods",
        "100212": "100212 - Home Appliances/Kitchen Appliances/Others",
        "100213":
          "100213 - Home Appliances/Electrical Circuitry & Parts/Electric Sockets & Extension Cords",
        "100214":
          "100214 - Home Appliances/Electrical Circuitry & Parts/Electrical Safety",
        "100215":
          "100215 - Home Appliances/Electrical Circuitry & Parts/Electricity Savers",
        "100216":
          "100216 - Home Appliances/Electrical Circuitry & Parts/Doorbells",
        "100217":
          "100217 - Home Appliances/Electrical Circuitry & Parts/Switches",
        "100218":
          "100218 - Home Appliances/Electrical Circuitry & Parts/House Alarms",
        "100219":
          "100219 - Home Appliances/Electrical Circuitry & Parts/Lightning Protection",
        "100220":
          "100220 - Home Appliances/Electrical Circuitry & Parts/Others",
        "100226": "100226 - Men Clothes/Hoodies & Sweatshirts/Hoodies",
        "100227": "100227 - Men Clothes/Hoodies & Sweatshirts/Sweatshirts",
        "100228": "100228 - Men Clothes/Hoodies & Sweatshirts/Others",
        "100229":
          "100229 - Men Clothes/Jackets, Coats & Vests/Winter Jackets & Coats",
        "100230": "100230 - Men Clothes/Jackets, Coats & Vests/Jackets",
        "100231": "100231 - Men Clothes/Jackets, Coats & Vests/Vests",
        "100232": "100232 - Men Clothes/Jackets, Coats & Vests/Others",
        "100233": "100233 - Men Clothes/Suits/Suit Sets",
        "100234": "100234 - Men Clothes/Suits/Suit Jackets & Blazers",
        "100235": "100235 - Men Clothes/Suits/Suit Pants",
        "100236": "100236 - Men Clothes/Suits/Suit Vests & Waistcoats",
        "100237": "100237 - Men Clothes/Suits/Others",
        "100238": "100238 - Men Clothes/Pants/Cargo",
        "100239": "100239 - Men Clothes/Pants/Joggers",
        "100240": "100240 - Men Clothes/Pants/Pants",
        "100241": "100241 - Men Clothes/Pants/Others",
        "100242": "100242 - Men Clothes/Tops/Shirts",
        "100243": "100243 - Men Clothes/Tops/Polo Shirts",
        "100244": "100244 - Men Clothes/Tops/T-Shirts",
        "100245": "100245 - Men Clothes/Tops/Tanks",
        "100246": "100246 - Men Clothes/Tops/Others",
        "100247": "100247 - Men Clothes/Innerwear & Underwear/Underwear",
        "100248": "100248 - Men Clothes/Innerwear & Underwear/Undershirts",
        "100249":
          "100249 - Men Clothes/Innerwear & Underwear/Thermal Innerwear",
        "100250": "100250 - Men Clothes/Innerwear & Underwear/Others",
        "100251": "100251 - Men Clothes/Traditional Wear/Tops",
        "100252": "100252 - Men Clothes/Traditional Wear/Bottoms",
        "100253": "100253 - Men Clothes/Traditional Wear/Sets",
        "100254": "100254 - Men Clothes/Traditional Wear/Others",
        "100255": "100255 - Men Shoes/Boots/Fashion Boots",
        "100256": "100256 - Men Shoes/Boots/Rain Boots",
        "100257": "100257 - Men Shoes/Boots/Safety Boots",
        "100258": "100258 - Men Shoes/Boots/Others",
        "100259": "100259 - Men Shoes/Sandals & Flip Flops/Flip Flops",
        "100260": "100260 - Men Shoes/Sandals & Flip Flops/Sandals",
        "100261": "100261 - Men Shoes/Sandals & Flip Flops/Indoor Slippers",
        "100262": "100262 - Men Shoes/Sandals & Flip Flops/Health Slippers",
        "100263": "100263 - Men Shoes/Sandals & Flip Flops/Others",
        "100264":
          "100264 - Men Shoes/Shoe Care & Accessories/Shoe Care & Cleaning Tools",
        "100265": "100265 - Men Shoes/Shoe Care & Accessories/Shoe Deodorizers",
        "100266": "100266 - Men Shoes/Shoe Care & Accessories/Shoe Laces",
        "100267":
          "100267 - Men Shoes/Shoe Care & Accessories/Shoe Horns & Trees",
        "100268": "100268 - Men Shoes/Shoe Care & Accessories/Shoe Insoles",
        "100269": "100269 - Men Shoes/Shoe Care & Accessories/Others",
        "100270":
          "100270 - Mobile & Gadgets/Wearable Devices/Smartwatches & Fitness Trackers",
        "100271": "100271 - Mobile & Gadgets/Wearable Devices/VR Devices",
        "100272": "100272 - Mobile & Gadgets/Wearable Devices/GPS Trackers",
        "100273": "100273 - Mobile & Gadgets/Wearable Devices/Others",
        "100275": "100275 - Mobile & Gadgets/Accessories/Mobile Lens",
        "100276":
          "100276 - Mobile & Gadgets/Accessories/Mobile Flashes & Selfie Lights",
        "100277": "100277 - Mobile & Gadgets/Accessories/USB & Mobile Fans",
        "100278": "100278 - Mobile & Gadgets/Accessories/Stylus",
        "100279": "100279 - Mobile & Gadgets/Accessories/Phone Grips",
        "100280":
          "100280 - Mobile & Gadgets/Accessories/Phone Straps & Keychains",
        "100281": "100281 - Mobile & Gadgets/Accessories/Memory Cards",
        "100282": "100282 - Mobile & Gadgets/Accessories/Casting Devices",
        "100283": "100283 - Mobile & Gadgets/Accessories/Mobile Pouches",
        "100285": "100285 - Mobile & Gadgets/Accessories/USB & Mobile Lights",
        "100286": "100286 - Mobile & Gadgets/Accessories/Pocket Wifi",
        "100288": "100288 - Mobile & Gadgets/Accessories/Wearable Accessories",
        "100289": "100289 - Mobile & Gadgets/Accessories/Screen Protectors",
        "100291": "100291 - Mobile & Gadgets/Accessories/Others",
        "100294":
          "100294 - Muslim Fashion/Women Muslim Wear/Muslimah Sports Apparels",
        "100295": "100295 - Muslim Fashion/Women Muslim Wear/Muslimah Swimwear",
        "100299": "100299 - Muslim Fashion/Women Muslim Wear/Others",
        "100300":
          "100300 - Muslim Fashion/Prayer Attire & Equipment/Prayer Sets",
        "100301":
          "100301 - Muslim Fashion/Prayer Attire & Equipment/Prayer Mats",
        "100302":
          "100302 - Muslim Fashion/Prayer Attire & Equipment/Peci, Songkok & Kopiah",
        "100303": "100303 - Muslim Fashion/Prayer Attire & Equipment/Telekung",
        "100304":
          "100304 - Muslim Fashion/Prayer Attire & Equipment/Telekung Travel",
        "100305": "100305 - Muslim Fashion/Prayer Attire & Equipment/Others",
        "100306": "100306 - Muslim Fashion/Men Muslim Wear/Tops",
        "100307": "100307 - Muslim Fashion/Men Muslim Wear/Robe & Jubah",
        "100308": "100308 - Muslim Fashion/Men Muslim Wear/Pants",
        "100309": "100309 - Muslim Fashion/Men Muslim Wear/Sarong",
        "100310": "100310 - Muslim Fashion/Men Muslim Wear/Baju Melayu",
        "100311": "100311 - Muslim Fashion/Men Muslim Wear/Others",
        "100313": "100313 - Muslim Fashion/Kid Muslim Wear/Boy's Muslim Attire",
        "100314": "100314 - Muslim Fashion/Kid Muslim Wear/Others",
        "100315": "100315 - Muslim Fashion/Outerwear/Vests",
        "100316": "100316 - Muslim Fashion/Outerwear/Jackets",
        "100317": "100317 - Muslim Fashion/Outerwear/Coats",
        "100318": "100318 - Muslim Fashion/Outerwear/Cardigans",
        "100319": "100319 - Muslim Fashion/Outerwear/Others",
        "100320":
          "100320 - Travel & Luggage/Travel Bags/Duffel & Weekender Bags",
        "100321": "100321 - Travel & Luggage/Travel Bags/Foldable Bags",
        "100322": "100322 - Travel & Luggage/Travel Bags/Drawstring Bags",
        "100323": "100323 - Travel & Luggage/Travel Bags/Others",
        "100324":
          "100324 - Travel & Luggage/Travel Accessories/Passport Holders & Covers",
        "100325":
          "100325 - Travel & Luggage/Travel Accessories/Travel Organizers",
        "100326":
          "100326 - Travel & Luggage/Travel Accessories/Luggage Protectors & Covers",
        "100327": "100327 - Travel & Luggage/Travel Accessories/Luggage Tags",
        "100328": "100328 - Travel & Luggage/Travel Accessories/Luggage Straps",
        "100329": "100329 - Travel & Luggage/Travel Accessories/Luggage Locks",
        "100330": "100330 - Travel & Luggage/Travel Accessories/Luggage Scales",
        "100331":
          "100331 - Travel & Luggage/Travel Accessories/Travel Pillows & Eye Covers",
        "100332":
          "100332 - Travel & Luggage/Travel Accessories/Travel Size Bottles & Containers",
        "100333": "100333 - Travel & Luggage/Travel Accessories/Others",
        "100334": "100334 - Women Bags/Laptop Bags/Laptop Bags & Cases",
        "100335": "100335 - Women Bags/Laptop Bags/Laptop Sleeves",
        "100336": "100336 - Women Bags/Laptop Bags/Laptop Backpacks",
        "100337": "100337 - Women Bags/Laptop Bags/Others",
        "100338": "100338 - Women Bags/Wallets/Card Holders",
        "100339": "100339 - Women Bags/Wallets/Coin Holders & Purses",
        "100340": "100340 - Women Bags/Wallets/Phone & Key Wallets",
        "100341": "100341 - Women Bags/Wallets/Bifold & Trifold Wallets",
        "100342": "100342 - Women Bags/Wallets/Long Wallets",
        "100343": "100343 - Women Bags/Wallets/Others",
        "100344": "100344 - Women Bags/Bag Accessories/Bag Straps",
        "100345": "100345 - Women Bags/Bag Accessories/Bag Holders",
        "100346": "100346 - Women Bags/Bag Accessories/Charms & Twillies",
        "100347": "100347 - Women Bags/Bag Accessories/Bag Organizers",
        "100348":
          "100348 - Women Bags/Bag Accessories/Cleaning & Care Equipment",
        "100349": "100349 - Women Bags/Bag Accessories/Others",
        "100350": "100350 - Women Clothes/Tops/Tanks & Camisoles",
        "100351": "100351 - Women Clothes/Tops/Tube Tops",
        "100352": "100352 - Women Clothes/Tops/T-shirts",
        "100353": "100353 - Women Clothes/Tops/Shirts & Blouses",
        "100354": "100354 - Women Clothes/Tops/Polo Shirts",
        "100355": "100355 - Women Clothes/Tops/Bodysuits",
        "100356": "100356 - Women Clothes/Tops/Others",
        "100357":
          "100357 - Women Clothes/Pants & Leggings/Leggings & Treggings",
        "100358": "100358 - Women Clothes/Pants & Leggings/Pants",
        "100359": "100359 - Women Clothes/Pants & Leggings/Others",
        "100360": "100360 - Women Clothes/Shorts/Shorts",
        "100361": "100361 - Women Clothes/Shorts/Skorts",
        "100362": "100362 - Women Clothes/Shorts/Others",
        "100363":
          "100363 - Women Clothes/Jumpsuits, Playsuits & Overalls/Jumpsuits",
        "100364":
          "100364 - Women Clothes/Jumpsuits, Playsuits & Overalls/Playsuits",
        "100365":
          "100365 - Women Clothes/Jumpsuits, Playsuits & Overalls/Overalls",
        "100366":
          "100366 - Women Clothes/Jumpsuits, Playsuits & Overalls/Others",
        "100367":
          "100367 - Women Clothes/Jackets, Coats & Vests/Winter Jackets & Coats",
        "100368": "100368 - Women Clothes/Jackets, Coats & Vests/Capes",
        "100369": "100369 - Women Clothes/Jackets, Coats & Vests/Blazers",
        "100370": "100370 - Women Clothes/Jackets, Coats & Vests/Jackets",
        "100371": "100371 - Women Clothes/Jackets, Coats & Vests/Vests",
        "100372": "100372 - Women Clothes/Jackets, Coats & Vests/Others",
        "100373": "100373 - Women Clothes/Hoodies & Sweatshirts/Sweatshirts",
        "100374": "100374 - Women Clothes/Hoodies & Sweatshirts/Hoodies",
        "100375": "100375 - Women Clothes/Hoodies & Sweatshirts/Others",
        "100376": "100376 - Women Clothes/Sets/Couple Sets",
        "100377": "100377 - Women Clothes/Sets/Family Sets",
        "100378": "100378 - Women Clothes/Sets/Individual Sets",
        "100379": "100379 - Women Clothes/Sets/Others",
        "100380": "100380 - Women Clothes/Lingerie & Underwear/Sets",
        "100381": "100381 - Women Clothes/Lingerie & Underwear/Bras",
        "100382": "100382 - Women Clothes/Lingerie & Underwear/Panties",
        "100383":
          "100383 - Women Clothes/Lingerie & Underwear/Thermal Innerwear",
        "100385": "100385 - Women Clothes/Lingerie & Underwear/Shapewear",
        "100386": "100386 - Women Clothes/Lingerie & Underwear/Safety Pants",
        "100387": "100387 - Women Clothes/Lingerie & Underwear/Sexy Lingerie",
        "100388": "100388 - Women Clothes/Lingerie & Underwear/Others",
        "100389": "100389 - Women Clothes/Sleepwear & Pajamas/Pajamas",
        "100390": "100390 - Women Clothes/Sleepwear & Pajamas/Night Dresses",
        "100391": "100391 - Women Clothes/Sleepwear & Pajamas/Kimonos & Robes",
        "100392": "100392 - Women Clothes/Sleepwear & Pajamas/Others",
        "100393": "100393 - Women Clothes/Maternity Wear/Nursing Bras",
        "100394": "100394 - Women Clothes/Maternity Wear/Maternity Dresses",
        "100395": "100395 - Women Clothes/Maternity Wear/Maternity Tops",
        "100396": "100396 - Women Clothes/Maternity Wear/Breastfeeding Wear",
        "100397": "100397 - Women Clothes/Maternity Wear/Maternity Sets",
        "100398": "100398 - Women Clothes/Maternity Wear/Maternity Bottoms",
        "100399": "100399 - Women Clothes/Maternity Wear/Others",
        "100400": "100400 - Women Clothes/Traditional Wear/Tops",
        "100401": "100401 - Women Clothes/Traditional Wear/Bottoms",
        "100402": "100402 - Women Clothes/Traditional Wear/Sets",
        "100403": "100403 - Women Clothes/Traditional Wear/Dresses",
        "100404": "100404 - Women Clothes/Traditional Wear/Others",
        "100405": "100405 - Women Clothes/Fabric/Batik",
        "100406": "100406 - Women Clothes/Fabric/Kebaya",
        "100407": "100407 - Women Clothes/Fabric/Cotton",
        "100408": "100408 - Women Clothes/Fabric/Wool",
        "100409": "100409 - Women Clothes/Fabric/Velvet, Silk & Satin",
        "100410": "100410 - Women Clothes/Fabric/Leather",
        "100411": "100411 - Women Clothes/Fabric/Vinyl & Nylon",
        "100412": "100412 - Women Clothes/Fabric/Denim",
        "100413": "100413 - Women Clothes/Fabric/Canvas",
        "100414": "100414 - Women Clothes/Fabric/Kain Pasang",
        "100415": "100415 - Women Clothes/Fabric/Songket",
        "100416": "100416 - Women Clothes/Fabric/Others",
        "100417": "100417 - Women Clothes/Socks & Stockings/Socks",
        "100418": "100418 - Women Clothes/Socks & Stockings/Pantyhose",
        "100419": "100419 - Women Clothes/Socks & Stockings/Others",
        "100420":
          "100420 - Health/Medical Supplies/Health Monitors & Tests/Blood Pressure Monitors",
        "100421":
          "100421 - Health/Medical Supplies/Health Monitors & Tests/Blood Glucose Monitors",
        "100422":
          "100422 - Health/Medical Supplies/Health Monitors & Tests/Pulse Oximeters",
        "100423":
          "100423 - Health/Medical Supplies/Health Monitors & Tests/Thermometers",
        "100424":
          "100424 - Health/Medical Supplies/Health Monitors & Tests/Others",
        "100425":
          "100425 - Health/Medical Supplies/First Aid Supplies/Plasters & Bandages",
        "100426":
          "100426 - Health/Medical Supplies/First Aid Supplies/First Aid Accessories",
        "100427":
          "100427 - Health/Medical Supplies/First Aid Supplies/Ointments & Creams",
        "100428":
          "100428 - Health/Medical Supplies/First Aid Supplies/Medical Antiseptics & Disinfectants",
        "100429": "100429 - Health/Medical Supplies/First Aid Supplies/Others",
        "100430":
          "100430 - Health/Medical Supplies/Injury & Disability Support/Walking Aids",
        "100431":
          "100431 - Health/Medical Supplies/Injury & Disability Support/Wheelchairs",
        "100432":
          "100432 - Health/Medical Supplies/Injury & Disability Support/Braces & Supports",
        "100433":
          "100433 - Health/Medical Supplies/Injury & Disability Support/Hearing Aids",
        "100434":
          "100434 - Health/Medical Supplies/Injury & Disability Support/Others",
        "100435": "100435 - Health/Personal Care/Eye Care/Contact lens",
        "100436":
          "100436 - Health/Personal Care/Eye Care/Lens Solutions & Eyedrops",
        "100437": "100437 - Health/Personal Care/Eye Care/Others",
        "100438": "100438 - Health/Personal Care/Oral Care/Manual Toothbrushes",
        "100439":
          "100439 - Health/Personal Care/Oral Care/Electric Toothbrushes & Accessories",
        "100440": "100440 - Health/Personal Care/Oral Care/Toothpastes",
        "100441": "100441 - Health/Personal Care/Oral Care/Dental Floss",
        "100442": "100442 - Health/Personal Care/Oral Care/Mouth Wash",
        "100443": "100443 - Health/Personal Care/Oral Care/Denture Care",
        "100444": "100444 - Health/Personal Care/Oral Care/Teeth Whitening",
        "100445":
          "100445 - Health/Personal Care/Oral Care/Orthodontic Accessories",
        "100446": "100446 - Health/Personal Care/Oral Care/Others",
        "100447":
          "100447 - Health/Personal Care/Feminine Care/Sanitary Napkins & Panty Liners",
        "100448": "100448 - Health/Personal Care/Feminine Care/Tampons",
        "100449":
          "100449 - Health/Personal Care/Feminine Care/Pregnancy & Fertility Tests",
        "100450": "100450 - Health/Personal Care/Feminine Care/Menstrual Cup",
        "100451": "100451 - Health/Personal Care/Feminine Care/Feminine Wash",
        "100452": "100452 - Health/Personal Care/Feminine Care/Vaginal Cream",
        "100453": "100453 - Health/Personal Care/Feminine Care/Others",
        "100454":
          "100454 - Home Appliances/Small Household Appliances/Telephones/Corded Phones",
        "100455":
          "100455 - Home Appliances/Small Household Appliances/Telephones/Cordless Phones",
        "100456":
          "100456 - Home Appliances/Small Household Appliances/Telephones/Fax Machines",
        "100457":
          "100457 - Home Appliances/Small Household Appliances/Telephones/Others",
        "100458":
          "100458 - Home Appliances/Small Household Appliances/Air Treatment/Purifiers",
        "100459":
          "100459 - Home Appliances/Small Household Appliances/Air Treatment/Dehumidifiers",
        "100460":
          "100460 - Home Appliances/Small Household Appliances/Air Treatment/Others",
        "100461":
          "100461 - Home Appliances/Large Household Appliances/Washing Machines & Dryers/Washing Machines",
        "100462":
          "100462 - Home Appliances/Large Household Appliances/Washing Machines & Dryers/Laundry Dryers",
        "100463":
          "100463 - Home Appliances/Large Household Appliances/Washing Machines & Dryers/Others",
        "100464":
          "100464 - Home Appliances/Large Household Appliances/Cooling/Air-Conditioners",
        "100465":
          "100465 - Home Appliances/Large Household Appliances/Cooling/Air Coolers & Portable Aircons",
        "100466":
          "100466 - Home Appliances/Large Household Appliances/Cooling/Fans",
        "100467":
          "100467 - Home Appliances/Large Household Appliances/Cooling/Others",
        "100468":
          "100468 - Home Appliances/Kitchen Appliances/Specialty Cookware/Waffle & Crepe Makers",
        "100469":
          "100469 - Home Appliances/Kitchen Appliances/Specialty Cookware/Egg Boilers",
        "100470":
          "100470 - Home Appliances/Kitchen Appliances/Specialty Cookware/Bread Makers",
        "100471":
          "100471 - Home Appliances/Kitchen Appliances/Specialty Cookware/Takoyaki Makers",
        "100472":
          "100472 - Home Appliances/Kitchen Appliances/Specialty Cookware/Dessert Machine",
        "100473":
          "100473 - Home Appliances/Kitchen Appliances/Specialty Cookware/Soda Makers",
        "100474":
          "100474 - Home Appliances/Kitchen Appliances/Specialty Cookware/Others",
        "100475":
          "100475 - Mobile & Gadgets/Accessories/Selfie Accessories/Selfie Sticks",
        "100476":
          "100476 - Mobile & Gadgets/Accessories/Selfie Accessories/Lazypods",
        "100477":
          "100477 - Mobile & Gadgets/Accessories/Selfie Accessories/Remote Shutters",
        "100478":
          "100478 - Mobile & Gadgets/Accessories/Selfie Accessories/Others",
        "100479":
          "100479 - Mobile & Gadgets/Accessories/Cables, Chargers & Converters/Cable Cases, Protectors, & Winders",
        "100480":
          "100480 - Mobile & Gadgets/Accessories/Cables, Chargers & Converters/Travel Adaptors",
        "100481":
          "100481 - Mobile & Gadgets/Accessories/Cables, Chargers & Converters/Mobile Cables & Converters",
        "100482":
          "100482 - Mobile & Gadgets/Accessories/Cables, Chargers & Converters/Chargers",
        "100483":
          "100483 - Mobile & Gadgets/Accessories/Cables, Chargers & Converters/Docks & Stands",
        "100484":
          "100484 - Mobile & Gadgets/Accessories/Cables, Chargers & Converters/Others",
        "100486":
          "100486 - Mobile & Gadgets/Accessories/Powerbanks & Batteries/Powerbanks",
        "100487":
          "100487 - Mobile & Gadgets/Accessories/Powerbanks & Batteries/Powercases",
        "100488":
          "100488 - Mobile & Gadgets/Accessories/Powerbanks & Batteries/Others",
        "100489":
          "100489 - Mobile & Gadgets/Accessories/Cases, Covers, & Skins/Tablet Cases, Covers, & Skins",
        "100490":
          "100490 - Mobile & Gadgets/Accessories/Cases, Covers, & Skins/Phone Cases, Covers, & Skins",
        "100491":
          "100491 - Mobile & Gadgets/Accessories/Cases, Covers, & Skins/Others",
        "100492": "100492 - Muslim Fashion/Women Muslim Wear/Veils/Khimar",
        "100493":
          "100493 - Muslim Fashion/Women Muslim Wear/Veils/Instant Hijab",
        "100494":
          "100494 - Muslim Fashion/Women Muslim Wear/Veils/Square Hijab",
        "100495": "100495 - Muslim Fashion/Women Muslim Wear/Veils/Pashmina",
        "100497": "100497 - Muslim Fashion/Women Muslim Wear/Veils/Others",
        "100498": "100498 - Muslim Fashion/Women Muslim Wear/Accessories/Socks",
        "100499":
          "100499 - Muslim Fashion/Women Muslim Wear/Accessories/Handsocks",
        "100500":
          "100500 - Muslim Fashion/Women Muslim Wear/Accessories/Others",
        "100501": "100501 - Muslim Fashion/Women Muslim Wear/Top/Tunic",
        "100502":
          "100502 - Muslim Fashion/Women Muslim Wear/Top/Shirts, Blouses & Inner Blouses",
        "100503":
          "100503 - Muslim Fashion/Women Muslim Wear/Top/Nursing Blouse",
        "100504": "100504 - Muslim Fashion/Women Muslim Wear/Top/Others",
        "100505":
          "100505 - Muslim Fashion/Women Muslim Wear/Dresses/Robe & Jubah",
        "100506":
          "100506 - Muslim Fashion/Women Muslim Wear/Dresses/Abaya Cloak",
        "100507":
          "100507 - Muslim Fashion/Women Muslim Wear/Dresses/Nursing Jubah",
        "100508": "100508 - Muslim Fashion/Women Muslim Wear/Dresses/Kaftan",
        "100509":
          "100509 - Muslim Fashion/Women Muslim Wear/Dresses/Baju Kelawar",
        "100510":
          "100510 - Muslim Fashion/Women Muslim Wear/Dresses/Baju Kurung",
        "100511": "100511 - Muslim Fashion/Women Muslim Wear/Dresses/Set Warda",
        "100512": "100512 - Muslim Fashion/Women Muslim Wear/Dresses/Jumpsuits",
        "100513": "100513 - Muslim Fashion/Women Muslim Wear/Dresses/Others",
        "100514": "100514 - Muslim Fashion/Women Muslim Wear/Bottoms/Skirt",
        "100515": "100515 - Muslim Fashion/Women Muslim Wear/Bottoms/Pants",
        "100516": "100516 - Muslim Fashion/Women Muslim Wear/Bottoms/Leggings",
        "100517": "100517 - Muslim Fashion/Women Muslim Wear/Bottoms/Palazzo",
        "100518": "100518 - Muslim Fashion/Women Muslim Wear/Bottoms/Others",
        "100519":
          "100519 - Muslim Fashion/Kid Muslim Wear/Girl's Muslim Attire/Veils",
        "100520":
          "100520 - Muslim Fashion/Kid Muslim Wear/Girl's Muslim Attire/Telekung",
        "100521":
          "100521 - Muslim Fashion/Kid Muslim Wear/Girl's Muslim Attire/Attire Piece & Sets",
        "100522":
          "100522 - Muslim Fashion/Kid Muslim Wear/Girl's Muslim Attire/Others",
        "100523":
          "100523 - Women Clothes/Lingerie & Underwear/Bra Accessories/Straps",
        "100524":
          "100524 - Women Clothes/Lingerie & Underwear/Bra Accessories/Paddings",
        "100525":
          "100525 - Women Clothes/Lingerie & Underwear/Bra Accessories/Nipple Stickers",
        "100526":
          "100526 - Women Clothes/Lingerie & Underwear/Bra Accessories/Others",
        "100527":
          "100527 - Muslim Fashion/Women Muslim Wear/Veils/Hijab Accessories/Inner Hijab",
        "100528":
          "100528 - Muslim Fashion/Women Muslim Wear/Veils/Hijab Accessories/Hijab Pin",
        "100529":
          "100529 - Muslim Fashion/Women Muslim Wear/Veils/Hijab Accessories/Others",
        "100557": "100557 - Women Shoes/Sneakers",
        "100559": "100559 - Women Shoes/Heels",
        "100560": "100560 - Women Shoes/Wedges",
        "100563": "100563 - Women Shoes/Others",
        "100564": "100564 - Men Bags/Backpacks",
        "100566": "100566 - Men Bags/Tote Bags",
        "100567": "100567 - Men Bags/Briefcases",
        "100568": "100568 - Men Bags/Clutches",
        "100569": "100569 - Men Bags/Waist Bags & Chest Bags",
        "100570": "100570 - Men Bags/Crossbody & Shoulder Bags",
        "100572": "100572 - Men Bags/Others",
        "100573": "100573 - Watches/Women Watches",
        "100574": "100574 - Watches/Men Watches",
        "100575": "100575 - Watches/Set & Couple Watches",
        "100577": "100577 - Watches/Others",
        "100578": "100578 - Audio/Earphones, Headphones & Headsets",
        "100580": "100580 - Audio/Microphones",
        "100581": "100581 - Audio/Amplifiers & Mixers",
        "100583": "100583 - Audio/Audio & Video Cables & Converters",
        "100584": "100584 - Audio/Others",
        "100585": "100585 - Women Shoes/Boots/Rain Boots",
        "100586": "100586 - Women Shoes/Boots/Fashion Boots",
        "100587": "100587 - Women Shoes/Boots/Others",
        "100588": "100588 - Women Shoes/Flats/Ballet Flats",
        "100589": "100589 - Women Shoes/Flats/Loafers & Boat Shoes",
        "100590": "100590 - Women Shoes/Flats/Oxfords & Lace-Ups",
        "100591": "100591 - Women Shoes/Flats/Slip Ons, Mary Janes & Mules",
        "100592": "100592 - Women Shoes/Flats/Others",
        "100593": "100593 - Women Shoes/Flat Sandals & Flip Flops/Flat Sandals",
        "100594": "100594 - Women Shoes/Flat Sandals & Flip Flops/Flip Flops",
        "100595":
          "100595 - Women Shoes/Flat Sandals & Flip Flops/Health Slippers",
        "100596":
          "100596 - Women Shoes/Flat Sandals & Flip Flops/Indoor Slippers",
        "100597": "100597 - Women Shoes/Flat Sandals & Flip Flops/Others",
        "100598":
          "100598 - Women Shoes/Shoe Care & Accessories/Shoe Deodorizers",
        "100599":
          "100599 - Women Shoes/Shoe Care & Accessories/Insoles & Heel Liners",
        "100600":
          "100600 - Women Shoes/Shoe Care & Accessories/Shoe Horns & Trees",
        "100601":
          "100601 - Women Shoes/Shoe Care & Accessories/Shoe Care & Cleaning Tools",
        "100602": "100602 - Women Shoes/Shoe Care & Accessories/Shoe Laces",
        "100603": "100603 - Women Shoes/Shoe Care & Accessories/Others",
        "100604": "100604 - Men Bags/Laptop Bags/Laptop Bags & Cases",
        "100605": "100605 - Men Bags/Laptop Bags/Laptop Sleeves",
        "100606": "100606 - Men Bags/Laptop Bags/Laptop Backpacks",
        "100607": "100607 - Men Bags/Laptop Bags/Others",
        "100608": "100608 - Men Bags/Wallets/Card Holders",
        "100609": "100609 - Men Bags/Wallets/Coin Holders & Purses",
        "100610": "100610 - Men Bags/Wallets/Phone & Key Wallets",
        "100611": "100611 - Men Bags/Wallets/Bifold & Trifold Wallets",
        "100612": "100612 - Men Bags/Wallets/Long Wallets",
        "100613": "100613 - Men Bags/Wallets/Others",
        "100614": "100614 - Watches/Watches Accessories/Straps",
        "100615": "100615 - Watches/Watches Accessories/Service Tools",
        "100616": "100616 - Watches/Watches Accessories/Buckles",
        "100617": "100617 - Watches/Watches Accessories/Batteries",
        "100618": "100618 - Watches/Watches Accessories/Boxes",
        "100619": "100619 - Watches/Watches Accessories/Others",
        "100620": "100620 - Audio/Media Players/MP3 & MP4 Players",
        "100621": "100621 - Audio/Media Players/CD, DVD, & Blu-ray Players",
        "100622": "100622 - Audio/Media Players/Voice Recorders",
        "100623": "100623 - Audio/Media Players/Radio & Cassette Players",
        "100624": "100624 - Audio/Media Players/Others",
        "100625": "100625 - Audio/Home Audio & Speakers/Speakers",
        "100626": "100626 - Audio/Home Audio & Speakers/Home Theater Systems",
        "100627": "100627 - Audio/Home Audio & Speakers/AV Receivers",
        "100628": "100628 - Audio/Home Audio & Speakers/Others",
        "100656": "100656 - Food & Beverages/Gift Set & Hampers",
        "100657": "100657 - Food & Beverages/Others",
        "100661": "100661 - Beauty/Perfumes & Fragrances",
        "100665": "100665 - Beauty/Beauty Sets & Packages",
        "100666": "100666 - Beauty/Others",
        "100673": "100673 - Pets/Others",
        "100685": "100685 - Mom & Baby/Gift Sets & Packages",
        "100686": "100686 - Mom & Baby/Others",
        "100688": "100688 - Baby & Kids Fashion/Baby Mittens & Footwear",
        "100694": "100694 - Baby & Kids Fashion/Others",
        "100696": "100696 - Gaming & Consoles/Console Accessories",
        "100698": "100698 - Gaming & Consoles/Others",
        "100701": "100701 - Cameras & Drones/Lenses",
        "100705": "100705 - Cameras & Drones/Drones",
        "100706": "100706 - Cameras & Drones/Drone Accessories",
        "100707": "100707 - Cameras & Drones/Others",
        "100712":
          "100712 - Home & Living/Hand Warmers, Hot Water Bags & Ice Bags",
        "100719": "100719 - Home & Living/Lighting",
        "100723": "100723 - Home & Living/Fengshui & Religious Supplies",
        "100724": "100724 - Home & Living/Others",
        "100729": "100729 - Sports & Outdoors/Others",
        "100736": "100736 - Stationery/Others",
        "100740": "100740 - Hobbies & Collections/CD, DVD & Bluray",
        "100742": "100742 - Hobbies & Collections/Vinyl Records",
        "100743": "100743 - Hobbies & Collections/Photo Albums",
        "100744": "100744 - Hobbies & Collections/Needlework",
        "100745": "100745 - Hobbies & Collections/Others",
        "100753": "100753 - Automobiles/Automotive Keychains & Key Covers",
        "100754": "100754 - Automobiles/Others",
        "100755": "100755 - Motorcycles/Motorcycles",
        "100758": "100758 - Motorcycles/Motorcycle Helmets & Accessories",
        "100759": "100759 - Motorcycles/Others",
        "100763": "100763 - Tickets, Vouchers & Services/Utilities",
        "100766": "100766 - Tickets, Vouchers & Services/Transport",
        "100767": "100767 - Tickets, Vouchers & Services/Lessons & Workshops",
        "100772": "100772 - Tickets, Vouchers & Services/Gaming & App Stores",
        "100775": "100775 - Tickets, Vouchers & Services/Others",
        "100778": "100778 - Books & Magazines/E-Books",
        "100779": "100779 - Books & Magazines/Others",
        "100780":
          "100780 - Food & Beverages/Convenience / Ready-to-eat/Cooked Food",
        "100781": "100781 - Food & Beverages/Convenience / Ready-to-eat/Others",
        "100782":
          "100782 - Food & Beverages/Convenience / Ready-to-eat/Instant Rice & Porridge",
        "100783":
          "100783 - Food & Beverages/Convenience / Ready-to-eat/Instant Hotpot",
        "100784":
          "100784 - Food & Beverages/Convenience / Ready-to-eat/Instant Noodles",
        "100785": "100785 - Food & Beverages/Snacks/Sweets & Candy",
        "100786": "100786 - Food & Beverages/Snacks/Chocolate",
        "100787": "100787 - Food & Beverages/Snacks/Biscuits, Cookies & Wafers",
        "100788": "100788 - Food & Beverages/Snacks/Chips & Crisps",
        "100789": "100789 - Food & Beverages/Snacks/Seeds",
        "100790": "100790 - Food & Beverages/Snacks/Popcorn",
        "100791": "100791 - Food & Beverages/Snacks/Seaweed",
        "100792": "100792 - Food & Beverages/Snacks/Nuts",
        "100793": "100793 - Food & Beverages/Snacks/Others",
        "100794":
          "100794 - Food & Beverages/Snacks/Pudding, Jellies & Marshmallow",
        "100797": "100797 - Food & Beverages/Food Staples/Noodles",
        "100798": "100798 - Food & Beverages/Food Staples/Rice",
        "100799": "100799 - Food & Beverages/Food Staples/Pasta",
        "100800": "100800 - Food & Beverages/Food Staples/Others",
        "100802": "100802 - Food & Beverages/Food Staples/Preserved Vegetables",
        "100803": "100803 - Food & Beverages/Cooking Essentials/Oil",
        "100805": "100805 - Food & Beverages/Cooking Essentials/Sugar",
        "100806": "100806 - Food & Beverages/Cooking Essentials/Sweetener",
        "100807":
          "100807 - Food & Beverages/Cooking Essentials/Stock, Gravy & Instant Soup",
        "100808":
          "100808 - Food & Beverages/Cooking Essentials/Cooking Paste & Kit",
        "100809":
          "100809 - Food & Beverages/Cooking Essentials/Flavour Enhancers",
        "100810": "100810 - Food & Beverages/Cooking Essentials/Flour Coating",
        "100811": "100811 - Food & Beverages/Cooking Essentials/Others",
        "100812": "100812 - Food & Beverages/Baking Needs/Baking Flavoring",
        "100813": "100813 - Food & Beverages/Baking Needs/Baking Powder & Soda",
        "100814": "100814 - Food & Beverages/Baking Needs/Baking Premix Flour",
        "100815": "100815 - Food & Beverages/Baking Needs/Flour",
        "100816": "100816 - Food & Beverages/Baking Needs/Food Coloring",
        "100817": "100817 - Food & Beverages/Baking Needs/Baking decoration",
        "100818": "100818 - Food & Beverages/Baking Needs/Others",
        "100819":
          "100819 - Food & Beverages/Breakfast Cereals & Spread/Honey & Maple Syrups",
        "100820":
          "100820 - Food & Beverages/Breakfast Cereals & Spread/Jam & Spread",
        "100821":
          "100821 - Food & Beverages/Breakfast Cereals & Spread/Cereal, Granola & Oats",
        "100822":
          "100822 - Food & Beverages/Breakfast Cereals & Spread/Breakfast Bar",
        "100823": "100823 - Food & Beverages/Breakfast Cereals & Spread/Others",
        "100824": "100824 - Food & Beverages/Beverages/Coffee",
        "100825": "100825 - Food & Beverages/Beverages/Tea & Tea Bags",
        "100826": "100826 - Food & Beverages/Beverages/Chocolate Drinks",
        "100827":
          "100827 - Food & Beverages/Beverages/Energy & Isotonic Drinks",
        "100828": "100828 - Food & Beverages/Beverages/Water",
        "100829": "100829 - Food & Beverages/Beverages/Juice & Juice Vinegar",
        "100830": "100830 - Food & Beverages/Beverages/Cordial & Syrups",
        "100831":
          "100831 - Food & Beverages/Beverages/Carbonated Drinks & Tonics",
        "100832": "100832 - Food & Beverages/Beverages/Powdered Drink Mixes",
        "100833": "100833 - Food & Beverages/Beverages/Dessert Drink",
        "100834":
          "100834 - Food & Beverages/Beverages/Traditional & Herbal Drinks",
        "100835": "100835 - Food & Beverages/Beverages/Drink Toppings",
        "100836": "100836 - Food & Beverages/Beverages/Non-dairy Milk",
        "100837": "100837 - Food & Beverages/Beverages/Others",
        "100839":
          "100839 - Food & Beverages/Dairy & Eggs/Yogurt & Cultured Milk",
        "100845": "100845 - Food & Beverages/Dairy & Eggs/Beancurd",
        "100850": "100850 - Food & Beverages/Fresh & Frozen Food/Others",
        "100853": "100853 - Food & Beverages/Fresh & Frozen Food/Mushroom",
        "100856": "100856 - Food & Beverages/Bakery/Breads",
        "100857": "100857 - Food & Beverages/Bakery/Cakes & Pies",
        "100858": "100858 - Food & Beverages/Bakery/Pastry",
        "100859": "100859 - Food & Beverages/Bakery/Others",
        "100868": "100868 - Beauty/Hand, Foot & Nail Care/Others",
        "100869": "100869 - Beauty/Hair Care/Shampoo",
        "100870": "100870 - Beauty/Hair Care/Hair Colour",
        "100871": "100871 - Beauty/Hair Care/Hair Treatment",
        "100872": "100872 - Beauty/Hair Care/Hair and Scalp Conditioner",
        "100873": "100873 - Beauty/Hair Care/Hair Styling",
        "100874": "100874 - Beauty/Hair Care/Others",
        "100875": "100875 - Beauty/Men's Care/Bath & Body Care",
        "100878": "100878 - Beauty/Men's Care/Hair Care",
        "100879": "100879 - Beauty/Men's Care/Others",
        "100880": "100880 - Beauty/Makeup/Others",
        "100884": "100884 - Beauty/Makeup/Makeup Removers",
        "100887": "100887 - Beauty/Beauty Tools/Body Slimming Tools",
        "100888": "100888 - Beauty/Beauty Tools/Hair Removal Tools",
        "100890": "100890 - Beauty/Beauty Tools/Others",
        "100891": "100891 - Beauty/Skincare/Facial Cleanser",
        "100892": "100892 - Beauty/Skincare/Toner",
        "100893": "100893 - Beauty/Skincare/Facial Moisturizer",
        "100894": "100894 - Beauty/Skincare/Facial Oil",
        "100895": "100895 - Beauty/Skincare/Facial Mist",
        "100896": "100896 - Beauty/Skincare/Facial Serum & Essence",
        "100897": "100897 - Beauty/Skincare/Face Scrub & Peel",
        "100898": "100898 - Beauty/Skincare/Face Mask & Packs",
        "100901": "100901 - Beauty/Skincare/Face Sunscreen",
        "100902": "100902 - Beauty/Skincare/After Sun Face Care",
        "100903": "100903 - Beauty/Skincare/Blotting Paper",
        "100904": "100904 - Beauty/Skincare/Acne Treatment",
        "100905": "100905 - Beauty/Skincare/Others",
        "100906": "100906 - Pets/Pet Food/Dog Food",
        "100907": "100907 - Pets/Pet Food/Dog Treats",
        "100908": "100908 - Pets/Pet Food/Cat Food",
        "100909": "100909 - Pets/Pet Food/Cat Treats",
        "100910": "100910 - Pets/Pet Food/Small Pet Food",
        "100911": "100911 - Pets/Pet Food/Small Pet Treats",
        "100912": "100912 - Pets/Pet Food/Aquarium Pet Food",
        "100913": "100913 - Pets/Pet Food/Bird Feed",
        "100914": "100914 - Pets/Pet Food/Reptile Food",
        "100915": "100915 - Pets/Pet Food/Others",
        "100916": "100916 - Pets/Pet Accessories/Bowls & Feeders",
        "100917": "100917 - Pets/Pet Accessories/Travel Essentials",
        "100918":
          "100918 - Pets/Pet Accessories/Leashes, Collars, Harnesses & Muzzles",
        "100921": "100921 - Pets/Pet Accessories/Aquarium Needs",
        "100922": "100922 - Pets/Pet Accessories/Others",
        "100923": "100923 - Pets/Litter & Toilet/Cat Litter & Boxes",
        "100924": "100924 - Pets/Litter & Toilet/Small Pet Bedding & Litter",
        "100925": "100925 - Pets/Litter & Toilet/Diapers",
        "100926": "100926 - Pets/Litter & Toilet/Dog Training Pads & Trays",
        "100927": "100927 - Pets/Litter & Toilet/Poop Bags & Scoopers",
        "100928": "100928 - Pets/Litter & Toilet/Others",
        "100929": "100929 - Pets/Pet Grooming/Hair Care",
        "100930": "100930 - Pets/Pet Grooming/Oral Care",
        "100931": "100931 - Pets/Pet Grooming/Claw Care",
        "100932": "100932 - Pets/Pet Grooming/Others",
        "100933": "100933 - Pets/Pet Clothing & Accessories/Pet Clothing",
        "100934": "100934 - Pets/Pet Clothing & Accessories/Wet Weather Gear",
        "100935":
          "100935 - Pets/Pet Clothing & Accessories/Boots, Socks & Paw Protectors",
        "100936": "100936 - Pets/Pet Clothing & Accessories/Neck Accessories",
        "100937": "100937 - Pets/Pet Clothing & Accessories/Eyewear",
        "100938": "100938 - Pets/Pet Clothing & Accessories/Hair Accessories",
        "100939": "100939 - Pets/Pet Clothing & Accessories/Hats",
        "100940": "100940 - Pets/Pet Clothing & Accessories/Others",
        "100941": "100941 - Pets/Pet Healthcare/Anti Fleas & Ticks",
        "100942": "100942 - Pets/Pet Healthcare/Medication",
        "100943": "100943 - Pets/Pet Healthcare/Others",
        "100944": "100944 - Pets/Pet Healthcare/Vitamins & Supplements",
        "100945": "100945 - Mom & Baby/Baby Travel Essentials/Baby Carrier",
        "100946":
          "100946 - Mom & Baby/Baby Travel Essentials/Strollers & Travel Systems",
        "100947":
          "100947 - Mom & Baby/Baby Travel Essentials/Stroller Accessories",
        "100948":
          "100948 - Mom & Baby/Baby Travel Essentials/Car & Motorbike Seats",
        "100949":
          "100949 - Mom & Baby/Baby Travel Essentials/Car & Motorbike Seats Accessories",
        "100950": "100950 - Mom & Baby/Baby Travel Essentials/Diaper Bags",
        "100951":
          "100951 - Mom & Baby/Baby Travel Essentials/Child Harnesses & Leashes",
        "100952": "100952 - Mom & Baby/Baby Travel Essentials/Others",
        "100955":
          "100955 - Mom & Baby/Feeding Essentials/Highchairs & Booster Seats",
        "100957": "100957 - Mom & Baby/Feeding Essentials/Bibs",
        "100958": "100958 - Mom & Baby/Feeding Essentials/Pacifiers",
        "100959": "100959 - Mom & Baby/Feeding Essentials/Food Processors",
        "100960": "100960 - Mom & Baby/Feeding Essentials/Others",
        "100961": "100961 - Mom & Baby/Maternity Accessories/Supporting Belts",
        "100962": "100962 - Mom & Baby/Maternity Accessories/Maternity Pillows",
        "100963": "100963 - Mom & Baby/Maternity Accessories/Others",
        "100964": "100964 - Mom & Baby/Maternity Healthcare/Maternity Milk",
        "100965":
          "100965 - Mom & Baby/Maternity Healthcare/Maternity Vitamins & Supplement",
        "100966":
          "100966 - Mom & Baby/Maternity Healthcare/Moisturizers & Creams",
        "100967": "100967 - Mom & Baby/Maternity Healthcare/Others",
        "100968": "100968 - Mom & Baby/Bath & Body Care/Bathing Tubs & Seats",
        "100969":
          "100969 - Mom & Baby/Bath & Body Care/Bath Robes, Towels & Wash Cloths",
        "100970": "100970 - Mom & Baby/Bath & Body Care/Shower Caps",
        "100971":
          "100971 - Mom & Baby/Bath & Body Care/Bathing Tools & Accessories",
        "100972": "100972 - Mom & Baby/Bath & Body Care/Hair Care & Body Wash",
        "100973":
          "100973 - Mom & Baby/Bath & Body Care/Baby Colognes & Fragrances",
        "100974": "100974 - Mom & Baby/Bath & Body Care/Baby Grooming Tools",
        "100975": "100975 - Mom & Baby/Bath & Body Care/Wipes",
        "100976": "100976 - Mom & Baby/Bath & Body Care/Baby Laundry Detergent",
        "100977": "100977 - Mom & Baby/Bath & Body Care/Others",
        "100978": "100978 - Mom & Baby/Nursery/Cribs & Cradles & Beds",
        "100979": "100979 - Mom & Baby/Nursery/Bouncers, Rockers & Jumpers",
        "100980": "100980 - Mom & Baby/Nursery/Walkers",
        "100982": "100982 - Mom & Baby/Nursery/Storage & Organization",
        "100983": "100983 - Mom & Baby/Nursery/Others",
        "100984": "100984 - Mom & Baby/Baby Safety/Monitors",
        "100985": "100985 - Mom & Baby/Baby Safety/Mosquito Netting",
        "100986": "100986 - Mom & Baby/Baby Safety/Bumpers, Rails & Guards",
        "100987": "100987 - Mom & Baby/Baby Safety/Edge & Corner Guards",
        "100988": "100988 - Mom & Baby/Baby Safety/Baby Gates & Doorways",
        "100989": "100989 - Mom & Baby/Baby Safety/Safety Locks & Straps",
        "100990": "100990 - Mom & Baby/Baby Safety/Others",
        "100991": "100991 - Mom & Baby/Milk Formula & Baby Food/Milk Formula",
        "100992":
          "100992 - Mom & Baby/Milk Formula & Baby Food/Baby Porridge, Puree & Cereal",
        "100993": "100993 - Mom & Baby/Milk Formula & Baby Food/Baby Snack",
        "100994": "100994 - Mom & Baby/Milk Formula & Baby Food/Others",
        "100995":
          "100995 - Mom & Baby/Baby Healthcare/Baby Vitamins & Supplements",
        "100996": "100996 - Mom & Baby/Baby Healthcare/Nasal Care",
        "100998": "100998 - Mom & Baby/Baby Healthcare/Baby Oral Care",
        "100999": "100999 - Mom & Baby/Baby Healthcare/Sun Care",
        "101000": "101000 - Mom & Baby/Baby Healthcare/Others",
        "101001": "101001 - Mom & Baby/Diapering & Potty/Changing Pads & Kits",
        "101002":
          "101002 - Mom & Baby/Diapering & Potty/Potty Training & Commode Chairs",
        "101003": "101003 - Mom & Baby/Diapering & Potty/Disposable Diapers",
        "101004":
          "101004 - Mom & Baby/Diapering & Potty/Cloth Diapers & Accessories",
        "101005": "101005 - Mom & Baby/Diapering & Potty/Others",
        "101007": "101007 - Mom & Baby/Toys/Block Toys",
        "101009": "101009 - Mom & Baby/Toys/Pretend Play",
        "101010": "101010 - Mom & Baby/Toys/Toy Vehicles",
        "101013": "101013 - Mom & Baby/Toys/Robot Toys",
        "101014": "101014 - Mom & Baby/Toys/Slime & Squishy Toys",
        "101015": "101015 - Mom & Baby/Toys/Others",
        "101016": "101016 - Baby & Kids Fashion/Baby Clothes/Regular Outerwear",
        "101017": "101017 - Baby & Kids Fashion/Baby Clothes/Winter Outerwear",
        "101018": "101018 - Baby & Kids Fashion/Baby Clothes/Dresses",
        "101020": "101020 - Baby & Kids Fashion/Baby Clothes/Sleepwear",
        "101021": "101021 - Baby & Kids Fashion/Baby Clothes/Tops",
        "101022":
          "101022 - Baby & Kids Fashion/Baby Clothes/Bodysuits & Jumpsuits",
        "101023": "101023 - Baby & Kids Fashion/Baby Clothes/Sets",
        "101024": "101024 - Baby & Kids Fashion/Baby Clothes/Swimwear",
        "101025": "101025 - Baby & Kids Fashion/Baby Clothes/Others",
        "101027":
          "101027 - Baby & Kids Fashion/Baby & Kids Accessories/Hats & Caps",
        "101028":
          "101028 - Baby & Kids Fashion/Baby & Kids Accessories/Eyewear",
        "101029":
          "101029 - Baby & Kids Fashion/Baby & Kids Accessories/Hair Accessories",
        "101030": "101030 - Baby & Kids Fashion/Baby & Kids Accessories/Gloves",
        "101031": "101031 - Baby & Kids Fashion/Baby & Kids Accessories/Belts",
        "101032": "101032 - Baby & Kids Fashion/Baby & Kids Accessories/Socks",
        "101033":
          "101033 - Baby & Kids Fashion/Baby & Kids Accessories/Scarves",
        "101034":
          "101034 - Baby & Kids Fashion/Baby & Kids Accessories/Watches",
        "101037":
          "101037 - Baby & Kids Fashion/Baby & Kids Accessories/Earmuffs",
        "101038": "101038 - Baby & Kids Fashion/Baby & Kids Accessories/Others",
        "101039": "101039 - Baby & Kids Fashion/Boy Clothes/Costumes",
        "101040":
          "101040 - Baby & Kids Fashion/Boy Clothes/Underwear & Innerwear",
        "101041": "101041 - Baby & Kids Fashion/Boy Clothes/Sleepwear",
        "101042": "101042 - Baby & Kids Fashion/Boy Clothes/Swimwear",
        "101046": "101046 - Baby & Kids Fashion/Boy Clothes/Suits & Sets",
        "101047": "101047 - Baby & Kids Fashion/Boy Clothes/Others",
        "101048": "101048 - Baby & Kids Fashion/Girl Clothes/Costumes",
        "101049":
          "101049 - Baby & Kids Fashion/Girl Clothes/Underwear & Innerwear",
        "101050": "101050 - Baby & Kids Fashion/Girl Clothes/Sleepwear",
        "101051": "101051 - Baby & Kids Fashion/Girl Clothes/Swimwear",
        "101055":
          "101055 - Baby & Kids Fashion/Girl Clothes/Rompers, Jumpsuits & Overalls",
        "101056": "101056 - Baby & Kids Fashion/Girl Clothes/Dresses",
        "101057": "101057 - Baby & Kids Fashion/Girl Clothes/Suits & Sets",
        "101058": "101058 - Baby & Kids Fashion/Girl Clothes/Others",
        "101059": "101059 - Baby & Kids Fashion/Boy Shoes/Boots",
        "101060": "101060 - Baby & Kids Fashion/Boy Shoes/Sandals",
        "101061": "101061 - Baby & Kids Fashion/Boy Shoes/Sneakers",
        "101062": "101062 - Baby & Kids Fashion/Boy Shoes/Flip Flops",
        "101063": "101063 - Baby & Kids Fashion/Boy Shoes/Formal Shoes",
        "101064": "101064 - Baby & Kids Fashion/Boy Shoes/Loafers",
        "101065": "101065 - Baby & Kids Fashion/Boy Shoes/Others",
        "101066": "101066 - Baby & Kids Fashion/Girl Shoes/Boots",
        "101067": "101067 - Baby & Kids Fashion/Girl Shoes/Sandals",
        "101068": "101068 - Baby & Kids Fashion/Girl Shoes/Sneakers",
        "101069": "101069 - Baby & Kids Fashion/Girl Shoes/Loafers",
        "101070": "101070 - Baby & Kids Fashion/Girl Shoes/Flip Flops",
        "101071": "101071 - Baby & Kids Fashion/Girl Shoes/Flats",
        "101072": "101072 - Baby & Kids Fashion/Girl Shoes/Others",
        "101073": "101073 - Gaming & Consoles/Console Machines/Playstation",
        "101074": "101074 - Gaming & Consoles/Console Machines/Xbox",
        "101075": "101075 - Gaming & Consoles/Console Machines/Wii",
        "101076": "101076 - Gaming & Consoles/Console Machines/Nintendo DS",
        "101077": "101077 - Gaming & Consoles/Console Machines/Gameboy",
        "101078": "101078 - Gaming & Consoles/Console Machines/Switch",
        "101079": "101079 - Gaming & Consoles/Console Machines/PS Vita",
        "101080": "101080 - Gaming & Consoles/Console Machines/PSP",
        "101081": "101081 - Gaming & Consoles/Console Machines/Others",
        "101082": "101082 - Gaming & Consoles/Video Games/Playstation",
        "101083": "101083 - Gaming & Consoles/Video Games/Xbox",
        "101084": "101084 - Gaming & Consoles/Video Games/Wii",
        "101085": "101085 - Gaming & Consoles/Video Games/Nintendo DS",
        "101086": "101086 - Gaming & Consoles/Video Games/Gameboy",
        "101087": "101087 - Gaming & Consoles/Video Games/Switch",
        "101088": "101088 - Gaming & Consoles/Video Games/PS Vita",
        "101089": "101089 - Gaming & Consoles/Video Games/PSP",
        "101090": "101090 - Gaming & Consoles/Video Games/PC Game",
        "101091": "101091 - Gaming & Consoles/Video Games/Others",
        "101092": "101092 - Cameras & Drones/Cameras/Point & Shoot",
        "101093": "101093 - Cameras & Drones/Cameras/Mirrorless Cameras",
        "101094": "101094 - Cameras & Drones/Cameras/Action Cameras",
        "101095": "101095 - Cameras & Drones/Cameras/Video Camcorders",
        "101096": "101096 - Cameras & Drones/Cameras/Instant Cameras",
        "101097": "101097 - Cameras & Drones/Cameras/Analog Cameras",
        "101098": "101098 - Cameras & Drones/Cameras/DSLRs",
        "101099": "101099 - Cameras & Drones/Cameras/Others",
        "101100":
          "101100 - Cameras & Drones/Security Cameras & Systems/CCTV Security Cameras",
        "101101": "101101 - Cameras & Drones/Security Cameras & Systems/DVRs",
        "101102":
          "101102 - Cameras & Drones/Security Cameras & Systems/Dummy Cameras",
        "101104": "101104 - Cameras & Drones/Security Cameras & Systems/Others",
        "101105":
          "101105 - Cameras & Drones/Lens Accessories/Lens Mount & Adaptors",
        "101106": "101106 - Cameras & Drones/Lens Accessories/Lens Caps",
        "101107": "101107 - Cameras & Drones/Lens Accessories/Filters",
        "101108": "101108 - Cameras & Drones/Lens Accessories/Lens Hoods",
        "101109": "101109 - Cameras & Drones/Lens Accessories/Others",
        "101110": "101110 - Cameras & Drones/Camera Accessories/Flashes",
        "101112":
          "101112 - Cameras & Drones/Camera Accessories/Gimbals & Stabilizers",
        "101113":
          "101113 - Cameras & Drones/Camera Accessories/Lighting & Studio Equipments",
        "101114":
          "101114 - Cameras & Drones/Camera Accessories/Photo Films & Papers",
        "101115": "101115 - Cameras & Drones/Camera Accessories/Photo Printers",
        "101116":
          "101116 - Cameras & Drones/Camera Accessories/Camera Cases & Bags",
        "101117":
          "101117 - Cameras & Drones/Camera Accessories/Battery Chargers",
        "101118":
          "101118 - Cameras & Drones/Camera Accessories/Batteries & Battery Grips",
        "101119":
          "101119 - Cameras & Drones/Camera Accessories/Tripods, Monopods, & Accessories",
        "101120": "101120 - Cameras & Drones/Camera Accessories/Others",
        "101121": "101121 - Cameras & Drones/Camera Care/Dry Boxes & Cabinets",
        "101122": "101122 - Cameras & Drones/Camera Care/Cleaning Kit",
        "101123": "101123 - Cameras & Drones/Camera Care/Silica Gel",
        "101124": "101124 - Cameras & Drones/Camera Care/Blowers",
        "101125": "101125 - Cameras & Drones/Camera Care/Lenspens & Brushes",
        "101126": "101126 - Cameras & Drones/Camera Care/Others",
        "101127":
          "101127 - Home & Living/Home Fragrance & Aromatherapy/Air Fresheners & Home Fragrance",
        "101128":
          "101128 - Home & Living/Home Fragrance & Aromatherapy/Essential Oils",
        "101129":
          "101129 - Home & Living/Home Fragrance & Aromatherapy/Diffusers, Humidifiers & Oil Burners",
        "101130": "101130 - Home & Living/Home Fragrance & Aromatherapy/Others",
        "101131":
          "101131 - Home & Living/Bathrooms/Toilet Bowls, Seats & Covers",
        "101132":
          "101132 - Home & Living/Bathrooms/Toothbrush Holders & Toothpaste Dispensers",
        "101133":
          "101133 - Home & Living/Bathrooms/Soap Dispensers, Holders & Boxes",
        "101134": "101134 - Home & Living/Bathrooms/Bathroom Racks & Cabinets",
        "101135": "101135 - Home & Living/Bathrooms/Bathtubs",
        "101137": "101137 - Home & Living/Bathrooms/Showerheads & Bidet Sprays",
        "101138": "101138 - Home & Living/Bathrooms/Bath Brushes & Loofahs",
        "101139": "101139 - Home & Living/Bathrooms/Shower Curtains",
        "101140": "101140 - Home & Living/Bathrooms/Shower Seats & Commodes",
        "101141": "101141 - Home & Living/Bathrooms/Safety Handles",
        "101142": "101142 - Home & Living/Bathrooms/Shower Caps",
        "101143": "101143 - Home & Living/Bathrooms/Others",
        "101144": "101144 - Home & Living/Bedding/Cooling Mats",
        "101145":
          "101145 - Home & Living/Bedding/Mattress Protectors & Toppers",
        "101146":
          "101146 - Home & Living/Bedding/Blankets, Comforters & Quilts",
        "101147": "101147 - Home & Living/Bedding/Pillows",
        "101148":
          "101148 - Home & Living/Bedding/Bedsheets, Pillowcases & Bolster Cases",
        "101149": "101149 - Home & Living/Bedding/Mattresses",
        "101150": "101150 - Home & Living/Bedding/Mosquito Nets",
        "101151": "101151 - Home & Living/Bedding/Bolsters",
        "101152": "101152 - Home & Living/Bedding/Others",
        "101153": "101153 - Home & Living/Decoration/Flowers",
        "101154":
          "101154 - Home & Living/Decoration/Furniture & Appliance Covers",
        "101155": "101155 - Home & Living/Decoration/Curtains & Blinds",
        "101156":
          "101156 - Home & Living/Decoration/Photo Frames & Wall Decoration",
        "101157":
          "101157 - Home & Living/Decoration/Wallpapers & Wall Stickers",
        "101158": "101158 - Home & Living/Decoration/Clocks",
        "101159": "101159 - Home & Living/Decoration/Floor Mats",
        "101160": "101160 - Home & Living/Decoration/Carpets & Rugs",
        "101161": "101161 - Home & Living/Decoration/Vases & Vessels",
        "101162": "101162 - Home & Living/Decoration/Candles & Candleholders",
        "101163": "101163 - Home & Living/Decoration/Mirrors",
        "101164": "101164 - Home & Living/Decoration/Table Cloths",
        "101165": "101165 - Home & Living/Decoration/Others",
        "101166": "101166 - Home & Living/Furniture/Cushions",
        "101167": "101167 - Home & Living/Furniture/Doorstoppers",
        "101168": "101168 - Home & Living/Furniture/Bed Frames & Headboards",
        "101169": "101169 - Home & Living/Furniture/Desks & Tables",
        "101170": "101170 - Home & Living/Furniture/Wardrobes",
        "101171": "101171 - Home & Living/Furniture/Benches, Chairs & Stools",
        "101172": "101172 - Home & Living/Furniture/Sofas",
        "101173": "101173 - Home & Living/Furniture/Cupboards & Cabinets",
        "101174": "101174 - Home & Living/Furniture/Shelves & Racks",
        "101175": "101175 - Home & Living/Furniture/Others",
        "101176": "101176 - Home & Living/Gardening/Plants",
        "101177": "101177 - Home & Living/Gardening/Garden Decorations",
        "101178":
          "101178 - Home & Living/Gardening/Garden Soils & Growing Media",
        "101179": "101179 - Home & Living/Gardening/Fertilizer",
        "101180": "101180 - Home & Living/Gardening/Seeds & Bulbs",
        "101181": "101181 - Home & Living/Gardening/Pots & Planters",
        "101182": "101182 - Home & Living/Gardening/Irrigation Systems",
        "101183": "101183 - Home & Living/Gardening/Gardening Tools",
        "101184": "101184 - Home & Living/Gardening/Others",
        "101185": "101185 - Home & Living/Gardening/Lawn Mowers",
        "101186":
          "101186 - Home & Living/Tools & Home Improvement/Industrial Adhesives & Tapes",
        "101187":
          "101187 - Home & Living/Tools & Home Improvement/Protective Gloves, Goggles & Masks",
        "101188":
          "101188 - Home & Living/Tools & Home Improvement/Sinks & Water Taps",
        "101189":
          "101189 - Home & Living/Tools & Home Improvement/Roofing & Flooring",
        "101190":
          "101190 - Home & Living/Tools & Home Improvement/Wall Paints & Coatings",
        "101192":
          "101192 - Home & Living/Tools & Home Improvement/Water Pumps, Parts & Accessories",
        "101193":
          "101193 - Home & Living/Tools & Home Improvement/Air Pumps, Parts & Accessories",
        "101194": "101194 - Home & Living/Tools & Home Improvement/Ladders",
        "101195": "101195 - Home & Living/Tools & Home Improvement/Trollies",
        "101196":
          "101196 - Home & Living/Tools & Home Improvement/Shades, Awnings & Tarpaulins",
        "101197":
          "101197 - Home & Living/Tools & Home Improvement/Construction Materials",
        "101198":
          "101198 - Home & Living/Tools & Home Improvement/Doors & Windows",
        "101199": "101199 - Home & Living/Tools & Home Improvement/Others",
        "101200":
          "101200 - Home & Living/Home Care Supplies/Clotheslines & Drying Racks",
        "101201": "101201 - Home & Living/Home Care Supplies/Cleaning Brushes",
        "101202": "101202 - Home & Living/Home Care Supplies/Brooms",
        "101203": "101203 - Home & Living/Home Care Supplies/Dusters",
        "101204": "101204 - Home & Living/Home Care Supplies/Mops",
        "101205":
          "101205 - Home & Living/Home Care Supplies/Basins, Buckets & Water Dippers",
        "101206":
          "101206 - Home & Living/Home Care Supplies/Sponges & Scouring Pads",
        "101207":
          "101207 - Home & Living/Home Care Supplies/Trash & Recycling Bins",
        "101208":
          "101208 - Home & Living/Home Care Supplies/Plastic Bags & Trash Bags",
        "101209": "101209 - Home & Living/Home Care Supplies/Cleaning Cloths",
        "101210":
          "101210 - Home & Living/Home Care Supplies/Pest & Weed Control",
        "101211":
          "101211 - Home & Living/Home Care Supplies/Tissue & Paper Towels",
        "101212": "101212 - Home & Living/Home Care Supplies/Toilet Paper",
        "101213": "101213 - Home & Living/Home Care Supplies/Cleaning Agents",
        "101215": "101215 - Home & Living/Home Care Supplies/Others",
        "101216": "101216 - Home & Living/Kitchenware/Grills & Accessories",
        "101217": "101217 - Home & Living/Kitchenware/Bakewares & Decorations",
        "101218": "101218 - Home & Living/Kitchenware/Pans",
        "101219": "101219 - Home & Living/Kitchenware/Pots",
        "101220": "101220 - Home & Living/Kitchenware/Food Storage",
        "101221": "101221 - Home & Living/Kitchenware/Cling Wrap",
        "101222": "101222 - Home & Living/Kitchenware/Aluminium Foil",
        "101223":
          "101223 - Home & Living/Kitchenware/Tea, Coffee & Bartending Equipments",
        "101224": "101224 - Home & Living/Kitchenware/Kitchen Racks",
        "101225": "101225 - Home & Living/Kitchenware/Aprons & Kitchen Gloves",
        "101226":
          "101226 - Home & Living/Kitchenware/Spatulas & Cooking Tweezers",
        "101227": "101227 - Home & Living/Kitchenware/Chopping Boards",
        "101228":
          "101228 - Home & Living/Kitchenware/Knives & Kitchen Scissors",
        "101229": "101229 - Home & Living/Kitchenware/Whisks & Beaters",
        "101230": "101230 - Home & Living/Kitchenware/Can & Bottle Openers",
        "101231":
          "101231 - Home & Living/Kitchenware/Measuring Glassess & Spoons",
        "101232": "101232 - Home & Living/Kitchenware/Strainers",
        "101233":
          "101233 - Home & Living/Kitchenware/Graters, Peelers & Cutters",
        "101234": "101234 - Home & Living/Kitchenware/Kitchen Weighing Scales",
        "101235": "101235 - Home & Living/Kitchenware/Sealers",
        "101237": "101237 - Home & Living/Kitchenware/Others",
        "101238":
          "101238 - Home & Living/Dinnerware/Jugs, Pitchers & Accessories",
        "101239": "101239 - Home & Living/Dinnerware/Tea Pots & Sets",
        "101240": "101240 - Home & Living/Dinnerware/Cups, Mugs & Glasses",
        "101241":
          "101241 - Home & Living/Dinnerware/Water Bottles & Accessories",
        "101242": "101242 - Home & Living/Dinnerware/Bowls",
        "101243": "101243 - Home & Living/Dinnerware/Plates",
        "101244": "101244 - Home & Living/Dinnerware/Cutleries",
        "101245": "101245 - Home & Living/Dinnerware/Straws",
        "101246": "101246 - Home & Living/Dinnerware/Food Covers",
        "101247": "101247 - Home & Living/Dinnerware/Placemats & Coasters",
        "101248": "101248 - Home & Living/Dinnerware/Others",
        "101249": "101249 - Home & Living/Safety & Security/Safes",
        "101250":
          "101250 - Home & Living/Safety & Security/Fire Fighting Equipments",
        "101251":
          "101251 - Home & Living/Safety & Security/Door Hardware & Locks",
        "101252": "101252 - Home & Living/Safety & Security/Others",
        "101253": "101253 - Home & Living/Home Organizers/Hangers & Pegs",
        "101254":
          "101254 - Home & Living/Home Organizers/Storage Boxes, Bags & Baskets",
        "101255": "101255 - Home & Living/Home Organizers/Shoe Storage Boxes",
        "101256": "101256 - Home & Living/Home Organizers/Hooks",
        "101257":
          "101257 - Home & Living/Home Organizers/Laundry Bags & Baskets",
        "101258": "101258 - Home & Living/Home Organizers/Desk Organizers",
        "101259": "101259 - Home & Living/Home Organizers/Wardrobe Organizers",
        "101260": "101260 - Home & Living/Home Organizers/Jewelry Organizers",
        "101261": "101261 - Home & Living/Home Organizers/Tissue Holders",
        "101262": "101262 - Home & Living/Home Organizers/Others",
        "101263": "101263 - Home & Living/Party Supplies/Balloons",
        "101264": "101264 - Home & Living/Party Supplies/Wooden Clips",
        "101265": "101265 - Home & Living/Party Supplies/Backdrops & Banners",
        "101266": "101266 - Home & Living/Party Supplies/Cards",
        "101267": "101267 - Home & Living/Party Supplies/Disposable Tableware",
        "101268": "101268 - Home & Living/Party Supplies/Party Hats & Masks",
        "101269": "101269 - Home & Living/Party Supplies/Sashes",
        "101270": "101270 - Home & Living/Party Supplies/Others",
        "101274":
          "101274 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Rock Climbing",
        "101285":
          "101285 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Baseball & Softball",
        "101286":
          "101286 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Squash",
        "101288":
          "101288 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Rugby",
        "101289":
          "101289 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Billiards",
        "101290":
          "101290 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Surfing & Wakeboarding",
        "101291":
          "101291 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Ice Skating & Winter Sports",
        "101293":
          "101293 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Boating",
        "101297":
          "101297 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Others",
        "101298": "101298 - Sports & Outdoors/Sports Footwear/Basketball Shoes",
        "101299": "101299 - Sports & Outdoors/Sports Footwear/Running Shoes",
        "101300": "101300 - Sports & Outdoors/Sports Footwear/Training Shoes",
        "101301": "101301 - Sports & Outdoors/Sports Footwear/Tennis Shoes",
        "101303": "101303 - Sports & Outdoors/Sports Footwear/Badminton Shoes",
        "101304": "101304 - Sports & Outdoors/Sports Footwear/Futsal Shoes",
        "101305": "101305 - Sports & Outdoors/Sports Footwear/Hiking Shoes",
        "101306": "101306 - Sports & Outdoors/Sports Footwear/Soccer Shoes",
        "101307":
          "101307 - Sports & Outdoors/Sports Footwear/Kid's Sport Shoes",
        "101308": "101308 - Sports & Outdoors/Sports Footwear/Others",
        "101309": "101309 - Sports & Outdoors/Sports & Outdoor Apparels/Sets",
        "101310":
          "101310 - Sports & Outdoors/Sports & Outdoor Apparels/Jackets",
        "101311":
          "101311 - Sports & Outdoors/Sports & Outdoor Apparels/T-shirts",
        "101312":
          "101312 - Sports & Outdoors/Sports & Outdoor Apparels/Jerseys",
        "101313":
          "101313 - Sports & Outdoors/Sports & Outdoor Apparels/Bottoms",
        "101315":
          "101315 - Sports & Outdoors/Sports & Outdoor Apparels/Sports Bras",
        "101316":
          "101316 - Sports & Outdoors/Sports & Outdoor Apparels/Kid's Sports Apparel",
        "101317": "101317 - Sports & Outdoors/Sports & Outdoor Apparels/Others",
        "101318":
          "101318 - Sports & Outdoors/Sports & Outdoor Accessories/Stopwatches & Pedometers",
        "101319":
          "101319 - Sports & Outdoors/Sports & Outdoor Accessories/Shoe Bags",
        "101320":
          "101320 - Sports & Outdoors/Sports & Outdoor Accessories/Sports Wristbands",
        "101321":
          "101321 - Sports & Outdoors/Sports & Outdoor Accessories/Sports Headbands",
        "101322":
          "101322 - Sports & Outdoors/Sports & Outdoor Accessories/Sports & Outdoor Hats",
        "101323":
          "101323 - Sports & Outdoors/Sports & Outdoor Accessories/Dry Bags",
        "101324":
          "101324 - Sports & Outdoors/Sports & Outdoor Accessories/Rain Coats",
        "101325":
          "101325 - Sports & Outdoors/Sports & Outdoor Accessories/Umbrellas",
        "101326":
          "101326 - Sports & Outdoors/Sports & Outdoor Accessories/Mouthguards & Sport Tapes",
        "101327":
          "101327 - Sports & Outdoors/Sports & Outdoor Accessories/Training Equipments",
        "101328":
          "101328 - Sports & Outdoors/Sports & Outdoor Accessories/Gym Protective Gears",
        "101329":
          "101329 - Sports & Outdoors/Sports & Outdoor Accessories/Others",
        "101330": "101330 - Stationery/Gift & Wrapping/Gift Wrappers",
        "101331": "101331 - Stationery/Gift & Wrapping/Gift Boxes",
        "101332": "101332 - Stationery/Gift & Wrapping/Gift Bags",
        "101333": "101333 - Stationery/Gift & Wrapping/Ribbons",
        "101334": "101334 - Stationery/Gift & Wrapping/Bubble Wraps",
        "101335": "101335 - Stationery/Gift & Wrapping/Carton Boxes",
        "101336": "101336 - Stationery/Gift & Wrapping/Others",
        "101337": "101337 - Stationery/Writing & Correction/Pens & Inks",
        "101338": "101338 - Stationery/Writing & Correction/Pencils",
        "101339":
          "101339 - Stationery/Writing & Correction/Eraser & Correction Supplies",
        "101340": "101340 - Stationery/Writing & Correction/Markers",
        "101341": "101341 - Stationery/Writing & Correction/Highlighters",
        "101342": "101342 - Stationery/Writing & Correction/Others",
        "101343":
          "101343 - Stationery/School & Office Equipment/Writing Boards & Board Stands",
        "101344": "101344 - Stationery/School & Office Equipment/Calculators",
        "101345":
          "101345 - Stationery/School & Office Equipment/Pen Knives & Paper Cutters",
        "101346":
          "101346 - Stationery/School & Office Equipment/Strings & Tapes",
        "101347": "101347 - Stationery/School & Office Equipment/Glues",
        "101348":
          "101348 - Stationery/School & Office Equipment/Label Printers",
        "101349":
          "101349 - Stationery/School & Office Equipment/Lanyards & Name Tags",
        "101350":
          "101350 - Stationery/School & Office Equipment/Clips, Pins & Tacks",
        "101351": "101351 - Stationery/School & Office Equipment/Hole Punchers",
        "101352": "101352 - Stationery/School & Office Equipment/Scissors",
        "101353":
          "101353 - Stationery/School & Office Equipment/Ink Stamps & Pads",
        "101354":
          "101354 - Stationery/School & Office Equipment/Staplers & Staples",
        "101355": "101355 - Stationery/School & Office Equipment/Calendars",
        "101356":
          "101356 - Stationery/School & Office Equipment/Folders, Paper Organizers & Accessories",
        "101357":
          "101357 - Stationery/School & Office Equipment/Rulers, Protractors & Stencils",
        "101358": "101358 - Stationery/School & Office Equipment/Sharpeners",
        "101359": "101359 - Stationery/School & Office Equipment/Pencil Cases",
        "101360": "101360 - Stationery/School & Office Equipment/Others",
        "101361": "101361 - Stationery/Art Supplies/Color Pencils",
        "101362": "101362 - Stationery/Art Supplies/Crayons & Pastels",
        "101363": "101363 - Stationery/Art Supplies/Water & Poster Colours",
        "101364": "101364 - Stationery/Art Supplies/Oil Paint",
        "101365": "101365 - Stationery/Art Supplies/Acrylic Paint",
        "101366": "101366 - Stationery/Art Supplies/Paint Brushes",
        "101367": "101367 - Stationery/Art Supplies/Paint Palettes",
        "101368": "101368 - Stationery/Art Supplies/Canvases & Easels",
        "101369": "101369 - Stationery/Art Supplies/Sketch Books",
        "101370": "101370 - Stationery/Art Supplies/Others",
        "101371": "101371 - Stationery/Notebooks & Papers/Bookmarks",
        "101372": "101372 - Stationery/Notebooks & Papers/Book Covers",
        "101373":
          "101373 - Stationery/Notebooks & Papers/Thermal Paper & Continuous Paper",
        "101374":
          "101374 - Stationery/Notebooks & Papers/Printing & Photocopy Paper",
        "101375": "101375 - Stationery/Notebooks & Papers/Loose Leaf",
        "101376": "101376 - Stationery/Notebooks & Papers/Memo & Sticky Notes",
        "101377": "101377 - Stationery/Notebooks & Papers/Art Paper & Boards",
        "101378": "101378 - Stationery/Notebooks & Papers/Notebooks & Notepads",
        "101379": "101379 - Stationery/Notebooks & Papers/Labels & Stickers",
        "101380": "101380 - Stationery/Notebooks & Papers/Others",
        "101381": "101381 - Stationery/Letters & Envelopes/Envelopes & Angpao",
        "101382": "101382 - Stationery/Letters & Envelopes/Post Cards",
        "101383":
          "101383 - Stationery/Letters & Envelopes/Postage Stamps & Duty Stamps",
        "101384": "101384 - Stationery/Letters & Envelopes/Others",
        "101385":
          "101385 - Hobbies & Collections/Collectible Items/Action Figurines",
        "101386":
          "101386 - Hobbies & Collections/Collectible Items/Statues & Sculptures",
        "101387":
          "101387 - Hobbies & Collections/Collectible Items/Mecha Models & Diecast",
        "101388":
          "101388 - Hobbies & Collections/Collectible Items/Vehicle Models & Diecast",
        "101389":
          "101389 - Hobbies & Collections/Collectible Items/Stones & Minerals",
        "101390":
          "101390 - Hobbies & Collections/Collectible Items/Idol Collectibles",
        "101391":
          "101391 - Hobbies & Collections/Collectible Items/Sports Collectibles",
        "101392":
          "101392 - Hobbies & Collections/Collectible Items/Anime & Manga Collectibles",
        "101393": "101393 - Hobbies & Collections/Collectible Items/Coins",
        "101394": "101394 - Hobbies & Collections/Collectible Items/Others",
        "101395": "101395 - Hobbies & Collections/Souvenirs/Hand Fans",
        "101396": "101396 - Hobbies & Collections/Souvenirs/Keychains",
        "101397": "101397 - Hobbies & Collections/Souvenirs/Coin Banks",
        "101398": "101398 - Hobbies & Collections/Souvenirs/Fridge Magnets",
        "101399": "101399 - Hobbies & Collections/Souvenirs/Others",
        "101400":
          "101400 - Hobbies & Collections/Toys & Games/Dice, Board & Card Games",
        "101401": "101401 - Hobbies & Collections/Toys & Games/Magic Toys",
        "101402": "101402 - Hobbies & Collections/Toys & Games/Prank Toys",
        "101403": "101403 - Hobbies & Collections/Toys & Games/Rubik's Cubes",
        "101404": "101404 - Hobbies & Collections/Toys & Games/Spinning Tops",
        "101405": "101405 - Hobbies & Collections/Toys & Games/Kendamas",
        "101406": "101406 - Hobbies & Collections/Toys & Games/Yo-yos",
        "101407":
          "101407 - Hobbies & Collections/Toys & Games/Remote Control Toys & Accessories",
        "101408": "101408 - Hobbies & Collections/Toys & Games/Capsule Toys",
        "101409": "101409 - Hobbies & Collections/Toys & Games/Others",
        "101410":
          "101410 - Hobbies & Collections/Musical Instruments & Accessories/Keyboards & Pianos",
        "101411":
          "101411 - Hobbies & Collections/Musical Instruments & Accessories/Percussion Instruments",
        "101412":
          "101412 - Hobbies & Collections/Musical Instruments & Accessories/Wind Instruments",
        "101413":
          "101413 - Hobbies & Collections/Musical Instruments & Accessories/Music Accessories",
        "101414":
          "101414 - Hobbies & Collections/Musical Instruments & Accessories/Others",
        "101415":
          "101415 - Automobiles/Automobile Interior Accessories/Navigation & AV Receivers",
        "101416":
          "101416 - Automobiles/Automobile Interior Accessories/Amplifiers, Speakers & Subwoofers",
        "101417":
          "101417 - Automobiles/Automobile Interior Accessories/Organizers & Compartments",
        "101418":
          "101418 - Automobiles/Automobile Interior Accessories/Perfumes, Air Fresheners & Purifiers",
        "101419":
          "101419 - Automobiles/Automobile Interior Accessories/Carpets & Mats",
        "101420":
          "101420 - Automobiles/Automobile Interior Accessories/Seat Headrests & Back Supports",
        "101421":
          "101421 - Automobiles/Automobile Interior Accessories/Car Mattresses",
        "101422":
          "101422 - Automobiles/Automobile Interior Accessories/Steering Wheels & Covers",
        "101423":
          "101423 - Automobiles/Automobile Interior Accessories/Seats & Seat Covers",
        "101424":
          "101424 - Automobiles/Automobile Interior Accessories/Phone Holders",
        "101425":
          "101425 - Automobiles/Automobile Interior Accessories/USB Chargers, FM & Bluetooth Transmitters",
        "101426":
          "101426 - Automobiles/Automobile Interior Accessories/Pedals & Gear Sticks",
        "101427":
          "101427 - Automobiles/Automobile Interior Accessories/Sun Shields & Dash Covers",
        "101428":
          "101428 - Automobiles/Automobile Interior Accessories/Locks & Security",
        "101429":
          "101429 - Automobiles/Automobile Interior Accessories/Camcorders & Parking Cameras",
        "101430":
          "101430 - Automobiles/Automobile Interior Accessories/HUD, Speedometers & Gauges",
        "101431": "101431 - Automobiles/Automobile Interior Accessories/Others",
        "101432":
          "101432 - Automobiles/Automobile Exterior Accessories/Garnish",
        "101433":
          "101433 - Automobiles/Automobile Exterior Accessories/Antennas",
        "101434": "101434 - Automobiles/Automobile Exterior Accessories/Covers",
        "101435":
          "101435 - Automobiles/Automobile Exterior Accessories/Stickers, Logos & Emblems",
        "101436":
          "101436 - Automobiles/Automobile Exterior Accessories/Mud Flaps & Splash Guards",
        "101437":
          "101437 - Automobiles/Automobile Exterior Accessories/Sill Plates",
        "101438":
          "101438 - Automobiles/Automobile Exterior Accessories/Gutters",
        "101439":
          "101439 - Automobiles/Automobile Exterior Accessories/Horns & Accessories",
        "101440":
          "101440 - Automobiles/Automobile Exterior Accessories/Mirrors & Accessories",
        "101441":
          "101441 - Automobiles/Automobile Exterior Accessories/License Plate Accessories",
        "101442": "101442 - Automobiles/Automobile Exterior Accessories/Others",
        "101443":
          "101443 - Automobiles/Automobile Spare Parts/Body, Frame & Bumpers",
        "101444":
          "101444 - Automobiles/Automobile Spare Parts/Windshield Wipers & Washers",
        "101445":
          "101445 - Automobiles/Automobile Spare Parts/Exhaust & Emissions",
        "101446":
          "101446 - Automobiles/Automobile Spare Parts/Wheels, Rims & Accessories",
        "101447":
          "101447 - Automobiles/Automobile Spare Parts/Tires & Accessories",
        "101448":
          "101448 - Automobiles/Automobile Spare Parts/Shocks, Struts & Suspension",
        "101449":
          "101449 - Automobiles/Automobile Spare Parts/Radiators, Engine Cooling & Climate Control",
        "101450":
          "101450 - Automobiles/Automobile Spare Parts/Drivetrain, Transmission & Clutches",
        "101451": "101451 - Automobiles/Automobile Spare Parts/Bearing & Seals",
        "101453": "101453 - Automobiles/Automobile Spare Parts/Brake System",
        "101454":
          "101454 - Automobiles/Automobile Spare Parts/Belts, Hoses & Pulleys",
        "101456": "101456 - Automobiles/Automobile Spare Parts/Fuel System",
        "101457": "101457 - Automobiles/Automobile Spare Parts/Others",
        "101458":
          "101458 - Automobiles/Automotive Tools/Test, Diagnostic & Repair Tools",
        "101459":
          "101459 - Automobiles/Automotive Tools/Tire Pressure Detectors",
        "101460": "101460 - Automobiles/Automotive Tools/Others",
        "101461": "101461 - Automobiles/Automotive Care/Wash & Waxes",
        "101462":
          "101462 - Automobiles/Automotive Care/Glass Care & Water Repellents",
        "101463": "101463 - Automobiles/Automotive Care/Interior Care",
        "101464": "101464 - Automobiles/Automotive Care/Tire & Wheel Care",
        "101465":
          "101465 - Automobiles/Automotive Care/Polish, Coating & Sealants",
        "101466": "101466 - Automobiles/Automotive Care/Others",
        "101472": "101472 - Automobiles/Automotive Oils & Lubes/Others",
        "101473": "101473 - Motorcycles/Motorcycle Accessories/Carpets",
        "101474":
          "101474 - Motorcycles/Motorcycle Accessories/Speedometers, Odometers & Gauges",
        "101475": "101475 - Motorcycles/Motorcycle Accessories/Covers",
        "101476":
          "101476 - Motorcycles/Motorcycle Accessories/Stickers, Logos & Emblems",
        "101477":
          "101477 - Motorcycles/Motorcycle Accessories/Seats & Seat Covers",
        "101478":
          "101478 - Motorcycles/Motorcycle Accessories/Mirrors & Accessories",
        "101479":
          "101479 - Motorcycles/Motorcycle Accessories/Locks & Security",
        "101480": "101480 - Motorcycles/Motorcycle Accessories/Boxes & Cases",
        "101481": "101481 - Motorcycles/Motorcycle Accessories/Phone Holders",
        "101482":
          "101482 - Motorcycles/Motorcycle Accessories/Mud Flaps & Splash Guards",
        "101483": "101483 - Motorcycles/Motorcycle Accessories/Others",
        "101485": "101485 - Motorcycles/Motorcycle Spare Parts/Fuel System",
        "101486": "101486 - Motorcycles/Motorcycle Spare Parts/Brake System",
        "101487":
          "101487 - Motorcycles/Motorcycle Spare Parts/Shocks, Struts & Suspension",
        "101489":
          "101489 - Motorcycles/Motorcycle Spare Parts/Batteries & Accessories",
        "101490":
          "101490 - Motorcycles/Motorcycle Spare Parts/Horns & Accessories",
        "101491": "101491 - Motorcycles/Motorcycle Spare Parts/Cables & Tubes",
        "101492": "101492 - Motorcycles/Motorcycle Spare Parts/Body & Frame",
        "101493":
          "101493 - Motorcycles/Motorcycle Spare Parts/Exhaust & Emissions",
        "101494":
          "101494 - Motorcycles/Motorcycle Spare Parts/Wheels, Rims & Accessories",
        "101495":
          "101495 - Motorcycles/Motorcycle Spare Parts/Tires & Accessories",
        "101496": "101496 - Motorcycles/Motorcycle Spare Parts/Lighting",
        "101497": "101497 - Motorcycles/Motorcycle Spare Parts/Others",
        "101498":
          "101498 - Tickets, Vouchers & Services/Events & Attractions/Theme Parks & Attractions",
        "101499":
          "101499 - Tickets, Vouchers & Services/Events & Attractions/Events, Fairs & Conferences",
        "101500":
          "101500 - Tickets, Vouchers & Services/Events & Attractions/Movies",
        "101501":
          "101501 - Tickets, Vouchers & Services/Events & Attractions/Concerts & Exhibitions",
        "101502":
          "101502 - Tickets, Vouchers & Services/Events & Attractions/Others",
        "101503":
          "101503 - Tickets, Vouchers & Services/F&B/Dine-in & Takeaway",
        "101504": "101504 - Tickets, Vouchers & Services/F&B/Food Delivery",
        "101505": "101505 - Tickets, Vouchers & Services/F&B/Others",
        "101506":
          "101506 - Tickets, Vouchers & Services/Shopping/Malls & Department Stores",
        "101507": "101507 - Tickets, Vouchers & Services/Shopping/Supermarkets",
        "101508": "101508 - Tickets, Vouchers & Services/Shopping/Others",
        "101509": "101509 - Tickets, Vouchers & Services/Services/Housekeeping",
        "101510":
          "101510 - Tickets, Vouchers & Services/Services/Delivery & Moving",
        "101512":
          "101512 - Tickets, Vouchers & Services/Services/Air-Conditioning Servicing",
        "101513":
          "101513 - Tickets, Vouchers & Services/Services/Photography & Printing",
        "101514": "101514 - Tickets, Vouchers & Services/Services/Others",
        "101515":
          "101515 - Tickets, Vouchers & Services/Beauty & Wellness/Health",
        "101516":
          "101516 - Tickets, Vouchers & Services/Beauty & Wellness/Dental",
        "101517":
          "101517 - Tickets, Vouchers & Services/Beauty & Wellness/Fitness",
        "101518":
          "101518 - Tickets, Vouchers & Services/Beauty & Wellness/Hair",
        "101519":
          "101519 - Tickets, Vouchers & Services/Beauty & Wellness/Spa & Massage",
        "101520":
          "101520 - Tickets, Vouchers & Services/Beauty & Wellness/Others",
        "101521":
          "101521 - Tickets, Vouchers & Services/Telco/Internet & Cable TV",
        "101522": "101522 - Tickets, Vouchers & Services/Telco/Mobile Data",
        "101523":
          "101523 - Tickets, Vouchers & Services/Telco/Mobile Top-ups & Pre-paid",
        "101524": "101524 - Tickets, Vouchers & Services/Telco/Others",
        "101525": "101525 - Tickets, Vouchers & Services/Travel/Accommodation",
        "101526": "101526 - Tickets, Vouchers & Services/Travel/Hot Springs",
        "101527": "101527 - Tickets, Vouchers & Services/Travel/Flights",
        "101528": "101528 - Tickets, Vouchers & Services/Travel/Trains",
        "101529": "101529 - Tickets, Vouchers & Services/Travel/Buses",
        "101530": "101530 - Tickets, Vouchers & Services/Travel/Car Rentals",
        "101531":
          "101531 - Tickets, Vouchers & Services/Travel/Tour & Packages",
        "101532": "101532 - Tickets, Vouchers & Services/Travel/Others",
        "101533":
          "101533 - Tickets, Vouchers & Services/Shopee/Shopee Official",
        "101534":
          "101534 - Tickets, Vouchers & Services/Shopee/Shopee Paid Ads Credits",
        "101535": "101535 - Tickets, Vouchers & Services/Shopee/Others",
        "101536": "101536 - Books & Magazines/Magazines & Newspaper/Business",
        "101537": "101537 - Books & Magazines/Magazines & Newspaper/Lifestyle",
        "101538": "101538 - Books & Magazines/Magazines & Newspaper/Teenager",
        "101539": "101539 - Books & Magazines/Magazines & Newspaper/Others",
        "101540": "101540 - Books & Magazines/Books/Comics & Manga",
        "101541": "101541 - Books & Magazines/Books/Children's Books",
        "101542":
          "101542 - Books & Magazines/Books/Agriculture, Forestry & Fishery",
        "101544": "101544 - Books & Magazines/Books/Arts, Design & Photography",
        "101545": "101545 - Books & Magazines/Books/Recipes & Cooking",
        "101546": "101546 - Books & Magazines/Books/Business & Investment",
        "101547":
          "101547 - Books & Magazines/Books/Politics, Law & Social Sciences",
        "101548": "101548 - Books & Magazines/Books/Computers & Technology",
        "101549": "101549 - Books & Magazines/Books/Health, Fitness & Dieting",
        "101550": "101550 - Books & Magazines/Books/Travel & Tourism",
        "101551":
          "101551 - Books & Magazines/Books/Language Learning & Dictionaries",
        "101552": "101552 - Books & Magazines/Books/Hobbies",
        "101555": "101555 - Books & Magazines/Books/Classic Literature",
        "101556": "101556 - Books & Magazines/Books/Fantasy",
        "101557": "101557 - Books & Magazines/Books/Action, Crime & Thrillers",
        "101558": "101558 - Books & Magazines/Books/Romance",
        "101559": "101559 - Books & Magazines/Books/Biography & Memoirs",
        "101560": "101560 - Books & Magazines/Books/Science & Maths",
        "101561": "101561 - Books & Magazines/Books/Medical",
        "101562": "101562 - Books & Magazines/Books/Parenting & Family",
        "101563": "101563 - Books & Magazines/Books/Psychology & Relationships",
        "101564": "101564 - Books & Magazines/Books/Religion & Philosophy",
        "101565": "101565 - Books & Magazines/Books/History & Cultures",
        "101566":
          "101566 - Books & Magazines/Books/Careers, Self Help & Personal Development",
        "101567": "101567 - Books & Magazines/Books/Horoscopes",
        "101568": "101568 - Books & Magazines/Books/Education & School",
        "101569": "101569 - Books & Magazines/Books/Sticker & Colouring Books",
        "101570": "101570 - Books & Magazines/Books/Music",
        "101571": "101571 - Books & Magazines/Books/Baby & Soft Books",
        "101572": "101572 - Books & Magazines/Books/Audio Books",
        "101573": "101573 - Books & Magazines/Books/Others",
        "101574":
          "101574 - Food & Beverages/Snacks/Dried Snacks/Meat Jerky and Bakkwa",
        "101575": "101575 - Food & Beverages/Snacks/Dried Snacks/Dried Fruits",
        "101576": "101576 - Food & Beverages/Snacks/Dried Snacks/Meat Floss",
        "101577":
          "101577 - Food & Beverages/Snacks/Dried Snacks/Dried Seafood Snacks",
        "101578": "101578 - Food & Beverages/Snacks/Dried Snacks/Others",
        "101579":
          "101579 - Food & Beverages/Food Staples/Dried Goods/Beans & Grains",
        "101580":
          "101580 - Food & Beverages/Food Staples/Dried Goods/Dried Seafood",
        "101581":
          "101581 - Food & Beverages/Food Staples/Dried Goods/Traditional Herbs",
        "101582": "101582 - Food & Beverages/Food Staples/Dried Goods/Others",
        "101583":
          "101583 - Food & Beverages/Food Staples/Canned Food/Canned Fruits",
        "101584":
          "101584 - Food & Beverages/Cooking Essentials/Seasonings & Condiments/Pepper",
        "101585":
          "101585 - Food & Beverages/Cooking Essentials/Seasonings & Condiments/Salt",
        "101586":
          "101586 - Food & Beverages/Cooking Essentials/Seasonings & Condiments/Herbs & Spices",
        "101587":
          "101587 - Food & Beverages/Cooking Essentials/Seasonings & Condiments/Sauce",
        "101588":
          "101588 - Food & Beverages/Cooking Essentials/Seasonings & Condiments/Chilli & Sambal",
        "101589":
          "101589 - Food & Beverages/Cooking Essentials/Seasonings & Condiments/Vinegar",
        "101590":
          "101590 - Food & Beverages/Cooking Essentials/Seasonings & Condiments/Cooking Wine",
        "101591":
          "101591 - Food & Beverages/Cooking Essentials/Seasonings & Condiments/Dressing",
        "101592":
          "101592 - Food & Beverages/Cooking Essentials/Seasonings & Condiments/Others",
        "101594": "101594 - Food & Beverages/Dairy & Eggs/Milk/UHT Milk",
        "101595":
          "101595 - Food & Beverages/Dairy & Eggs/Milk/Condensed & Evaporated Milk",
        "101596": "101596 - Food & Beverages/Dairy & Eggs/Milk/Powdered Milk",
        "101597": "101597 - Food & Beverages/Dairy & Eggs/Milk/Others",
        "101607": "101607 - Beauty/Hand, Foot & Nail Care/Hand Care/Hand Masks",
        "101608":
          "101608 - Beauty/Hand, Foot & Nail Care/Hand Care/Lotion, Cream & Scrubs",
        "101609":
          "101609 - Beauty/Hand, Foot & Nail Care/Hand Care/Hand Washes",
        "101610": "101610 - Beauty/Hand, Foot & Nail Care/Hand Care/Others",
        "101611":
          "101611 - Beauty/Hand, Foot & Nail Care/Foot Care/Foot Deodorant",
        "101612": "101612 - Beauty/Hand, Foot & Nail Care/Foot Care/Foot Masks",
        "101613":
          "101613 - Beauty/Hand, Foot & Nail Care/Foot Care/Lotion, Cream & Scrubs",
        "101614": "101614 - Beauty/Hand, Foot & Nail Care/Foot Care/Others",
        "101615":
          "101615 - Beauty/Hand, Foot & Nail Care/Nail Care/Base & Top Coat",
        "101616": "101616 - Beauty/Men's Care/Skincare/Facial Cleanser",
        "101617": "101617 - Beauty/Men's Care/Skincare/Moisturizer & Treatment",
        "101618": "101618 - Beauty/Men's Care/Skincare/Others",
        "101619": "101619 - Beauty/Men's Care/Shaving & Grooming/Aftershave",
        "101620":
          "101620 - Beauty/Men's Care/Shaving & Grooming/Creams, Foams & Gels",
        "101621":
          "101621 - Beauty/Men's Care/Shaving & Grooming/Razors & Blades",
        "101622": "101622 - Beauty/Men's Care/Shaving & Grooming/Shavers",
        "101623":
          "101623 - Beauty/Men's Care/Shaving & Grooming/Shaving Brushes",
        "101624":
          "101624 - Beauty/Men's Care/Shaving & Grooming/Trimmers, Clippers & Multi-functional Groomers",
        "101625":
          "101625 - Beauty/Men's Care/Shaving & Grooming/Shaving Accessories",
        "101626": "101626 - Beauty/Men's Care/Shaving & Grooming/Others",
        "101627": "101627 - Beauty/Makeup/Face/Makeup Base & Primer",
        "101628": "101628 - Beauty/Makeup/Face/Foundation",
        "101629": "101629 - Beauty/Makeup/Face/BB & CC Cream",
        "101630": "101630 - Beauty/Makeup/Face/Powder",
        "101631": "101631 - Beauty/Makeup/Face/Concealer & Corrector",
        "101632": "101632 - Beauty/Makeup/Face/Bronzer,Contour & Highlighter",
        "101633": "101633 - Beauty/Makeup/Face/Setting & Finishing Spray",
        "101634": "101634 - Beauty/Makeup/Face/Blush",
        "101635": "101635 - Beauty/Makeup/Face/Others",
        "101636": "101636 - Beauty/Makeup/Eyes/Eyeshadow",
        "101637": "101637 - Beauty/Makeup/Eyes/Eye Primer",
        "101638": "101638 - Beauty/Makeup/Eyes/Eyeliner",
        "101639": "101639 - Beauty/Makeup/Eyes/Mascara",
        "101640": "101640 - Beauty/Makeup/Eyes/Eyebrows",
        "101641": "101641 - Beauty/Makeup/Eyes/Others",
        "101642": "101642 - Beauty/Makeup/Lips/Lipstick",
        "101643": "101643 - Beauty/Makeup/Lips/Lip Gloss",
        "101644": "101644 - Beauty/Makeup/Lips/Lip Liner",
        "101645": "101645 - Beauty/Makeup/Lips/Lip Tint & Stain",
        "101646": "101646 - Beauty/Makeup/Lips/Lip Plumper",
        "101647": "101647 - Beauty/Makeup/Lips/Others",
        "101648": "101648 - Beauty/Beauty Tools/Makeup Accessories/Others",
        "101649":
          "101649 - Beauty/Beauty Tools/Makeup Accessories/Cotton Pads and Buds",
        "101650":
          "101650 - Beauty/Beauty Tools/Makeup Accessories/Makeup Bags & Organizers",
        "101651": "101651 - Beauty/Beauty Tools/Makeup Accessories/Mirrors",
        "101652":
          "101652 - Beauty/Beauty Tools/Makeup Accessories/Makeup Brush Cleaners",
        "101653":
          "101653 - Beauty/Beauty Tools/Makeup Accessories/Makeup Brushes",
        "101654":
          "101654 - Beauty/Beauty Tools/Makeup Accessories/Sponges & Applicators",
        "101655":
          "101655 - Beauty/Beauty Tools/Makeup Accessories/Eyelash Curlers",
        "101656":
          "101656 - Beauty/Beauty Tools/Makeup Accessories/Eyebrow Templates",
        "101657":
          "101657 - Beauty/Beauty Tools/Makeup Accessories/False Eyelashes",
        "101658":
          "101658 - Beauty/Beauty Tools/Makeup Accessories/Eyelid Tape & Glue",
        "101659":
          "101659 - Beauty/Beauty Tools/Makeup Accessories/Pencil Sharpeners",
        "101660":
          "101660 - Beauty/Beauty Tools/Facial Care Tools/Facial Steamers",
        "101661":
          "101661 - Beauty/Beauty Tools/Facial Care Tools/Facial Slimming & Massage Tools",
        "101662":
          "101662 - Beauty/Beauty Tools/Facial Care Tools/Facial Cleansing Tools",
        "101663": "101663 - Beauty/Beauty Tools/Facial Care Tools/Others",
        "101664": "101664 - Beauty/Beauty Tools/Hair Tools/Brushes & Combs",
        "101665": "101665 - Beauty/Beauty Tools/Hair Tools/Hair Dryers",
        "101666":
          "101666 - Beauty/Beauty Tools/Hair Tools/Hair Styling Appliances",
        "101667":
          "101667 - Beauty/Beauty Tools/Hair Tools/Heatless Styling Tools",
        "101668": "101668 - Beauty/Beauty Tools/Hair Tools/Others",
        "101669": "101669 - Beauty/Skincare/Eye Treatment/Eye Cream",
        "101670": "101670 - Beauty/Skincare/Eye Treatment/Eye Mask & Packs",
        "101671":
          "101671 - Beauty/Skincare/Eye Treatment/Eyebrow & Eyelash Serum",
        "101672": "101672 - Beauty/Skincare/Eye Treatment/Others",
        "101673": "101673 - Beauty/Skincare/Lip Treatment/Lip Balm",
        "101674":
          "101674 - Beauty/Skincare/Lip Treatment/Lip Scrub & Exfoliator",
        "101675": "101675 - Beauty/Skincare/Lip Treatment/Lip Mask",
        "101676": "101676 - Beauty/Skincare/Lip Treatment/Others",
        "101678": "101678 - Pets/Pet Accessories/Toys/Small Pet Toys",
        "101679": "101679 - Pets/Pet Accessories/Toys/Bird Toys",
        "101680": "101680 - Pets/Pet Accessories/Toys/Others",
        "101681": "101681 - Pets/Pet Accessories/Pet Furniture/Beds & Mats",
        "101682": "101682 - Pets/Pet Accessories/Pet Furniture/Houses",
        "101683":
          "101683 - Pets/Pet Accessories/Pet Furniture/Habitats & Accessories",
        "101684": "101684 - Pets/Pet Accessories/Pet Furniture/Cages & Crates",
        "101685":
          "101685 - Pets/Pet Accessories/Pet Furniture/Scratching Pads & Posts",
        "101686": "101686 - Pets/Pet Accessories/Pet Furniture/Others",
        "101691":
          "101691 - Mom & Baby/Feeding Essentials/Bottle-feeding/Bottle Cooler Bag",
        "101692":
          "101692 - Mom & Baby/Feeding Essentials/Bottle-feeding/Bottles & Bottle accessories",
        "101693":
          "101693 - Mom & Baby/Feeding Essentials/Bottle-feeding/Bottle Cleansing",
        "101694":
          "101694 - Mom & Baby/Feeding Essentials/Bottle-feeding/Warmers",
        "101695":
          "101695 - Mom & Baby/Feeding Essentials/Bottle-feeding/Sterilizers",
        "101696":
          "101696 - Mom & Baby/Feeding Essentials/Bottle-feeding/Others",
        "101697":
          "101697 - Mom & Baby/Feeding Essentials/Breastfeeding/Breast Pump & Accessories",
        "101698":
          "101698 - Mom & Baby/Feeding Essentials/Breastfeeding/Breast Pads, Shells & Shields",
        "101699":
          "101699 - Mom & Baby/Feeding Essentials/Breastfeeding/Nursing Covers",
        "101700":
          "101700 - Mom & Baby/Feeding Essentials/Breastfeeding/Breastmilk Storage Bags",
        "101701": "101701 - Mom & Baby/Feeding Essentials/Breastfeeding/Others",
        "101702": "101702 - Mom & Baby/Feeding Essentials/Utensils/Baby Cups",
        "101703":
          "101703 - Mom & Baby/Feeding Essentials/Utensils/Baby Tableware",
        "101704":
          "101704 - Mom & Baby/Feeding Essentials/Utensils/Baby Food Containers",
        "101705": "101705 - Mom & Baby/Feeding Essentials/Utensils/Others",
        "101706":
          "101706 - Mom & Baby/Nursery/Mattresses & Bedding/Blankets & Wrappers",
        "101707":
          "101707 - Mom & Baby/Nursery/Mattresses & Bedding/Pillows & Bolsters",
        "101708": "101708 - Mom & Baby/Nursery/Mattresses & Bedding/Bedsheets",
        "101709": "101709 - Mom & Baby/Nursery/Mattresses & Bedding/Others",
        "101710":
          "101710 - Mom & Baby/Baby Healthcare/Baby Skincare/Lotion & Creams",
        "101711": "101711 - Mom & Baby/Baby Healthcare/Baby Skincare/Oils",
        "101712": "101712 - Mom & Baby/Baby Healthcare/Baby Skincare/Powders",
        "101713": "101713 - Mom & Baby/Baby Healthcare/Baby Skincare/Others",
        "101714":
          "101714 - Mom & Baby/Toys/Baby & Toddler Toys/Playgym & Playmats",
        "101715":
          "101715 - Mom & Baby/Toys/Baby & Toddler Toys/Playards & Playpens",
        "101716": "101716 - Mom & Baby/Toys/Baby & Toddler Toys/Bath Toys",
        "101717":
          "101717 - Mom & Baby/Toys/Baby & Toddler Toys/Crib Mobiles & Rattles",
        "101718": "101718 - Mom & Baby/Toys/Baby & Toddler Toys/Teethers",
        "101719": "101719 - Mom & Baby/Toys/Baby & Toddler Toys/Others",
        "101720":
          "101720 - Mom & Baby/Toys/Dolls & Stuffed Toys/Dolls & Accessories",
        "101721":
          "101721 - Mom & Baby/Toys/Dolls & Stuffed Toys/Doll Houses & Accessories",
        "101722": "101722 - Mom & Baby/Toys/Dolls & Stuffed Toys/Stuffed Toys",
        "101723": "101723 - Mom & Baby/Toys/Dolls & Stuffed Toys/Others",
        "101724":
          "101724 - Mom & Baby/Toys/Sports & Outdoor Play/Bicycles, Scooters & Ride-ons",
        "101725":
          "101725 - Mom & Baby/Toys/Sports & Outdoor Play/Inflatables & Slides",
        "101726":
          "101726 - Mom & Baby/Toys/Sports & Outdoor Play/Play Tents, Tunnels & Ball Pits",
        "101727":
          "101727 - Mom & Baby/Toys/Sports & Outdoor Play/Pool, Water & Sand Toys",
        "101728":
          "101728 - Mom & Baby/Toys/Sports & Outdoor Play/Blasters & Toy Guns",
        "101729": "101729 - Mom & Baby/Toys/Sports & Outdoor Play/Sports Toys",
        "101730":
          "101730 - Mom & Baby/Toys/Sports & Outdoor Play/Flying Toys, Kites & Wind Spinners",
        "101731": "101731 - Mom & Baby/Toys/Sports & Outdoor Play/Others",
        "101732": "101732 - Mom & Baby/Toys/Educational Toys/Arts & Crafts",
        "101733": "101733 - Mom & Baby/Toys/Educational Toys/Math Toys",
        "101734":
          "101734 - Mom & Baby/Toys/Educational Toys/Science & Tech Toys",
        "101735": "101735 - Mom & Baby/Toys/Educational Toys/Shape Sorters",
        "101736": "101736 - Mom & Baby/Toys/Educational Toys/Puzzles",
        "101737": "101737 - Mom & Baby/Toys/Educational Toys/Musical Toys",
        "101738":
          "101738 - Mom & Baby/Toys/Educational Toys/Toy Tablets & Computers",
        "101739": "101739 - Mom & Baby/Toys/Educational Toys/Others",
        "101740":
          "101740 - Baby & Kids Fashion/Baby Clothes/Bottoms/Pants & Leggings",
        "101741": "101741 - Baby & Kids Fashion/Baby Clothes/Bottoms/Shorts",
        "101742": "101742 - Baby & Kids Fashion/Baby Clothes/Bottoms/Skirts",
        "101743": "101743 - Baby & Kids Fashion/Baby Clothes/Bottoms/Others",
        "101744":
          "101744 - Baby & Kids Fashion/Baby & Kids Accessories/Bags & Luggage/Backpacks",
        "101745":
          "101745 - Baby & Kids Fashion/Baby & Kids Accessories/Bags & Luggage/Crossbody & Shoulder Bags",
        "101746":
          "101746 - Baby & Kids Fashion/Baby & Kids Accessories/Bags & Luggage/Luggage & Trolley Bags",
        "101747":
          "101747 - Baby & Kids Fashion/Baby & Kids Accessories/Bags & Luggage/Wallets",
        "101748":
          "101748 - Baby & Kids Fashion/Baby & Kids Accessories/Bags & Luggage/Others",
        "101749":
          "101749 - Baby & Kids Fashion/Baby & Kids Accessories/Jewelry/Bracelets",
        "101750":
          "101750 - Baby & Kids Fashion/Baby & Kids Accessories/Jewelry/Earrings",
        "101751":
          "101751 - Baby & Kids Fashion/Baby & Kids Accessories/Jewelry/Necklaces",
        "101752":
          "101752 - Baby & Kids Fashion/Baby & Kids Accessories/Jewelry/Rings",
        "101753":
          "101753 - Baby & Kids Fashion/Baby & Kids Accessories/Jewelry/Others",
        "101754":
          "101754 - Baby & Kids Fashion/Baby & Kids Accessories/Rain Gear/Raincoats",
        "101755":
          "101755 - Baby & Kids Fashion/Baby & Kids Accessories/Rain Gear/Rain Boots",
        "101756":
          "101756 - Baby & Kids Fashion/Baby & Kids Accessories/Rain Gear/Others",
        "101757": "101757 - Baby & Kids Fashion/Boy Clothes/Tops/T-shirts",
        "101758": "101758 - Baby & Kids Fashion/Boy Clothes/Tops/Polo Shirts",
        "101759": "101759 - Baby & Kids Fashion/Boy Clothes/Tops/Shirts",
        "101760": "101760 - Baby & Kids Fashion/Boy Clothes/Tops/Others",
        "101761":
          "101761 - Baby & Kids Fashion/Boy Clothes/Outerwear/Regular Jackets & Coats",
        "101762":
          "101762 - Baby & Kids Fashion/Boy Clothes/Outerwear/Winter Outerwear",
        "101763": "101763 - Baby & Kids Fashion/Boy Clothes/Outerwear/Vests",
        "101764":
          "101764 - Baby & Kids Fashion/Boy Clothes/Outerwear/Sweaters & Cardigans",
        "101765": "101765 - Baby & Kids Fashion/Boy Clothes/Outerwear/Blazers",
        "101766": "101766 - Baby & Kids Fashion/Boy Clothes/Outerwear/Hoodies",
        "101767": "101767 - Baby & Kids Fashion/Boy Clothes/Outerwear/Others",
        "101768": "101768 - Baby & Kids Fashion/Boy Clothes/Bottoms/Jeans",
        "101769": "101769 - Baby & Kids Fashion/Boy Clothes/Bottoms/Pants",
        "101770": "101770 - Baby & Kids Fashion/Boy Clothes/Bottoms/Shorts",
        "101771": "101771 - Baby & Kids Fashion/Boy Clothes/Bottoms/Overalls",
        "101772": "101772 - Baby & Kids Fashion/Boy Clothes/Bottoms/Others",
        "101773": "101773 - Baby & Kids Fashion/Girl Clothes/Tops/T-shirts",
        "101774": "101774 - Baby & Kids Fashion/Girl Clothes/Tops/Polo Shirts",
        "101775":
          "101775 - Baby & Kids Fashion/Girl Clothes/Tops/Shirts & Blouses",
        "101776": "101776 - Baby & Kids Fashion/Girl Clothes/Tops/Others",
        "101777":
          "101777 - Baby & Kids Fashion/Girl Clothes/Outerwear/Regular Jackets & Coats",
        "101778":
          "101778 - Baby & Kids Fashion/Girl Clothes/Outerwear/Winter Outerwear",
        "101779": "101779 - Baby & Kids Fashion/Girl Clothes/Outerwear/Vests",
        "101780":
          "101780 - Baby & Kids Fashion/Girl Clothes/Outerwear/Sweaters & Cardigans",
        "101781": "101781 - Baby & Kids Fashion/Girl Clothes/Outerwear/Blazers",
        "101782": "101782 - Baby & Kids Fashion/Girl Clothes/Outerwear/Hoodies",
        "101783": "101783 - Baby & Kids Fashion/Girl Clothes/Outerwear/Others",
        "101784": "101784 - Baby & Kids Fashion/Girl Clothes/Bottoms/Jeans",
        "101785": "101785 - Baby & Kids Fashion/Girl Clothes/Bottoms/Pants",
        "101786": "101786 - Baby & Kids Fashion/Girl Clothes/Bottoms/Shorts",
        "101787": "101787 - Baby & Kids Fashion/Girl Clothes/Bottoms/Skirts",
        "101788": "101788 - Baby & Kids Fashion/Girl Clothes/Bottoms/Leggings",
        "101789": "101789 - Baby & Kids Fashion/Girl Clothes/Bottoms/Others",
        "101790":
          "101790 - Home & Living/Bathrooms/Towels & Bathrobes/Bath Towels",
        "101791":
          "101791 - Home & Living/Bathrooms/Towels & Bathrobes/Face & Hand Towels",
        "101792":
          "101792 - Home & Living/Bathrooms/Towels & Bathrobes/Bath Robes",
        "101793": "101793 - Home & Living/Bathrooms/Towels & Bathrobes/Others",
        "101794":
          "101794 - Home & Living/Tools & Home Improvement/Tools/Tool Boxes",
        "101795":
          "101795 - Home & Living/Tools & Home Improvement/Tools/Measuring Tapes",
        "101796":
          "101796 - Home & Living/Tools & Home Improvement/Tools/Spanner Sets",
        "101797":
          "101797 - Home & Living/Tools & Home Improvement/Tools/Hammers",
        "101798":
          "101798 - Home & Living/Tools & Home Improvement/Tools/Pliers",
        "101799":
          "101799 - Home & Living/Tools & Home Improvement/Tools/Nails, Screws & Fasteners",
        "101800":
          "101800 - Home & Living/Tools & Home Improvement/Tools/Drills, Screwdrivers & Accessories",
        "101801":
          "101801 - Home & Living/Tools & Home Improvement/Tools/Saws, Cut-off Machines & Grinders",
        "101802":
          "101802 - Home & Living/Tools & Home Improvement/Tools/Pressure Washers",
        "101803":
          "101803 - Home & Living/Tools & Home Improvement/Tools/Power Generators",
        "101804":
          "101804 - Home & Living/Tools & Home Improvement/Tools/Electrical Testers & Multimeters",
        "101805":
          "101805 - Home & Living/Tools & Home Improvement/Tools/Levels & Measuring Wheels",
        "101806":
          "101806 - Home & Living/Tools & Home Improvement/Tools/Rangefinders",
        "101807":
          "101807 - Home & Living/Tools & Home Improvement/Tools/Air Compressors",
        "101808":
          "101808 - Home & Living/Tools & Home Improvement/Tools/Sandpaper, Power Sanders & Accessories",
        "101809":
          "101809 - Home & Living/Tools & Home Improvement/Tools/Power Welding Tools",
        "101810":
          "101810 - Home & Living/Tools & Home Improvement/Tools/Blowers",
        "101811":
          "101811 - Home & Living/Tools & Home Improvement/Tools/Others",
        "101812":
          "101812 - Home & Living/Home Care Supplies/Laundry Care/Fabric Fragrances",
        "101813":
          "101813 - Home & Living/Home Care Supplies/Laundry Care/Fabric Conditioners & Softeners",
        "101814":
          "101814 - Home & Living/Home Care Supplies/Laundry Care/Detergents",
        "101815":
          "101815 - Home & Living/Home Care Supplies/Laundry Care/Others",
        "101816":
          "101816 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fishing/Fishing Hooks",
        "101817":
          "101817 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fishing/Fishing Rods & Reels",
        "101818":
          "101818 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fishing/Fish Finders",
        "101819":
          "101819 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fishing/Fishing Lines",
        "101820":
          "101820 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fishing/Lures & Baits",
        "101821":
          "101821 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fishing/Fishing Bags",
        "101822":
          "101822 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fishing/Fishing Nets",
        "101823":
          "101823 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fishing/Others",
        "101824":
          "101824 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Cycling/Bicycles",
        "101825":
          "101825 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Cycling/Bicycle Parts & Accessories",
        "101826":
          "101826 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Cycling/Cycling Helmets",
        "101827":
          "101827 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Cycling/Others",
        "101828":
          "101828 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Camping & Hiking/Camping Cookware",
        "101829":
          "101829 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Camping & Hiking/Camping Lamps & Flashlights",
        "101830":
          "101830 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Camping & Hiking/Tents & Tent Accessories",
        "101831":
          "101831 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Camping & Hiking/Sleeping Bags",
        "101832":
          "101832 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Camping & Hiking/Binoculars, Monoculars & Telescopes",
        "101833":
          "101833 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Camping & Hiking/Compasses",
        "101834":
          "101834 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Camping & Hiking/Camping Knives & Survival Kits",
        "101835":
          "101835 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Camping & Hiking/Hammocks",
        "101836":
          "101836 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Camping & Hiking/Hiking Sticks",
        "101837":
          "101837 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Camping & Hiking/Picnic Mats & Baskets",
        "101838":
          "101838 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Camping & Hiking/Others",
        "101839":
          "101839 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Boardsports/Skateboards & Roller Blades",
        "101840":
          "101840 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Boardsports/Scooters & Unicycles",
        "101841":
          "101841 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Boardsports/Segways & Hoverboards",
        "101842":
          "101842 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Boardsports/Helmets & Protective Gears",
        "101843":
          "101843 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Boardsports/Others",
        "101844":
          "101844 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Archery/Bows",
        "101845":
          "101845 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Archery/Arrows",
        "101846":
          "101846 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Archery/Others",
        "101847":
          "101847 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Soccer, Futsal & Sepak Takraw/Balls",
        "101848":
          "101848 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Soccer, Futsal & Sepak Takraw/Goalkeeper Gloves & Shin Guards",
        "101849":
          "101849 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Soccer, Futsal & Sepak Takraw/Goal Posts & Nets",
        "101850":
          "101850 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Soccer, Futsal & Sepak Takraw/Others",
        "101851":
          "101851 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Basketball/Basket Balls",
        "101852":
          "101852 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Basketball/Basketball Rings",
        "101853":
          "101853 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Basketball/Others",
        "101854":
          "101854 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Volleyball/Volley Balls",
        "101855":
          "101855 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Volleyball/Volley Nets",
        "101856":
          "101856 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Volleyball/Others",
        "101857":
          "101857 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Badminton/Badminton Rackets",
        "101858":
          "101858 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Badminton/Shuttlecocks",
        "101859":
          "101859 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Badminton/Badminton Nets",
        "101860":
          "101860 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Badminton/Others",
        "101861":
          "101861 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Tennis/Tennis Rackets",
        "101862":
          "101862 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Tennis/Tennis Balls",
        "101863":
          "101863 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Tennis/Tennis Nets",
        "101864":
          "101864 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Tennis/Others",
        "101866":
          "101866 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Table Tennis/Table Tennis Bats",
        "101867":
          "101867 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Table Tennis/Table Tennis Nets",
        "101868":
          "101868 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Table Tennis/Others",
        "101869":
          "101869 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Boxing & Martial Arts/Punching Bags & Paddings",
        "101870":
          "101870 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Boxing & Martial Arts/Martial Arts Belts",
        "101871":
          "101871 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Boxing & Martial Arts/Gloves, Wraps & Helmets",
        "101872":
          "101872 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Boxing & Martial Arts/Others",
        "101873":
          "101873 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Golf/Golf Balls",
        "101874":
          "101874 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Golf/Golf Clubs",
        "101875":
          "101875 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Golf/Gears &Training Equipment",
        "101876":
          "101876 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Golf/Golf Bags & Organizers",
        "101877":
          "101877 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Golf/Others",
        "101878":
          "101878 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Swimming & Diving/Swimming Caps",
        "101879":
          "101879 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Swimming & Diving/Swimming Goggles",
        "101880":
          "101880 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Swimming & Diving/Swimming Boards",
        "101881":
          "101881 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Swimming & Diving/Diving Flashlights",
        "101882":
          "101882 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Swimming & Diving/Diving Masks",
        "101883":
          "101883 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Swimming & Diving/Flippers",
        "101884":
          "101884 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Swimming & Diving/Life Jackets",
        "101885":
          "101885 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Swimming & Diving/Others",
        "101886":
          "101886 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Yoga & Pilates/Yoga Mats",
        "101887":
          "101887 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Yoga & Pilates/Yoga Blocks, Rings & Foam Rollers",
        "101888":
          "101888 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Yoga & Pilates/Resistance Bands",
        "101889":
          "101889 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Yoga & Pilates/Others",
        "101890":
          "101890 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fitness Equipment/Weight Training",
        "101891":
          "101891 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fitness Equipment/Skipping Ropes",
        "101892":
          "101892 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fitness Equipment/Gym Balls",
        "101893":
          "101893 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fitness Equipment/Fitness Machines",
        "101894":
          "101894 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fitness Equipment/Ab Rollers",
        "101895":
          "101895 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fitness Equipment/Pull Up & Push Up Bars",
        "101896":
          "101896 - Sports & Outdoors/Sports & Outdoor Recreation Equipments/Fitness Equipment/Others",
        "101897":
          "101897 - Sports & Outdoors/Sports & Outdoor Apparels/Swimming Attire/Swimming Trunks",
        "101898":
          "101898 - Sports & Outdoors/Sports & Outdoor Apparels/Swimming Attire/Bikini Sets",
        "101899":
          "101899 - Sports & Outdoors/Sports & Outdoor Apparels/Swimming Attire/One-Piece Swimsuits",
        "101900":
          "101900 - Sports & Outdoors/Sports & Outdoor Apparels/Swimming Attire/Rash Guards",
        "101901":
          "101901 - Sports & Outdoors/Sports & Outdoor Apparels/Swimming Attire/Others",
        "101902":
          "101902 - Automobiles/Automobile Spare Parts/Engine Parts/Pistons",
        "101903":
          "101903 - Automobiles/Automobile Spare Parts/Engine Parts/Air Filters",
        "101904":
          "101904 - Automobiles/Automobile Spare Parts/Engine Parts/Oil Filters",
        "101905":
          "101905 - Automobiles/Automobile Spare Parts/Engine Parts/Others",
        "101907":
          "101907 - Automobiles/Automobile Spare Parts/Electronics/Batteries & Accessories",
        "101908":
          "101908 - Automobiles/Automobile Spare Parts/Electronics/Lighting",
        "101909":
          "101909 - Automobiles/Automobile Spare Parts/Electronics/Others",
        "101912":
          "101912 - Automobiles/Automotive Oils & Lubes/Automotive Fluids/Others",
        "101913":
          "101913 - Motorcycles/Motorcycle Spare Parts/Ignition & Engine Parts/Radiators",
        "101914":
          "101914 - Motorcycles/Motorcycle Spare Parts/Ignition & Engine Parts/Pistons",
        "101915":
          "101915 - Motorcycles/Motorcycle Spare Parts/Ignition & Engine Parts/CDI & ECU",
        "101916":
          "101916 - Motorcycles/Motorcycle Spare Parts/Ignition & Engine Parts/Spark Plugs",
        "101917":
          "101917 - Motorcycles/Motorcycle Spare Parts/Ignition & Engine Parts/Ignition Coils",
        "101918":
          "101918 - Motorcycles/Motorcycle Spare Parts/Ignition & Engine Parts/V-Belts",
        "101919":
          "101919 - Motorcycles/Motorcycle Spare Parts/Ignition & Engine Parts/Air Filters",
        "101920":
          "101920 - Motorcycles/Motorcycle Spare Parts/Ignition & Engine Parts/Others",
        "101921":
          "101921 - Motorcycles/Motorcycle Spare Parts/Drivetrain, Transmission & Clutches/Chains & Gears",
        "101922":
          "101922 - Motorcycles/Motorcycle Spare Parts/Drivetrain, Transmission & Clutches/Clutches",
        "101923":
          "101923 - Motorcycles/Motorcycle Spare Parts/Drivetrain, Transmission & Clutches/Bearings",
        "101924":
          "101924 - Motorcycles/Motorcycle Spare Parts/Drivetrain, Transmission & Clutches/Others",
        "101925":
          "101925 - Pets/Pet Accessories/Toys/Dog & Cat Toys/Sticks & Chasers",
        "101926":
          "101926 - Pets/Pet Accessories/Toys/Dog & Cat Toys/Chew, Bones & Balls",
        "101927": "101927 - Pets/Pet Accessories/Toys/Dog & Cat Toys/Frisbees",
        "101928": "101928 - Pets/Pet Accessories/Toys/Dog & Cat Toys/Others",
        "101929":
          "101929 - Automobiles/Automobile Spare Parts/Electronics/Ignition/Ignition Coils",
        "101930":
          "101930 - Automobiles/Automobile Spare Parts/Electronics/Ignition/Spark Plugs",
        "101931":
          "101931 - Automobiles/Automobile Spare Parts/Electronics/Ignition/Others",
        "101933": "101933 - Computers & Accessories/Monitors",
        "101937": "101937 - Computers & Accessories/Softwares",
        "101942": "101942 - Computers & Accessories/Laptops",
        "101943": "101943 - Computers & Accessories/Others",
        "101944":
          "101944 - Computers & Accessories/Desktop Computers/Desktop PC",
        "101945": "101945 - Computers & Accessories/Desktop Computers/Mini PC",
        "101946":
          "101946 - Computers & Accessories/Desktop Computers/Server PC",
        "101947":
          "101947 - Computers & Accessories/Desktop Computers/All-in-One Desktops",
        "101948": "101948 - Computers & Accessories/Desktop Computers/Others",
        "101949":
          "101949 - Computers & Accessories/Desktop & Laptop Components/Fans & Heatsinks",
        "101950":
          "101950 - Computers & Accessories/Desktop & Laptop Components/Processors",
        "101951":
          "101951 - Computers & Accessories/Desktop & Laptop Components/Motherboards",
        "101952":
          "101952 - Computers & Accessories/Desktop & Laptop Components/Graphics Cards",
        "101953":
          "101953 - Computers & Accessories/Desktop & Laptop Components/Thermal Paste",
        "101954":
          "101954 - Computers & Accessories/Desktop & Laptop Components/Power Supply Units",
        "101955":
          "101955 - Computers & Accessories/Desktop & Laptop Components/RAM",
        "101956":
          "101956 - Computers & Accessories/Desktop & Laptop Components/UPS & Stabilizers",
        "101957":
          "101957 - Computers & Accessories/Desktop & Laptop Components/PC Cases",
        "101958":
          "101958 - Computers & Accessories/Desktop & Laptop Components/Optical Drives",
        "101959":
          "101959 - Computers & Accessories/Desktop & Laptop Components/Sound Cards",
        "101960":
          "101960 - Computers & Accessories/Desktop & Laptop Components/Others",
        "101961": "101961 - Computers & Accessories/Data Storage/Hard Drives",
        "101962": "101962 - Computers & Accessories/Data Storage/SSD",
        "101963":
          "101963 - Computers & Accessories/Data Storage/Network Attached Storage (NAS)",
        "101964":
          "101964 - Computers & Accessories/Data Storage/Flash Drives & OTG",
        "101965":
          "101965 - Computers & Accessories/Data Storage/Hard Disk Casings & Dockings",
        "101966": "101966 - Computers & Accessories/Data Storage/Compact Discs",
        "101967": "101967 - Computers & Accessories/Data Storage/Others",
        "101968":
          "101968 - Computers & Accessories/Network Components/Modems & Wireless Routers",
        "101969":
          "101969 - Computers & Accessories/Network Components/Repeaters",
        "101970":
          "101970 - Computers & Accessories/Network Components/Wireless Adapters & Network Cards",
        "101971":
          "101971 - Computers & Accessories/Network Components/Powerline Adapters",
        "101972":
          "101972 - Computers & Accessories/Network Components/Network Switches & PoE",
        "101973":
          "101973 - Computers & Accessories/Network Components/Network Cables & Connectors",
        "101974":
          "101974 - Computers & Accessories/Network Components/KVM Switches",
        "101975":
          "101975 - Computers & Accessories/Network Components/Print Servers",
        "101976": "101976 - Computers & Accessories/Network Components/Others",
        "101977":
          "101977 - Computers & Accessories/Office Equipment/Typewriters",
        "101978":
          "101978 - Computers & Accessories/Office Equipment/Absence Machines",
        "101979":
          "101979 - Computers & Accessories/Office Equipment/Paper Shredders",
        "101980":
          "101980 - Computers & Accessories/Office Equipment/Money Counters",
        "101981": "101981 - Computers & Accessories/Office Equipment/Others",
        "101982":
          "101982 - Computers & Accessories/Printers & Scanners/Printers, Scanners & Photocopy Machines",
        "101983":
          "101983 - Computers & Accessories/Printers & Scanners/Thermal & Barcode Printers",
        "101984":
          "101984 - Computers & Accessories/Printers & Scanners/Inks & Toners",
        "101985":
          "101985 - Computers & Accessories/Printers & Scanners/3D Printers",
        "101986": "101986 - Computers & Accessories/Printers & Scanners/Others",
        "101987":
          "101987 - Computers & Accessories/Peripherals & Accessories/USB Hubs & Card Readers",
        "101988":
          "101988 - Computers & Accessories/Peripherals & Accessories/Webcams",
        "101989":
          "101989 - Computers & Accessories/Peripherals & Accessories/Laptop Skins & Covers",
        "101990":
          "101990 - Computers & Accessories/Peripherals & Accessories/Cooling Pads",
        "101991":
          "101991 - Computers & Accessories/Peripherals & Accessories/Laptop Stands & Foldable Laptop Desks",
        "101992":
          "101992 - Computers & Accessories/Peripherals & Accessories/Keyboard & Trackpad Covers",
        "101993":
          "101993 - Computers & Accessories/Peripherals & Accessories/Laptop Batteries",
        "101994":
          "101994 - Computers & Accessories/Peripherals & Accessories/Laptop Chargers & Adaptors",
        "101995":
          "101995 - Computers & Accessories/Peripherals & Accessories/Video Conference Devices",
        "101996":
          "101996 - Computers & Accessories/Peripherals & Accessories/Mouse Pads",
        "101997":
          "101997 - Computers & Accessories/Peripherals & Accessories/Others",
        "101998": "101998 - Computers & Accessories/Keyboards & Mice/Mice",
        "101999": "101999 - Computers & Accessories/Keyboards & Mice/Keyboards",
        "102000":
          "102000 - Computers & Accessories/Keyboards & Mice/Drawing Tablets",
        "102001": "102001 - Computers & Accessories/Keyboards & Mice/Others",
        "102003": "102003 - Beauty/Bath & Body Care/Body Wash & Soap",
        "102004": "102004 - Beauty/Bath & Body Care/Body Scrub & Peel",
        "102005": "102005 - Beauty/Bath & Body Care/Body Masks",
        "102006": "102006 - Beauty/Bath & Body Care/Body Oil",
        "102007":
          "102007 - Beauty/Bath & Body Care/Body Cream, Lotion & Butter",
        "102008": "102008 - Beauty/Bath & Body Care/Body Deodorants",
        "102009": "102009 - Beauty/Bath & Body Care/Massage Oil",
        "102010": "102010 - Beauty/Bath & Body Care/Hair Removal Cream & Wax",
        "102012": "102012 - Beauty/Bath & Body Care/Breast Care",
        "102013": "102013 - Beauty/Bath & Body Care/Others",
        "102014":
          "102014 - Beauty/Bath & Body Care/Sun Care/Body Sunscreen & After Sun",
        "102015":
          "102015 - Beauty/Bath & Body Care/Sun Care/Tanning Oil & Self Tanners",
        "102016": "102016 - Beauty/Bath & Body Care/Sun Care/Others",
        "102018":
          "102018 - Hobbies & Collections/Musical Instruments & Accessories/String Instruments/Ukuleles",
        "102019":
          "102019 - Hobbies & Collections/Musical Instruments & Accessories/String Instruments/Guitars & Bass Guitars",
        "102020":
          "102020 - Hobbies & Collections/Musical Instruments & Accessories/String Instruments/Others",
        "102021":
          "102021 - Cameras & Drones/Camera Accessories/Flash Accessories/Flash Triggers",
        "102022":
          "102022 - Cameras & Drones/Camera Accessories/Flash Accessories/Flash Diffusers",
        "102023":
          "102023 - Cameras & Drones/Camera Accessories/Flash Accessories/Others",
        "102024":
          "102024 - Food & Beverages/Food Staples/Canned Food/Canned Meat",
        "102025":
          "102025 - Food & Beverages/Food Staples/Canned Food/Canned Seafood",
        "102026":
          "102026 - Food & Beverages/Food Staples/Canned Food/Canned Vegetables",
        "102027":
          "102027 - Food & Beverages/Food Staples/Canned Food/Canned Soup",
        "102028": "102028 - Food & Beverages/Food Staples/Canned Food/Others",
        "102029":
          "102029 - Beauty/Hand, Foot & Nail Care/Nail Care/Nail Polish",
        "102030":
          "102030 - Beauty/Hand, Foot & Nail Care/Nail Care/Nail Polish Remover",
        "102031":
          "102031 - Beauty/Hand, Foot & Nail Care/Nail Care/Nail Treatment",
        "102032":
          "102032 - Beauty/Hand, Foot & Nail Care/Nail Care/Artificial Nail",
        "102033":
          "102033 - Beauty/Hand, Foot & Nail Care/Nail Care/Nail Art & Sticker",
        "102034":
          "102034 - Beauty/Hand, Foot & Nail Care/Nail Care/Manicure Tools & Devices",
        "102035": "102035 - Beauty/Hand, Foot & Nail Care/Nail Care/Others",
        "102036": "102036 - Tickets, Vouchers & Services/Services/Design",
        "102071":
          "102071 - Food & Beverages/Fresh & Frozen Food/Vegetables/Cabbage & Brussel Sprouts",
        "102072":
          "102072 - Food & Beverages/Fresh & Frozen Food/Vegetables/Fresh Herbs",
        "102073":
          "102073 - Food & Beverages/Fresh & Frozen Food/Vegetables/Leafy Vegetables",
        "102074":
          "102074 - Food & Beverages/Fresh & Frozen Food/Vegetables/Brinjals, Gourds & Squashes",
        "102075":
          "102075 - Food & Beverages/Fresh & Frozen Food/Vegetables/Broccoli & Cauliflowers",
        "102076":
          "102076 - Food & Beverages/Fresh & Frozen Food/Vegetables/Chilli & Capsicum",
        "102077":
          "102077 - Food & Beverages/Fresh & Frozen Food/Vegetables/Garlic, Ginger & Onion ",
        "102078":
          "102078 - Food & Beverages/Fresh & Frozen Food/Vegetables/Peas, Beans, Nuts & Corn",
        "102079":
          "102079 - Food & Beverages/Fresh & Frozen Food/Vegetables/Root & Stem Vegetables",
        "102080":
          "102080 - Food & Beverages/Fresh & Frozen Food/Vegetables/Sprouts",
        "102081":
          "102081 - Food & Beverages/Fresh & Frozen Food/Vegetables/Salads Vegetables ",
        "102082":
          "102082 - Food & Beverages/Fresh & Frozen Food/Vegetables/Pre-cut & Assorted Vegetable Set",
        "102083":
          "102083 - Food & Beverages/Fresh & Frozen Food/Vegetables/Others",
        "102084":
          "102084 - Food & Beverages/Fresh & Frozen Food/Fruits/Apples & Pears",
        "102085":
          "102085 - Food & Beverages/Fresh & Frozen Food/Fruits/Bananas",
        "102086":
          "102086 - Food & Beverages/Fresh & Frozen Food/Fruits/Berries",
        "102087": "102087 - Food & Beverages/Fresh & Frozen Food/Fruits/Citrus",
        "102088": "102088 - Food & Beverages/Fresh & Frozen Food/Fruits/Grapes",
        "102089": "102089 - Food & Beverages/Fresh & Frozen Food/Fruits/Melons",
        "102090":
          "102090 - Food & Beverages/Fresh & Frozen Food/Fruits/Stonefruits",
        "102091":
          "102091 - Food & Beverages/Fresh & Frozen Food/Fruits/Tropical & Exotic Fruits",
        "102092":
          "102092 - Food & Beverages/Fresh & Frozen Food/Fruits/Pre-cut & Assorted Fruit Set",
        "102093": "102093 - Food & Beverages/Fresh & Frozen Food/Fruits/Others",
      },
      errorInfo: { timeout: "系统业务繁忙，请稍后在试！" },
      lazada_Ventures: { SG: "SG", TH: "TH", PH: "PH", ID: "ID", VN: "VN" },
    },
    siteData: {
      userName: "",
      phoneNumber: "",
      email: "",
      addr: "",
      siteDomain: "",
      caseNo: "",
      promote: "",
      personalQr: "",
      enterpriseQr: "",
      homeLogo: "",
      loginLogo: "",
      designLogo: "",
      siteName: "",
      nickName: "",
      homeLogoUrl: "",
      loginLogoUrl: "",
      designLogoUrl: "",
      personalQrUrl: "",
      enterpriseQrUrl: "",
      sellerCnt: 0,
    },
  },
  mutations: {
    SET_NGINX: (state, nginx) => {
      state.nginx = nginx
    },
    SET_DICT_DATA: (state, dictData) => {
      state.dictData = dictData

      for (let key in dictData) {
        state.dictMap[key] = {}
        for (let value of dictData[key]) {
          state.dictMap[key][value.value] = value.label
          if (
            key == "lazada_status" ||
            key == "tiktok_status" ||
            key == "shopee_status"
          ) {
            state.dictMap[key][value.value] =
              value.label + (value.remark ? "/" + value.remark : "")
          }
        }
      }
    },
    SET_SITE_DATA: (state, siteData) => {
      state.siteData = siteData
    },
  },
  actions: {
    getNginx({ commit }, val) {
      getNginx()
        .then((res) => {
          commit("SET_NGINX", res.data.nginx)
        })
        .catch()
    },
  },
}

export default setting
