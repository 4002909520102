<template>
  <div class="plat-nav-bm-categories">
    <ul class="plat-nav-list">
      <li class="presentation" tabindex="-1">
        <a role="button" @click="handleJump('/rank')">最新排行</a>
      </li>
      <li class="presentation" tabindex="-1">
        <a role="button" hotrep="hp.header.product" href="/">首页</a>
      </li>
      <li class="product-menu presentation" tabindex="-1">
        <a
          role="button"
          hotrep="hp.header.solution"
          href="/product_type?productType=01&remark="
          @mouseenter="preloadData('1')"
          >定制产品</a
        >
        <div
          class="plat-nav-bubble plat-nav-bubble-top plat-p-all"
          style="left: 16px; padding-left: 0px; transition: transform 0.3s ease 0s; transform-origin: center top; transform: scaleY(1);"
        >
          <div
            class="plat-nav-bubble-inner plat-nav-scrollbar"
            style="left: 203px;"
          >
            <div class="plat-nav-dropdown-menu-all">
              <div class="menu-list-all">
                <div class="menu-area">
                  <div class="index-custom-tips">
                    <div class="index-custom-container">
                      <div
                        v-if="index > 0"
                        v-for="(record, index) in typeIdTree"
                        :key="index"
                        class="container-item-wrap"
                        @mouseenter="
                          loadData(record.typeId, record.typeName, '01', '')
                        "
                      >
                        <div v-if="record.proNum > 0" class="container-left">
                          <a @click="handleSelectType(record.typeId, '01', '')"
                            ><span class="mr10 container-left-label">{{
                              record.typeName
                            }}</span
                            ><i class="el-icon-arrow-right arrow-class"></i
                          ></a>
                        </div>
                      </div>
                    </div>
                    <div class="type-name">
                      <div class="type-name-text">{{ typeName }}</div>
                      <div
                        class="type-name-more"
                        @click="handleSelectType(typeId, '01', '')"
                      >
                        更多
                      </div>
                    </div>
                    <div class="product-list">
                      <div class="new-cat-top">
                        <div v-for="type in childrenTree" :key="type.typeId">
                          <a
                            @mouseenter="
                              loadData(type.typeId, type.typeName, '01', '')
                            "
                            @click="handleSelectType(type.typeId, '01', '')"
                            class="sub_catgory p-cat"
                            >{{ type.typeName }}</a
                          >
                        </div>
                      </div>
                      <template v-for="(item, index) in tableData">
                        <div class="pro-card">
                          <div class="pro-card-content-detail">
                            <div
                              @mouseover="handleMouseOverProduct(item)"
                              @mouseout="handleMouseOutProduct(item)"
                              @click="showDetail(item)"
                              class="card-img"
                            >
                              <!-- item.thumb -->
                              <img
                                class="evenflow_scale"
                                :style="{ height: imageHeight + 'px' }"
                                :src="
                                  item.mainList.length > 0
                                    ? `${
                                        item.mainList[item.currentProduct]
                                          .picUrl
                                      }?x-oss-process=image/resize,m_fill,h_500,w_500`
                                    : ''
                                "
                                lazy
                              />
                            </div>
                            <div
                              :style="{
                                width: imageWidth + 'px',
                                top: imageWidth - 36 + 'px',
                              }"
                              :class="`sku-img-list tp-${index}`"
                            >
                              <div class="img-content">
                                <ul>
                                  <li
                                    :class="{
                                      'is-select':
                                        item.currentProduct === number,
                                    }"
                                    @mouseover="handleSelectSku(item, number)"
                                    v-for="(record, number) in item.mainList"
                                    :key="number"
                                    :style="{
                                      backgroundColor:
                                        dictMap['color'][record.skuName],
                                    }"
                                  ></li>
                                </ul>
                              </div>
                            </div>

                            <div
                              @mouseover="handleMouseOut(item)"
                              class="card-info"
                            >
                              <div class="info-div" v-show="item.showTitle">
                                <div class="detail">
                                  <div
                                    :title="item.proName"
                                    class="product-title"
                                  >
                                    {{ item.proName }}
                                  </div>
                                  <div class="text" style="line-height: 28px">
                                    <el-popover
                                      placement="bottom-start"
                                      width="322"
                                      trigger="hover"
                                    >
                                      <div
                                        class="price"
                                        slot="reference"
                                        style="display: inline-block; position: relative"
                                      >
                                        <span class="unit">￥</span
                                        >{{ item.price }}
                                        <i
                                          style="margin: 0 0 0 2px; font-size: 12px"
                                          class="el-icon-arrow-down"
                                        ></i>
                                        <i
                                          style="
                                margin: 0 0 0 2px;
                                font-size: 12px;
                                display: none;
                              "
                                          class="el-icon-arrow-up"
                                        ></i>
                                      </div>
                                      <div>
                                        <div class="prod_drop_con">
                                          <div
                                            v-for="(price, index) in item
                                              .skuList[0].priceList"
                                            :key="index"
                                            class="prod_drop_item"
                                          >
                                            <div class="prod_drop_desc">
                                              <span
                                                style="color: rgb(51, 51, 51)"
                                                >¥{{ price.price }}</span
                                              >
                                              <span
                                                >{{ price.lowNum }}-{{
                                                  price.highNum
                                                }}件</span
                                              >
                                            </div>
                                            <div
                                              v-if="
                                                index !==
                                                  item.skuList[0].priceList
                                                    .length -
                                                    1
                                              "
                                              class="el-divider el-divider--vertical"
                                            >
                                              <!---->
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </el-popover>

                                    <span class="date">{{
                                      dictMap["prod_days"][item.prodDays]
                                    }}</span>
                                  </div>
                                  <div v-if="false" class="product-tag">
                                    <div>
                                      {{
                                        dictMap["product_type"]
                                          ? dictMap["product_type"][
                                              item.productType
                                            ]
                                          : ""
                                      }}
                                    </div>
                                  </div>
                                  <div class="factory text">
                                    平均发货时效：{{ item.avgDelivery }}小时
                                  </div>
                                </div>
                              </div>
                              <div class="btn-div" v-show="!item.showTitle">
                                <div class="pa c153 price-gradient-box">
                                  <div class="relative">
                                    <div
                                      class="flex-row flex-x-full weight-box"
                                      style="margin-bottom: 8px"
                                    >
                                      <div class="w50">
                                        {{ Math.round(Number(item.weight)) }}g
                                      </div>
                                      <div class="w50">
                                        {{ item.pdMaterial }}
                                      </div>
                                    </div>
                                    <div class="pl10 mt15">
                                      80%平均时效：{{ item.mostDelivery }}小时
                                    </div>
                                    <div class="pl10 mt5">
                                      平均发货时效：{{ item.avgDelivery }}小时
                                    </div>
                                    <div class="pl10 mt5 pb10">
                                      生产商承诺发货：{{
                                        item.agreeDelivery
                                      }}小时
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="item.remark"
                              class="product-type"
                              style="background: rgb(255, 144, 0)"
                              :style="{
                                left:
                                  form.productType == '02' ? '34px' : '14px',
                              }"
                            >
                              {{ item.remark }}
                            </div>
                            <div class="vip-type">
                              <img :src="getVipType(item.vipType)" />
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="product-menu presentation" tabindex="-1">
        <a
          role="button"
          hotrep="hp.header.solution"
          href="/product_type?productType=02&remark=1"
          @mouseenter="preloadData('2')"
          >主题产品
        </a>
        <div
          class="plat-nav-bubble plat-nav-bubble-top plat-p-all"
          style="left: 16px; padding-left: 0px; transition: transform 0.3s ease 0s; transform-origin: center top; transform: scaleY(1);"
        >
          <div
            class="plat-nav-bubble-inner plat-nav-scrollbar"
            style="left: 308px;"
          >
            <div class="plat-nav-dropdown-menu-all">
              <div class="menu-list-all">
                <div class="menu-area">
                  <div class="index-custom-tips">
                    <div class="index-custom-container">
                      <div
                        v-if="index > 0"
                        v-for="(record, index) in typeIdTreeTheme"
                        :key="index"
                        class="container-item-wrap"
                        @mouseenter="
                          loadData(record.typeId, record.typeName, '02', '1')
                        "
                      >
                        <div v-if="record.proNum > 0" class="container-left">
                          <a @click="handleSelectType(record.typeId, '02', '1')"
                            ><span class="mr10 container-left-label">{{
                              record.typeName
                            }}</span
                            ><i class="el-icon-arrow-right arrow-class"></i
                          ></a>
                        </div>
                      </div>
                    </div>
                    <div class="type-name">
                      <div class="type-name-text">{{ typeName }}</div>
                      <div
                        class="type-name-more"
                        @click="handleSelectType(typeId, '02', '1')"
                      >
                        更多
                      </div>
                    </div>
                    <div class="product-list">
                      <div class="new-cat-top">
                        <div v-for="type in childrenTree" :key="type.typeId">
                          <a
                            @mouseenter="
                              loadData(
                                type.typeId,
                                type.typeName,
                                '02',
                                '1'
                              )
                            "
                            @click="handleSelectType(type.typeId, '02', '1')"
                            class="sub_catgory p-cat"
                            >{{ type.typeName }}</a
                          >
                        </div>
                      </div>
                      <template v-for="(item, index) in tableData">
                        <div class="pro-card">
                          <div class="pro-card-content-detail">
                            <div
                              @mouseover="handleMouseOver(item)"
                              @mouseout="handleMouseOut(item)"
                              @click="showDetail(item)"
                              class="card-img"
                            >
                              <!-- item.thumb -->
                              <img
                                class="evenflow_scale"
                                :style="{ height: imageHeight + 'px' }"
                                :src="
                                  item.mainList.length > 0
                                    ? `${
                                        item.mainList[item.currentProduct]
                                          .picUrl
                                      }?x-oss-process=image/resize,m_fill,h_500,w_500`
                                    : ''
                                "
                                lazy
                              />
                            </div>
                            <div
                              :style="{
                                width: imageWidth + 'px',
                                top: imageWidth - 36 + 'px',
                              }"
                              :class="`sku-img-list tp-${index}`"
                            >
                              <div class="img-content">
                                <ul>
                                  <li
                                    :class="{
                                      'is-select':
                                        item.currentProduct === number,
                                    }"
                                    @mouseover="handleSelectSku(item, number)"
                                    v-for="(record, number) in item.mainList"
                                    :key="number"
                                    :style="{
                                      backgroundColor:
                                        dictMap['color'][record.skuName],
                                    }"
                                  ></li>
                                </ul>
                              </div>
                            </div>

                            <div
                              @mouseover="handleMouseOut(item)"
                              class="card-info"
                            >
                              <div class="info-div" v-show="item.showTitle">
                                <div class="detail">
                                  <div
                                    :title="item.proName"
                                    class="product-title"
                                  >
                                    {{ item.proName }}
                                  </div>
                                  <div class="text" style="line-height: 28px">
                                    <el-popover
                                      placement="bottom-start"
                                      width="322"
                                      trigger="hover"
                                    >
                                      <div
                                        class="price"
                                        slot="reference"
                                        style="display: inline-block; position: relative"
                                      >
                                        <span class="unit">￥</span
                                        >{{ item.price }}
                                        <i
                                          style="margin: 0 0 0 2px; font-size: 12px"
                                          class="el-icon-arrow-down"
                                        ></i>
                                        <i
                                          style="
                                margin: 0 0 0 2px;
                                font-size: 12px;
                                display: none;
                              "
                                          class="el-icon-arrow-up"
                                        ></i>
                                      </div>
                                      <div>
                                        <div class="prod_drop_con">
                                          <div
                                            v-for="(price, index) in item
                                              .skuList[0].priceList"
                                            :key="index"
                                            class="prod_drop_item"
                                          >
                                            <div class="prod_drop_desc">
                                              <span
                                                style="color: rgb(51, 51, 51)"
                                                >¥{{ price.price }}</span
                                              >
                                              <span
                                                >{{ price.lowNum }}-{{
                                                  price.highNum
                                                }}件</span
                                              >
                                            </div>
                                            <div
                                              v-if="
                                                index !==
                                                  item.skuList[0].priceList
                                                    .length -
                                                    1
                                              "
                                              class="el-divider el-divider--vertical"
                                            >
                                              <!---->
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </el-popover>

                                    <span class="date">{{
                                      dictMap["prod_days"][item.prodDays]
                                    }}</span>
                                  </div>
                                  <div v-if="false" class="product-tag">
                                    <div>
                                      {{
                                        dictMap["product_type"]
                                          ? dictMap["product_type"][
                                              item.productType
                                            ]
                                          : ""
                                      }}
                                    </div>
                                  </div>
                                  <div class="factory text">
                                    平均发货时效：{{ item.avgDelivery }}小时
                                  </div>
                                </div>
                              </div>
                              <div class="btn-div" v-show="!item.showTitle">
                                <div class="pa c153 price-gradient-box">
                                  <div class="relative">
                                    <div
                                      class="flex-row flex-x-full weight-box"
                                      style="margin-bottom: 8px"
                                    >
                                      <div class="w50">
                                        {{ Math.round(Number(item.weight)) }}g
                                      </div>
                                      <div class="w50">
                                        {{ item.pdMaterial }}
                                      </div>
                                    </div>
                                    <div class="pl10 mt15">
                                      80%平均时效：{{ item.mostDelivery }}小时
                                    </div>
                                    <div class="pl10 mt5">
                                      平均发货时效：{{ item.avgDelivery }}小时
                                    </div>
                                    <div class="pl10 mt5 pb10">
                                      生产商承诺发货：{{
                                        item.agreeDelivery
                                      }}小时
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="item.remark"
                              class="product-type"
                              style="background: rgb(255, 144, 0)"
                              :style="{
                                left:
                                  form.productType == '02' ? '34px' : '14px',
                              }"
                            >
                              {{ item.remark }}
                            </div>
                            <div class="vip-type">
                              <img :src="getVipType(item.vipType)" />
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="product-menu presentation" tabindex="-1">
        <a
          role="button"
          hotrep="hp.header.solution"
          href="/product_type?productType=02&remark=2"
          @mouseenter="preloadData('3')"
          >公版产品
        </a>
        <div
          class="plat-nav-bubble plat-nav-bubble-top plat-p-all"
          style="left: 16px; padding-left: 0px; transition: transform 0.3s ease 0s; transform-origin: center top; transform: scaleY(1);"
        >
          <div
            class="plat-nav-bubble-inner plat-nav-scrollbar"
            style="left: 405px;"
          >
            <div class="plat-nav-dropdown-menu-all">
              <div class="menu-list-all">
                <div class="menu-area">
                  <div class="index-custom-tips">
                    <div class="index-custom-container">
                      <div
                        v-if="index > 0"
                        v-for="(record, index) in typeIdTreeCommon"
                        :key="index"
                        class="container-item-wrap"
                        @mouseenter="
                          loadData(record.typeId, record.typeName, '02', 2)
                        "
                      >
                        <div v-if="record.proNum > 0" class="container-left">
                          <a @click="handleSelectType(record.typeId, '02', 2)"
                            ><span class="mr10 container-left-label">{{
                              record.typeName
                            }}</span
                            ><i class="el-icon-arrow-right arrow-class"></i
                          ></a>
                        </div>
                      </div>
                    </div>
                    <div class="type-name">
                      <div class="type-name-text">{{ typeName }}</div>
                      <div
                        class="type-name-more"
                        @click="handleSelectType(typeId, '02', 2)"
                      >
                        更多
                      </div>
                    </div>
                    <div class="product-list">
                      <div class="new-cat-top">
                        <div v-for="type in childrenTree" :key="type.typeId">
                          <a
                            @mouseenter="
                              loadData(
                                type.typeId,
                                type.typeName,
                                '02',
                                '2'
                              )
                            "
                            @click="handleSelectType(type.typeId, '02', '2')"
                            class="sub_catgory p-cat"
                            >{{ type.typeName }}</a
                          >
                        </div>
                      </div>
                      <template v-for="(item, index) in tableData">
                        <div class="pro-card">
                          <div class="pro-card-content-detail">
                            <div
                              @mouseover="handleMouseOver(item)"
                              @mouseout="handleMouseOut(item)"
                              @click="showDetail(item)"
                              class="card-img"
                            >
                              <!-- item.thumb -->
                              <img
                                class="evenflow_scale"
                                :style="{ height: imageHeight + 'px' }"
                                :src="
                                  item.mainList.length > 0
                                    ? `${
                                        item.mainList[item.currentProduct]
                                          .picUrl
                                      }?x-oss-process=image/resize,m_fill,h_500,w_500`
                                    : ''
                                "
                                lazy
                              />
                            </div>
                            <div
                              :style="{
                                width: imageWidth + 'px',
                                top: imageWidth - 36 + 'px',
                              }"
                              :class="`sku-img-list tp-${index}`"
                            >
                              <div class="img-content">
                                <ul>
                                  <li
                                    :class="{
                                      'is-select':
                                        item.currentProduct === number,
                                    }"
                                    @mouseover="handleSelectSku(item, number)"
                                    v-for="(record, number) in item.mainList"
                                    :key="number"
                                    :style="{
                                      backgroundColor:
                                        dictMap['color'][record.skuName],
                                    }"
                                  ></li>
                                </ul>
                              </div>
                            </div>

                            <div
                              @mouseover="handleMouseOut(item)"
                              class="card-info"
                            >
                              <div class="info-div" v-show="item.showTitle">
                                <div class="detail">
                                  <div
                                    :title="item.proName"
                                    class="product-title"
                                  >
                                    {{ item.proName }}
                                  </div>
                                  <div class="text" style="line-height: 28px">
                                    <el-popover
                                      placement="bottom-start"
                                      width="322"
                                      trigger="hover"
                                    >
                                      <div
                                        class="price"
                                        slot="reference"
                                        style="display: inline-block; position: relative"
                                      >
                                        <span class="unit">￥</span
                                        >{{ item.price }}
                                        <i
                                          style="margin: 0 0 0 2px; font-size: 12px"
                                          class="el-icon-arrow-down"
                                        ></i>
                                        <i
                                          style="
                                margin: 0 0 0 2px;
                                font-size: 12px;
                                display: none;
                              "
                                          class="el-icon-arrow-up"
                                        ></i>
                                      </div>
                                      <div>
                                        <div class="prod_drop_con">
                                          <div
                                            v-for="(price, index) in item
                                              .skuList[0].priceList"
                                            :key="index"
                                            class="prod_drop_item"
                                          >
                                            <div class="prod_drop_desc">
                                              <span
                                                style="color: rgb(51, 51, 51)"
                                                >¥{{ price.price }}</span
                                              >
                                              <span
                                                >{{ price.lowNum }}-{{
                                                  price.highNum
                                                }}件</span
                                              >
                                            </div>
                                            <div
                                              v-if="
                                                index !==
                                                  item.skuList[0].priceList
                                                    .length -
                                                    1
                                              "
                                              class="el-divider el-divider--vertical"
                                            >
                                              <!---->
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </el-popover>

                                    <span class="date">{{
                                      dictMap["prod_days"][item.prodDays]
                                    }}</span>
                                  </div>
                                  <div v-if="false" class="product-tag">
                                    <div>
                                      {{
                                        dictMap["product_type"]
                                          ? dictMap["product_type"][
                                              item.productType
                                            ]
                                          : ""
                                      }}
                                    </div>
                                  </div>
                                  <div class="factory text">
                                    平均发货时效：{{ item.avgDelivery }}小时
                                  </div>
                                </div>
                              </div>
                              <div class="btn-div" v-show="!item.showTitle">
                                <div class="pa c153 price-gradient-box">
                                  <div class="relative">
                                    <div
                                      class="flex-row flex-x-full weight-box"
                                      style="margin-bottom: 8px"
                                    >
                                      <div class="w50">
                                        {{ Math.round(Number(item.weight)) }}g
                                      </div>
                                      <div class="w50">
                                        {{ item.pdMaterial }}
                                      </div>
                                    </div>
                                    <div class="pl10 mt15">
                                      80%平均时效：{{ item.mostDelivery }}小时
                                    </div>
                                    <div class="pl10 mt5">
                                      平均发货时效：{{ item.avgDelivery }}小时
                                    </div>
                                    <div class="pl10 mt5 pb10">
                                      生产商承诺发货：{{
                                        item.agreeDelivery
                                      }}小时
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="item.remark"
                              class="product-type"
                              style="background: rgb(255, 144, 0)"
                              :style="{
                                left:
                                  form.productType == '02' ? '34px' : '14px',
                              }"
                            >
                              {{ item.remark }}
                            </div>
                            <div class="vip-type">
                              <img :src="getVipType(item.vipType)" />
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="presentation" tabindex="-1">
        <a role="button" @click="handleJump('/design/online/tool')">在线设计</a>
      </li>
      <li class="presentation" tabindex="-1">
        <a role="button" @click="handleJump('/advantage')">平台优势</a>
      </li>
      <li class="presentation" tabindex="-1">
        <a role="button" @click="handleJump('/vip')">会员服务</a>
      </li>
      <li class="presentation" tabindex="-1">
        <a role="button" @click="handleJump('/contact')">联系我们</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { getProductTypeListTree } from "@/api/product/type.js"
import { getProductList } from "@/api/customProduct/product"
import { mapGetters } from "vuex"
import vip0 from "@/assets/images/user/vip0.png"
import vip1 from "@/assets/images/user/vip1.png"
import vip2 from "@/assets/images/user/vip2.png"
import vip3 from "@/assets/images/user/vip3.png"
import vip4 from "@/assets/images/user/vip4.png"
import vip5 from "@/assets/images/user/vip5.png"
export default {
  computed: {
    ...mapGetters(["dictData", "dictMap"]),
    routeName() {
      return this.$route.path
    },
  },
  data() {
    return {
      typeIdTree: [],
      typeIdTreeTheme: [],
      typeIdTreeCommon: [],
      form: {
        prodDays: "",
        productType: "",
        pageNum: 1,
        pageSize: 6,
        proName: "",
        dzStatus: "01",
        isAsc: "",
        orderByColumn: "",
        beginPrice: "",
        endPrice: "",
        priceValue: "",
        weightValue: "",
        vipType: "",
        saleType: [],
        sex: "",
        labelAttr: [],
        features: "",
        remark: "1",
      },
      tableData: [],
      imageHeight: "200",
      imageWidth: "223",
      rows: [],
      isCheckAll: false,
      vip0: vip0,
      vip1: vip1,
      vip2: vip2,
      vip3: vip3,
      vip4: vip4,
      vip5: vip5,
      isExpandLableAttr: false,
      labelAttr: "",
      isCheckLabelAttr: true,
      labelAttrList: [],
      showBroadcast: false,
      typeName: "",
      typeId: "",
      childrenTree: [],
    }
  },
  methods: {
    handleJump(path) {
      this.$router.push({
        path: path,
      })
    },
    handleSelectType(typeId, productType, remark) {
      this.$router.push({
        path: "/product_type",
        query: {
          typeId: typeId,
          productType: productType,
          remark: remark,
        },
      })
    },
    handleMouseOver() {
      let element = document.querySelector(".top-button1")
      if (element) {
        element.style.display = "none"
      }
    },
    handleMouseOut() {
      let element = document.querySelector(".top-button1")
      if (element) {
        element.style.display = "block"
      }
    },
    handleMouseOverProduct(item) {
      if (item.mainList[item.currentProduct].picUrlOther) {
        item.mainList[item.currentProduct].picUrl =
          item.mainList[item.currentProduct].picUrlOther
      }
    },
    handleMouseOutProduct(item) {
      if (item.mainList[item.currentProduct].picUrlOther) {
        item.mainList[item.currentProduct].picUrl =
          item.mainList[item.currentProduct].picUrlBak
      }
      item.currentProduct = 0
    },
    async getTree() {
      let productType = "01"
      const { data } = await getProductTypeListTree({
        productType,
        status: "1",
      })
      if (data) {
        data.map((record) => {
          record.typeName = `${record.typeName}`
          if (record.childrenTree) {
            record.childrenTree.map((item) => {
              item.typeName = `${item.typeName}`
              if (item.childrenTree) {
                item.childrenTree.map((menu) => {
                  menu.typeName = `${menu.typeName}`
                })
              }
            })
          }
        })
        this.typeIdTree = data
      }
    },
    async getTreeTheme() {
      let productType = "02"
      const { data } = await getProductTypeListTree({
        productType,
        status: "1",
        remark: "1",
      })
      if (data) {
        data.map((record) => {
          record.typeName = `${record.typeName}`
          if (record.childrenTree) {
            record.childrenTree.map((item) => {
              item.typeName = `${item.typeName}`
              if (item.childrenTree) {
                item.childrenTree.map((menu) => {
                  menu.typeName = `${menu.typeName}`
                })
              }
            })
          }
        })
        this.typeIdTreeTheme = data
      }
    },
    async getTreeCommon() {
      let productType = "02"
      const { data } = await getProductTypeListTree({
        productType,
        status: "1",
        remark: "2",
      })
      if (data) {
        data.map((record) => {
          record.typeName = `${record.typeName}`
          if (record.childrenTree) {
            record.childrenTree.map((item) => {
              item.typeName = `${item.typeName}`
              if (item.childrenTree) {
                item.childrenTree.map((menu) => {
                  menu.typeName = `${menu.typeName}`
                })
              }
            })
          }
        })
        this.typeIdTreeCommon = data
      }
    },
    preloadData(type) {
      if (type == "1") {
        this.typeId = this.typeIdTree[1].typeId
        this.typeName = this.typeIdTree[1].typeName
        this.loadData(
          this.typeIdTree[1].typeId,
          this.typeIdTree[1].typeName,
          "01",
          ""
        )
      } else if (type == "2") {
        this.typeId = this.typeIdTreeTheme[1].typeId
        this.typeName = this.typeIdTreeTheme[1].typeName
        this.loadData(
          this.typeIdTreeTheme[1].typeId,
          this.typeIdTreeTheme[1].typeName,
          "02",
          "1"
        )
      } else if (type == "3") {
        this.typeId = this.typeIdTreeCommon[1].typeId
        this.typeName = this.typeIdTreeCommon[1].typeName
        this.loadData(
          this.typeIdTreeCommon[13].typeId,
          this.typeIdTreeCommon[13].typeName,
          "02",
          "2"
        )
      }
    },
    loadData(typeId, typeName, productType, remark) {
      let form = this._.clone(this.form)
      this.typeName = typeName
      this.typeId = typeId
      form.typeId = typeId
      form.remark = remark
      form.productType = productType
      form.saleType = form.saleType.join(",")
      form.labelAttr = form.labelAttr.join(",")
      if (form.saleType == "2,1") {
        form.saleType = "1,2"
      }
      if (productType == "01") {
        for (let record of this.typeIdTree) {
          if (record.typeId == typeId) {
            this.childrenTree = record.childrenTree
            break
          }
        }
      } else if (productType == "02" && remark == "1") {
        for (let record of this.typeIdTreeTheme) {
          if (record.typeId == typeId) {
            this.childrenTree = record.childrenTree
            break
          }
        }
      } else if (productType == "02" && remark == "2") {
        for (let record of this.typeIdTreeCommon) {
          if (record.typeId == typeId) {
            this.childrenTree = record.childrenTree
            break
          }
        }
      }
      getProductList(form)
        .then((res) => {
          for (let record of res.rows) {
            record.showTitle = true
            record.isChecked = false
            record.currentProduct = 0
            for (let image of record.mainList) {
              if (image.isMain == "2") {
                for (let picture of record.mainList) {
                  if (picture.isMain == "1") {
                    picture.picUrlOther = image.picUrl
                    picture.picUrlBak = picture.picUrl
                    break
                  }
                }
                break
              }
            }
            for (let i = 0; i < record.mainList.length; i++) {
              if (record.mainList[i].isMain == "2") {
                record.mainList.splice(i, 1)
                i--
              }
            }
          }
          this.tableData = res.rows
          this.total = res.total
        })
        .catch(() => {})
    },
    getVipType(vipType) {
      if (vipType == "0" || !vipType) {
        return vip0
      } else if (vipType == "1") {
        return vip1
      } else if (vipType == "2") {
        return vip2
      } else if (vipType == "3") {
        return vip3
      } else if (vipType == "4") {
        return vip4
      } else if (vipType == "5") {
        return vip5
      }
    },
    showDetail(item) {
      let routeUrl = ""
      if (item.designId) {
        routeUrl = this.$router.resolve({
          path: "product",
          query: {
            designId: item.designId,
            showButton: 1,
            remark: this.form.remark,
            productType: this.form.productType,
          },
        })
      } else {
        routeUrl = this.$router.resolve({
          path: "product",
          query: {
            pId: item.pId,
            remark: this.form.remark,
            productType: this.form.productType,
          },
        })
      }

      window.open(routeUrl.href, "_blank")
    },
  },
  mounted() {
    this.getTree()
    this.getTreeTheme()
    this.getTreeCommon()
  },
}
</script>

<style lang="scss" scoped>
plat-nav-bar {
  /*max-width: 1200px;*/
  margin: 0 auto;
  position: relative;
}

.index-custom-hot {
  display: flex;
  align-items: center;
}

.index-custom-tips {
  width: 880px;
}
.index-custom-hot {
  height: 46px;
  overflow: hidden;
}
.index-custom-hot .check-new {
  position: absolute;
  right: 10px;
  color: #4934b2 !important;
  font-size: 14px;
  font-weight: bold;
}
.index-custom-hot .check-new i {
  color: #4934b2;
  font-size: 14px;
  font-weight: bold;
}
.index-custom-container {
  padding: 12px 0;
  width: 166px;
  float: left;
}
.container-item-wrap {
  display: flex;
  transition: none;
}
.container-item-wrap:hover {
  background-color: #edf5fe;
}
.container-item-wrap .icon-arrow_right {
  transition: none;
}
.container-left {
  width: 150px;
  font-size: 12px;
  line-height: 26px;
  margin: 4px 0;
  text-align: right;
  flex-shrink: 0;
}
.container-body {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
}
.container-body > a:nth-of-type(n + 6) {
  display: none;
}
.item-name {
  margin: 5px 0;
  padding-left: 20px;
  box-sizing: border-box;
  text-align: left;
  position: relative;
  line-height: 26px;
  color: #666666;
}
.item-content-type a:hover {
  color: #59a5e6;
}
.item-content-type a {
  transition: none;
  position: relative;
  cursor: pointer !important;
  color: #3d3d3f;
  font-size: 12px;
}
.item-content-type a:hover {
  color: #4934b2;
}
.item-content-type {
  margin-left: 0;
}
.item-content-type.red a {
}
.item-name:last-of-type .item-content-type:before {
  width: 0;
}
.item-name:nth-of-type(7n) .item-content-type:before {
  width: 0;
}
.index-custom-hot {
  display: flex;
  align-items: center;
}
.index-custom-tips .container-body a {
  cursor: default;
}
.index-custom-hot .hot {
  font-family: MicrosoftYaHei-Bold;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  color: #fa3b33;
}
.item-hot {
  line-height: 20px;
  padding: 0 4px;
  margin-left: 22px;
  cursor: pointer;
  transition: none;
  cursor: pointer;
  color: #fff;
  background-image: linear-gradient(
      -90deg,
      #fc7764 0%,
      #fc5b61 63%,
      #fc3f5e 100%
    ),
    linear-gradient(#dd3029, #dd3029);
  background-blend-mode: normal, normal;
}
.item-name .icon-home-sale {
  color: #ed5244;
  font-size: 26px;
  position: absolute;
  bottom: -1px;
  right: -19px;
}
.item-name .icon-home-new {
  color: #66cccc;
  font-size: 26px;
  position: absolute;
  bottom: -1px;
  right: -19px;
}
.container-left-label {
  display: inline-block;
  margin-right: 12px;
  font-size: 12px;
  color: #333333;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  font-stretch: normal;
  transition: none;
  font-weight: bold;
  cursor: pointer;
}
.container-left-label:hover {
  color: #4934b2;
}
.user-level-icon {
  display: inline-block;
  width: 15px;
  height: 19px;
  vertical-align: middle;
  // background-repeat: no-repeat;
  // background-image: url("/static/frontend/images/metro.png?v=1");
}
.level4 {
  background-position: -109px -107px;
}
.factory {
  background-position: -154px -107px;
}
.shipping {
  background-position: -154px -125px;
}
.agent {
  background-position: -176px -107px;
}
.level2 {
  background-position: -131px -107px;
}
.level3 {
  background-position: -109px -125px;
}
.level1 {
  background-position: -131px -125px;
}
.f16 {
  font-size: 16px;
}
.index-login > a:first-child {
  padding-right: 0;
}
.user-login {
  padding-right: 10px !important;
}
.main-btn {
  cursor: pointer;
  color: #fff;
  background-color: #4796f1;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  transition: none;
}
.main-btn:hover {
  opacity: 0.8;
}
.control {
  width: 76px;
  height: 30px;
  line-height: 30px;
}
.ml14 {
  margin-left: 14px;
}
.arrow-class {
  font-size: 12px;
  color: #333333;
  font-weight: bold !important;
}
.index-search input[type="text"] {
  width: 260px;
  height: 42px;
}
.index-search i.search {
  right: -55px;
  width: 55px;
  font-size: 20px;
  line-height: 42px;
}
.index-tips,
.index-tips > div {
  height: 46px;
  font-size: 14px;
}
.notice-swiper .swiper-slide,
.notice-swiper,
.notice-swiper span {
  height: 46px !important;
  line-height: 46px;
  font-size: 12px;
}
.index-tips:before {
  height: 46px;
}
.index-tips-p * {
  font-size: 12px;
}
.history-record {
  display: none;
  width: 322px;
  padding: 5px 0;
  position: absolute;
  top: 40px;
  left: 0;
  background-color: #fff;
  border: solid 1px #b5d7fe;
  box-sizing: border-box;
  z-index: 10;
}
.history-record li:hover {
  background-color: #edf5fe;
}
.history-record-del {
  display: none;
}
.history-record li:hover .history-record-del {
  display: block;
}
.history-record li {
  display: block;
  padding: 0 10px;
}
.history-record * {
  transition: none;
  color: #348cf0;
  cursor: pointer;
  line-height: 26px;
}
.history-item {
  display: inline-block;
  width: 274px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.index-tips-p > li {
  height: auto;
}
.location-get-smscode1 {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.reg-pop-module1 {
  min-width: 360px !important;
  width: 360px;
}
.com-pop-cont {
  padding: 20px;
}
.err-wrap {
  margin-left: -34px;
  margin-top: 125px;
  z-index: 100000;
}
.err-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
  display: none;
}
.yuguo-banner {
  width: 100%;
  height: 50px;
  // background: url("/static/frontend/images/index/yuguo_banner@2x.png?v=3")
  //   no-repeat center;
  // background-size: cover;
}
.yuguo-banner .close-fill {
  width: 32px;
  height: 32px;
  transform: scale(0.6);
  // background: url("/static/frontend/images/index/close-fill@2x.png?v=1")
  //   no-repeat center;
  // background-size: cover;
}
@media screen and (max-width: 1500px) {
  .yuguo-banner .close-fill {
    right: 15%;
  }
}
@media only screen and (min-width: 1500px) {
  .yuguo-banner .close-fill {
    left: 78.5%;
  }
}
.yuguo-img {
  width: 216px;
  height: 54px;
  margin-left: 41px;
  transform: scale(0.5);
  // background: url("/static/frontend/images/index/yuguo_img@2x.png?v=3")
  //   no-repeat center;
}
.college_submit {
  width: 130px;
  height: 40px;
  margin-top: 25px;
  background: #f0f0f0;
  border: 1px solid #4934b2;
  font-weight: 400;
  color: #4934b2;
  line-height: 40px;
}
.cate_channel {
  overflow: hidden;
  padding-left: 40px;
  height: 24px;
  margin: 16px 0 0 0;
}
.cate_channel_lk {
  *cursor: pointer;
  float: left;
  margin-right: 10px;
  padding: 0 10px;
  height: 24px;
  background-color: #e1251b;
  line-height: 24px;
  color: #ffffff !important;
}
.cate_channel_lk:hover {
  color: #ffffff !important;
}
.cate_channel_arrow {
  margin-left: 5px;
}
.color-arrow {
  color: #ffffff;
}
.cate_channel_lk:hover {
  /* background-color: #4934b2;
  color: #fff; */
}
.plat-nav-bubble {
  display: none;
}
.product-menu:hover .plat-nav-bubble {
  display: block;
}
.plat-nav-bm-text-btn {
  cursor: pointer;
}
.form-label {
  display: inline-block;
  font-size: 13px;
  color: #595a5a;
  width: 80px;
  padding: 0 0 0 32px;
}
.presentation {
  font-weight: 600;
}

.product-list {
  overflow: hidden;
  overflow: hidden;
  margin: 0 0 0 43px;
  float: left;
  width: 660px;

  .pro-card {
    width: calc(33% - 16px);
    float: left;
    box-sizing: border-box;
    margin: 4px 12px 12px 4px;
    background: #ffffff;
    border: 1px solid transparent;
    border-radius: 5px;
    background: transparent;
    position: relative;
    .pro-card-content-detail {
      padding: 0;
      border: 1px solid #ddd;
      background: #ffffff;
      overflow: hidden;
      border-radius: 17px;
      padding: 8px;
    }
    .detail {
      line-height: 30px;
    }
    .unit {
      font-size: 12px;
    }
    .card-img {
      cursor: pointer;
      overflow: hidden;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    .image {
      padding: 16px;
      cursor: pointer;
    }
    .text {
      overflow: hidden;
      font-size: 14px;
    }
    .product-title {
      font-size: 14px;
      box-sizing: border-box;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .product-tag {
      line-height: 14px;
      margin: 0 0 10px 0;
      div {
        display: inline-block;
        font-size: 12px;
        padding: 2px 6px;
        background: #4934b2;
        border-radius: 5px;
        color: #ffffff;
      }
    }
    .factory {
      border-top: 1px solid #ebeef5;
      margin: 6px 0 0 0;
      padding: 3px 0 0 0;
    }
    .price {
      float: left;
      font-size: 18px;

      font-weight: bold;
    }
    .date {
      float: right;
    }
  }
  .pro-card:hover {
    cursor: auto;
    color: #000 !important;
    box-shadow: rgb(0 0 0 / 5%) 2px 6px 10px 5px;
    border: 1px solid #dddddd !important;
    border-radius: 20px;
    .pro-card-content-detail {
      // border: 1px solid transparent;
    }
    .product-type {
      display: block;
    }
    .vip-type {
      display: block;
    }
  }
}
.product-type {
  position: absolute;
  left: 34px;
  top: 14px;
  border-color: #ff9000;
  background-color: #ff9000;
  height: 20px;
  padding: 0 5px;
  line-height: 19px;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  display: none;
}

.vip-type {
  position: absolute;
  right: 17px;
  top: 14px;
  height: 20px;
  padding: 0 5px;
  line-height: 19px;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  display: none;
  img {
    width: 39px;
  }
}

.evenflow_scale:hover {
  transform: scale(1.05, 1.05);
}
.evenflow_scale {
  transition: all 0.6s ease;
}
.type-name {
  width: 629px;
  font-size: 14px;
  float: left;
  margin: 19px 0 16px 48px;
  overflow: hidden;
}
.type-name-text {
  float: left;
}
.type-name-more {
  float: right;
  color: #4934b2;
  cursor: pointer;
}
.new-cat-top {
  width: 100%;
  padding-bottom: 14px;
  margin-bottom: 14px;
  column-count: 3;
  border-bottom: 1px solid #e4e4e4;
  max-height: 100%;
  padding: 0 0 16px 5px;
  /* overflow-y: auto; */
}
.new-cat-top > div {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
}
.new-cat-top a {
  cursor: pointer;
  color: #404a58;
  font-weight: normal;
}
.new-cat-top a:hover {
  color: #4934b2 !important;
}
</style>
