import request from "@/utils/request"

// 登录方法
export function login(username, password, code, uuid) {
  const data = {
    username,
    password,
    code,
    uuid,
  }
  data.platform = 0
  return request({
    url: "/login",
    method: "post",
    data: data,
  })
}

// 获取用户详细信息
export function loginSms(username, smsCode, code, uuid) {
  const data = {
    username,
    smsCode,
    uuid,
  }
  data.platform = 0
  return request({
    url: "/sms/login",
    method: "post",
    data: data,
  })
}
// 获取用户详细信息
export function loginAdmin(username, password, code, uuid) {
  const data = {
    username,
    password,
    code,
    uuid,
  }
  data.platform = 0
  return request({
    url: "/admin-login",
    method: "post",
    data: data,
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: "/getInfo",
    method: "get",
  })
}

// 退出方法
export function logout() {
  return request({
    url: "/logout",
    method: "post",
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: "/captchaImage",
    method: "get",
  })
}

//用户注册
export function register(data) {
  return request({
    url: "/register",
    method: "post",
    data: data,
  })
}

//发送注册短信验证码
export function sendSms(data) {
  return request({
    url: "/sendSms",
    method: "post",
    data: data,
  })
}
//发送注册短信验证码
export function resetPwd(data) {
  return request({
    url: "/resetPwd",
    method: "post",
    data: data,
  })
}
