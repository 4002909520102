<template>
  <div class="body">
    <TopNav :showDetail="!showDetail"></TopNav>
    <Detail :fixed="showDetail" v-show="showDetail"></Detail>
    <Carousel></Carousel>
    <!-- <SectionHot></SectionHot> -->
    <img :src="require(`@/assets/index_img/2.png`)" alt="" class="new_img" style="width: 100%;">
    <Ad1></Ad1>
    <!-- <Question></Question> -->
    <img :src="require(`@/assets/index_img/3.jpg`)" class="new_img" width="1400">
    <Process></Process>
    <!-- <img :src="require(`@/assets/index_img/4.jpg`)" class="new_img" width="100%"> -->
    <Ad2></Ad2>
    <Product></Product>
    <Parnter></Parnter>
    <!-- <Platphom></Platphom> -->
    <img :src="require(`@/assets/index_img/5.png`)" class="new_img" width="1200">

    <Service></Service>
    <Footer></Footer>
    <Feedback></Feedback>
    <!-- <Feedback></Feedback> -->
  </div>
</template>

<script>
import TopNav from "./TopNav"
import Detail from "./Detail"
import Carousel from "./Carousel"
import SectionHot from "./SectionHot"
import Product from "./Product"
import Free from "./Free"
import Process from "./Process"
import Service from "./Service"
import Parnter from "./Partner"
import Footer from "./Footer"
import Set from "./Set"
import Feedback from "./Feedback"
import Ad1 from "./Ad1"
import Question from "./Question"
import Ad2 from "./Ad2"
import Platphom from "./Platphom"

export default {
  components: {
    Detail,
    TopNav,
    Carousel,
    SectionHot,
    Product,
    Free,
    Process,
    Service,
    Parnter,
    Footer,
    Set,
    Feedback,
    Ad1,
    Question,
    Ad2,
    Platphom,
  },
  data() {
    return {
      showDetail: false,
    }
  },
  methods: {},
  mounted() {
    let _self = this
    $(window).scroll(function() {
      var scrollTop = $(window).scrollTop()
      if (scrollTop > 200) {
        _self.showDetail = true
      } else {
        _self.showDetail = false
      }
    })
  },
}
</script>

<style>
@import "../../assets/css/default/top-nav.css";
@import "../../assets/css/default/main.css";
.new_img{
   display: block;
   margin: auto;
}
</style>
