import request from '@/utils/request'
//采集器模块

// 加载表格数据
export function getProductTypeList(params){
    return request({
        url: '/server/cp/type/list',
        method: 'get',
        params: params
    })
}

//获取产品类型
export function getProductTypeTree(params){
    return request({
        url: '/server/cp/type/tree',
        method: 'get',
        params: params
    })
}
export function getProductTypeListTree(params){
    return request({
        url: '/server/cp/type/listTree',
        method: 'get',
        params: params
    })
}