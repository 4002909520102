<template>
  <table class="normal">
    <thead>
      <tr>
        <th v-if="data && data.length > 0 && data[0].skuSelect">{{ data[0].skuSelect }}</th>
        <th v-if="data && data.length > 0 && data[0].attrValue1">{{ data[0].attrValue1 }}</th>
        <th v-if="data  && data.length > 0&& data[0].attrValue2">{{ data[0].attrValue2 }}</th>
        <th v-if="data && data.length > 0 && data[0].attrValue3">{{ data[0].attrValue3 }}</th>
        <th v-if="data && data.length > 0 && data[0].attrValue4">{{ data[0].attrValue4 }}</th>
        <th v-if="data && data.length > 0 && data[0].attrValue5">{{ data[0].attrValue5 }}</th>
        <th v-if="data && data.length > 0 && data[0].attrValue6">{{ data[0].attrValue6 }}</th>
        <th v-if="data && data.length > 0 && data[0].attrValue7">{{ data[0].attrValue7 }}</th>
        <th v-if="data && data.length > 0 && data[0].attrValue8">{{ data[0].attrValue8 }}</th>
        <th v-if="data && data.length > 0 && data[0].attrValue9">{{ data[0].attrValue9 }}</th>
        <th v-if="data && data.length > 0 && data[0].attrValue10">{{ data[0].attrValue10 }}</th>
      </tr>
    </thead>
    <tbody>
      <tr style="color:#666666;" v-for="record,index in tableData" :key="index">
        <td v-if="record.skuSelect">{{ record.skuSelect }}</td>
        <td v-if="record.attrValue1">{{ record.attrValue1 }}</td>
        <td v-if="record.attrValue2">{{ record.attrValue2 }}</td>
        <td v-if="record.attrValue3">{{ record.attrValue3 }}</td>
        <td v-if="record.attrValue4">{{ record.attrValue4 }}</td>
        <td v-if="record.attrValue5">{{ record.attrValue5 }}</td>
        <td v-if="record.attrValue6">{{ record.attrValue6 }}</td>
        <td v-if="record.attrValue7">{{ record.attrValue7 }}</td>
        <td v-if="record.attrValue8">{{ record.attrValue8 }}</td>
        <td v-if="record.attrValue9">{{ record.attrValue9 }}</td>
        <td v-if="record.attrValue10">{{ record.attrValue10 }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "descTable",
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  computed:{
    tableData(){
      let tableData = this._.clone(this.data)
      tableData.splice(0,1)
      return tableData
    }
  },
  data() {
    return {}
  },
}
</script>

<style scoped>
table.normal {
  width: 100%;
  border-collapse: collapse;
}

.normal thead th {
  background: #fafafa;
  border-top: 1px solid rgb(221, 221, 221);
  border-bottom: 1px solid rgb(221, 221, 221);
  border-right: 1px solid rgb(221, 221, 221);
  height: 40px;
  white-space: nowrap;
  font-weight: normal;
  /*position: relative;*/
}

.normal thead th:first-child {
  border-left: 1px solid rgb(221, 221, 221);
}

.normal thead th:last-child {
  border-right: 1px solid rgb(221, 221, 221);
}

.normal td {
  text-align: center;
  border: 1px solid rgb(221, 221, 221);
  height: 38px;
  padding: 5px;
  font-size: 12px;
}

.normal td.empty-td {
  height: 120px;
}

.normal.not-border tbody td {
  border: none;
}

.normal tbody td a {
  color: #4934b2;
}

.normal tbody td a.main-btn-w {
  color: #444;
}

.normal tbody td a:hover {
  opacity: 0.8;
}
.normal tbody tr:nth-child(even) {
  background-color: #fafafa;
}
</style>
