const custom = {
    state: {
        multi_language_list: [
            {
                "language": "en", //英语
                "name": ""
            },
            {
                "language": "fr",//法语
                "name": ""
            },
            {
                "language": "es",//西班牙语
                "name": ""
            },
            {
                "language": "de",//德语
                "name": ""
            },
            {
                "language": "it",
                "name": ""
            },
            {
                "language": "ru",
                "name": ""
            },
            {
                "language": "ar",
                "name": ""
            },
            {
                "language": "zh-tw",
                "name": ""
            },
            {
                "language": "zh-cn",//中文简体
                "name": ""
            },
            {
                "language": "th",//泰语
                "name": ""
            },
            {
                "language": "id",
                "name": ""
            },
            {
                "language": "nl",
                "name": ""
            },
            {
                "language": "tr",
                "name": ""
            },
            {
                "language": "vi",
                "name": ""
            },
            {
                "language": "pt-br",//巴西葡语
                "name": ""
            },
            {
                "language": "he",
                "name": ""
            },
            {
                "language": "sv",
                "name": ""
            },
            {
                "language": "pl",
                "name": ""
            },
            {
                "language": "ja-jp",
                "name": ""
            },
            {
                "language": "pt-pt",
                "name": ""
            },
            {
                "language": "ko",
                "name": ""
            },
            {
                "language": "ja",
                "name": ""
            },
            {
                "language": "el-gr",
                "name": ""
            },
            {
                "language": "cs-cz",
                "name": ""
            }
        ]
    },
    mutations: {

    },
    actions: {

    }
}

export default custom
