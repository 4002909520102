<template>
  <div class="product-type">
    <ul class="left_url_tab">
      <div class="menu">
        <div class="title">产品分类</div>
        <el-tree
          :data="typeIdTree"
          :props="defaultProps"
          @node-click="handleNodeClick"
        ></el-tree>
      </div>
    </ul>
    <div id="custom-online" class="product-list">
      <div class="form-body">
        <div v-if="false">
          <div class="form-label">生产时长</div>
          <el-radio-group
            @change="loadData"
            v-model="form.productType"
            class="hide_input"
          >
            <el-radio label="">全部</el-radio>
            <el-radio
              :label="value.value"
              :key="value.value"
              v-for="value in dictData['product_type']"
              >{{ value.label }}</el-radio
            >
          </el-radio-group>
        </div>
        <div>
          <div class="form-label">生产时长</div>
          <el-radio-group
            @change="loadData"
            v-model="form.prodDays"
            class="hide_input"
          >
            <el-radio label="">全部</el-radio>
            <el-radio
              :label="value.value"
              :key="value.value"
              v-for="value in dictData['prod_days']"
              >{{ value.label }}</el-radio
            >
          </el-radio-group>
        </div>
        <div>
          <div class="form-label">采购价格</div>
          <el-radio-group
            v-model="form.priceValue"
            class="hide_input"
            @change="typeChange"
          >
            <el-radio label="">全部</el-radio>
            <el-radio v-for="item in priceList" :label="item.id">{{
              item.name
            }}</el-radio>
          </el-radio-group>
        </div>
        <div>
          <div class="form-label">产品重量</div>
          <el-radio-group
            v-model="form.weightValue"
            class="hide_input"
            @change="typeChange"
          >
            <el-radio label="">全部</el-radio>
            <el-radio v-for="item in weightList" :label="item.id">{{
              item.name
            }}</el-radio>
          </el-radio-group>
        </div>
        <div class="filter">
          <div class="left">
            <el-input
              size="small"
              style="width: 300px; margin-right: 16px"
              placeholder="搜索产品名称/编号"
              v-model="form.proName"
            ></el-input>

            <el-input
              size="small"
              style="width: 100px; margin-right: 16px"
              placeholder="最低价格"
              v-model="form.beginPrice"
            ></el-input>

            <el-input
              size="small"
              style="width: 100px; margin-right: 16px"
              placeholder="最高价格"
              v-model="form.endPrice"
            ></el-input>
            <el-button size="small" type="primary" @click="loadData"
              >搜索</el-button
            >
          </div>
          <div class="right order-area">
            <el-table
              class="customer-table"
              :data="rows"
              style="width: 100%"
              @sort-change="handleSortChange"
              :default-sort="{ prop: 'default', order: 'descending' }"
            >
              <el-table-column
                prop="default"
                label="默认排序"
                sortable
                width="100"
                :sort-orders="['descending']"
              >
              </el-table-column>
              <el-table-column
                prop="sales"
                label="产品销量"
                sortable
                width="100"
                :sort-orders="['descending', 'ascending']"
              >
              </el-table-column>
              <el-table-column
                prop="price"
                label="价格排序"
                sortable
                width="100"
                :sort-orders="['descending', 'ascending']"
              >
              </el-table-column>
              <el-table-column
                prop="weight"
                label="重量排序"
                sortable
                width="100"
                :sort-orders="['descending', 'ascending']"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <div class="tab-body" style="margin-top: 0">
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="form.pageNum"
          :limit.sync="form.pageSize"
          @pagination="loadData"
          style="height: 39px"
        />
        <div class="product-list">
          <template v-for="(item, index) in tableData">
            <div class="pro-card">
              <div class="pro-card-content-detail">
                <div
                  @mouseover="handleMouseOver(item)"
                  @mouseout="handleMouseOut(item)"
                  @click="showDetail(item)"
                  class="card-img"
                >
                  <!-- item.thumb -->
                  <img
                    :style="{ height: imageHeight + 'px' }"
                    :src="
                      item.mainList.length > 0
                        ? `${
                            item.mainList[item.currentProduct].picUrl
                          }?x-oss-process=image/resize,m_fill,h_500,w_500`
                        : ''
                    "
                    lazy
                    class="evenflow_scale"
                  />
                </div>
                <div
                  :style="{
                    width: imageWidth + 'px',
                    top: imageWidth - 36 + 'px',
                  }"
                  :class="`sku-img-list tp-${index}`"
                >
                  <div class="img-content">
                    <ul>
                      <li
                        :class="{ 'is-select': item.currentProduct === number }"
                        @mouseover="handleSelectSku(item, number)"
                        v-for="(record, number) in item.mainList"
                        :key="number"
                        :style="{
                          backgroundColor: dictMap['color'][record.skuName],
                        }"
                      ></li>
                    </ul>
                  </div>
                </div>

                <div class="card-info">
                  <div class="info-div" v-show="item.showTitle">
                    <div class="detail">
                      <div :title="item.proName" class="product-title">
                        {{ item.proName }}
                      </div>
                      <div class="text" style="line-height: 28px">
                        <el-popover
                          placement="bottom-start"
                          width="322"
                          trigger="hover"
                        >
                          <div
                            class="price"
                            slot="reference"
                            style="display: inline-block; position: relative"
                          >
                            <span class="unit">￥</span>{{ item.price }}
                            <i
                              style="margin: 0 0 0 2px; font-size: 12px"
                              class="el-icon-arrow-down"
                            ></i>
                            <i
                              style="
                                margin: 0 0 0 2px;
                                font-size: 12px;
                                display: none;
                              "
                              class="el-icon-arrow-up"
                            ></i>
                          </div>
                          <div>
                            <div class="prod_drop_con">
                              <div
                                v-for="(price, index) in item.skuList[0]
                                  .priceList"
                                :key="index"
                                class="prod_drop_item"
                              >
                                <div class="prod_drop_desc">
                                  <span style="color: rgb(51, 51, 51)"
                                    >¥{{ price.price }}</span
                                  >
                                  <span
                                    >{{ price.lowNum }}-{{
                                      price.highNum
                                    }}件</span
                                  >
                                </div>
                                <div
                                  v-if="
                                    index !==
                                      item.skuList[0].priceList.length - 1
                                  "
                                  class="el-divider el-divider--vertical"
                                >
                                  <!---->
                                </div>
                              </div>
                            </div>
                          </div>
                        </el-popover>

                        <span class="date">{{
                          dictMap["prod_days"][item.prodDays]
                        }}</span>
                      </div>
                      <div v-if="false" class="product-tag">
                        <div>
                          {{
                            dictMap["product_type"]
                              ? dictMap["product_type"][item.productType]
                              : ""
                          }}
                        </div>
                      </div>
                      <div class="factory text">
                        平均发货时效：{{ item.avgDelivery }}小时
                      </div>
                    </div>
                  </div>
                  <div class="btn-div" v-show="!item.showTitle">
                    <div class="pa c153 price-gradient-box">
                      <div class="relative">
                        <div
                          class="flex-row flex-x-full weight-box"
                          style="margin-bottom: 8px"
                        >
                          <div class="w50">
                            {{ Math.round(Number(item.weight)) }}g
                          </div>
                          <div class="w50">{{ item.pdMaterial }}</div>
                        </div>
                        <div class="pl10 mt15">
                          80%平均时效：{{ item.mostDelivery }}小时
                        </div>
                        <div class="pl10 mt5">
                          平均发货时效：{{ item.avgDelivery }}小时
                        </div>
                        <div class="pl10 mt5 pb10">
                          生产商承诺发货：{{ item.agreeDelivery }}小时
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="form.pageNum"
          :limit.sync="form.pageSize"
          @pagination="loadData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getProductList } from "@/api/customProduct/product"
import { getProductTypeListTree } from "@/api/product/type.js"
import { mapGetters } from "vuex"
export default {
  data() {
    return {
      loading: false,
      defaultProps: {
        value: "typeId",
        label: "typeName",
        children: "childrenTree",
      },
      tableData: [],
      typeIdTree: [],
      form: {
        prodDays: "",
        productType: "01",
        pageNum: 1,
        pageSize: 30,
        proName: "",
        dzStatus: "01",
        isAsc: "desc",
        orderByColumn: "sort",
        beginPrice: "",
        endPrice: "",
        priceValue: "",
        weightValue: "",
      },
      total: 0,
      imageHeight: "200",
      imageWidth: "223",
      productTypeList: [
        { name: "定制产品", id: "1" },
        { name: "优选产品", id: "2" },
      ],
      priceList: [
        { name: "1-10元", id: "1" },
        { name: "10-20元", id: "2" },
        { name: "20-40元", id: "3" },
        { name: "40-60元", id: "4" },
        { name: "大于60元", id: "5" },
      ], //采购价格
      weightList: [
        { name: "0-100g", id: "1" },
        { name: "100-200g", id: "2" },
        { name: "200-400g", id: "3" },
        { name: "大于400g", id: "4" },
      ], //产品重量
      rows: [],
      isSet: false,
    }
  },
  computed: {
    ...mapGetters(["dictData", "dictMap"]),
    routeName() {
      console.log(this.$route)
      return this.$route.fullPath
    },
  },
  created() {},
  watch: {
    routeName: {
      handler() {
        let typeId = this.$route.query.typeId
        let proName = this.$route.query.proName
        if (proName) {
          this.form.proName = proName
        }
        if (typeId) {
          this.form.typeId = typeId
        }
        this.loadData()
        this.getTree()
      },
      immediate: true,
    },
    "form.priceValue"() {
      if (this.form.priceValue == "") {
        this.form.beginPrice = ""
        this.form.endPrice = ""
      } else if (this.form.priceValue == "1") {
        this.form.beginPrice = "1"
        this.form.endPrice = "10"
      } else if (this.form.priceValue == "2") {
        this.form.beginPrice = "10"
        this.form.endPrice = "20"
      } else if (this.form.priceValue == "3") {
        this.form.beginPrice = "20"
        this.form.endPrice = "40"
      } else if (this.form.priceValue == "4") {
        this.form.beginPrice = "40"
        this.form.endPrice = "60"
      } else if (this.form.priceValue == "5") {
        this.form.beginPrice = "60"
        this.form.endPrice = ""
      }
      this.loadData()
    },
    "form.weightValue"() {
      if (this.form.weightValue == "") {
        this.form.beginWeight = ""
        this.form.endWeight = ""
      } else if (this.form.weightValue == "1") {
        this.form.beginWeight = "0"
        this.form.endWeight = "100"
      } else if (this.form.weightValue == "2") {
        this.form.beginWeight = "100"
        this.form.endWeight = "200"
      } else if (this.form.weightValue == "3") {
        this.form.beginWeight = "200"
        this.form.endWeight = "400"
      } else if (this.form.weightValue == "4") {
        this.form.beginWeight = "40"
        this.form.endWeight = ""
      }
      this.loadData()
    },
  },
  methods: {
    handleJump(path) {
      this.$router.push({
        path: path,
      })
    },
    handleNodeClick(data) {
      this.form.typeId = data.typeId
      this.form.pageNum = "1"
      //
      this.loadData()
    },

    loadData() {
      let productType = this.$route.query.productType
      let remark = this.$route.query.remark

      this.form.productType = productType
      this.form.remark = remark
      // if (this.form.isAsc) {
      //   this.form.sort = 1
      // } else {
      //   this.form.sort = 0
      // }

      this.startLoading()
      getProductList(this.form)
        .then((res) => {
          for (let record of res.rows) {
            record.showTitle = true
            record.currentProduct = 0
            for (let image of record.mainList) {
              if (image.isMain == "2") {
                for (let picture of record.mainList) {
                  if (picture.isMain == "1") {
                    picture.picUrlOther = image.picUrl
                    picture.picUrlBak = picture.picUrl
                    break
                  }
                }
                break
              }
            }
            for (let i = 0; i < record.mainList.length; i++) {
              if (record.mainList[i].isMain == "2") {
                record.mainList.splice(i, 1)
                i--
              }
            }
          }
          this.tableData = res.rows
          this.total = res.total
          this.endLoading()
          this.$nextTick(() => {
            let cardImg = document.querySelector(".card-img")
            if (cardImg && !this.isSet) {
              let height = cardImg.offsetWidth
              this.imageWidth = cardImg.offsetWidth
              this.imageHeight = height
              this.isSet = true
            }
          })
        })
        .catch(() => {
          this.endLoading()
        })
    },
    handleCommandSales(command) {
      this.form.isAsc = command
      this.form.sortType = "saleNum"
      this.loadData()
    },
    handleCommandPrice(command) {
      this.form.isAsc = command
      this.form.sortType = "lowPrice"
      this.loadData()
    },
    handleCommandWeight(command) {
      this.form.isAsc = command
      this.form.sortType = "weight"
      this.loadData()
    },
    handleMouseOver(item) {
      if (item.mainList[item.currentProduct].picUrlOther) {
        item.mainList[item.currentProduct].picUrl =
          item.mainList[item.currentProduct].picUrlOther
      }
      item.showTitle = false
    },
    handleMouseOut(item) {
      if (item.mainList[item.currentProduct].picUrlOther) {
        item.mainList[item.currentProduct].picUrl =
          item.mainList[item.currentProduct].picUrlBak
      }
      item.showTitle = true
    },
    handleSortChange({ column, prop, order }) {
      if (order == "descending") {
        this.form.isAsc = "desc"
      } else {
        this.form.isAsc = "asc"
      }
      if (prop == "default") {
        this.form.orderByColumn = "sort"
      } else if (prop == "sales") {
        this.form.orderByColumn = "sales"
      } else if (prop == "price") {
        this.form.orderByColumn = "price"
      } else if (prop == "weight") {
        this.form.orderByColumn = "weight"
      }
      this.loadData()
    },
    showDetail(item) {
      let routeUrl = ""
      if (item.designId) {
        routeUrl = this.$router.resolve({
          path: "product",
          query: {
            designId: item.designId,
            showButton: 1,
            remark: this.form.remark,
            productType: this.form.productType,
          },
        })
      } else {
        routeUrl = this.$router.resolve({
          path: "product",
          query: {
            pId: item.pId,
            remark: this.form.remark,
            productType: this.form.productType,
          },
        })
      }

      window.open(routeUrl.href, "_blank")
    },
    handleSelectSku(item, number) {
      item.currentProduct = number
    },
    typeChange(val) {
      this.loadData()
    },

    async getTree() {
      let productType = this.$route.query.productType
      let remark = this.$route.query.remark

      this.form.productType = productType
      this.form.remark = remark

      const { data } = await getProductTypeListTree({
        productType,
        status: "1",
        remark: remark,
      })
      if (data) {
        data.map((record) => {
          record.typeName = `${record.typeName}(${record.proNum})`
          if (record.childrenTree) {
            record.childrenTree.map((item) => {
              item.typeName = `${item.typeName}(${item.proNum})`
              if (item.childrenTree) {
                item.childrenTree.map((menu) => {
                  menu.typeName = `${menu.typeName}(${menu.proNum})`
                })
              }
            })
          }
        })
        for (let i = 0; i < data.length; i++) {
          if (data[i].proNum == 0) {
            data.splice(i, 1)
            i--
          } else if (data[i].childrenTree) {
            for (let j = 0; j < data[i].childrenTree.length; j++) {
              if (data[i].childrenTree[j].proNum == 0) {
                data[i].childrenTree.splice(j, 1)
                j--
              }
            }
          }
        }
        this.typeIdTree = data
      }
    },
  },
  mounted() {
    let proName = this.$route.query.proName
    if (proName) {
      this.form.proName = proName
    }
    this.getTree()
    this.loadData()
  },
}
</script>

<style lang="scss" scoped>
#alibaba-register-box {
  background-color: #f5f5f6;
}
.product-type {
  padding: 0px 0 30px 0;
  text-align: center;
  width: 100%;
  border: none;
  overflow: hidden;
  ::v-deep .el-input-group__prepend {
    padding: 0 12px;
    font-size: 18px;
  }
}
.left_url_tab {
  float: left;
  width: 260px;
  border: inherit;
  padding: 0 16px;
  box-sizing: border-box;
  margin: -4px 0 0 0;
}
.left_url_tab ::v-deep .el-tree-node__content {
  padding: 2px 0;
}
.left_url_tab
  ::v-deep
  .is-current
  > .el-tree-node__content
  > .el-tree-node__label {
  background: #4934b2;
  padding: 0 4px;
  border-radius: 4px;
  color: #ffffff;
}
.title {
  font-weight: 600;
  font-size: 14px !important;
  display: flex;
  align-items: center;
  height: 38px;
  padding: 0 10px;
  color: #333;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  margin-right: 16px;
}
.title::before {
  content: " ";
  position: absolute;
  left: 0;
  width: 3px;
  height: 20px;
  background-color: #302d98;
}
.product-list {
  text-align: left;
}

#custom-online {
  margin: 0 0 0 260px;
}
#custom-online .tab-body .pro-card {
  border: 1px solid transparent;
  padding: 0;
  margin-top: 0px;
}
#custom-online .tab-body .pro-card .pro-content {
  border: 0;
  border-radius: 5px;
  padding: 0px 8px 8px 8px;
  box-sizing: border-box;
}
#custom-online .tab-body {
  ::v-deep .el-form-item__content {
    line-height: 50px;
  }
  .product-list {
    overflow: hidden;
    ::v-deep .pro-card:last-child {
    }

    .pro-card {
      width: calc(20% - 16px);
      float: left;
      box-sizing: border-box;
      margin: 4px 12px 12px 4px;
      background: #ffffff;
      border: 1px solid transparent;
      border-radius: 5px;
      background: transparent;
      position: relative;

      .pro-card-content-detail {
        padding: 0;
        border: 1px solid #ddd;
        background: #ffffff;
        border-radius: 17px;
      }
      .detail {
        line-height: 30px;
      }
      .unit {
        font-size: 12px;
      }
      .card-img {
        cursor: pointer;
        overflow: hidden;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }
      .image {
        padding: 16px;
        cursor: pointer;
      }
      .text {
        overflow: hidden;
        font-size: 14px;
      }
      .product-title {
        font-size: 14px;
        box-sizing: border-box;
        padding-bottom: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .product-tag {
        line-height: 14px;
        margin: 0 0 10px 0;
        div {
          display: inline-block;
          font-size: 12px;
          padding: 2px 6px;
          background: #4934b2;
          border-radius: 5px;
          color: #ffffff;
        }
      }
      .factory {
        border-top: 1px solid #ebeef5;
        margin: 6px 0 0 0;
        padding: 3px 0 0 0;
        line-height: 26px;
      }
      .price {
        float: left;
        font-size: 18px;
        font-weight: bold;
      }
      .date {
        float: right;
      }
    }
    .pro-card:hover {
      cursor: auto;
      color: #000 !important;
      box-shadow: rgb(0 0 0 / 5%) 2px 6px 10px 5px;
      border: 1px solid #dddddd !important;
      border-radius: 20px;
      .pro-card-content-detail {
        // border: 1px solid transparent;
      }
    }
  }
}
.form-body {
  margin-right: 8px;
  padding-bottom: 6px;
  padding: 10px 0;
}
.form {
  padding: 0 16px;
}

.sku-img-list {
  clear: both;
  width: 223px;
}

.sku-img-list .prev {
  float: left;
  margin-right: 2px;
}
.sku-img-list .next {
  float: right;
}
.sku-img-list .prev,
.tab-body .pro-card .pro-content .sku-img-list .next {
  display: block;
  font-family: "宋体";
  text-align: center;
  width: 10px;
  height: 25px;
  line-height: 25px;
  cursor: pointer;
  text-decoration: none;
}
.sku-img-list .img-content {
  position: relative;
  height: 45px;
  overflow: hidden;
}
.sku-img-list ul {
  position: absolute;
  width: 99999px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
  list-style-type: none;
}
.sku-img-list ul li {
  float: left;
  width: 25px;
  height: 25px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ebeef5;
  margin-right: 4px;
}
.sku-img-list ul li img {
  width: 25px;
  height: 25px;
}
.sku-img-list ul li img:hover {
}
.price-gradient-box {
  overflow: hidden;
  width: 100%;
  background: #fff;
  z-index: 100;
  transform: translateZ(1000px);
  font-size: 12px;
}
.price-gradient-box .weight-box {
  height: 30px;
  line-height: 30px;
  border: 1px solid #ededed;
  text-align: center;
}
.price-gradient-box .weight-box div:not(:first-child) {
  border-left: 1px solid #ededed;
}
.price-gradient-box > div {
  background: #fff;
}
.price-gradient-box:hover,
.com-pt-img:hover ~ .price-gradient-box {
  bottom: 5px;
  left: 0;
  overflow: hidden;
  z-index: 100;
  transform: translateZ(1000px);
}
.price-gradient-box .swiper-slide {
  text-align: center;
  border: 1px solid #ededed;
  padding: 10px 0;
}
.price-gradient-box .swiper-slide + .swiper-slide {
  border-left: none;
}
.product-swiper-box .next-btn,
.product-swiper-box .per-btn,
.price-gradient-box .next-btn,
.price-gradient-box .per-btn {
  z-index: 2;
  top: 60px;
  padding: 5px;
  width: 20px;
  height: 20px;
  line-height: 0px;
  background: rgba(235, 246, 255, 0.5);
  text-align: center;
}
.w50 {
  width: 50% !important;
}
.flex-x-full {
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.flex-row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.red {
  color: #ff4d3a !important;
}
.c153 {
  color: rgb(153, 153, 153);
}
.filter {
  background: #ffffff;
  border-top: 1px solid rgb(221, 221, 221);
  margin: 4px 0 0 0;
  overflow: hidden;
  padding: 12px 16px 0 16px;
}
.left {
  float: left;
}
.right {
  float: right;
}
.is-select {
  border: 1px solid #f25551 !important;
}
#custom-online .tab-body .card-img:hover {
  // .card-info .info-div {
  //   opacity: 0;
  // }
  // .card-info .btn-div {
  //   opacity: 1;
  // }
}
#custom-online .tab-body .pro-card .card-info {
  height: 95px;
  padding: 2px 16px 8px 16px;
}
.title {
  font-size: 14px;
  border-bottom: 1px solid #cccccc;
  padding: 8px;
}
.left_url_tab
  ::v-deep
  .is-current
  > .el-tree-node__content
  > .el-tree-node__label {
  background: #4934b2;
  padding: 0 4px;
  border-radius: 4px;
  color: #ffffff;
}
.left_url_tab ::v-deep .el-tree-node:focus > .el-tree-node__content {
  background-color: inherit;
}
.left_url_tab ::v-deep .el-tree-node {
  padding: 2px 0;
}
.form-label {
  display: inline-block;
  font-size: 13px;
  color: #595a5a;
  width: 80px;
  padding: 0 0 0 32px;
}
.sku-img-list {
  position: absolute;
  display: none;
}
.sku-img-list {
  top: 251px;
  left: 16px;
}
.pro-card-content-detail:hover {
  .sku-img-list {
    display: block;
  }
}
.card_shadow {
  box-shadow: rgb(0 0 0 / 5%) 2px 6px 10px 5px;
  border: 1px solid rgb(228, 228, 228) !important;
}
.tab-body .pro-card .card-info .btn-div {
  opacity: 1;
  height: 65px;
  position: absolute;
  top: 19px;
  width: calc(100% - 30px);
  display: flex;
  justify-content: center;
  align-items: center;
}

#custom-online .tab-body .pro-card .card-info {
  position: relative;
}
#custom-online .tab-body .pro-card .card-info .info-div {
  opacity: 1;
}
#custom-online .tab-body .pro-card .card-info .btn-div {
  opacity: 1;
  height: 65px;
  position: absolute;
  top: 19px;
  width: calc(100% - 30px);
  display: flex;
  justify-content: center;
  align-items: center;
}
#custom-online .tab-body .pro-card .card-info .btn-div .cus-btn {
  height: 40px;
  border: 1px solid #4934b2;
  width: 80%;
  border-radius: 1px;
  background: #4934b2;
  color: white;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

#custom-online .tab-body .pro-card .card-info .info-div .span-price {
  color: rgb(0, 122, 255);
}
#custom-online .tab-body .pro-card .card-info .info-div .el-row {
  font-size: 14px;
}
#custom-online .tab-body .pro-card .card-info .info-div .el-row .el-col {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#custom-online .tab-body .pro-card .card-info .info-div .el-row {
  padding-left: 10px;
}
#custom-online .tab-body .pro-card .pro-content {
  background-color: white;
  border: 3px solid #eaeaea;
  display: grid;
  justify-content: center;
}
#custom-online .tab-body .pro-card .pro-content:hover {
  border: 3px solid white;
  box-shadow: 0px 0px 10px #93e2ff;
}
#custom-online .tab-body .pro-card .pro-content .sku-img-list {
  clear: both;
  width: 223px;
}
#custom-online .tab-body .pro-card .pro-content .sku-img-list .prev {
  float: left;
  margin-right: 2px;
}
#custom-online .tab-body .pro-card .pro-content .sku-img-list .next {
  float: right;
}
#custom-online .tab-body .pro-card .pro-content .sku-img-list .prev,
.tab-body .pro-card .pro-content .sku-img-list .next {
  display: block;
  font-family: "宋体";
  text-align: center;
  width: 10px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  text-decoration: none;
}
#custom-online .tab-body .pro-card .pro-content .sku-img-list .img-content {
  float: left;
  position: relative;
  width: 200px;
  height: 40px;
  overflow: hidden;
}
#custom-online .tab-body .pro-card .pro-content .sku-img-list ul {
  position: absolute;
  width: 99999px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
  list-style-type: none;
}
#custom-online .tab-body .pro-card .pro-content .sku-img-list ul li {
  float: left;
  width: 40px;
  text-align: center;
  cursor: pointer;
}
#custom-online .tab-body .pro-card .pro-content .sku-img-list ul li img {
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  padding: 2px;
  object-fit: contain;
}
#custom-online .tab-body .pro-card .pro-content .sku-img-list ul li img:hover {
  border: 2px solid red;
  padding: 1px;
}
.prod_drop_con {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}
.prod_drop_item,
.prod_drop_item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.prod_drop_desc,
.prod_drop_desc {
  display: flex;
  flex-direction: column;
}
.prod_drop_item,
.prod_drop_item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.prod_drop_desc span:nth-child(1),
.prod_drop_desc span:nth-child(1) {
  text-align: center;
  font-size: 12px;
  font-family: PingFangSC-Semibold, "PingFang SC";
  font-weight: 600;
  color: rgb(153, 153, 153);
  line-height: 20px;
}
.prod_drop_desc span:nth-child(2),
.prod_drop_desc span:nth-child(2) {
  text-align: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, "PingFang SC";
  font-weight: 400;
  color: rgb(153, 153, 153);
  line-height: 20px;
}
.sku-img-list {
  position: absolute;
  display: none;
}
.sku-img-list {
  top: 251px;
  left: 16px;
}

.pro-card-content-detail:hover {
  .sku-img-list {
    display: block;
  }
}

.detail .price:hover {
  .el-icon-arrow-down {
    display: none;
  }
  .el-icon-arrow-up {
    display: inline-block !important;
  }
}
.order-area {
  margin: 2px 0 0 0;
}
.order-area ::v-deep {
  .el-table__body-wrapper {
    display: none;
  }
  .el-table td,
  .el-table th {
    padding: 0;
  }
}
.evenflow_scale:hover {
  transform: scale(1.05, 1.05);
}
.evenflow_scale {
  transition: all 0.6s ease;
}
</style>
