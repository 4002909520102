<template>
  <div>
    <div class="vip-wrap">
      <div class="banner"></div>
      <div class="list">
        <div class="top">
          <div class="one">权限功能对比</div>
          <div class="item" v-for="(item, index) in serviceList" :key="index">
            <div class="tip">{{ item.title }}</div>
            <div class="name" v-if="index === 0">
              <span style="color: #333">{{ item.cost }}</span>
            </div>
            <div class="name" v-else>
              ￥ <span>{{ item.cost }}</span> /
              {{ item.timeType == "01" ? "年" : "月" }}
            </div>
            <div class="special" v-if="index === 2">推荐</div>
            <div
              class="btn2"
              @click="handleJump('/index')"
              v-if="index < Number(userExt.vipType)"
            >
              立即使用
            </div>
            <div
              class="btn2"
              @click="handleJump('/system/account/purchaseMember?vip=' + index)"
              v-else-if="index >= Number(userExt.vipType) && index != 0"
            >
              立即购买
            </div>
          </div>
        </div>
        <div class="content">
          <div class="item" v-for="(i, index) in contents" :key="index">
            <div class="one">{{ i.name }}</div>
            <div :class="['two', i.line1, i.line1 == '0个' ? 'no' : '']">
              {{
                ["no", "yes"].includes(i.line1)
                  ? ""
                  : i.line1 == "0个"
                  ? ""
                  : i.line1
              }}
            </div>
            <div :class="['three', i.line2]">
              {{ ["no", "yes"].includes(i.line2) ? "" : i.line2 }}
            </div>
            <div :class="['four', i.line3]">
              {{ ["no", "yes"].includes(i.line3) ? "" : i.line3 }}
            </div>
            <div :class="['five', i.line4]">
              {{ ["no", "yes"].includes(i.line4) ? "" : i.line4 }}
            </div>
            <div :class="['six', i.line5]">
              {{ ["no", "yes"].includes(i.line5) ? "" : i.line5 }}
            </div>
          </div>
        </div>
      </div>
      <div class="tip">
        *
        付费用户，暂不支持在到期之前取消。若到期后未续费的用户，对应服务会在到期后停用。
      </div>
      <div
        @click="handleJump('/system/account/purchaseMember')"
        class="info-btn"
      >
        咨询更多价格及优惠信息
      </div>

      <div class="vip-list">
        <div class="item" v-for="(item, index) in serviceListAdd" :key="index">
          <div v-if="false" class="tip2">{{ item.tip }}</div>
          <div class="name">{{ item.title }}</div>
          <div class="desc">
            ¥
            <span>{{ item.price }}</span>
            {{ item.unit }}
          </div>
          <div
            @click="
              handleJump(
                '/system/account/purchaseMember?type=2&service=' +
                  item.serviceItem
              )
            "
            class="btn"
          >
            立即购买
          </div>
        </div>
      </div>
      <div
        @click="handleJump('/system/account/purchaseMember?type=2')"
        class="info-btn"
      >
        咨询更多价格及优惠信息
      </div>
    </div>
  </div>
</template>

<script>
import { getUserProfile } from "@/api/system/user"
import {
  getSeriveList,
  getAddServiceList,
  getVipServiceList,
  buyVipService,
  buyAddService,
} from "@/api/mine/service"
import { mapGetters } from "vuex"
import banner from "@/assets/images/vip/banner.png"

export default {
  components: {},
  computed: {
    ...mapGetters(["dictData", "dictMap", "token", "user"]),
  },
  data() {
    return {
      contents: [],
      banner: banner,
      vipList: [
        {
          tip: "企业版扩容5折",
          name: "图库容量",
          desc: "25",
        },
        {
          tip: "买5送1",
          name: "空白底板",
          desc: "1,000",
        },
        {
          tip: "企业版扩容5折",
          name: "设计次数",
          desc: "90",
        },
        {
          tip: "企业版扩容5折",
          name: "独有域名",
          desc: "200",
        },
      ],
      userExt: {},
      serviceList: [],
      serviceListAdd: [],
    }
  },

  methods: {
    mapName(index) {
      const map = {
        0: "/GB(360天)",
        1: "/个",
        2: "/万次",
        3: "/个",
      }
      return map[index]
    },
    getUserProfile() {
      getUserProfile().then((res) => {
        this.userExt = res.user
      })
    },
    handleJump(path) {
      this.$router.push({ path: path })
    },
    async getVipServiceList() {
      let result = await getVipServiceList()
      let serviceList = result
      serviceList[0].title = "普通用户"
      serviceList[0].cost = "永久免费"
      serviceList[0].desc = "适合初创公司"
      serviceList[1].title = "基础版(V0)"
      serviceList[1].desc = "适合初创公司"
      serviceList[2].title = "创业版(V1)"
      serviceList[2].desc = "适合初创公司"
      serviceList[3].title = "专业版(V2)"
      serviceList[3].desc = "适合发展期公司"
      serviceList[4].title = "企业版(V3)"
      serviceList[4].desc = "适合大型公司"
      this.serviceList = serviceList
      this.costPrice = serviceList[0].cost
      this.servicePrice = serviceList[0].cost

      this.contents = []

      this.contents.push({
        name: "老板号（主账号）",
        line1: "1" + "个",
        line2: "1" + "个",
        line3: "1" + "个",
        line4: "1" + "个",
        line5: "1" + "个",
      })

      this.contents.push({
        name: "子账号数量",
        line1: serviceList[0].accountNum + "个",
        line2: serviceList[1].accountNum + "个",
        line3: serviceList[2].accountNum + "个",
        line4: serviceList[3].accountNum + "个",
        line5: serviceList[4].accountNum + "个",
      })

      this.contents.push({
        name: "开通私有产品",
        line1: "no",
        line2: "yes",
        line3: "yes",
        line4: "yes",
        line5: "yes",
      })

      this.contents.push({
        name: "API专属接入",
        line1: "no",
        line2: "yes",
        line3: "yes",
        line4: "yes",
        line5: "yes",
      })

      this.contents.push({
        name: "店铺数量",
        line1: serviceList[0].shopNum + "个",
        line2: serviceList[1].shopNum + "个",
        line3: serviceList[2].shopNum + "个",
        line4: serviceList[3].shopNum + "个",
        line5: serviceList[4].shopNum + "个",
      })

      this.contents.push({
        name: "设计次数",
        line1:
          serviceList[0].designNum / 10000 +
          "万次/" +
          (serviceList[1].timeType == "01" ? "年" : "月"),
        line2:
          serviceList[1].designNum / 10000 +
          "万次/" +
          (serviceList[1].timeType == "01" ? "年" : "月"),
        line3:
          serviceList[2].designNum / 10000 +
          "万次/" +
          (serviceList[2].timeType == "01" ? "年" : "月"),
        line4:
          serviceList[3].designNum / 10000 +
          "万次/" +
          (serviceList[3].timeType == "01" ? "年" : "月"),
        line5:
          serviceList[4].designNum / 10000 +
          "万次/" +
          (serviceList[4].timeType == "01" ? "年" : "月"),
      })

      this.contents.push({
        name: "图库容量",
        line1: (serviceList[0].mapSpace / 1024).toFixed(1) + "G",
        line2: (serviceList[1].mapSpace / 1024).toFixed(1) + "G",
        line3: (serviceList[2].mapSpace / 1024).toFixed(1) + "G",
        line4: (serviceList[3].mapSpace / 1024).toFixed(1) + "G",
        line5: (serviceList[4].mapSpace / 1024).toFixed(1) + "G",
      })
      this.contents.push({
        name: "产品数量",
        line1: "0",
        line2: "0-1000",
        line3: "1001-5000",
        line4: "5001-10000 ",
        line5: "所有",
      })

      this.contents.push({
        name: "JIT日限件数",
        line1: serviceList[0].limitNum + '件',
        line2: serviceList[1].limitNum + '件',
        line3: serviceList[2].limitNum + '件',
        line4: serviceList[3].limitNum + '件',
        line5: serviceList[4].limitNum + '件',
      })
    },
    async getAddServiceList() {
      let result = await getAddServiceList()
      let serviceListAdd = result
      serviceListAdd.map((record) => {
        if (record.serviceItem == "ACCOUNT") {
          record.title = "子账号数量"
          record.unit =
            `/${record.serviceNum}个/` + (record.timeType == "01" ? "年" : "月")
        }
        if (record.serviceItem == "SHOP") {
          record.title = "店铺数量"
          record.unit =
            `/${record.serviceNum}个/` + (record.timeType == "01" ? "年" : "月")
        }
        if (record.serviceItem == "DESIGN") {
          record.title = "设计次数"
          record.unit =
            `/${record.serviceNum / 10000}万次/` +
            (record.timeType == "01" ? "年" : "月")
        }
        if (record.serviceItem == "MAP") {
          record.title = "图库容量"
          record.unit =
            `/${(record.serviceNum / 1024).toFixed(2)}G/` +
            (record.timeType == "01" ? "年" : "月")
        }
      })
      this.serviceListAdd = serviceListAdd
    },
  },
  mounted() {
    if (this.token) {
      this.getUserProfile()
    }
    this.getVipServiceList()
    this.getAddServiceList()
  },
}
</script>

<style></style>

<style lang="scss" scoped>
.vip-wrap {
  .banner {
    width: 100%;
    height: 15.47vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: url("../../assets/images/vip/banner.png") no-repeat;
    background-size: 100% 100%;

    .title {
      font-size: 48px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      line-height: 38px;
      margin-bottom: 20px;
    }

    .sub {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      line-height: 38px;
    }
  }
}

.list {
  width: 1200px;
  margin: -60px auto 20px;
  border: 1px solid #dddddd;
  border-radius: 6px;
  background: #ffffff;

  .top {
    height: 280px;
    display: flex;

    .one {
      width: 320px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      border-bottom: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
    }

    .item {
      width: 220px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-bottom: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
      position: relative;

      .special {
        position: absolute;
        top: 50px;
        right: 32px;
        line-height: 24px;
        padding: 0 15px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #fff;
        height: 24px;
        background: linear-gradient(270deg, #fad126, #ff544f);
        border-radius: 12px 12px 12px 0px;
      }

      &:last-child {
        border-right: 0;
      }

      .tip {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-style: italic;
        color: #333333;
        margin-bottom: 10px;
      }

      .name {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #181b1c;
        margin-bottom: 20px;

        span {
          color: rgb(73, 52, 178);
          font-size: 26px;
          font-weight: bold;
        }
      }

      .btn1 {
        color: #fff;
        width: 120px;
        height: 48px;
        background: linear-gradient(270deg, rgb(73, 52, 178), #4892ff);
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .btn2 {
        color: rgb(73, 52, 178);
        width: 120px;
        height: 48px;
        background: #f2f4ff;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          color: #fff;
          width: 120px;
          height: 48px;
          background: rgb(73, 52, 178);
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .content {
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #dddddd;
      height: 60px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;

      .no {
        &::before {
          content: "";
          width: 15px;
          height: 15px;
          background: url("../../assets/images/vip/no.png") no-repeat;
          background-size: contain;
        }
      }

      .yes {
        &::before {
          content: "";
          width: 20px;
          height: 15px;
          background: url("../../assets/images/vip/yes.png") no-repeat;
          background-size: contain;
        }
      }

      &:last-child {
        border-bottom: 0;
      }

      .one {
        width: 320px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        height: 100%;
      }

      .two,
      .three,
      .four,
      .five,
      .six {
        height: 100%;
        width: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-bottom: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
      }

      .six {
        border-right: 0;
      }
    }
  }
}

.tip {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 18px;
  margin: 30px auto;
  width: 100%;
  text-align: center;
}

.info-btn {
  width: 300px;
  height: 64px;
  background: rgb(73, 52, 178);
  border-radius: 6px;
  margin: 50px auto 90px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
}

.vip-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  margin: 60px auto 30px;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 270px;
    height: 336px;
    background: #ffffff;
    border: 1px solid #e9e9e9;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    position: relative;

    .tip2 {
      position: absolute;
      bottom: 175px;
      right: 32px;
      line-height: 24px;
      padding: 0 15px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #fff;
      height: 24px;
      background: linear-gradient(270deg, #fad126, #ff544f);
      border-radius: 12px 12px 12px 0px;
    }

    .name {
      width: 75%;
      text-align: center;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #363839;
    }

    .desc {
      width: 75%;
      margin: 45px auto 20px;
      font-size: 16px;
      color: #181b1c;
      text-align: center;

      span {
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: rgb(73, 52, 178);
      }
    }

    .btn {
      width: 120px;
      height: 48px;
      background: #f2f4ff;
      border-radius: 6px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgb(73, 52, 178);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        background: rgb(73, 52, 178);
        color: #ffffff;
      }
    }
  }
}
.top-banner {
  width: 100%;
}
</style>
