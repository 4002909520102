<template>
  <div class="register-2017-body">
    <div class="pro-single">
      <div class="pro-category">
        <span v-for="(record, index) in pathList" :key="index"
          >{{ record.typeName }} >
        </span>
        {{ detail.proName }}
      </div>
      <div class="content">
        <div class="f">
          <div class="pro-sku">
            <div class="pro-sku-img" style="width: 500px">
              <div
                class="main-pic"
                style="width: 500px; height: 500px;"
              >
                <pic-zoom :url="mainPic" :scale="3"></pic-zoom>
              </div>
              <div class="sku-pic-list">
                <a class="prev border text-noSelect" @click="prevPage">&lt;</a>
                <a class="next border text-noSelect" @click="nextPage">&gt;</a>
                <div class="items">
                  <ul>
                    <li v-for="(pic, index) in currentSku.picList" :key="index">
                      <div class="index">{{ index + 1 }}</div>
                      <img
                        :class="{ 'img-select': pic.picUrl === mainPic }"
                        class="img-normal"
                        :src="pic.picUrl"
                        title1="99"
                        @mouseover="hoverImg($event, pic.picUrl)"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="pro-sku-info">
              <div class="pro-title">
                <div class="tag">
                  {{ productType }}
                </div>
                <div>{{ detail.proName }}</div>
              </div>
              <div class="price-list">
                <div class="price-area" style="margin-bottom: 24px">
                  <div class="title">售价</div>
                  <div class="price">
                    ￥{{
                      user.userType == "00"
                        ? currentSku.sellPrice
                        : currentSku.price
                    }}
                  </div>
                  <div v-if="detail.moq" class="limit">
                    {{ detail.moq }}件起订
                  </div>
                  <div class="limit">
                    <span style="color:green;" v-if="currentSku.stock > 10">库存充足</span>
                    <span style="color:orange;"  v-if="currentSku.stock <= 10 && currentSku.stock > 0">库存紧张</span>
                    <span style="color:red;"  v-if="currentSku.stock == 0">售罄</span>
                  </div>
                </div>
                <div class="price-area" style="margin-bottom: 24px">
                  <div class="title">批发&nbsp;</div>
                  <div class="detail">
                    <div
                      v-for="(record, index) in currentSku.priceList"
                      :key="index"
                      class="price-batch"
                    >
                      <div class="money">￥{{ record.price }}</div>
                    <div class="num" style="transform: translateX(3px)">
                        {{ record.lowNum }}-{{ record.highNum }}件
                      </div>
                    </div>
                  </div>
                </div>
                <div class="price-area" >
                  <div class="title">审核价</div>
                    <div class="detail" style="transform: translateX(-9px)">{{ detail.auditPrice ? '￥'+ detail.auditPrice  : '暂无' }}</div>
                </div>
                <div v-if="false" class="caculator">成本计算</div>
              </div>
              <div class="product-params">
                <div class="title">参数</div>
                <div class="texture-container">
                   <div class="info-item">
                    <div class="info-value">{{ dictMap['country_site'][detail.state] }}</div>
                    <div class="info-key">国家</div>
                  </div>
                  <div class="info-item">
                    <div class="info-value">{{ detail.pdMaterial }}</div>
                    <div class="info-key">材质</div>
                  </div>
                  <div class="info-item">
                    <div class="info-value">{{ detail.prodTech }}</div>
                    <div class="info-key">生产工艺</div>
                  </div>
                  <div class="info-item">
                    <div class="info-value">{{ currentSku.weight }}g</div>
                    <div class="info-key">重量</div>
                  </div>
                  <div class="info-item">
                    <div class="info-value">{{ detail.mostDelivery }}小时</div>
                    <div class="info-key">发货时效(80%订单)</div>
                  </div>
                </div>
              </div>
              <div class="sku-attr">
                <div class="sku-name" style="margin: 7px 32px 0 0">颜色</div>
                <div class="sku-detail" style="margin: 2px 0 0 -7px">
                  <!-- <el-popover
                    placement="top-start"
                    :title="color"
                    width="100"
                    trigger="hover"
                    :content="color"
                    v-for="(color, index) in attribute['颜色']"
                    :key="index"
                  >
                    </el-popover> -->
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="color"
                    placement="top"
                    v-for="(color, index) in colorList"
                    :key="index"
                  >
                    <a
                      :style="{ background: dictMap['color'][color] }"
                      :class="{ 'is-select': currentAttr.color === color }"
                      @click="handleSelectColor(color)"
                      class="sku-color"
                    ></a>
                  </el-tooltip>
                </div>
              </div>
              <div class="sku-attr" style="padding: 1px 0 0 8px">
                <div class="sku-name" style="margin: 7px 23px 0 0">尺码</div>
                <div class="sku-detail" style="margin: 0 0 0 6px">
                  <a
                    :class="{ 'is-select': currentAttr.size === size }"
                    @click="handleSelectSize(size)"
                    class="sku-size"
                    v-for="(size, index) in sizeList"
                    :key="index"
                    >{{ size }}</a
                  >
                </div>
              </div>
              <div v-if="detail.productType === '02'" class="sku-attr">
                <div class="sku-name">数量</div>
                <div class="sku-detail" style="margin: 0">
                  <el-input-number
                    style="width: 90px"
                    v-model="num"
                    size="small"
                    controls-position="right"
                    :precision="0"
                    :min="1"
                    :step="1"
                    :max="9999"
                  ></el-input-number>
                </div>
              </div>
              <div class="normal-btn-group">
                 <el-button
                  v-if="
                    (detail.productType === '02' || designId) &&
                      user.userType != '00'
                  "
                  type="primary"
                  @click="handleAddCart"
                  >加入购物车</el-button
                >
                <el-button
                  v-if="detail.productType === '01' && !designId"
                  type="danger"
                  @click="handleCustomDesign(detail.pId)"
                  >定制设计</el-button
                >
                <el-button
                  v-if="
                    detail.productType === '01' && !designId && showBuyButton
                  "
                  type="primary"
                  @click="handleBuyButtom(detail.pId)"
                  >底板购买</el-button
                >
                <el-button
                  v-if="detail.productType === '02' && !designId"
                  type="danger"
                  @click="handleAddProduct(detail.pId)"
                  >加入我的现货</el-button
                >
                <el-button
                  v-if="showAddDesignButton"
                  type="danger"
                  @click="handleAddDesign(detail.designId, detail.pId)"
                  >加入我的主题</el-button
                >
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="product-detail">
          <div id="tabs" class="tabs">
            <div
              v-for="(record, index) in menuList"
              :key="record.key"
              @click="handleClickMenu(record.key, index)"
              :class="{ active: currentMenu === record.key }"
            >
              {{ record.value }}
            </div>
          </div>
          <div class="body">
            <div class="p-detail">
              <div class="title">产品详情</div>
              <div class="description">
                <div class="params">
                  <div class="params-element">
                    <div class="params-element-key">定制名称 :</div>
                    <div
                      :title="detail.proName"
                      style="width: calc(100% - 100px)"
                      class="params-element-value text-ellipsis"
                    >
                      {{ detail.proName }}
                    </div>
                  </div>
                  <div class="params-element">
                    <div class="params-element-key">英文名称 :</div>
                    <div
                      :title="detail.engProName"
                      style="width: calc(100% - 100px)"
                      class="params-element-value text-ellipsis"
                    >
                      {{ detail.engProName }}
                    </div>
                  </div>
                  <div class="params-element">
                    <div class="params-element-key">产品编号 :</div>
                    <div class="params-element-value">
                      <span
                        style="color: #4934b2; cursor: pointer"
                        @click="handleJumpDetail(detail.pId)"
                        >{{ detail.pId }}</span
                      >
                    </div>
                  </div>
                  <div class="params-element">
                    <div class="params-element-key">生产工艺 :</div>
                    <div class="params-element-value">
                      {{ detail.prodTech }}
                    </div>
                  </div>
                  <div class="params-element">
                    <div class="params-element-key">产品材质 :</div>
                    <div class="params-element-value">
                      {{ detail.pdMaterial }}
                    </div>
                  </div>
                </div>
                <div class="others">
                  <div class="others-title">产品描述：</div>
                  <div v-html="detail.remarks"></div>
                  <div class="others-title">英文描述：</div>
                  <div v-html="detail.engProductDesc"></div>
                  <div class="others-title">温馨提示：</div>
                  <div v-html="detail.reminder"></div>
                </div>
              </div>
            </div>
            <div class="p-design">
              <div class="title">设计说明</div>
              <div v-html="detail.desiHint" class="description"></div>
            </div>
            <div class="p-size">
              <div class="title">产品尺码</div>
              <div class="description">
                <DescTable :data="detail.sizeList"></DescTable>
              </div>
            </div>
            <div class="p-package">
              <div class="title">包装规格</div>
              <div class="description">
                <DescTable :data="detail.packList"></DescTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as math from "mathjs"
import { getProductDetail } from "@/api/customProduct/product"
import { getPlatPath } from "@/api/sysSupport/category"
import { mapGetters } from "vuex"
import { addCart } from "@/api/order/cart"
import DescTable from "@/views/customProduct/online/components/descTable"
import PicZoom from "vue-piczoom"
import { getDesign, addDesign, addDesignCopy } from "@/api/customProduct/design"

export default {
  name: "productSingle",
  components: { DescTable, PicZoom },
  computed: {
    ...mapGetters(["dictData", "dictMap", "user", "token"]),
    productType() {
      let productType = this.dictMap["product_type"][this.detail.productType]
      const designId = this.$route.query.designId
      if (designId) {
        productType = "定制产品"
      }
      return productType
    },
     showAddDesignButton() {
      let showButton = this.$route.query.showButton
      if (showButton) {
        return true
      } else {
        return false
      }
    },
    sizeList() {
      let sizeList = []
      if (this.detail.skuList) {
        for (let record of this.detail.skuList) {
          let color = record.properVal.split(":")[0]
          let size = record.properVal.split(":")[1]
          if (color == this.currentAttr.color) {
            if (!sizeList.includes(size)) {
              sizeList.push(size)
            }
          }
        }
      }
      return sizeList
    },
    colorList() {
      let colorList = []
      if (this.detail.skuList) {
        for (let record of this.detail.skuList) {
          let color = record.properVal.split(":")[0]
          if (!colorList.includes(color)) {
            colorList.push(color)
          }
        }
      }

      return colorList
    },
  },
  data() {
    return {
      detail: {},
      attribute: {},
      currentAttr: {
        color: "",
        size: "",
      },
      currentSku: {},
      mainPic: undefined,
      num: 1,
      skuList: [], //sku属性集合
      tempLength: 0,
      menuList: [
        {
          key: "p-detail",
          value: "产品详情",
          offsetTop: 0,
        },
        {
          key: "p-design",
          value: "设计说明",
          offsetTop: 0,
        },
        {
          key: "p-size",
          value: "产品尺码",
          offsetTop: 0,
        },
        {
          key: "p-package",
          value: "包装规格",
          offsetTop: 0,
        },
      ],
      currentMenu: "p-detail",
      pathTree: {},
      pathList: [],
      designId: "",
    }
  },
  created() {
    this.loadData()
  },
  mounted() {
    var oDiv = document.getElementById("tabs")
    var oTop = oDiv.offsetTop
    //计算每个位置的坐标
    var pDetail = document.querySelector(".p-detail")
    var pDesign = document.querySelector(".p-design")
    var pSize = document.querySelector(".p-size")
    var pPackage = document.querySelector(".p-package")
    this.menuList[0].offsetTop = pDetail.offsetTop
    this.menuList[1].offsetTop = pDesign.offsetTop
    this.menuList[2].offsetTop = pSize.offsetTop
    this.menuList[3].offsetTop = pPackage.offsetTop

    window.onscroll = function() {
      console.log(1)
      var oheight =
        document.documentElement.scrollTop || document.body.scrollTop
      if (oTop < oheight) {
        oDiv.style.position = "fixed"
        oDiv.style.width = "calc(100% - 350px)"
        oDiv.style.top = 0
      } else {
        oDiv.style.position = "relative"
        oDiv.style.width = "100%"
      }
    }
  },
  methods: {
    loadData() {
      const pId = this.$route.query.pId
      const designId = this.$route.query.designId
      if (designId) {
        this.designId = designId
      } else {
        this.designId = ""
      }
      if (designId) {
        getDesign(designId)
          .then((res) => {
            this.processData(res)
          })
          .catch((err) => {})
      } else if (pId) {
        getProductDetail(pId)
          .then((res) => {
            this.processData(res)
          })
          .catch((err) => {})
      }
    },
    handleJumpDetail(pId) {
      let routeUrl = this.$router.resolve({
        path: "/select/custom/product",
        query: { pId: pId },
      })
      window.open(routeUrl.href, "_blank")
    },
    async processData(res) {
       let data = JSON.parse(JSON.stringify(res.data))
      if (res.code == 200) {

        for (let sku of res.data.skuList) {
          for (let i = 0; i < sku.picList.length; i++) {
            if (sku.picList[i].isMain == "1") {
              sku.picList.splice(i, 1)
              i--
            }
          }
        }
        this.attribute = JSON.parse(res.data.attribute)
        this.detail = res.data
        let properVal = res.data.skuList[0].properVal.split(":")
        this.currentAttr.color = properVal[0]
        this.currentAttr.size = properVal[1].replace(/\\/g, "")
        this.currentSku = res.data.skuList[0]
        
        // if (this.currentSku.picList.length == 0) {
          this.mainPic = data.skuList[0].picList[0].picUrl
        // }
        let result = await getPlatPath(this.detail.typeId)
        this.pathTree = result.data
        this.getPathList()
      }
    },
    getPathList() {
      let data = this._.clone(this.pathTree)
      this.pathList = [
        {
          typeId: "",
          typeName: "所有分类",
        },
      ]
      if (data) {
        this.pathList.push({
          typeId: data.typeId,
          typeName: data.typeName,
        })
      }
      while (data.children) {
        data = data.children
        this.pathList.push({
          typeId: data.typeId,
          typeName: data.typeName,
        })
      }
    },
    hoverImg(e, url) {
      this.mainPic = url
    },
    async handleAddCart() {
      if (!this.token) {
        this.msgError("请登录后再进行操作")
        this.$router.push({
          path: "/login",
        })
        return
      }
      let form = []
      let currentSku = this._.clone(this.currentSku)
      form.skuId = form.proSkuId ? form.proSkuId : form.skuId

      form.push({
        pId: this.detail.pId,
        designId: this.detail.designId,
        properName: currentSku.properName,
        productNum: this.num,
        properVal: currentSku.properVal,
        skuId: currentSku.skuId ? currentSku.skuId : currentSku.proSkuId,
        productType: this.detail.productType,
      })

      try {
        await addCart(form)
        this.$store.dispatch("GetIndexStat")
        this.msgSuccess("加入购物车成功")
      } catch (error) {}
    },
    //立即购买
    buyNow() {
      let subtotal = math.multiply(
        math.bignumber(this.currentSku.price),
        math.bignumber(this.num)
      )
      const params = {
        operNo: this.proData.operNo,
        pId: this.proData.pId,
        price: this.currentSku.price,
        picUrl: this.currentSku.picList[0].picUrl,
        proAmount: this.num,
        proTitle: this.proData.proTitle,
        properName: this.currentSku.properName,
        properVal: this.currentSku.properVal,
        skuId: this.currentSku.skuId,
        subtotal: math.number(subtotal).toFixed(2),
        selfType: this.proData.selfType,
      }
      this.$router.push({
        path: "/order/myOrder/settlement",
        query: { checkList: [params], total: params.subtotal },
      })
    },
    async handleAddProduct(pId) {
      try {
        if (!this.token) {
          this.msgError("请登录后再进行操作")
          this.$router.push({
            path: "/login",
          })
          return
        }
        await addDesign({ pId: pId })
        this.msgSuccess("加入我的现货成功")
      } catch (error) {}
    },
    async handleAddDesign(designId, pId) {
      try {
        if (!this.token) {
          this.msgError("请登录后再进行操作")
          this.$router.push({
            path: "/login",
          })
          return
        }
        await addDesignCopy({ designId: designId, pId })
        this.msgSuccess("加入我的主题成功")
      } catch (error) {}
    },
    nextPage() {
      let scrollDiv = $(".sku-pic-list .items ul") //进行移动动画的容器
      let scrollItems = $(".sku-pic-list .items ul li") //移动容器里的集合
      let moveLength = scrollItems.eq(0).width() * 1 + 12 //计算每次移动的长度
      let countLength = (scrollItems.length - 4) * scrollItems.eq(0).width() //计算总长度,总个数*单个长度
      if (this.tempLength < countLength) {
        if (countLength - this.tempLength > moveLength) {
          scrollDiv.animate({ left: "-=" + moveLength + "px" }, 300)
          this.tempLength += moveLength
        } else {
          scrollDiv.animate(
            { left: "-=" + (countLength - this.tempLength) + "px" },
            300
          )
          this.tempLength += countLength - this.tempLength
        }
      }
    },
    prevPage() {
      let scrollDiv = $(".sku-pic-list .items ul") //进行移动动画的容器
      let scrollItems = $(".sku-pic-list .items ul li") //移动容器里的集合
      let moveLength = scrollItems.eq(0).width() * 1 //计算每次移动的长度
      if (this.tempLength > 0) {
        if (this.tempLength > moveLength) {
          scrollDiv.animate({ left: "+=" + moveLength + "px" }, 300)
          this.tempLength -= moveLength
        } else {
          scrollDiv.animate({ left: "+=" + this.tempLength + "px" }, 300)
          this.tempLength = 0
        }
      }
    },
    handleClickMenu(key, index) {
      this.currentMenu = key
      window.scrollTo(0, this.menuList[index].offsetTop - 16)
    },
    handleCustomDesign(pId) {
      window.open("/design/online/tool?pId=" + pId)
    },
    handleSelectColor(color) {
      this.currentAttr.color = color
      this.selectSku()
    },
    handleSelectSize(size) {
      this.currentAttr.size = size
      this.selectSku()
    },
    selectSku() {
      for (let record of this.detail.skuList) {
        record.properVal = record.properVal.replace(/\\/g, "")
        if (
          record.properVal ===
          `${this.currentAttr.color}:${this.currentAttr.size}`
        ) {
          this.currentSku = record
          this.mainPic = this.currentSku.picList[0].picUrl
          break
        }
      }
    },
    handleBuyButtom(pId) {
      window.open("/select/selection/addModel?pId=" + pId)
    },
  },
}
</script>

<style lang="scss" scoped>
.register-2017-body {
  width: 1400px;
  margin: 30px auto;
  padding: 16px 0;
}

.content {
  background: #ffffff;
  padding: 6px 16px 16px 16px;
}
.pro-single {
  width: 100%;
  font-size: 14px;
}
.pro-single .pro-category {
  width: 100%;
  color: #606266;
}
.pro-single .pro-category span {
  font-size: 13px;
}
.pro-single .f .pro-sku {
  margin-top: 10px;
  display: flex;
}
.pro-single .f .pro-sku .pro-sku-info {
  margin-left: 35px;
  width: 100%;
}
.pro-single .f .pro-sku .pro-sku-img .sku-pic-list {
  clear: both;
  margin-top: 16px;
  width: 502px;
}
.pro-single .f .pro-sku .pro-sku-img .sku-pic-list .prev {
  float: left;
  margin-right: 4px;
}
.pro-single .f .pro-sku .pro-sku-img .sku-pic-list .next {
  float: right;
}
.pro-single .f .pro-sku .pro-sku-img .sku-pic-list .prev,
.pro-single .f .pro-sku .pro-sku-img .sku-pic-list .next {
  display: block;
  font-family: "宋体";
  text-align: center;
  width: 17px;
  height: 70px;
  background-color: #FAFAFA;
  cursor: pointer;
  text-decoration: none;
  padding: 23px 14px 2px 9px;
  color: #aaaaaa;
  border-radius: 15px;
  margin: 6px 0 0 0;
  font-size: 12px;
  font-weight: bold;
  box-sizing: border-box;
}
.pro-single .f .pro-sku .pro-sku-img .sku-pic-list .prev:hover,
.pro-single .f .pro-sku .pro-sku-img .sku-pic-list .next:hover {
  background-color: #eeeeee;
}
.zoom-image ::v-deep img{
  border-radius: 20px;
}
.pro-single .f .pro-sku .pro-sku-img .sku-pic-list .items {
  float: left;
  position: relative;
  width: 442px;
  height: 86px;
  overflow: hidden;
  padding: 0 8px;
  box-sizing: border-box;
}
.pro-single .f .pro-sku .pro-sku-img .sku-pic-list ul {
  position: absolute;
  width: 99999px;
  height: 86px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
  list-style-type: none;
}
.pro-single .f .pro-sku .pro-sku-img .sku-pic-list ul li {
  float: left;
  width: 86px;
  height: 86px;
  margin-right: 8px;
  text-align: center;
  position: relative;
  .index {
    position: absolute;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    left: 9px;
    top: 5px;
    background: #4934b2;
    color: #ffffff;
    font-size: 12px;
    line-height: 17px;
  }
}
.pro-single .f .pro-sku .pro-sku-img .sku-pic-list ul li img {
  width: 80px;
  height: 80px;
  padding: 1px;
  border-radius: 12px;
  object-fit: contain;
}
.pro-single .f .pro-sku .pro-sku-img .sku-pic-list ul li img:hover {
  border: 2px solid #4934b2;
  padding: 1px;
}
.img-normal {
  border: 2px solid #fff;
}
.img-select {
  border: 2px solid #4934b2 !important;
}
.pro-title {
  margin: 0 0 16px 0;
  font-size: 20px;
  min-height: 30px;
  .tag {
    background: #ff8f21;
    color: #ffffff;
    font-size: 12px;
    float: left;
    padding: 2px 8px;
    border-radius: 5px;
    margin: 0 8px 0 0;
    margin-top: 5px;
  }
}

.pro-single .f .pro-sku .pro-sku-info .price-list {
  padding: 30px 30px 30px 8px;
  background-color: #fafafa;
  font-size: 14px;
  position: relative;
  .caculator {
    cursor: pointer;
    font-size: 12px;
    letter-spacing: 1px;
    color: #4934b2;
    position: absolute;
    top: 18px;
    right: 10px;
  }
  .price-area {
    overflow: hidden;
    .title {
      float: left;
      margin: 0 32px 0 0;
    }
    .price {
      float: left;
      font-size: 26px;
      color: #f25551;
      font-weight: bold;
      line-height: 26px;
    }
    .detail {
      display: flex;
      .price-batch {
        flex: 1;
        .money {
          margin: 0 0 4px 0;
          font-size: 16px;
        }
        .num {
          font-size: 14px;
          line-height: 14px;
          margin-top: 8px;
          color: #999;
        }
      }
    }
  }
}
.product-params {
  margin: 24px 0 0 0;
  padding: 8px;
  .title {
    float: left;
    font-size: 14px;
    margin: 0 32px 0 0;
  }
  .texture-container {
    display: flex;
    font-size: 14px;
    line-height: 24px;
    .info-item {
      flex: 1;
      .info-key {
        margin-top: 14px;
        line-height: 14px;
        color: #999;
      }
      .info-value {
      }
    }
  }
}

.pro-single .f .pro-sku .pro-sku-info .sku-attr {
  overflow: hidden;
  margin-top: 15px;
  padding: 0 0 0 8px;
  display: flex;

  .sku-name {
    margin: 0 32px 0 0;
    width: 36px;
  }
}
.pro-single .f .pro-sku .pro-sku-info .normal-btn-group {
  display: flex;
  padding-left: 8px;
  margin: 16px 0 0 0;
  button {
    margin-right: 20px;
  }
}
.pro-single .f .pro-sku .pro-sku-info .normal-btn-group .add-to-shopping-cat,
.pro-single .f .pro-sku .pro-sku-info .normal-btn-group .buy-now {
  width: 115px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
}
.pro-single .f .pro-sku .pro-sku-info .normal-btn-group .add-to-shopping-cat {
  color: white;
  background-color: red;
  border: 1px solid red;
}
.pro-single
  .f
  .pro-sku
  .pro-sku-info
  .normal-btn-group
  .add-to-shopping-cat:active {
  color: white;
  background-color: rgba(255, 0, 0, 0.5);
  border: 1px solid rgba(255, 0, 0, 0.5);
}
.pro-single .f .pro-sku .pro-sku-info .normal-btn-group .buy-now {
  color: white;
  background-color: #4934b2;
  border: 1px solid #4934b2;
  margin-left: 0px;
  margin-right: 40px;
}
.pro-single .f .pro-sku .pro-sku-info .normal-btn-group .add-now {
  color: white;
  background-color: #ff8f21;
  border: 1px solid #ff8f21;
  margin-left: 20px;
  width: 150px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
}
.pro-single .f .pro-sku .pro-sku-info .normal-btn-group .buy-now:active {
  color: white;
  background-color: rgba(66, 139, 202, 0.5);
  border: 1px solid rgba(66, 139, 202, 0.5);
}
.pro-single .f .pro-sku .pro-sku-info .custom-btn {
  width: 150px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: white;
  border: 1px solid #4934b2;
  background-color: #4934b2;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
}
.pro-single .f .pro-sku .pro-sku-info .custom-btn:active {
  background-color: rgba(66, 139, 202, 0.78);
  border-color: rgba(66, 139, 202, 0.78);
}
.product-detail {
  margin: 32px 0 0 0;
  .tabs {
    background-color: #fafafa;
    display: flex;
    div {
      width: 140px;
      height: 60px;
      color: #666;
      font-size: 16px;
      line-height: 16px;
      padding-top: 21px;
      text-align: center;
      cursor: pointer;
      box-sizing: border-box;
    }
    .active {
      box-sizing: border-box;
      border-top: 4px solid #4934b2;
      background-color: #fff;
      color: #4934b2;
      padding-top: 17px;
      cursor: default;
    }
  }
  .body {
    .title {
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px dashed #ccc;
      font-size: 16px;
      line-height: 16px;
      color: #292929;
      box-sizing: border-box;
    }
    .description {
      margin: 20px 0 0 0;
    }
    .p-detail {
      .description {
        margin: 20px 0 0 0;
        line-height: 32px;
        .params {
          overflow: hidden;
          .params-element {
            width: 33%;
            float: left;
            overflow: hidden;
            .params-element-key {
              color: #666;
              padding-right: 8px;
              float: left;
            }
            .params-element-value {
              float: left;
            }
          }
        }
        .others {
          margin: 8px 0;
          .others-title {
            font-weight: bold;
            color: #666;
          }
        }
      }
    }
    .p-design {
      .description {
        margin: 20px 0 0 0;
        line-height: 32px;
        .params {
          overflow: hidden;
          .params-element {
            width: 100%;
            overflow: hidden;
            .params-element-key {
              color: #666;
              padding-right: 8px;
              float: left;
            }
            .params-element-value {
              float: left;
            }
          }
        }
        .others {
          margin: 8px 0;
          .others-title {
            font-weight: bold;
            color: #666;
          }
        }
      }
    }
  }
}
.sku-color {
  margin: 0 8px 0 0;
  border: 1px solid #aaaaaa;
  cursor: pointer;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  text-align: center;
  transition: all 0.5s ease-in-out;
  border: 3px solid transparent;
  box-shadow: 0 0 0 1px #ddd;
}
.sku-size {
  color: #333;
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
  text-transform: capitalize;
  display: inline-block;
  margin: 0;
  min-width: 30px;
  overflow: hidden;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 7px;
  transition: all 0.5s ease-in-out;
  padding: 0 12px;
  margin: 0 8px 5px 0;

  border: 3px solid transparent;
  box-shadow: 0 0 0 1px #ddd;
  cursor: pointer;
  box-sizing: border-box;
}
.is-select {
  border: 3px solid #f25551;
  box-shadow: none;
}
.limit {
  margin: 0px 0 0 28px;
  float: left;
  font-size: 14px;
  color: #f25551;
  font-weight: bold;
}
</style>
