import { constantRoutes } from '@/router'
import { getRouters } from '@/api/menu'
import Home from '@/views/Home.vue'

const permission = {
  state: {
    routes: [],
    addRoutes: []
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes;
      // state.routes = constantRoutes.concat(routes)
      routes.filter(router =>{
        constantRoutes[0].children.push(router);
      })
      state.routes = constantRoutes;
    }
  },
  actions: {
    // 生成路由
    GenerateRoutes({ commit }) {
      return new Promise(resolve => {
        // 向后端请求路由数据
        getRouters().then(res => {
          const accessedRoutes = filterAsyncRouter(res.data);
          // accessedRoutes.push({ path: '*', redirect: '/404', hidden: true })
          commit('SET_ROUTES', accessedRoutes)
          resolve(accessedRoutes)
        })
      })
    }
  }
}

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap) {

  return asyncRouterMap.filter(route => {
    if (route.component) {
      // 当前项目没有Blank组件，需要特殊处理
      if (route.component === 'Blank') {
        route.component = Home
      }else{
        route.component = loadView(route.component)
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children)
    }
    return true
  })
}

export const loadView = (view) => { // 路由懒加载
  return (resolve) =>  require([`@/views/${view}`], resolve)
}

export default permission
