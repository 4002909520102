import Cookies from "js-cookie"

const TokenKey = "Admin-Token"

export function getToken() {
  return Cookies.get(TokenKey) ? Cookies.get(TokenKey) : ""
}

export function setToken(token) {
  localStorage.setItem("token", token)
  return Cookies.set(TokenKey, token, { expires: 365 })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
