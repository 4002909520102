import request from '@/utils/request'
/**资金管理 */


// 查询商家充值管理列表
export function listRecharge(data) {
  return request({
      url: '/money/recharge/list',
      method: 'get',
      params: data
  })
}
// 充值审核
export function  updateRecharge(data) {
  return request({
      url: '/money/recharge/update',
      method: 'post',
      data: data
  })
}


//查询资金流水列表
export function listFund(data) {
  return request({
      url: '/money/fund/list',
      method: 'get',
      params: data
  });
}
//导出资金流水列表
export function exportFundList(data) {
  return request({
      url: '/money/fund/export',
      method: 'get',
      params: data
  });
}
//导出资金流水列表
export function editFund(data) {
  return request({
      url: '/money/fund/edit',
      method: 'post',
      data: data
  });
}



//查询商家提现管理列表
export function listWithdraw(data) {
  return request({
      url: '/money/withdraw/list',
      method: 'get',
      params: data
  });
}
// 审核提现申请
export function  updateWithdraw(data) {
  return request({
      url: '/money/withdraw/update',
      method: 'post',
      data: data
  })
}
// 审核提现申请
export function  listInvite(data) {
  return request({
      url: '/money/recharge/listInvite',
      method: 'get',
      params: data
  })
}