<template>
  <div class="body">
    <TopNav></TopNav>
    <Main></Main>
    <Service></Service>
    <Footer></Footer>
    <Feedback></Feedback>
  </div>
</template>

<script>
import Main from "./Main"
import TopNav from "../advantage/TopNav"
import Service from "../default2/Service"
import Footer from "../default2/Footer"
import Feedback from "../default2/Feedback"

export default {
  components: {
    TopNav,
    Footer,
    Main,
    Service,
    Feedback,
  },
}
</script>

<style></style>
