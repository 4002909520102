<template>
  <div>
    <div class="rno-2-section-inner">
      <div class="rno-2-section-hd">
        热卖单品
      </div>
      <h5 style="margin-bottom: 30px;" class="rno-2-section-desc">
        新品持续更新 紧跟潮流热卖
      </h5>
      <div class="rno-2-section-bd">
        <!-- 选项卡 s -->
        <div class="rno-2-tabs">
          <div v-if="false" class="rno-2-tabs-bar">
            <ul class="rno-2-tabs-list">
              <li
                :class="[
                  'rno-2-tabs-item',
                  active === 'hot' ? 'rno-2-tabs-active' : '',
                  'J-homeProductTab',
                ]"
                @click="changeActive('hot')"
                data-target="hot"
              >
                <a href="javascript:;" class="rno-2-tabs-item-con">
                  近期热卖
                </a>
              </li>
              <li
                :class="[
                  'rno-2-tabs-item',
                  active === 'essential' ? 'rno-2-tabs-active' : '',
                  'J-homeProductTab',
                ]"
                @click="changeActive('essential')"
                data-target="essential"
              >
                <a href="javascript:;" class="rno-2-tabs-item-con">
                  新品速递
                </a>
              </li>
            </ul>
          </div>

          <div class="rno-2-tabs-content">
            <div class="flex-row flex-wrap index-goods-list flex-x-sbetween">
              <div
                @mouseout="handleMouseOut(record)"
                @mouseover="handleMouseOver(record)"
                v-for="(record, index) in productList"
                :key="index"
                class="good-item"
              >
                <a
                  href="/frontend/create/index?blankProId=Y9I9U8"
                  style="display: none"
                ></a>

                <div class="index-goods-vip"></div>

                <a @click="goProduct(record)">
                  <img
                    class="evenflow_scale index-goods-img"
                    :src="record.picUrl"
                  />
                </a>
                <div style="height: 101px">
                  <div class="index-goods-detail pt13">
                    <div class="index-goods-tit shop_tit_style">
                      {{ record.proName }}
                    </div>
                    <div class="index-goods-price">
                      <span class="zc">￥{{ record.price }}</span> 起
                    </div>
                    <div class="mt5 shop_newTime_style">
                      上新时间：{{ record.createTime }}
                    </div>
                  </div>
                  <div class="index-goods-order">
                    <div class="index-goods-active">
                      <div :title="record.pdMaterial" class="text-ellipsis">
                        材质：{{ record.pdMaterial }}
                      </div>
                      <div>重量：{{ record.weight }}g</div>
                      <div
                        class="shop_factory_style"
                        title=""
                        style="height: 2px"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import customDesign from "@/assets/images/default/custom_design.png"
import hotSale from "@/assets/images/default2/hot-sale.png"
import { getProductList } from "@/api/customProduct/product"

export default {
  data() {
    return {
      active: "hot",
      productList: [],
      dataRecent: [],
      dataHSales: [],
      customDesign: customDesign,
      hotSale: hotSale,
    }
  },
  computed: {
    ...mapGetters(["user", "token"]),
  },
  methods: {
    changeActive(type) {
      this.productList = []
      this.active = type
      if (type == "hot") {
        this.productList = this.dataHSales
      } else {
        this.productList = this.dataRecent
      }
    },

    handleMouseOver(record) {
      // if (record.picUrlHover) {
      //   record.picUrl = record.picUrlHover
      // }
    },

    handleMouseOut(record) {
      record.picUrl = record.picUrlBak
    },
    async getListNew() {
      let result = await listNew({ pageNum: 1, pageSize: 10 })
      let dataRecent = []
      for (let record of result.rows) {
        for (let image of record.mainList) {
          if (image.isMain == "2") {
            record.picUrlHover = image.picUrl
          }

          if (image.isMain == "1" && !record.picUrlBak) {
            record.picUrl = image.picUrl
            record.picUrlBak = image.picUrl
          }
        }
        dataRecent.push({
          picUrl: `${record.picUrl}?x-oss-process=image/resize,m_fill,h_500,w_500/quality,q_100`,
          picUrlBak: `${record.picUrlBak}?x-oss-process=image/resize,m_fill,h_500,w_500/quality,q_100`,
          picUrlHover: `${record.picUrlHover}?x-oss-process=image/resize,m_fill,h_500,w_500/quality,q_100`,
          displayUrl: `${record.skuList[0].mainPic.picUrl}?x-oss-process=image/resize,m_fill,h_500,w_500/quality,q_100`,
          originUrl: `${record.skuList[0].mainPic.picUrl}?x-oss-process=image/resize,m_fill,h_500,w_500/quality,q_100`,
          imageUrl: `${record.skuList[0].mainPic.picUrl}?x-oss-process=image/resize,m_fill,h_500,w_500/quality,q_100`,
          isHover: false,
          price: record.skuList[0].lowPrice,
          proName: record.proName,
          createTime: record.createTime
            ? record.createTime.substring(0, 10)
            : "",
          pdMaterial: record.pdMaterial,
          weight: record.skuList[0].weight,
          pId: record.pId,
          manufacturer: record.manufacturer,
        })
      }
      this.dataRecent = dataRecent
    },
    async getListSale() {
      let result = await getProductList({
        pageNum: 1,
        pageSize: 10,
        isAsc: "desc",
        productType: "02",
        orderByColumn: "pId",
        dzStatus: "01",
      })
      let dataHSales = []
      for (let record of result.rows) {
        for (let image of record.mainList) {
          if (image.isMain == "2") {
            record.picUrlHover = image.picUrl
          }

          if (image.isMain == "1" && !record.picUrlBak) {
            record.picUrl = image.picUrl
            record.picUrlBak = image.picUrl
          }
        }
        dataHSales.push({
          picUrl: `${record.picUrl}?x-oss-process=image/resize,m_fill,h_500,w_500/quality,q_100`,
          picUrlBak: `${record.picUrlBak}?x-oss-process=image/resize,m_fill,h_500,w_500/quality,q_100`,
          picUrlHover: `${record.picUrlHover}?x-oss-process=image/resize,m_fill,h_500,w_500/quality,q_100`,
          displayUrl: `${record.skuList[0].mainPic.picUrl}?x-oss-process=image/resize,m_fill,h_500,w_500/quality,q_100`,
          originUrl: `${record.skuList[0].mainPic.picUrl}?x-oss-process=image/resize,m_fill,h_500,w_500/quality,q_100`,
          imageUrl: `${record.skuList[0].mainPic.picUrl}?x-oss-process=image/resize,m_fill,h_500,w_500/quality,q_100`,
          isHover: false,
          price: record.skuList[0].lowPrice,
          proName: record.proName,
          createTime: record.createTime
            ? record.createTime.substring(0, 10)
            : "",
          pdMaterial: record.pdMaterial,
          weight: record.skuList[0].weight,
          pId: record.pId,
          manufacturer: record.manufacturer,
        })
      }
      this.dataHSales = dataHSales
    },
    goProduct(row) {
      if (this.token) {
        let routeUrl = this.$router.resolve({
          path: "/select/custom/product",
          query: { pId: row.pId },
        })
        window.open(routeUrl.href, "_blank")
      } else {
        let routeUrl = this.$router.resolve({
          path: "/product",
          query: { pId: row.pId },
        })
        window.open(routeUrl.href, "_blank")
      }
    },
    handleJump(path) {
      this.$router.push({ path: path })
    },
  },
  async mounted() {
    // await this.getListNew()
    await this.getListSale()
    this.changeActive("hot")
  },
}
</script>

<style lang="scss" scoped>
.rno-2-section-inner {
  margin-top: 60px;
}
.custon-design {
  background-image: url("../../assets/images/default/topImage5.png") !important;
  background-size: cover;
  background-position: center center;
  height: calc(100vw * 0.53);
  position: relative;
  .website-text {
    text-align: left;
    position: absolute;
    right: 15%;
    top: 30%;
    .text1 {
      font-size: 58px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #333333;
      opacity: 0.92;
      line-height: 60px;
    }
    .text2 {
      font-size: 58px;
      font-family: PingFang SC;
      color: #333333;
      opacity: 0.92;
      line-height: 40px;
      margin: 60px 0 30px 60px;
    }
    .text3 {
      font-size: 25px;
      font-family: PingFang SC;
      color: #333333;
      opacity: 0.92;
      line-height: 40px;
      margin: 60px 0 30px 60px;
    }
    .text-button {
      margin: 5vw 0 0 60px;
      img {
        height: 50px;
        cursor: pointer;
      }
    }
  }
}
.cixiu1 {
  background-image: url("../../assets/images/default/cixiu1.png") !important;
  background-size: cover;
  background-position: center center;
  height: calc(100vw * 0.542);
  position: relative;
  .website-text {
    text-align: left;
    position: absolute;
    left: 15%;
    top: 30%;
    .text1 {
      font-size: 49px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #333333;
      opacity: 0.92;
      line-height: 60px;
    }
    .text2 {
      font-size: 26px;
      font-family: PingFang SC;
      color: #333333;
      opacity: 0.92;
      line-height: 40px;
      margin: 20px 0 0px 0px;
    }
    .text3 {
      color: #6e4943;
      font-size: 19px;
      font-family: PingFang SC;
      opacity: 0.92;
      line-height: 40px;
      margin: 16px 0 30px 0px;
    }
    .text-button {
      margin: 5vw 0 0 -10px;
      img {
        height: 50px;
        cursor: pointer;
      }
    }
  }
}

.cixiu2 {
  background-image: url("../../assets/images/default/topImage4.png") !important;
  background-size: cover;
  background-position: center center;
  height: calc(100vw * 0.42);
  position: relative;
  .website-text {
    text-align: left;
    position: absolute;
    right: 10%;
    top: 30%;
    .text1 {
      font-size: 47px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #333333;
      opacity: 0.92;
      line-height: 60px;
    }
    .text2 {
      font-size: 25px;
      font-family: PingFang SC;
      color: #333333;
      opacity: 0.92;
      line-height: 40px;
      margin: 29px 0 30px 0px;
    }
    .text-button {
      margin: 5vw 0 0 -10px;
      img {
        height: 50px;
        cursor: pointer;
      }
    }
  }
}
.flex-x-sbetween {
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.flex-row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.index-goods-list {
  font-size: 14px;
}
.index-goods-order {
  display: none;
}
.index-goods-list .good-item {
  position: relative;
  display: block;
  text-align: center;
  background: #fff;
  border: 2px solid #222;
  width: 220px;
  margin-bottom: 39px;
}
.index-goods-list .index-goods-img {
  display: inline-block;
  width: 200px;
  height: 200px;
  margin: 10px;
  background: center no-repeat;
  background-size: 100%;
}
.shop_tit_style {
  font-size: 14px;
  font-weight: bold;
  color: rgba(51, 51, 51, 1);
}
.index-goods-tit {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #181b1c;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.index-goods-price {
  margin: 5px 0;
}
.shop_newTime_style {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.shop_factory_style {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: bottom;
}
.index-goods-list a {
  cursor: pointer;
  text-decoration: none;
  color: rgb(89, 90, 90);
}
.zc {
  color: #4934b2;
  border-color: #4934b2;
  fill: #4934b2;
}
.index-goods-price > span {
  font-size: 16px;
  font-weight: bold;
}
.index-goods-list .good-item:hover {
  cursor: auto;
  color: #000 !important;
  box-shadow: 0 0px 13px 0 rgb(64 147 243 / 35%);
  transition: all 0.35s;

  .index-goods-detail {
    display: none;
  }

  .index-goods-order {
    display: block;
  }
}
.shop_start_style {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  width: 100%;
  background: #0053ff;
  color: #ffffff !important;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.evenflow_scale:hover {
  transform: scale(1.05, 1.05);
}
.evenflow_scale {
  transition: all 0.6s ease;
}
.index-goods-active {
  /* display: none; */
  padding: 10px 15px;
  line-height: 25px;
  color: #333;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}
.rno-2-tabs-list::before {
  background-color: transparent;
}
.rno-2-tabs-active .rno-2-tabs-item-con:before {
  border: none;
}
.rno-2-tabs-active .rno-2-tabs-item-con:after {
  background: none;
}
.rno-2-tabs-active .rno-2-tabs-item-con {
  background: #0053ff;
  color: #ffffff;
  border-radius: 50%;
}
.rno-2-tabs-active .rno-2-tabs-item-con:hover {
  // color: #0053ff;
}

.index-goods-list .good-item {
  border-radius: 20px;
}

.rno-2-section-desc {
  font-size: 16px;
  text-align: center;
}

.rno-2-section-hd {
  font-size: 30px;
  color: rgb(73, 52, 178);
  font-weight: bold;
}
</style>
