<template>
  <div>
    <div :class="{ fixed: fixed }" class="plat-nav-mod">
      <div class="plat-nav-top" style="color: #ffffff; font-size: 24px">
        <img
          :src="logoBlue"
          style="
              width: 112px;
              margin: 12px 0 0 16px;
              cursor: pointer;
              float: left;
            "
          @click="handleJump('/index')"
        />

        <Categories
        ></Categories>

        <div
          style="    float: left;
    height: 11px;
    border-left: 1px solid rgb(126, 126, 126);
    margin: 20px 20px 0px 20px;"
        ></div>

        <div style="float:left;margin: 4px 0 0 0;" class="plat-nav-top-ctrl">
          <div class="plat-nav-top-links">
            <a
              v-if="token"
              hotrep="hp.header.console"
              class="link-item"
              role="button"
              @click="handleJumpWorkSpace()"
              style="line-height: 44px"
            >
              <img
                style="
                      vertical-align: -3px;
                      margin: 1px 5px 0 5px;
                      width: 16px;
                    "
                :src="workspace"
              />工作台</a
            >
          </div>
        </div>
        <div style="float:left;" class="plat-nav-bm-right">
          <div v-if="!token">
            <a
              hotrep="hp.pc.topnav.login"
              rel="nofollow"
              class="plat-nav-bm-text-btn login-button"
              style="color: #7e7e7e"
              @click.prevent="handleJump('/login')"
              >登录</a
            ><a
              hotrep="hp.pc.topnav.register"
              rel="nofollow"
              class="nav-register-btn plat-nav-btn"
              @click.prevent="handleJump('/register')"
              style="border-radius: 5px"
              >免费注册</a
            >
          </div>
          <div v-else class="plat-nav-dropdown-btn nav-bm-user-btn">
            <div class="common-topbar-user-info" style="display: block">
              <div class="common-topbar-user-image-wrapper">
                <img style="width: 40px; margin: 0 6px 0 0" :src="getImage" />
                {{ userExt.nickName ? userExt.nickName : name }}
              </div>
            </div>
            <TopBar></TopBar>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Categories from "./Categories"
import TopBar from "@/components/topBar"
import header from "@/assets/images/default/header.png"
import location from "@/assets/images/default/location.png"
import mn from "@/assets/images/index/mn.png"
import cart from "@/assets/images/user/cart.png"
import { listNotice } from "@/api/system/notice"
import { mapGetters } from "vuex"
import { listNew, listSale } from "@/api/customProduct/product"
import vip0 from "@/assets/images/user/vip0.png"
import vip1 from "@/assets/images/user/vip1.png"
import vip2 from "@/assets/images/user/vip2.png"
import vip3 from "@/assets/images/user/vip3.png"
import vip4 from "@/assets/images/user/vip4.png"
import vip5 from "@/assets/images/user/vip5.png"
import logoWhite from "@/assets/images/default/logo_white.png"
import logoBlue from "@/assets/images/default2/logo_blue.png"
import designButton from "@/assets/images/default/design_button.png"
import { getUserProfile } from "@/api/system/user"
import shoppingCart from "@/assets/images/advantage/shoppingCart.png"
import workspace from "@/assets/images/advantage/workspace.png"
import station from "@/assets/images/advantage/station.png"
import topImage from "@/assets/images/default/topImage.png"

import topButton1 from "@/assets/images/default/topButton1.png"
import topButton2 from "@/assets/images/default/topButton2.png"
import topButton3 from "@/assets/images/default/topButton3.png"

export default {
  components: {
    Categories,
    TopBar,
  },
  data() {
    return {
      showTopad: true,
      showMenu: false,
      showSearchBar: false,
      avator: header,
      location: location,
      mn: mn,
      cart: cart,
      noticeDialogData: {
        title: "",
      },
      orderData: { title: "Abra Philippines", date: "1分钟前" },
      tableData: [],
      tableDataOrder: [
        { title: "Abra Philippines", date: "1分钟前" },
        { title: "กรุงเทพมหานคร Thailand", date: "6分钟前" },
        { title: "Kuala Lumpur  Malaysia", date: "9分钟前" },
        { title: "Jakarta  Indonesia", date: "12分钟前" },
      ],
      page: {
        status: "0",
        pageNum: 1,
        pageSize: 10,
        total: 0,
        length: 0,
      },
      logoWhite: logoWhite,
      designButton: designButton,
      noticeDialogVisible: false,
      current: 0,
      currentOrder: 0,
      searchValue: "",
      timer: "",
      dataRecent: [],
      dataHSales: [],
      vip0: vip0,
      vip1: vip1,
      vip2: vip2,
      vip3: vip3,
      vip4: vip4,
      vip5: vip5,
      userExt: {},
      shoppingCart: shoppingCart,
      workspace: workspace,
      station: station,
      topImage: topImage,
      topButton1: topButton1,
      topButton2: topButton2,
      topButton3: topButton3,
    }
  },
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["dictData", "dictMap", "nginx", "user", "token", "name"]),
    getImage() {
      if (this.userExt.vipType == "0" || !this.userExt.vipType) {
        return vip0
      } else if (this.userExt.vipType == "1") {
        return vip1
      } else if (this.userExt.vipType == "2") {
        return vip2
      } else if (this.userExt.vipType == "3") {
        return vip3
      } else if (this.userExt.vipType == "4") {
        return vip4
      } else if (this.userExt.vipType == "5") {
        return vip5
      } else if (this.userExt.vipType == "6") {
        return vip6
      }
    },
    showMeta() {
      if (document.location.host == "chuanghe.onecust.com") {
        return true
      } else {
        return false
      }
    },
    logoBlue() {
      return this.$store.state.setting.siteData.homeLogoUrl
    },
  },
  watch: {
    searchValue() {},
  },
  methods: {
    handleJump(path) {
      this.$router.push({
        path: path,
      })
    },
    handleSetSearchBar(flag) {
      this.showSearchBar = flag
      this.$nextTick(() => {
        this.$refs["searchbar"].focus()
      })
    },
    loadNoticeData() {},
    handleSearch() {
      this.$router.push({
        path: "/product_type",
        query: {
          proName: this.searchValue,
        },
      })
    },

    handleJumpWorkSpace() {
      if (this.user.userType == "00") {
        this.$router.push({
          path: "/welcome",
        })
      } else {
        this.$router.push({
          path: "/index",
        })
      }
    },
    handleClear() {
      this.searchValue = ""
      this.showSearchBar = false
    },
    handleShowSearchBar() {
      if (!this.searchValue) {
        this.showSearchBar = false
      }
    },
    async getListNew() {
      let result = await listNew({ pageNum: 1, pageSize: 5 })
      this.dataRecent = result.rows
    },
    async getListSale() {
      let result = await listSale({ pageNum: 1, pageSize: 5 })
      this.dataHSales = result.rows
    },
    goProduct(row) {
      let routeUrl = this.$router.resolve({
        path: "/product",
        query: { pId: row.pId },
      })
      window.open(routeUrl.href, "_blank")
    },
    handleBlurSearchBar() {},
    handleClickUp() {
      if (this.current > 0) {
        this.current--
        this.noticeDialogData = this.tableData[this.current]
      }
    },
    handleClickDown() {
      if (this.current < this.page.total - 1) {
        this.current++
        this.noticeDialogData = this.tableData[this.current]
      }
    },
    getUserProfile() {
      if (this.token) {
        getUserProfile().then((res) => {
          this.userExt = res.user
        })
      }
    },
  },
  mounted() {
    let _self = this
    this.loadNoticeData()
    this.timer = window.setInterval(() => {
      this.noticeDialogData = this.tableData[this.current]
      this.current++
      if (this.current == this.page.length) {
        this.current = 0
      }
    }, 5000)
    window.setInterval(() => {
      this.orderData = this.tableDataOrder[this.currentOrder]
      this.currentOrder++
      if (this.currentOrder == 9) {
        this.currentOrder = 0
      }
    }, 5000)
    this.getListNew()
    this.getListSale()

    document.onclick = function(event) {
      var e = event || window.event
      var elem = e.srcElement || e.target

      while (elem) {
        if (elem.id == "plat-nav-top-operation") {
          // 这里就是除这个id外点击其他地方执行下面HiddenDiv方法
          return
        }
        elem = elem.parentNode
      }
      //触发事件
      hide()
    }
    function hide() {
      _self.showSearchBar = false
    }
    this.getUserProfile()
  },
}
</script>

<style lang="scss" scoped>
#qcportal-kit-topnav {
  outline: none;
}

.top-button {
  img {
    width: 5vw;
    background: transparent;
    cursor: pointer;
  }
}

.top-button1 {
  position: absolute;
  top: 15.8vw;
  left: 20vw;
}
.top-button2 {
  position: absolute;
  top: 72.5vw;
  left: 47.3vw;
}
.top-button3 {
  position: absolute;
  top: 110.5vw;
  left: 19.8vw;
}

.plat-nav-bar {
  margin: 0 auto;
  position: relative;
}

.topad {
  width: 100%;
  height: 50px;
  background-image: url("../../assets/images/home/topBanner.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}

.topad .close {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 37px;
  height: 37px;
  z-index: 1000;
  cursor: pointer;
  background-image: url("../../assets/images/home/close.png");
}

.index-custom-hot {
  display: flex;
  align-items: center;
}

.index-custom-tips {
  width: 880px;
}
.index-custom-hot {
  height: 46px;
  overflow: hidden;
}
.index-custom-hot .check-new {
  position: absolute;
  right: 10px;
  color: #4934b2 !important;
  font-size: 14px;
  font-weight: bold;
}
.index-custom-hot .check-new i {
  color: #4934b2;
  font-size: 14px;
  font-weight: bold;
}
.index-custom-container {
  padding: 12px 0;
}
.container-item-wrap {
  display: flex;
  transition: none;
}
.container-item-wrap:hover {
  background-color: #edf5fe;
}
.container-item-wrap .icon-arrow_right {
  transition: none;
}
.container-left {
  width: 150px;
  font-size: 12px;
  line-height: 21px;
  margin: 4px 0;
  text-align: right;
  flex-shrink: 0;
}
.container-body {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
}
.container-body > a:nth-of-type(n + 6) {
  display: none;
}
.item-name {
  margin: 5px 0;
  padding-left: 20px;
  box-sizing: border-box;
  text-align: left;
  position: relative;
  line-height: 21px;
  color: #666666;
}
.item-content-type a:hover {
  color: #59a5e6;
}
.item-content-type a {
  transition: none;
  position: relative;
  cursor: pointer !important;
  color: #3d3d3f;
  font-size: 12px;
}
.item-content-type a:hover {
  color: #4934b2;
}
.item-content-type {
  margin-left: 0;
}
.item-name:last-of-type .item-content-type:before {
  width: 0;
}
.item-name:nth-of-type(7n) .item-content-type:before {
  width: 0;
}
.index-custom-hot {
  display: flex;
  align-items: center;
}
.index-custom-tips .container-body a {
  cursor: default;
}
.index-custom-hot .hot {
  font-family: MicrosoftYaHei-Bold;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  color: #fa3b33;
}
.item-hot {
  line-height: 20px;
  padding: 0 4px;
  margin-left: 22px;
  cursor: pointer;
  transition: none;
  cursor: pointer;
  color: #fff;
  background-image: linear-gradient(
      -90deg,
      #fc7764 0%,
      #fc5b61 63%,
      #fc3f5e 100%
    ),
    linear-gradient(#dd3029, #dd3029);
  background-blend-mode: normal, normal;
}
.item-name .icon-home-sale {
  color: #ed5244;
  font-size: 26px;
  position: absolute;
  bottom: -1px;
  right: -19px;
}
.item-name .icon-home-new {
  color: #66cccc;
  font-size: 26px;
  position: absolute;
  bottom: -1px;
  right: -19px;
}
.container-left-label {
  display: inline-block;
  margin-right: 12px;
  font-size: 12px;
  color: #333333;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  font-stretch: normal;
  transition: none;
  font-weight: bold;
}
.container-left-label:hover {
  color: #4934b2;
}
.user-level-icon {
  display: inline-block;
  width: 15px;
  height: 19px;
  vertical-align: middle;
  // background-repeat: no-repeat;
  // background-image: url("/static/frontend/images/metro.png?v=1");
}
.level4 {
  background-position: -109px -107px;
}
.factory {
  background-position: -154px -107px;
}
.shipping {
  background-position: -154px -125px;
}
.agent {
  background-position: -176px -107px;
}
.level2 {
  background-position: -131px -107px;
}
.level3 {
  background-position: -109px -125px;
}
.level1 {
  background-position: -131px -125px;
}
.f16 {
  font-size: 16px;
}
.index-login > a:first-child {
  padding-right: 0;
}
.user-login {
  padding-right: 10px !important;
}
.main-btn {
  cursor: pointer;
  color: #fff;
  background-color: #4796f1;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  transition: none;
}
.main-btn:hover {
  opacity: 0.8;
}
.control {
  width: 76px;
  height: 30px;
  line-height: 30px;
}
.ml14 {
  margin-left: 14px;
}
.arrow-class {
  font-size: 12px;
  color: #333333;
  font-weight: bold !important;
}
.index-search input[type="text"] {
  width: 260px;
  height: 42px;
}
.index-search i.search {
  right: -55px;
  width: 55px;
  font-size: 20px;
  line-height: 42px;
}
.index-tips,
.index-tips > div {
  height: 46px;
  font-size: 14px;
}
.notice-swiper .swiper-slide,
.notice-swiper,
.notice-swiper span {
  height: 46px !important;
  line-height: 46px;
  font-size: 12px;
}
.index-tips:before {
  height: 46px;
}
.index-tips-p * {
  font-size: 12px;
}
.history-record {
  display: none;
  width: 322px;
  padding: 5px 0;
  position: absolute;
  top: 40px;
  left: 0;
  background-color: #fff;
  border: solid 1px #b5d7fe;
  box-sizing: border-box;
  z-index: 10;
}
.history-record li:hover {
  background-color: #edf5fe;
}
.history-record-del {
  display: none;
}
.history-record li:hover .history-record-del {
  display: block;
}
.history-record li {
  display: block;
  padding: 0 10px;
}
.history-record * {
  transition: none;
  color: #348cf0;
  cursor: pointer;
  line-height: 26px;
}
.history-item {
  display: inline-block;
  width: 274px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.index-tips-p > li {
  height: auto;
}
.location-get-smscode1 {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.reg-pop-module1 {
  min-width: 360px !important;
  width: 360px;
}
.com-pop-cont {
  padding: 20px;
}
.err-wrap {
  margin-left: -34px;
  margin-top: 125px;
  z-index: 100000;
}
.err-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
  display: none;
}
.yuguo-banner {
  width: 100%;
  height: 50px;
  // background: url("/static/frontend/images/index/yuguo_banner@2x.png?v=3")
  //   no-repeat center;
  // background-size: cover;
}
.yuguo-banner .close-fill {
  width: 32px;
  height: 32px;
  transform: scale(0.6);
  // background: url("/static/frontend/images/index/close-fill@2x.png?v=1")
  //   no-repeat center;
  // background-size: cover;
}
@media screen and (max-width: 1500px) {
  .yuguo-banner .close-fill {
    right: 15%;
  }
}
@media only screen and (min-width: 1500px) {
  .yuguo-banner .close-fill {
    left: 78.5%;
  }
}
.yuguo-img {
  width: 216px;
  height: 54px;
  margin-left: 41px;
  transform: scale(0.5);
  // background: url("/static/frontend/images/index/yuguo_img@2x.png?v=3")
  //   no-repeat center;
}
.college_submit {
  width: 130px;
  height: 40px;
  margin-top: 25px;
  background: #f0f0f0;
  border: 1px solid #4934b2;
  font-weight: 400;
  color: #4934b2;
  line-height: 40px;
}
.cate_channel {
  overflow: hidden;
  padding-left: 40px;
  height: 24px;
  margin: 16px 0 0 0;
}
.cate_channel_lk {
  *cursor: pointer;
  float: left;
  margin-right: 10px;
  padding: 0 10px;
  height: 24px;
  background-color: #e1251b;
  line-height: 24px;
  color: #ffffff !important;
}
.cate_channel_lk:hover {
  color: #ffffff !important;
}
.cate_channel_arrow {
  margin-left: 5px;
}
.color-arrow {
  color: #ffffff;
}
.plat-nav-bubble {
  display: none;
}
.product-menu:hover .plat-nav-bubble {
  display: block;
}
.plat-nav-bm-text-btn {
  cursor: pointer;
}
.nav-bm-user-btn:hover .common-topbar-user-info-dropdown {
  display: block;
}
.common-topbar-user-info {
  float: right;
  padding: 0;
  height: 50px;
  box-sizing: border-box;
  position: relative;
  padding: 0 12px;
}

.common-topbar-user-info .common-topbar-user-image-wrapper {
  overflow: hidden;
  cursor: pointer;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.common-topbar-body * {
  box-sizing: content-box;
}
.common-topbar-user-info img {
  // height: 100%;
}
.common-topbar-user-info:hover {
  background: #8071c9;
}
.link-item {
  cursor: pointer;
  font-weight: 600;
}
.hotList {
  color: #333333;
  font-size: 12px;
  text-align: left;
  padding: 0 0 0 16px;
  cursor: default;
  a {
    color: #aaaaaa;
    display: block;
  }
  a:hover {
    color: #4934b2;
  }
  .item__PortalLayout {
    cursor: pointer;
  }
}
.link {
  display: inline-block;
  cursor: pointer;
  margin: 0 16px;
  color: #4934b2;
}
.gray {
  color: #aaaaaa;
}
.plat-nav-mod .plat-nav-top {
  background: transparent;
  color: #333333 !important;
  width: 1200px;
  margin: 0 auto;
}
.plat-nav-dropdown-btn-inner {
  color: #333333 !important;
}
.plat-nav-mod .plat-nav-top-links .link-item {
  color: #333333;
}
.plat-nav-mod .search-m .plat-top-searchbar {
  background-color: #ffffff;
}
.plat-nav-mod .search-m .plat-top-searchbar-box .search-ipt {
  background-color: #ffffff;
  color: #333333;
}
.plat-nav-mod .plat-nav-top-search .nav-search-divider {
  height: 19px;
}
.plat-nav-mod .plat-nav-top-search {
  background: #ffffff;
}
.plat-nav-mod .plat-nav-top-search {
  background-image: url("../../assets/images/default/search-icon.png");
  line-height: 38px;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 112px;
  background-size: 62px;
}
.plat-nav-mod .search-m .plat-top-searchbar {
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}
.plat-nav-mod .nav-search-icon {
  display: inline-block;
  background-image: url(https://cloudcache.tencent-cloud.com/qcloud/portal/kit/images/slice/search-1616-3-inline.c3eeb948.svg);
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.plat-nav-wrap.plat-nav-air-sub .plat-nav-bottom {
  background: transparent;
  position: relative;
  z-index: 0;
  top: 0;
  left: 0;
}
.plat-nav-bar ::v-deep .presentation a {
  color: #333333;
}

.plat-nav-bar ::v-deep .presentation .cate_channel_lk a {
}
#qcportal-kit-topnav {
  background-image: url("../../assets/images/default/topImage7.png") !important;
  background-size: cover;
  background-position: center center;
  height: calc(100vw * 1.426);
}
.plat-nav {
  position: relative;
  z-index: 0;
}
.plat-nav-wrap.plat-nav-air-sub {
  height: auto;
}
.website-text {
  width: 1000px;
  text-align: left;
  position: absolute;
  left: 8%;
  top: 7vw;
  .text1 {
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #333333;
    opacity: 0.92;
    line-height: 60px;
  }
  .text2 {
    font-size: 23px;
    font-family: PingFang SC;
    color: #333333;
    opacity: 0.92;
    line-height: 40px;
    margin: 30px 0 30px 0;
  }
  .text3 {
    font-size: 19px;
    font-family: PingFang SC;
    color: #777777;
    opacity: 0.92;
    line-height: 40px;
  }
  .text-button {
    margin: 5vw 0 0 -10px;
    img {
      height: 50px;
      cursor: pointer;
    }
  }
}
.plat-nav-mod .plat-nav-bottom {
  border-bottom: none;
}
.plat-nav-mod .plat-nav-top {
  border-bottom: none;
}
.common-topbar-user-info:hover {
  background: transparent;
}
.login-button:hover {
  color: rgb(255, 137, 17);
  opacity: 0.6;
}
.fixed {
  position: fixed;
  background: #ffffff;
  border-bottom: 1px solid #dddddd;
  top: 0px;
  width: 100%;
  z-index: 10000;
}
.plat-nav-mod {
  height: 50px;
}
</style>
