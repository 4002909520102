<template>
  <div style="margin: 60px 0 0 0;">
    <div class="flowBox">
      <div class="rno-2-section-hd">
        我们的解决方案
      </div>
    </div>
    <div class="solution">
      <div class="glo-list">
        <div class="glo-item glo-item1">
          <div class="name">批量设计SKU</div>
          <div class="desc">
            借助先进的在线定制器，可实现快速/批量设计，新手也能轻松驾驭
          </div>
        </div>
        <div class="glo-item glo-item2">
          <div class="name">一键店群铺货</div>
          <div class="desc">
            官方认证接口，无需担心关联风险支持一键批量刊登
          </div>
        </div>
        <div class="glo-item glo-item3">
          <div class="name">
            本土供应链
          </div>
          <div class="desc">
            生产基地遍布菲律宾、印度尼西亚、 泰国、马来西亚，保障时效需求
          </div>
        </div>
        <div class="glo-item glo-item4">
          <div class="name">柔性定制生产</div>
          <div class="desc">
            解锁用户的店群铺货新模式，无库 存/无最低起订量，一件也是出厂价
          </div>
        </div>
        <div class="glo-item glo-item5">
          <div class="name">多平台统一管理</div>
          <div class="desc">
            支持东南亚地区绝大部分主流平台 多平台多账户统一管理
          </div>
        </div>
        <div class="glo-item glo-item6">
          <div class="name">高效订单处理</div>
          <div class="desc">
            批量处理多店铺订单，提高订单处 理效率，减少发货出错率
          </div>
        </div>
        <div class="glo-item glo-item7">
          <div class="name">销售数据统计</div>
          <div class="desc">
            多维度可视化数据报表，帮助运营 实现精准数据分析
          </div>
        </div>
        <div class="glo-item glo-item8">
          <div class="name">精准物流追踪</div>
          <div class="desc">
            可视化追踪已发包产品，实时掌握 最新物流状态
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from "@/assets/images/product/1.jpeg"
import img1Hover from "@/assets/images/product/1-1.jpeg"
import img2 from "@/assets/images/product/2.jpeg"
import img2Hover from "@/assets/images/product/2-2.jpeg"
import img3 from "@/assets/images/product/3.jpeg"
import img3Hover from "@/assets/images/product/3-3.jpeg"
import img4 from "@/assets/images/product/4.jpeg"
import img4Hover from "@/assets/images/product/4-4.jpeg"
import img5 from "@/assets/images/product/5.jpeg"
import img5Hover from "@/assets/images/product/5-5.jpeg"
import img6 from "@/assets/images/product/6.png"
import img6Hover from "@/assets/images/product/6-6.jpeg"

import img7 from "@/assets/images/product/7.jpeg"
import img7Hover from "@/assets/images/product/7-7.jpeg"
import img8 from "@/assets/images/product/8.jpeg"
import img8Hover from "@/assets/images/product/8-8.jpeg"
import img9 from "@/assets/images/product/9.jpeg"
import img9Hover from "@/assets/images/product/9-9.jpeg"
import img10 from "@/assets/images/product/10.jpeg"
import img10Hover from "@/assets/images/product/10-10.jpeg"
import img11 from "@/assets/images/product/11.jpeg"
import img11Hover from "@/assets/images/product/11-11.jpeg"
import img12 from "@/assets/images/product/12.jpeg"
import img12Hover from "@/assets/images/product/12-12.jpeg"

import img13 from "@/assets/images/product/13.jpeg"
import img13Hover from "@/assets/images/product/13-13.jpeg"
import img14 from "@/assets/images/product/14.jpeg"
import img14Hover from "@/assets/images/product/14-14.jpeg"
import img15 from "@/assets/images/product/15.jpeg"
import img15Hover from "@/assets/images/product/15-15.jpeg"

import img16 from "@/assets/images/product/16.jpeg"
import img16Hover from "@/assets/images/product/16-16.jpeg"
import img17 from "@/assets/images/product/17.jpeg"
import img17Hover from "@/assets/images/product/17-17.jpeg"

import img18 from "@/assets/images/product/18.jpeg"
import img18Hover from "@/assets/images/product/18-18.jpeg"

import solution from "@/assets/images/default2/solution.png"

export default {
  data() {
    return {
      productList1: [
        {
          picUrl: img1,
          picUrlBak: img1,
          picUrlHover: img1Hover,
          name: "全鞋类",
        },
        {
          picUrl: img2,
          picUrlBak: img2,
          picUrlHover: img2Hover,
          name: "生活百货",
        },
        {
          picUrl: img3,
          picUrlBak: img3,
          picUrlHover: img3Hover,
          name: "鞋类配件",
        },
        {
          picUrl: img4,
          picUrlBak: img4,
          picUrlHover: img4Hover,
          name: "穿戴配饰",
        },
        {
          picUrl: img5,
          picUrlBak: img5,
          picUrlHover: img5Hover,
          name: "装修",
        },
        {
          picUrl: img6,
          picUrlBak: img6,
          picUrlHover: img6Hover,
          name: "收纳",
        },
      ],
      productList2: [
        {
          picUrl: img7,
          picUrlBak: img7,
          picUrlHover: img7Hover,
          name: "瑜伽",
        },
        {
          picUrl: img8,
          picUrlBak: img8,
          picUrlHover: img8Hover,
          name: "户外",
        },
        {
          picUrl: img9,
          picUrlBak: img9,
          picUrlHover: img9Hover,
          name: "书包",
        },
        {
          picUrl: img10,
          picUrlBak: img10,
          picUrlHover: img10Hover,
          name: "床上用品",
        },
        {
          picUrl: img11,
          picUrlBak: img11,
          picUrlHover: img11Hover,
          name: "精品时装",
        },
        {
          picUrl: img12,
          picUrlBak: img12,
          picUrlHover: img12Hover,
          name: "帽子",
        },
      ],
      productList3: [
        {
          picUrl: img13,
          picUrlBak: img13,
          picUrlHover: img13Hover,
          name: "时装包",
        },
        {
          picUrl: img14,
          picUrlBak: img14,
          picUrlHover: img14Hover,
          name: "消费3C",
        },
        {
          picUrl: img15,
          picUrlBak: img15,
          picUrlHover: img15Hover,
          name: "沙滩",
        },
        {
          picUrl: img16,
          picUrlBak: img16,
          picUrlHover: img16Hover,
          name: "汽配",
        },
        {
          picUrl: img17,
          picUrlBak: img17,
          picUrlHover: img17Hover,
          name: "防护",
        },
        {
          picUrl: img18,
          picUrlBak: img18,
          picUrlHover: img18Hover,
          name: "婴童",
        },
      ],
      gloList: [
        {
          index: 1,
          name: "选品设计",
          desc:
            "通过信息的捕获设计不同的主题，根据图片主题快速选择商品，开启泛精品差异化运营第一步",
          color: "#2763F9",
        },
        {
          index: 2,
          name: "授权刊登",
          desc:
            "用户只需将海外店铺与Onecust 1号进行授权绑定，并将设计的商品刊登到您的海外店铺进行销售",
          color: "#FF8911",
        },
        {
          index: 3,
          name: "下单支付",
          desc:
            "授权绑定的店铺出单后即可自动同步，支持多种支付方式及全球主流币种结算",
          color: "#26D17B",
        },
        {
          index: 4,
          name: "生产发货",
          desc:
            "支持订单状态可视化，一件即可下单，48小时内即可完成生产并向全球发货",
          color: "#FFAC61",
        },
      ],
      solution: solution,
    }
  },
  methods: {
    handleMouseOver(record) {
      if (record.picUrlHover) {
        record.picUrl = record.picUrlHover
      }
    },

    handleMouseOut(record) {
      record.picUrl = record.picUrlBak
    },
  },
}
</script>

<style scoped lang="scss">
.flowBox {
  box-sizing: border-box;
  text-align: center;
  background-size: cover;
  background-position: center center;
  position: relative;
  background: #ffffff;
}

.boxHeader {
  margin-bottom: 50px;
  text-align: center;
}

.boxHeader h2 {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #181b1c;
  margin-bottom: 10px;
}

.boxHeader p {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.flowBox .flowItem {
  width: 160px;
  height: 220px;
  background: #ffffff;
  border-radius: 10px;
  display: inline-block;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.6s ease 0s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 0px 13px 0 rgb(64 147 243 / 35%);
  }
}

.flexItem {
  flex: 1;
  text-align: center;
}

.flowItem1 {
  left: 10%;
  top: 550px;
}

.flowItem2 {
  left: 23%;
  top: 253px;
}

.flowItem3 {
  left: 41%;
  top: 503px;
}

.flowItem4 {
  left: 55%;
  top: 229px;
}

.flowItem5 {
  left: 68%;
  top: 543px;
}

.flowItem6 {
  left: 85%;
  top: 208px;
}

.flowBox .img {
  width: 135px;
  height: 135px;
  margin-bottom: 20px;
}

.flowBox .title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #181b1c;
  background-image: url("../../assets/images/default/text-bg.png");
  background-size: 49px;
  line-height: 38px;
  background-position: center center;
  background-repeat: no-repeat;
}

.global-title {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #181b1c;
  margin-bottom: 40px;
  margin-top: 60px;
}

.global-title-sub {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}

.solution {
  background: url("../../assets/images/default2/solution-bg.png") no-repeat;
  background-size: cover;
  height: 850px;
}

.glo-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  margin: 60px auto 30px;
  position: relative;

  padding: 0 0 111px 0;

  .glo-item {
    width: 250px;
    height: 250px;
    background: #ffffff;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    transition: all 0.3s linear;
    &:hover {
      box-shadow: 0 0px 13px 0 rgb(64 147 243 / 35%);
      transform: translateY(-10px);
    }

    .index {
      position: absolute;
      background-size: cover;
      width: 87px;
      height: 87px;
      top: -43px;
      left: 32%;
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .name {
      width: 75%;
      text-align: center;
      margin: 50px auto 20px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #363839;
    }

    .desc {
      width: 75%;
      margin: 0 auto;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: #363839;
      line-height: 30px;
    }
  }
  .glo-item1 {
    position: absolute;
    top: 100px;
    left: 0;
  }
  .glo-item2 {
    position: absolute;
    top: 0px;
    left: 315px;
  }
  .glo-item3 {
    position: absolute;
    top: 50px;
    left: 630px;
  }
  .glo-item4 {
    position: absolute;
    top: 25px;
    left: 945px;
  }
  .glo-item5 {
    position: absolute;
    top: 390px;
    left: 0;
  }
  .glo-item6 {
    position: absolute;
    top: 290px;
    left: 315px;
  }
  .glo-item7 {
    position: absolute;
    top: 340px;
    left: 630px;
  }
  .glo-item8 {
    position: absolute;
    top: 315px;
    left: 945px;
  }
}
.rno-2-section-hd{
  font-size: 30px;
  color: rgb(73, 52, 178);
  font-weight: bold;
}
</style>
