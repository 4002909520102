<template>
  <div style="position: relative;margin: 60px 0 0 0;">
    <div class="rno-2-section-hd">
      <h3 class="rno-2-section-title">传统运营模式的痛点</h3>
      <h5 class="rno-2-section-desc">
        低效率、重运营、高风险
      </h5>
    </div>
    <div class="rno-action-panel">
      <img style="width:1200px;margin:0 auto;" :src="ad1" />
    </div>
  </div>
</template>

<script>
import ad1 from "@/assets/images/default2/ad-bar2.png"

export default {
  data() {
    return {
      ad1: ad1,
    }
  },
}
</script>

<style lang="scss" scoped>
#section-hot {
  margin: 30px auto 89px auto;
}
.rno-2-event-cont {
  text-align: center;
}
.adImage {
  width: 150px;
  margin: 0 0 16px 0;
}
.rno-2-event-panel-title {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #181b1c;
  margin: 0 0 20px 0;
}
.rno-2-event-panel-desc {
  color: #7e7e7e;
}
.website-text {
  width: 500px;
  text-align: left;
  position: absolute;
  left: 15%;
  top: 200px;
  .text-button {
    margin: 0 0 0 -10px;
    img {
      height: 50px;
      cursor: pointer;
    }
  }
}
.rno-2-section-hd {
}
.rno-2-section-desc {
  font-size: 16px;
}
</style>
