<template>
  <div
    class="register-2017-body"
    data-spm-anchor-id="a2c44.11131956.0.i2.168a5455pOElMx"
  >
    <!-- <div class="top-bg"><img width="100%" :src="appBg1" /></div> -->
    <div class="register-width iframe-loaded" id="register">
      <div class="title-box">
        <div class="head">欢迎注册{{ siteName }}</div>
      </div>
      <div id="container" data-spm-anchor-id="0.0.0.i0.a10834dc9MfOkc">
        <div data-reactroot="" class="schema-form">
          <form class="next-form next-form-left ver next-form-large">
            <div class="form-content">
              <el-form
                :model="registerForm"
                :rules="registerRules"
                ref="registerForm"
                label-width="0px"
              >
                <el-form-item prop="username">
                  <el-input
                    v-model="registerForm.username"
                    placeholder="请输入账号"
                  >
                    <template slot="prepend"
                      ><i class="el-icon-user"></i
                    ></template>
                  </el-input>
                </el-form-item>

                <el-form-item prop="phoneNumber">
                  <el-input
                    v-model="registerForm.phoneNumber"
                    placeholder="请输入手机号"
                  >
                    <template slot="prepend"
                      ><i class="el-icon-mobile-phone"></i
                    ></template>
                  </el-input>
                </el-form-item>
                <el-form-item prop="smsCode">
                  <el-input
                    v-model="registerForm.smsCode"
                    placeholder="请输入短信验证码"
                  >
                    <template slot="prepend"
                      ><i class="el-icon-edit"></i
                    ></template>
                    <template slot="append"
                      ><span
                        v-if="!isSend"
                        style="cursor: pointer"
                        @click="handleSendMessage"
                        >发送验证码</span
                      ><span v-if="isSend">{{ remain }} s</span></template
                    >
                  </el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input
                    type="password"
                    v-model="registerForm.password"
                    placeholder="请输入登录密码"
                  >
                    <template slot="prepend"
                      ><i class="el-icon-lock"></i
                    ></template>
                  </el-input>
                </el-form-item>
                <el-form-item prop="confirmPassword">
                  <el-input
                    type="password"
                    v-model="registerForm.confirmPassword"
                    placeholder="请再次输入登录密码"
                  >
                    <template slot="prepend"
                      ><i class="el-icon-lock"></i
                    ></template>
                  </el-input>
                </el-form-item>
                <el-form-item prop="inviter">
                  <el-input
                    v-model="registerForm.inviter"
                    placeholder="请输入邀请码"
                  >
                    <template slot="prepend"
                      ><i class="el-icon-edit-outline"></i
                    ></template>
                  </el-input>
                </el-form-item>
                <el-form-item v-if="false">
                  <el-input
                    v-model="registerForm.name"
                    placeholder="请输入手机号"
                  >
                    <template slot="append"
                      ><el-button>发送短信</el-button></template
                    ></el-input
                  >
                </el-form-item>
              </el-form>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="button">
      <button @click="handleRegister">免费注册</button>
    </div>
    <div v-if="false" style="text-align: center; margin:20px 0 0 0">
      <img style="width: 50vw" :src="shoes" />
    </div>
    <div v-if="false" class="bottom-bg">
      <img width="100%" :src="appBg2" />
    </div>
    <div
      style="font-size: 12px;font-weight: 700;margin:30px auto 0 auto;color: #ffffff;line-height: 30px;width: 70%;border-radius: 10px;background: #4934b2;"
    >
      本平台属于生产力系统，请使用PC端体验
    </div>
    <div
      v-if="false"
      style="    font-size: 15px;font-weight: 700;color: #aaaaaa;line-height: 30px;"
    >
      数以千计的东南亚热销产品汇集于此
    </div>
  </div>
</template>

<script>
import { getCodeImg, register, sendSms } from "@/api/login"
import shoes from "@/assets/images/user/t_shirt.png"
import registerButton from "@/assets/images/user/register.png"
import appBg1 from "@/assets/images/user/app_bg1.png"
import appBg2 from "@/assets/images/user/app_bg2.png"

export default {
  name: "register",
  data() {
    //确认密码校验
    let validateConfirmPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"))
      } else if (value !== this.registerForm.password) {
        callback(new Error("两次输入密码不一致!"))
      } else {
        callback()
      }
    }
    let validatePhone = (rule, value, callback) => {
      var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (value === "") {
        callback(new Error("请输入手机号"))
      } else if (!reg_tel.test(value)) {
        callback(new Error("请输入正确的手机号"))
      } else {
        callback()
      }
    }
    return {
      codeUrl: "",
      cookiePassword: "",
      registerForm: {
        username: "",
        password: "",
        phoneNumber: "",
        confirmPassword: "",
        imgCode: "",
        smsCode: "",
        channel: "",
        inviter: "",
      },
      registerRules: {
        username: [{ required: true, trigger: "blur", message: "请输入账号" }],
        phoneNumber: [
          {
            required: true,
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        password: [{ required: true, trigger: "blur", message: "请输入密码" }],
        confirmPassword: [
          {
            required: true,
            validator: validateConfirmPassword,
            trigger: "blur",
          },
        ],
        imgCode: [
          { required: true, trigger: "change", message: "请输入验证码" },
        ],
        smsCode: [
          { required: true, trigger: "blur", message: "请输入短信验证码" },
        ],
        inviter: [
          { required: true, trigger: "blur", message: "请输入邀请码" },
        ],
      },
      loading: false,
      isSend: false,
      remain: 60,
      checked: false,
      shoes: shoes,
      registerButton: registerButton,

      appBg1: appBg1,
      appBg2: appBg2
    }
  },

  computed: {
    showMeta() {
      if (document.location.host == "chuanghe.onecust.com") {
        return true
      } else {
        return false
      }
    },
    siteName() {
      return this.$store.state.setting.siteData.siteName
    },
  },
  created() {
    this.getCode()
  },
  methods: {
    getCode() {
      getCodeImg().then((res) => {
        this.codeUrl = "data:image/gif;base64," + res.img
        this.registerForm.uuid = res.uuid
      })
    },
    handleRegister() {
      var _self = this
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          this.loading = true
          let t = this.$route.query.t
          if (t) {
            this.registerForm.inviter = t
          }
          let channel = this.$route.query.channel
          if (channel) {
            this.registerForm.channel = channel
          }
          register(this.registerForm)
            .then((res) => {
              if (res.code == 200) {
                this.$alert(
                  '<strong style="color:#29ae89;font-size: 16px">恭喜你, 注册成功！请尽快完善个人信息！</strong>',
                  "",
                  {
                    dangerouslyUseHTMLString: true,
                  }
                ).then(() => {
                  _self.$router.push({ path: "/app_login" })
                })
              }
            })
            .catch(() => {
              this.loading = false
              this.getCode()
            })
        }
      })
    },
    handleJump(path) {
      this.$router.push({
        path: path,
      })
    },
    async handleSendMessage() {
      try {
        if (this.isSend) {
          return
        }
        let _self = this

        let result = await sendSms({
          phoneNumber: this.registerForm.phoneNumber,
          imgCode: this.registerForm.imgCode,
          uuid: this.registerForm.uuid,
          type: "1",
          from: "h5",
        })
        if (result.code == 200) {
          let timer = window.setInterval(() => {
            _self.remain = Number(_self.remain) - 1
            if (this.remain == 0) {
              window.clearInterval(timer)
              _self.remain = 60
              this.isSend = false
            }
          }, 1000)
          this.isSend = true
          this.msgSuccess("短信发送成功")
        } else {
          this.getCode()
        }
      } catch (error) {}
    },
  },
  mounted() {
    let timer = window.setInterval(() => {
      document.title = `${this.siteName}跨境电商平台-专业服务跨境电商提供柔性定制供应链平台`
      if (this.siteName) {
        clearInterval(timer)
      }
    }, 1000)
  },
}
</script>

<style>
.body {
  height: 100%;
}
</style>

<style lang="scss" scoped>
#alibaba-register-box {
}
.register-2017-body {
}

.register-2017-body {
  padding: 6vh 0 20px 0;
  text-align: center;
  margin: auto;
  width: 100%;
  border: none;
  background: #f5f5f5;
  height: 100vh;
  ::v-deep .el-input-group__prepend {
    padding: 0 12px;
    font-size: 18px;
  }
}

#register {
  margin: 0 auto 19px auto;
  border: none;
  margin: 0 8% 0 8%;
  padding: 0 0 16px 0;
  background-color: #ffffff;

  font-family: PingFang SC;
  border-radius: 20px;
}


/*
#register ::v-deep .el-input-group__append,
#register ::v-deep .el-input-group__prepend {
  background-color: #ffffff;
  border: none;
}*/

.title-box {
  position: relative;
  margin: 0 auto;
}
.head {
  padding: 29px 0 19px 0;
  font-size: 19px;
  font-weight: bold;
  color: #373d41;
  letter-spacing: 1px;
  line-height: 36px;
  text-align: center;
}
.tips {
  position: absolute;
  top: 43px;
  right: 40px;
  font-size: 12px;
  color: #73777a;
  letter-spacing: 0.4px;
  line-height: 30px;
  text-align: left;
}
.tips a {
  color: #4934b2;
}
#container {
  position: relative;
  margin: 0 auto;
}
.schema-form {
  margin: 0 auto;
  padding: 0 16px;
}
.next-form {
  color: #333;
  font-size: 12px;
  line-height: 1.28571;
}
.next-form-item {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 18px;
}
.next-form .next-row {
  padding: 0;
}
.next-input {
  display: inline-table;
  border-collapse: separate;
  overflow: visible;
  border: 1px solid #ededed;
  width: 200px;
  border-spacing: 0;
  background-color: #fff;
  transition: all 0.3s ease-out;
}
.next-row .next-col-24 {
  width: 100%;
  max-width: 100%;
  -moz-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}
.next-input input,
.next-input textarea {
  width: 100%;
  border: none;
  outline: none;
  padding: 0;
  font-weight: 400;
  vertical-align: baseline;
  background-color: transparent;
}
.next-input-large input {
  height: 38px;
  line-height: 38px \0;
  margin: 0;
  padding: 0 12px;
  font-size: 12px;
}
.next-input.next-input-single {
  border-color: #ebeef5;
}
.next-form-item-control > .next-input,
.next-form-item-control > .next-input-group {
  width: 100%;
}
.next-form,
.next-form *,
.next-form :after,
.next-form :before {
  box-sizing: border-box;
}
.form-content {
  padding: 0px 0 0px 0;
}
.button {
  margin: 3vh 0 0 0;

  button {
    height: 40px;
    width: 84vw;
    padding: 0 20px;
    background-color: rgb(73, 52, 178);
    color: #fff;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    outline: 0 none;
    box-sizing: border-box;
    border: 0;
    border-radius: 5px;
  }
}

.form-content ::v-deep .el-input-group__append {
  background-color: #4934b2;
  color: #ffffff;
  border: #4934b2;
}

.top-bg{
  margin: 0px 0 20px 0;
}

.bottom-bg{
  margin: 20px 0 0 0;
}
</style>
