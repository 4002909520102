<template>
  <!-- -->
  <div class="main">
    <div class="notice">
      <div style="flex: 2; margin: 0 20px 0 0">
        <div style="background: #ffffff">
          <div class="title" style="overflow: hidden; padding: 8px">
            <span>登录成功</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

import bus from "@/utils/bus"

export default {
  computed: {
    ...mapGetters([
      "name",
      "nickName",
      "avatar",
      "permission_routes",
      "stat",
      "user",
    ]),
  },
  data() {
    return {}
  },
  watch: {},
  methods: {},
  mounted() {
    let timer = setInterval(() => {
      if (
        this.permission_routes &&
        this.permission_routes[0] &&
        this.permission_routes[0].children
      ) {
        clearInterval(timer)
        if (this.nickName == "ph_admin") {
          this.$router.push({
            path: "/order_mng/order/lazada",
          })
          bus.$emit("close_tag", "/welcome")
        } else if (
          JSON.stringify(this.permission_routes).indexOf("管理员首页") > -1
        ) {
          console.log(JSON.stringify(this.permission_routes))
          this.$router.push({
            path: "/index_admin",
          })
          bus.$emit("close_tag", "/welcome")
        }
      }
    }, 100)
  },
}
</script>

<style lang="scss" scoped></style>
