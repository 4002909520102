// 获取配置数据
import request from '@/utils/request'
export function getNginx() {
    return request({
        url:'/comm/nginx',
        method:'get'
    });
}
export function getOSSToken() {
    return request({
        url:'/comm/oss/sign',
        method:'post'
    });
}

export function getOSSTokenCharge() {
    return request({
        url:'/comm/oss/charge/sign',
        method:'post'
    });
}


