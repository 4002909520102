import request from "@/utils/request"

// 查询增值服务信息列表
export function getAddServiceList(query) {
  return request({
    url: "/sAddService/list",
    method: "get",
    params: query,
  })
}
// 查询会员服务信息列表
export function getVipServiceList(query) {
  return request({
    url: "/sVipService/list",
    method: "get",
    params: query,
  })
}

// 购买会员服务
export function buyVipService(data) {
  return request({
    url: "/sServiceItem/buyVipService",
    method: "post",
    data: data,
  })
}
// 购买增值服务
export function buyAddService(data) {
  return request({
    url: "/sServiceItem/buyAddService",
    method: "post",
    data: data,
  })
}

// 购买增值服务
export function getPersonInfo(data) {
  return request({
    url: "/sServiceItem/getPersonInfo",
    method: "get",
    data: data,
  })
}

// 购买会员服务
export function updateList(data) {
  return request({
    url: "/sVipService/updateList",
    method: "post",
    data: data,
  })
}

// 购买会员服务
export function updateListAdd(data) {
  return request({
    url: "/sAddService/updateList",
    method: "post",
    data: data,
  })
}


// 查询增值服务信息列表
export function buyVipInfo(query) {
  return request({
    url: "/sServiceItem/buyVipInfo",
    method: "get",
    params: query,
  })
}