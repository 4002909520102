<template>
  <div>
    <div class="contact-wrap">
      <img class="top-banner" :src="banner" />
      <div class="list">
        <div class="top">
          <div class="left-wrap">
            <img src="../../assets/images/contact/top.png" alt="" />
          </div>
          <div class="right-wrap">
            <div class="name"></div>
            <div class="desc">
              Temu定制供应链平台是一个ERP+柔性定制供应链的集合体。EPR部分给用户提供简单、 易用且高度自动化的销售管理服务，帮助用户能够更高效地刊登产品与管理订单； 轻松实现JIt模式和备货两种模式，有效解决用户大量测款和销售问题。 借助Temu定制平台，用户可以突破性地实现批量设计一键上传、多账户多平台统一管理、 店铺运营、店群模式无限铺货、24h一件代发的全新电商运营模式。
            </div>
          </div>
        </div>
      </div>
      <div class="tip">
        <div class="title">企业核心</div>
        <div class="sub-title">ERP + 本土柔性定制供应链</div>
      </div>
      <div class="contact-list">
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="image">
            <img :src="item.image" />
          </div>
          <div class="name" :title="item.name">{{ item.name }}</div>
          <div class="desc" :title="item.desc">
            {{ item.desc }}
          </div>
        </div>
      </div>
      <div
        style="padding: 50px 0 50px 0;text-align:center;box-sizing: border-box; position:relative;"
        class="map"
      >
        <img style="width:80%;" :src="contact" />
        <div
          style="position: absolute;left:1px;    top: 121px;left: 205px;font-weight:bold;font-size:20px;"
        >
          {{ phoneNumber }}
        </div>
        <div
          style="position: absolute;left:1px; top: 188px;left: 205px;font-weight:bold;font-size:20px;"
        >
          {{ email }}
        </div>
        <div
          style="position: absolute;left:1px; top: 256px;left: 205px;font-weight:bold;font-size:20px;"
        >
          {{ addr }}
        </div>
        <div
          style="position: absolute;left:1px; top: 99px;left: 363px;font-weight:bold;"
        >
          <img style="width:100px;" :src="enterpriseQrUrl">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import image0 from "@/assets/images/contact/image-0.png"
import image1 from "@/assets/images/contact/image-1.png"
import image2 from "@/assets/images/contact/image-2.png"
import image3 from "@/assets/images/contact/image-3.png"
import banner from "@/assets/images/contact/banner.png"
import contact from "@/assets/images/contact/contact.png"

export default {
  components: {},
  data() {
    return {
      list: [
        {
          image: image0,
          name: "技术研发",
          desc:
            "汇聚全球顶尖的IT人员，为东南亚本土卖家量身开发全新的定制铺货ERP平台技术研发”修改为“汇聚全球顶尖的IT人员，为全/半托管卖家量身开发全新的定制铺货ERP平台技术研发",
        },
        {
          image: image1,
          name: "本土供应链",
          desc:
            "供应链覆盖广州、泉州、义乌、河北等主要区域",
        },
        {
          image: image2,
          name: "柔性定制",
          desc:
            "先进的柔性定制生产系统确保了产品的多元化与差异化，满足店群铺货的需求",
        },
        {
          image: image3,
          name: "产品开发",
          desc: "针对市场做产品定向开发，每个单品都是四季常青热销款",
        },
      ],
      banner: banner,
      contact: contact,
    }
  },
  computed: {
    phoneNumber() {
      return this.$store.state.setting.siteData.phoneNumber
    },
    email() {
      return this.$store.state.setting.siteData.email
    },
    addr() {
      return this.$store.state.setting.siteData.addr
    },
    enterpriseQrUrl() {
      return this.$store.state.setting.siteData.enterpriseQrUrl
    },
  },
  mounted() {
    this.getMap()
  },
  methods: {
    getMap() {
      var map = new BMapGL.Map("container")
      // 创建地图实例
      var point = new BMapGL.Point(119.041538, 25.438527)
      // 创建标注
      var marker = new BMapGL.Marker(point)
      var label = new BMapGL.Label("平台定制总店", {
        // 创建文本标注
        position: point, // 设置标注的地理位置
        offset: new BMapGL.Size(-20, 10), // 设置标注的偏移量
      })

      map.addOverlay(label) // 将标注添加到地图中
      map.addOverlay(marker)
      // 创建点坐标
      map.centerAndZoom(point, 15)
    },
  },
}
</script>

<style></style>

<style lang="scss" scoped>
.contact-wrap {
  .banner {
    width: 100%;

    .title {
      font-size: 48px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      line-height: 38px;
      margin-bottom: 20px;
    }

    .sub {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      line-height: 38px;
    }
  }
}

.list {
  width: 1200px;
  margin: -50px auto 20px;

  .top {
    height: 400px;
    display: flex;
    justify-content: space-between;

    .left-wrap {
      width: 580px;
      height: 400px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .right-wrap {
      width: 590px;

      .name {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        margin: 80px auto 30px;
      }

      .desc {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 30px;
        text-align: left;
      }
    }
  }
}

.tip {
  margin: 90px auto 30px;
  width: 100%;
  text-align: center;

  .title {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #181b1c;
    margin-bottom: 10px;
  }

  .sub-title {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
  }
}

.contact-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  margin: 60px auto 30px;

  .item {
    width: 270px;
    height: 330px;
    background: #ffffff;
    border: 1px solid #e9e9e9;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    overflow: hidden;
    transition: all 0.3s linear;

    &:hover {
      box-shadow: 0 0px 13px 0 rgb(64 147 243 / 35%);
      transform: translateY(-10px);
    }

    .image {
      width: 270px;
      height: 200px;
      margin-bottom: 20px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .name {
      width: 85%;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #181b1c;
      margin: 0 auto 20px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .desc {
      width: 85%;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin: 0 auto;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
}

.map {
  width: 1200px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  margin: 60px auto;

  .top {
    width: 1200px;
    height: 400px;
  }

  .bottom {
    .title {
      height: 32px;
      font-size: 32px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #181b1c;
      width: 80%;
      margin: 40px auto;
      text-align: left;
    }

    .line {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #181b1c;
      width: 80%;
      line-height: 30px;
      margin: 10px auto;
    }
  }
}
.top-banner {
  width: 100%;
}
</style>
