<template>
  <div class="body">
    <TopNav></TopNav>
    <div class="activity-wrap">
      <div class="banner">
        <div class="banner-form">
          <h3>
            <a>{{ params.countryName }}站TOP 25W搜索词排名</a>
          </h3>
          <div style="display: flex;margin-left:5px;margin-top:15px;">
            <a
              v-for="(item, index) in country"
              :key="index"
              @click="handleSelectCountry(item)"
              class="county-item"
              style="cursor: pointer;"
              >{{ item.name }}
            </a>
          </div>
          <div class="input-wrap">
            <input
              type="text"
              v-model="params.keyword"
              placeholder="请输入搜索词"
              class="br0 txt-1"
              id="keys"
              @keyup.enter="getRank"
            />
            <a
              href="javascript:void(0)"
              class="form-btn searchbtn"
              @click="getRank"
              >查询</a
            >
          </div>
          <div
            style="text-align: center;margin:20px 0;color:#fff;font-size:12px;"
          >
            *
            本搜索词排名数据来源于亚马逊后台品牌分析功能（ABA），一周更新一次。更新时间2021-09-15
            17:46
          </div>
        </div>
      </div>
      <div class="list-wrap">
        <div class="left-list">
          <div class="filter-item">
            <div class="filter-title">本周排名</div>
            <div class="filter-list">
              <a @click="setRank('', '', 0)" :class="{ cur: currentRank == 0 }"
                >全部</a
              >
              <a
                @click="setRank('1', '1000', 1)"
                :class="{ cur: currentRank == 1 }"
                >1-1000</a
              >
              <a
                @click="setRank('1001', '10000', 2)"
                :class="{ cur: currentRank == 2 }"
                >1001-10000</a
              >
              <a
                @click="setRank('10001', '50000', 3)"
                :class="{ cur: currentRank == 3 }"
                >10001-50000</a
              >
              <a
                @click="setRank('50000', '', 4)"
                :class="{ cur: currentRank == 4 }"
                >50000以上</a
              >
            </div>
          </div>
          <div class="list">
            <div class="header">
              <div class="keywords">搜索词</div>
              <div class="rank">
                <a href="uktopkeywords-1-1-.htm">本周排名</a>
              </div>
              <div class="rank">
                <a href="uktopkeywords-1-2-.htm">上周排名</a>
              </div>
              <div class="rank">
                <a href="uktopkeywords-1-3-.htm">排名涨跌</a>
              </div>
            </div>
            <div
              class="listdata"
              v-for="(item, index) in leftList"
              :key="index"
              @click="
                handleJumpAmazon(
                  `${amazonSite[params.countryCode]}${item.keyword}`
                )
              "
            >
              <div class="keywords">
                <a href="javascript:void(0)" target="_blank">{{
                  item.keyword
                }}</a>
              </div>
              <div class="rank">{{ item.rank }}</div>
              <div class="rank">{{ item.lastweekRank }}</div>
              <div class="rank">
                <i
                  :class="{
                    'el-icon-top': item.fluRange >= 0,
                    'el-icon-bottom': item.fluRange < 0,
                  }"
                  :style="{ color: item.fluRange >= 0 ? '#f00' : '#008000' }"
                  style="font-weight:bold;"
                />
                {{ item.fluRange > 0 ? item.fluRange : -item.fluRange }}
              </div>
            </div>
          </div>
        </div>
        <div class="right-list">
          <div class="filter-item">
            <div class="filter-title">涨跌幅度</div>
            <div class="filter-list">
              <a @click="setFlu('', '', 0)" :class="{ cur: currentFlu == 0 }"
                >全部</a
              >
              <a @click="setFlu('1', '50', 1)" :class="{ cur: currentFlu == 1 }"
                >1-50</a
              >
              <a
                @click="setFlu('51', '100', 2)"
                :class="{ cur: currentFlu == 2 }"
                >51-100</a
              >
              <a
                @click="setFlu('101', '1000', 3)"
                :class="{ cur: currentFlu == 3 }"
                >101-1000</a
              >
              <a
                @click="setFlu('1000', '', 4)"
                :class="{ cur: currentFlu == 4 }"
                >1000以上</a
              >
            </div>
          </div>
          <div class="list">
            <div class="header">
              <div class="keywords">搜索词</div>
              <div class="rank">
                <a href="uktopkeywords-1-1-.htm">本周排名</a>
              </div>
              <div class="rank">
                <a href="uktopkeywords-1-2-.htm">上周排名</a>
              </div>
              <div class="rank">
                <a href="uktopkeywords-1-3-.htm">排名涨跌</a>
              </div>
            </div>
            <div
              class="listdata"
              v-for="(item, index) in rightList"
              :key="index"
              @click="
                handleJumpAmazon(
                  `${amazonSite[params.countryCode]}${item.keyword}`
                )
              "
            >
              <div class="keywords">
                <a href="javascript:void(0)" target="_blank">{{
                  item.keyword
                }}</a>
              </div>
              <div class="rank">{{ item.rank }}</div>
              <div class="rank">{{ item.lastweekRank }}</div>
              <div class="rank">
                <i
                  :class="{
                    'el-icon-top': item.fluRange >= 0,
                    'el-icon-bottom': item.fluRange < 0,
                  }"
                  :style="{ color: item.fluRange >= 0 ? '#f00' : '#008000' }"
                  style="font-weight:bold;"
                />
                {{ item.fluRange > 0 ? item.fluRange : -item.fluRange }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="params.pageNum"
        :page-size="400"
        layout="total, prev, pager, next"
        :total="dataSize"
        style="width: 1200px;margin: 0 auto;text-align:center;padding: 0px 0 80px 0;"
      >
      </el-pagination>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import TopNav from "./default/TopNav"
import Footer from "./default/Footer"

import { queryKeyWords } from "@/api/product/coll"

export default {
  name: "rank",
  components: {
    TopNav,
    Footer,
  },
  data() {
    return {
      country: [
        {
          value: "USA",
          name: "美国",
        },
        {
          value: "UK",
          name: "英国",
        },
        {
          value: "DE",
          name: "德国",
        },
        {
          value: "JP",
          name: "日本",
        },
        {
          value: "ESP",
          name: "西班牙",
        },
        {
          value: "IT",
          name: "意大利",
        },
        {
          value: "FR",
          name: "法国",
        },
        {
          value: "MX",
          name: "墨西哥",
        },
        {
          value: "CA",
          name: "加拿大",
        },
      ],
      leftList: [],
      rightList: [],
      array: [],
      params: {
        pageNum: 1,
        pageSize: 400,
        fluRangeStart: "",
        fluRangeEnd: "",
        countryCode: "USA",
        countryName: "美国",
        startNum: "",
        endNum: "",
        keyword: "",
      },
      dataSize: 0,
      currentRank: 0,
      currentFlu: 0,
      dataList: [],
      amazonSite: {
        USA: "https://www.amazon.com/s?ref=nb_sb_noss_1&k=",
        UK: "https://www.amazon.co.uk/s?ref=nb_sb_noss_1&k=",
        DE: "https://www.amazon.de/s?ref=nb_sb_noss_1&k=",
        JP: "https://www.amazon.co.jp/s?ref=nb_sb_noss_1&k=",
        ESP: "https://www.amazon.es/s?ref=nb_sb_noss_1&k=",
        IT: "https://www.amazon.it/s?ref=nb_sb_noss_1&k=",
        FR: "https://www.amazon.fr/s?ref=nb_sb_noss_1&k=",
        MX: "https://www.amazon.com.mx/s?ref=nb_sb_noss_1&k=",
        CA: "https://www.amazon.ca/s?ref=nb_sb_noss_1&k=",
      },
    }
  },
  mounted() {
    this.getRank()
  },
  methods: {
    async getRank() {
      let result = await queryKeyWords(this.params)
      let dataList = result.data
      let count = 0
      let length = dataList.length
      let leftList = []
      let rightList = []
      for (let record of dataList) {
        count++
        if (count <= Math.ceil(length / 2)) {
          leftList.push(record)
        } else {
          rightList.push(record)
        }
      }
      this.leftList = leftList
      this.rightList = rightList
      this.dataSize = result.total
    },
    setRank(startNum, endNum, index) {
      this.params.startNum = startNum
      this.params.endNum = endNum
      this.getRank()
      this.currentRank = index
    },
    setFlu(fluRangeStart, fluRangeEnd, index) {
      this.params.fluRangeStart = fluRangeStart
      this.params.fluRangeEnd = fluRangeEnd
      this.getRank()
      this.currentFlu = index
    },
    handleSelectCountry(item) {
      this.params.countryCode = item.value
      this.params.countryName = item.name

      this.getRank()
    },
    handleSizeChange() {
      this.getRank()
    },
    handleCurrentChange() {
      this.getRank()
    },
    handleJumpAmazon(url) {
      window.open(url)
    },
  },
}
</script>

<style>
@import "../assets/css/default/top-nav.css";
@import "../assets/css/default/main.css";
</style>

<style lang="scss" scoped>
.activity-wrap {
  .banner {
    background: url("../assets/images/activity/slider.png") no-repeat center;
    background-size: 100% 100%;
    text-align: center;
    position: relative;
    padding-top: 80px;
    height: 240px;
    padding-bottom: 30px;
    margin-bottom: 50px;

    .banner-form {
      width: 50%;
      margin: 20px auto;

      h3 {
        margin-bottom: 30px;

        a {
          font-size: 24px;
          color: #fff;
          text-align: center;
          padding-top: 50px;
          font-weight: bold;
          cursor: pointer;
        }

        span {
          display: inline-block;
          font-size: 14px;
          color: #999999;
          padding-left: 10px;
        }

        b {
          font-weight: bold;
          color: #4934b2;
        }

        span {
          display: inline-block;
          font-size: 14px;
          color: #999999;
          padding-left: 10px;

          em {
            color: #4934b2;
          }
        }
      }

      input:focus {
        outline: none;
      }

      .input-wrap {
        height: 55px;
        margin-top: 5px;
        position: relative;
        display: flex;

        input {
          float: left;
          width: calc(100% - 105px);
          height: 45px;
          padding-left: 15px;
          box-sizing: border-box;
          border-radius: 35px 0 0 35px;
          border: none;

          &::-webkit-input-placeholder {
            font-size: 16px;
            color: #999;
          }
        }

        .form-btn {
          float: left;
          width: 105px;
          height: 45px;
          text-align: center;
          line-height: 45px;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          background-color: #4934b2;
          font-size: 18px;
          color: #fff;
          border-radius: 0 35px 35px 0;
        }
      }

      .county-item {
        margin-right: 10px;
        color: #fff;
        font-size: 14px;
      }
    }
  }
}

.list-wrap {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  .left-list,
  .right-list {
    width: 48%;
  }

  .filter-item {
    display: flex;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 30px;
    justify-content: center;

    .filter-title {
      width: 70px;
      font-size: 14px;
      color: #bbb;
      padding: 4px 0px;
    }

    a {
      font-size: 14px;
      color: #282828;
      line-height: 30px;
      padding: 4px 12px;

      &.cur {
        background: #f2f2f2;
        color: #4934b2;
        border-radius: 4px;
      }
    }
  }

  .list {
    margin-bottom: 60px;

    .header {
      display: flex;
      margin-bottom: 20px;

      .keywords {
        width: 50%;
        color: #777;
        text-align: left;
        font-size: 14px;
        font-weight: bold;
        box-sizing: border-box;
        padding-left: 30px;
      }

      .rank {
        position: relative;
        color: #777;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        width: calc(50% / 3);
        cursor: pointer;
        color: #4934b2;
      }
    }

    .listdata {
      padding: 10px 0;
      font-size: 14px;
      display: flex;
      cursor: pointer;

      &:hover {
        background: #6f79ed;
        color: #fff !important;
        border-radius: 35px;
        a {
          color: #fff !important;
        }
      }

      &:nth-of-type(odd) {
        background-color: #f9f9f9;
        border-radius: 35px;

        &:hover {
          background: #6f79ed;
          color: #fff !important;
          border-radius: 35px;
          a {
            color: #fff !important;
          }
        }
      }

      .keywords {
        width: 50%;
        text-align: left;
        box-sizing: border-box;
        padding-left: 30px;
      }

      .rank {
        width: calc(50% / 3);
        text-align: center;
      }
    }
  }

  .filter-list {
    a {
      cursor: pointer;
    }
  }
}
</style>
