const order = {
    state: {
        orderStatus: [],//订单状态集合（字典数据）
        baseTemplate: null,//订单导出模板
    },
    mutations: {
        SET_ORDERSTATUS: (state, orderStatus) => {
            state.orderStatus = orderStatus;
        },
        SET_BASE_TEMPLATE: (state, baseTemplate) => {
            state.baseTemplate = baseTemplate;
        }
    },
    actions: {
        setBaseTemplate({commit},val){
            commit('SET_BASE_TEMPLATE',val);
        },
    }
}

export default order


