<template>
  <div class="home">
    <el-container id="ct1">
      <el-header>
        <TopSideBar />
      </el-header>
      <section
        style="padding: 0;margin:0;"
        v-if="showSyncHint"
        class="sync_product_info"
      >
        <div>
          <span v-if="!isFinish" class="rotate_iocn"
            ><i class="el-icon-loading"></i
          ></span>
          <span v-if="!isFinish">数据同步中，请稍后...</span>
          <span v-else>同步结束。</span>
          <div
            v-if="syncResult.total > 0"
            style="display:inline-block;margin: 0 0 0 8px;"
          >
            共 {{ syncResult.total }} 个，成功<span
              style="color:green;margin:0 4px;"
              >{{ syncResult.success }}</span
            ><span>个，失败</span
            ><span style="color:red;margin:0 4px;">{{ syncResult.fail }}</span
            >个。
            <span
              @click="dialogVisible = true"
              style="color:#4934b2;cursor:pointer;"
              >查看</span
            >
            <span>
              <i
                style="cursor:pointer;margin: 0 0 0 16px;"
                @click="handleCloseHint"
                class="el-icon-close"
              ></i>
            </span>
          </div>
        </div>
      </section>
      <el-container id="ct2">
        <Menu v-show="userType == '00'" />
        <el-container v-if="userType == '00'">
          <v-tags></v-tags>
          <div style="margin: 39px 0 0 0; width: 100%">
            <transition name="move" mode="out-in">
              <keep-alive :include="tagsList">
                <router-view></router-view>
              </keep-alive>
            </transition>
          </div>
        </el-container>
        <el-container v-else>
          <router-view></router-view>
        </el-container>
      </el-container>
    </el-container>
    <el-dialog
      title="同步结果"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleCloseDialog"
    >
      <div style="font-size:14px;">
        <p>
          同步产品 {{ syncResult.total }} 个， <span>成功</span
          ><span style="color:green;margin:0 4px;">{{
            syncResult.success
          }}</span
          ><span>个，失败</span
          ><span style="color:red;margin:0 4px;">{{ syncResult.fail }}</span
          ><span>个</span>。
        </p>
        <br />
        <p>同步失败列表：</p>
        <div
          style="margin: 0 0 8px 0;"
          :key="key"
          v-for="(record, key) in syncResult.failList"
        >
          <p>{{ key + 1 }}. {{ record.designId }}</p>
          <p>{{ record.msg }}</p>
        </div>
        <div v-if="syncResult.failList.length == 0">暂无</div>
      </div>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import '../assets/css/main.css'
import TopSideBar from '@/components/topSide/sideBar.vue'
import {mapGetters} from 'vuex'
import Menu from '@/components/Menu/index.vue'
import store from '../store'
import { getShopList } from "@/api/shop/shop";
import { getToken } from '@/utils/auth'
import { getDictData } from "@/api/system/dict/data";
import vTags from '@/components/Tags';
import bus from '@/utils/bus';
import { getSiteConfig } from '@/api/system/config'
import { getInfo } from "@/api/login"

export default {
  name: 'Home',
  data(){
    return {
      userType: '00',
      tagsList: [],
      showSyncHint: false,
      dialogVisible: false,
      isFinish: false,
    }
  },
  components: {
    TopSideBar,
    Menu,
    vTags
  },
  computed:{
    ...mapGetters(['orderStatus', 'user', 'syncResult']),
     routeName() {
      return this.$route.path
    },
  },
  watch:{
    user: {
      handler(){
        this.userType = this.user.userType
      },
      deep: true,
      immediate: true
    },
    async routeName(){
      let permitRoutes = ['/system/account/purchaseMember','/system/account/recharge','/index', '/authorization/shop/lazada', '/authorization/shop/shopee', '/authorization/shop/tiktok']
      if(permitRoutes.includes(this.routeName)){
        return
      }
      let result = await getInfo()
      if (result.isExpire == "0") {
        this.$confirm(
          `<span style="color:red;">你的会员已过期，目前已使用店铺${result.bindCnt}个，当前套餐允许使用店铺数${result.maxCnt}个，需要续费${result.payCnt}个，请前往续费套餐或删除多余店铺，谢谢支持！</span>`,
          "提示",
          {
            confirmButtonText: "续费套餐",
            cancelButtonText: "处理店铺",
            dangerouslyUseHTMLString: true,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            closeOnHashChange: false,
            showClose: false
          }
        )
          .then((type) => {
            if (type == "confirm") {
              this.$router.push({
                path: "/system/account/purchaseMember",
              })
            }
          })
          .catch(() => {
            this.$router.push({
              path: "/authorization/shop/lazada",
            })
          })
      }
    }
  },
  created() {
    this.loadInitData();
    // this.getSiteConfig()
     bus.$on('collapse-content', msg => {
      this.collapse = msg;
    });

    // 只有在标签页列表里的页面才使用keep-alive，即关闭标签之后就不保存到内存中了。
    bus.$on('tags', msg => {
      let arr = [];
      for (let i = 0, len = msg.length; i < len; i++) {
        msg[i].title && arr.push(msg[i].title);
      }
      this.tagsList = arr;
    });
    localStorage.removeItem('form')
  },
  mounted() {
    if (getToken()) {
      getDictData().then((result) => {
        this.$store.commit("SET_DICT_DATA", result.data)
      })
      this.$store.dispatch("GetIndexStat")
    }
    this.events.$on("showSyncHint", this.handleShowSyncHint)
  },
  methods:{
    handleCloseDialog(done) {
      this.dialogVisible = false
    },
    loadInitData(){
    },
    async getSiteConfig(){
      let result = await getSiteConfig();
    },
     handleShowSyncHint(flag) {
      this.isFinish = !flag
      if(!flag && this.syncResult.total > 0){
        return
      }
      this.showSyncHint = flag
    },
    handleCloseHint(){
      this.showSyncHint = false
      this.$store.commit("SET_SYNC_STATUS", false)
    }
  }
}
</script>
<style scoped>
.home {
  height: 100%;
}
.el-header {
  background-color: #4934b2;
  color: #333;
  text-align: center;
  line-height: 50px;
  padding: 0 20px;
  z-index: 999 !important;
  height: 50px !important;
  /*position: relative;*/
}
.el-aside {
  background-color: rgba(226, 242, 255, 0);
  color: #333;
  margin: 15px;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: white;
  color: #333;
}

#ct1 {
  width: 100%;
  height: 100%;
}
</style>
