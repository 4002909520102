<template>
  <div>
    <div class="flowBox">
      <div class="boxHeader">
        <h2>覆盖多元行业及品类</h2>
        <p>多行业品类覆盖，丰富的服务</p>
      </div>

      <div
        style="
          display: flex;
          width: 100%;
          margin: 0 0 7vh 0;
          padding: 0 5%;
          box-sizing: border-box;
        "
      >
        <div
          v-for="(record, index) in productList1"
          :key="index"
          class="flexItem"
        >
          <div
            @mouseout="handleMouseOut(record)"
            @mouseover="handleMouseOver(record)"
            class="flowItem"
          >
            <img class="img" :src="record.picUrl" />
            <div class="title">{{ record.name }}</div>
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          width: 100%;
          margin: 0 0 7vh 0;
          padding: 0 5%;
          box-sizing: border-box;
        "
      >
        <div
          v-for="(record, index) in productList2"
          :key="index"
          class="flexItem"
        >
          <div
            @mouseout="handleMouseOut(record)"
            @mouseover="handleMouseOver(record)"
            class="flowItem"
          >
            <img class="img" :src="record.picUrl" />
            <div class="title">{{ record.name }}</div>
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          width: 100%;
          padding: 0 5%;
          box-sizing: border-box;
        "
      >
        <div
          v-for="(record, index) in productList3"
          :key="index"
          class="flexItem"
        >
          <div
            @mouseout="handleMouseOut(record)"
            @mouseover="handleMouseOver(record)"
            class="flowItem"
          >
            <img class="img" :src="record.picUrl" />
            <div class="title">{{ record.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="boxHeader">
      <h2>一站定制，货通全球</h2>
      <p>从定制设计至生产发货如此简单</p>
    </div>
    <div class="glo-list">
      <div
        :class="{
          'glo-item1': index == 0,
          'glo-item2': index == 1,
          'glo-item3': index == 2,
          'glo-item4': index == 3,
        }"
        class="glo-item"
        v-for="(item, index) in gloList"
        :key="index"
      >
        <div :style="{ color: item.color }" class="name">{{ item.name }}</div>
        <div class="desc">{{ item.desc }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from "@/assets/images/product/1.jpeg"
import img1Hover from "@/assets/images/product/1-1.jpeg"
import img2 from "@/assets/images/product/2.jpeg"
import img2Hover from "@/assets/images/product/2-2.jpeg"
import img3 from "@/assets/images/product/3.jpeg"
import img3Hover from "@/assets/images/product/3-3.jpeg"
import img4 from "@/assets/images/product/4.jpeg"
import img4Hover from "@/assets/images/product/4-4.jpeg"
import img5 from "@/assets/images/product/5.jpeg"
import img5Hover from "@/assets/images/product/5-5.jpeg"
import img6 from "@/assets/images/product/6.png"
import img6Hover from "@/assets/images/product/6-6.jpeg"

import img7 from "@/assets/images/product/7.jpeg"
import img7Hover from "@/assets/images/product/7-7.jpeg"
import img8 from "@/assets/images/product/8.jpeg"
import img8Hover from "@/assets/images/product/8-8.jpeg"
import img9 from "@/assets/images/product/9.jpeg"
import img9Hover from "@/assets/images/product/9-9.jpeg"
import img10 from "@/assets/images/product/10.jpeg"
import img10Hover from "@/assets/images/product/10-10.jpeg"
import img11 from "@/assets/images/product/11.jpeg"
import img11Hover from "@/assets/images/product/11-11.jpeg"
import img12 from "@/assets/images/product/12.jpeg"
import img12Hover from "@/assets/images/product/12-12.jpeg"

import img13 from "@/assets/images/product/13.jpeg"
import img13Hover from "@/assets/images/product/13-13.jpeg"
import img14 from "@/assets/images/product/14.jpeg"
import img14Hover from "@/assets/images/product/14-14.jpeg"
import img15 from "@/assets/images/product/15.jpeg"
import img15Hover from "@/assets/images/product/15-15.jpeg"

import img16 from "@/assets/images/product/16.jpeg"
import img16Hover from "@/assets/images/product/16-16.jpeg"
import img17 from "@/assets/images/product/17.jpeg"
import img17Hover from "@/assets/images/product/17-17.jpeg"

import img18 from "@/assets/images/product/18.jpeg"
import img18Hover from "@/assets/images/product/18-18.jpeg"

export default {
  data() {
    return {
      productList1: [
        {
          picUrl: img1,
          picUrlBak: img1,
          picUrlHover: img1Hover,
          name: "全鞋类",
        },
        {
          picUrl: img2,
          picUrlBak: img2,
          picUrlHover: img2Hover,
          name: "生活百货",
        },
        {
          picUrl: img3,
          picUrlBak: img3,
          picUrlHover: img3Hover,
          name: "鞋类配件",
        },
        {
          picUrl: img4,
          picUrlBak: img4,
          picUrlHover: img4Hover,
          name: "穿戴配饰",
        },
        {
          picUrl: img5,
          picUrlBak: img5,
          picUrlHover: img5Hover,
          name: "装修",
        },
        {
          picUrl: img6,
          picUrlBak: img6,
          picUrlHover: img6Hover,
          name: "收纳",
        },
      ],
      productList2: [
        {
          picUrl: img7,
          picUrlBak: img7,
          picUrlHover: img7Hover,
          name: "瑜伽",
        },
        {
          picUrl: img8,
          picUrlBak: img8,
          picUrlHover: img8Hover,
          name: "户外",
        },
        {
          picUrl: img9,
          picUrlBak: img9,
          picUrlHover: img9Hover,
          name: "书包",
        },
        {
          picUrl: img10,
          picUrlBak: img10,
          picUrlHover: img10Hover,
          name: "床上用品",
        },
        {
          picUrl: img11,
          picUrlBak: img11,
          picUrlHover: img11Hover,
          name: "精品时装",
        },
        {
          picUrl: img12,
          picUrlBak: img12,
          picUrlHover: img12Hover,
          name: "帽子",
        },
      ],
      productList3: [
        {
          picUrl: img13,
          picUrlBak: img13,
          picUrlHover: img13Hover,
          name: "时装包",
        },
        {
          picUrl: img14,
          picUrlBak: img14,
          picUrlHover: img14Hover,
          name: "消费3C",
        },
        {
          picUrl: img15,
          picUrlBak: img15,
          picUrlHover: img15Hover,
          name: "沙滩",
        },
        {
          picUrl: img16,
          picUrlBak: img16,
          picUrlHover: img16Hover,
          name: "汽配",
        },
        {
          picUrl: img17,
          picUrlBak: img17,
          picUrlHover: img17Hover,
          name: "防护",
        },
        {
          picUrl: img18,
          picUrlBak: img18,
          picUrlHover: img18Hover,
          name: "婴童",
        },
      ],
      gloList: [
        {
          index: 1,
          name: "选品设计",
          desc: "通过信息的捕获设计不同的主题，根据图片主题快速选择商品，开启泛精品差异化运营第一步",
          color: "#2763F9",
        },
        {
          index: 2,
          name: "授权刊登",
          desc: "用户只需将海外店铺与Onecust 1号进行授权绑定，并将设计的商品刊登到您的海外店铺进行销售",
          color: "#FF8911",
        },
        {
          index: 3,
          name: "下单支付",
          desc: "授权绑定的店铺出单后即可自动同步，支持多种支付方式及全球主流币种结算",
          color: "#26D17B",
        },
        {
          index: 4,
          name: "生产发货",
          desc: "支持订单状态可视化，一件即可下单，48小时内即可完成生产并向全球发货",
          color: "#FFAC61",
        },
      ],
    }
  },
  methods: {
    handleMouseOver(record) {
      if (record.picUrlHover) {
        record.picUrl = record.picUrlHover
      }
    },

    handleMouseOut(record) {
      record.picUrl = record.picUrlBak
    },
  },
}
</script>

<style scoped lang="scss">
.flowBox {
  height: 960px;
  box-sizing: border-box;
  padding: 0 0 120px 0;
  text-align: center;
  background-size: cover;
  background-position: center center;
  position: relative;
  margin-bottom: 90px;
  background: #ffffff;
}

.boxHeader {
  margin-bottom: 50px;
  text-align: center;
}

.boxHeader h2 {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #181b1c;
  margin-bottom: 10px;
}

.boxHeader p {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.flowBox .flowItem {
  width: 160px;
  height: 220px;
  background: #ffffff;
  border-radius: 10px;
  display: inline-block;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.6s ease 0s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 0px 13px 0 rgb(64 147 243 / 35%);
  }
}

.flexItem {
  flex: 1;
  text-align: center;
}

.flowItem1 {
  left: 10%;
  top: 550px;
}

.flowItem2 {
  left: 23%;
  top: 253px;
}

.flowItem3 {
  left: 41%;
  top: 503px;
}

.flowItem4 {
  left: 55%;
  top: 229px;
}

.flowItem5 {
  left: 68%;
  top: 543px;
}

.flowItem6 {
  left: 85%;
  top: 208px;
}

.flowBox .img {
  width: 135px;
  height: 135px;
  margin-bottom: 20px;
}

.flowBox .title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #181b1c;
  background-image: url("../../assets/images/default/text-bg.png");
  background-size: 49px;
  line-height: 38px;
  background-position: center center;
  background-repeat: no-repeat;
}

.global-title {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #181b1c;
  margin-bottom: 40px;
  margin-top: 60px;
}

.global-title-sub {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}

.glo-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  margin: 60px auto 30px;
  background: url("../../assets/images/default/process_bg.png") no-repeat;
  background-size: cover;
  padding: 0 0 111px 0;

  .glo-item {
    width: 250px;
    height: 340px;
    background: #ffffff;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    position: relative;
    transition: all 0.3s linear;

    &:hover {
      box-shadow: 0 0px 13px 0 rgb(64 147 243 / 35%);
      transform: translateY(-10px);
    }

    .index {
      position: absolute;
      background: url("../../assets/images/home/list-dot.png") no-repeat;
      background-size: cover;
      width: 87px;
      height: 87px;
      top: -43px;
      left: 32%;
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .name {
      width: 75%;
      text-align: center;
      margin: 80px auto 20px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #363839;
    }

    .desc {
      width: 75%;
      margin: 0 auto;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: #363839;
      line-height: 30px;
    }
  }
  .glo-item1 {
    box-shadow: 0px 1px 5px 0px rgba(39, 99, 249, 0.25);
  }
  .glo-item2 {
    box-shadow: 0px 1px 7px 0px rgba(85, 251, 209, 0.27);
  }
  .glo-item3 {
    box-shadow: 0px 1px 14px 1px rgba(196, 212, 255, 0.27);
  }
  .glo-item4 {
    box-shadow: 0px 1px 7px 0px rgba(247, 202, 136, 0.27);
  }
}
.img1:hover {
  content: url(../../assets/images/home/1643273225867.jpeg);
}
</style>
