<template>
  <div class="portalFooter">
    <div class="hdWrap__PortalLayout-16BGU8">
      <div class="hd">
        <div>零库存</div>
        <div>无忧售后</div>
        <div>一件代发</div>
        <div>高净利润</div>
      </div>
    </div>
    <div class="bd">
      <div class="qrcode">
        <div class="logo"></div>
        <div class="contact">
          <div>联系客服 service@onecust.com</div>
          <div>商务合作 bd@onecust.com</div>
          <div>技术支持 support@onecust.com</div>
        </div>
      </div>
      <div class="contact1">
        <div class="tel">135 5939 7618</div>
        <div class="qq">
          <div style="float: left; margin: 0 0 0 0px">
            <img :src="imageWechart" /> <br /><span style="padding: 0 0 0 8px"
              >扫码添加微信</span
            >
          </div>
          <div style="float: left; margin: 0 0 0 29px">
            <img :src="imageQQ" /> <br /><span style="padding: 0 0 0 8px"
              >扫码添加QQ</span
            >
          </div>
        </div>
      </div>
      <div class="linkCol__PortalLayout-1EV_-b">
        <h3>新手指南</h3>
        <a target="_blank" rel="noopener noreferrer">DIY铺货基础学习</a
        ><a target="_blank" rel="noopener noreferrer">选择产品/开发产品</a
        ><a target="_blank" rel="noopener noreferrer">如何设计</a
        ><a target="_blank" rel="noopener noreferrer">成品发布</a
        ><a target="_blank" rel="noopener noreferrer">如何下单</a>
      </div>
      <div class="linkCol__PortalLayout-1EV_-b">
        <h3>合作专区</h3>
        <a>会员服务</a><a>生产商合作</a><a>服务商合作</a>
      </div>
      <div class="linkCol__PortalLayout-1EV_-b">
        <h3>关于我们</h3>
        <a>关于我们</a><a target="_blank" rel="noopener noreferrer">售后说明</a>
      </div>
    </div>

    <div class="copyright__PortalLayout-3W9Nm3">
      Copyright @平台定制<a
        href="https://beian.miit.gov.cn/#/Integrated/index"
        target="blank"
        style="color: rgb(255, 255, 255); margin: 0px 10px"
        >闽ICP备2021011432号-1</a
      >

      <a
        href="https://beian.miit.gov.cn/#/Integrated/index"
        target="blank"
        style="color: rgb(255, 255, 255); margin: 0px 10px"
        >闽ICP备2021011432号-2</a
      >

      <a
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35020302035083"
        target="blank"
        style="color: rgb(255, 255, 255); margin: 0px 10px"
        >闽公网安备35020302035083号</a
      >

      All Rights Reserved.
    </div>
  </div>
</template>

<script>
import imageWechart from "@/assets/images/wechart.jpg"
import imageQQ from "@/assets/images/qq.png"

export default {
  data() {
    return {
      imageWechart: imageWechart,
      imageQQ: imageQQ,
    }
  },
}
</script>

<style lang="scss" scoped>
.portalFooter {
  background: rgb(46, 48, 51);
  color: #fff;
}

.portalFooter .hdWrap__PortalLayout-16BGU8 {
  background: rgb(46, 48, 51);
}

.portalFooter .hd {
  width: 1200px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  border-bottom: 1px solid #45484c;
}

.portalFooter .hd > div {
  font-size: 18px;
  line-height: 45px;
  color: #fff;
  padding-left: 60px;
}

.portalFooter .hd > div:nth-child(1) {
  background: url("../../assets/images/home/bottom-1.png") no-repeat left
    center/48px;
}

.portalFooter .hd > div:nth-child(2) {
  background: url("../../assets/images/home/bottom-2.png") no-repeat left
    center/48px;
}

.portalFooter .hd > div:nth-child(3) {
  background: url("../../assets/images/home/bottom-3.png") no-repeat left
    center/48px;
}

.portalFooter .hd > div:nth-child(4) {
  background: url("../../assets/images/home/bottom-4.png") no-repeat left
    center/48px;
}

.portalFooter .bd {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
}

.portalFooter .logo {
  background: url("../../assets/images/logo_white.png") no-repeat;
  background-size: cover;
  width: 178px;
  height: 58px;
  margin: 21px 0 0px -29px;
}

.portalFooter .contact {
  width: 219px;
  height: 58px;
  margin: 30px 0 0px -29px;
  font-size: 14px;
  line-height: 29px;
}

.portalFooter .linkCol__PortalLayout-1EV_-b {
  margin-left: 55px;
}

.portalFooter .linkCol__PortalLayout-1EV_-b > h3 {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 25px;
}

.portalFooter .linkCol__PortalLayout-1EV_-b > a {
  display: block;
  margin-bottom: 15px;
  font-size: 14px;
  color: #fff;
}

.portalFooter .linkCol__PortalLayout-1EV_-b > a:last-child {
  margin-bottom: 0;
}

.portalFooter .linkCol__PortalLayout-1EV_-b > a:hover {
  color: #fff;
}

.portalFooter .copyright__PortalLayout-3W9Nm3 {
  line-height: 60px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  border-top: 1px solid #4d4d4d;
}

.portalFooter .contact1 {
  margin-left: 80px;
  margin-top: -52px;
}

.portalFooter .contact1 .tel,
.portalFooter .contact1 .qq {
  margin-bottom: 20px;
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #fff;
  padding-left: 55px;
  padding-left: 80px;
  overflow: hidden;
  img {
    width: 117px;
    height: 117px;
    margin: 0 0 0 10px;
  }
}

.portalFooter .contact1 .qq {
  font-size: 14px;
  text-align: center;
  font-weight: normal;
}
.portalFooter .contact1 .tel {
  margin-top: 40px;
  background: url("../../assets/images/home/bottom-tel.png") no-repeat left
    center/32px;
}

.portalFooter .contact1 .qq {
}

.portalFooter .contact1 .time__PortalLayout-30tB3L {
  font-size: 14px;
  color: #ccc;
}

.portalFooter .qrcode {
  width: 110px;
  height: 110px;
  margin-left: 25px;
}

.portalFooter .qrcode > img {
  width: 100%;
  margin: 0 0 0 5px;
}
</style>
