<template>
  <div
    :style="{ minWidth: user.userType != '00' ? '320px' : '0px' }"
    class="common-topbar-user-info-dropdown"
  >
    <div v-if="user.userType != '00'" style="padding: 12px;text-align:left;">
      <div class="email">
        {{ userData.userName }}
        <div style="line-height:1.5;display:inline-block;">
          <button class="account-button">
            {{ accountName }}
          </button>
        </div>
      </div>
    </div>
    <div v-if="user.userType != '00'" class="menu-button">
      <div style="line-height:30px;">余额（元）</div>
      <div
        style="line-height:30px;color:#FF7E00;font-size: 16px;font-weight:bold;"
      >
        ￥{{ userData.balance }}
      </div>
      <div>
        <div style="display:inline-block;">
          <a
            loading="0"
            @click="handleJump('/system/account/recharge')"
            class="link-button"
            style="margin: 0 16px 0 0;"
            >在线充值</a
          >
        </div>
        <div v-if="user.userType != '02'" style="display:inline-block;">
          <a
            @click="handleJump('/system/account/withdrawal')"
            class="link-button"
            >账户提现</a
          >
        </div>
      </div>
    </div>
    <div v-if="user.userType == '01'" class="sc-obd7zb-0 CB-fPLuA-D">
      <div
        style="padding: 16px 0  0 45px;text-align:left;"
        class="sc-3k5shv-0 CB-kXOHJN"
      >
        <a @click="handleJump('/map/map_mng/imageMe')" class="menu-logo">
          <div style="height:40px;">
            <img :src="user1" />
          </div>
          <div class="sc-fga5tf-3 CB-fRjyTt">我的图库</div></a
        >
        <a @click="handleJump('/select/selection/design')" class="menu-logo">
          <div style="height:40px;">
            <img :src="user2" />
          </div>
          <div class="sc-fga5tf-3 CB-fRjyTt">我的设计</div></a
        >
        <a @click="handleJump('/system/account/userInfo')" class="menu-logo">
          <div style="height:40px;">
            <img :src="user3" />
          </div>
          <div class="sc-fga5tf-3 CB-fRjyTt">个人信息</div></a
        >
        <a @click="handleJump('/order/send/manage')" class="menu-logo">
          <div style="height:40px;">
            <img :src="user4" />
          </div>
          <div class="sc-fga5tf-3 CB-fRjyTt">物流查询</div></a
        >
        <a
          @click="handleJump('/analysis/transaction/myBill')"
          class="menu-logo"
        >
          <div style="height:40px;">
            <img :src="user5" />
          </div>
          <div class="sc-fga5tf-3 CB-fRjyTt">交易记录</div></a
        >
        <a
          @click="handleJump('/system/advisory/announcement')"
          class="menu-logo"
        >
          <div style="height:40px;">
            <img :src="user6" />
          </div>
          <div class="sc-fga5tf-3 CB-fRjyTt">公告信息</div></a
        >
      </div>
    </div>

    <div v-if="user.userType == '02'" class="sc-obd7zb-0 CB-fPLuA-D">
      <div
        style="padding: 16px 0  0 45px;text-align:left;"
        class="sc-3k5shv-0 CB-kXOHJN"
      >
        <a @click="handleJump('/map/map_mng/imageMe')" class="menu-logo">
          <div style="height:40px;">
            <img :src="user1" />
          </div>
          <div class="sc-fga5tf-3 CB-fRjyTt">我的图库</div></a
        >
        <a @click="handleJump('/select/selection/design')" class="menu-logo">
          <div style="height:40px;">
            <img :src="user2" />
          </div>
          <div class="sc-fga5tf-3 CB-fRjyTt">我的设计</div></a
        >
        <a @click="handleJump('/system/account/userInfo')" class="menu-logo">
          <div style="height:40px;">
            <img :src="user3" />
          </div>
          <div class="sc-fga5tf-3 CB-fRjyTt">个人信息</div></a
        >
        <a @click="handleJump('/order/send/manage')" class="menu-logo">
          <div style="height:40px;">
            <img :src="user4" />
          </div>
          <div class="sc-fga5tf-3 CB-fRjyTt">物流查询</div></a
        >
        <a
          @click="handleJump('/analysis/transaction/myBill')"
          class="menu-logo"
        >
          <div style="height:40px;">
            <img :src="user5" />
          </div>
          <div class="sc-fga5tf-3 CB-fRjyTt">交易记录</div></a
        >
        <a
          @click="handleJump('/system/advisory/announcement')"
          class="menu-logo"
        >
          <div style="height:40px;">
            <img :src="user6" />
          </div>
          <div class="sc-fga5tf-3 CB-fRjyTt">公告信息</div></a
        >
      </div>
    </div>
    <footer class="sc-1dhdgcg-0 CB-eLAMyj">
      <div class="sc-1akil4-0 CB-laomNT">
        <div class="sc-pzopam-0 CB-fjEFxU">
          <a
            @click="handleLogOut"
            class="sc-2fc5kz-0 sc-fga5tf-0 CB-gauNDS CB-glpSCo"
            >退出登录</a
          >
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import user1 from "@/assets/images/workspace/user1.png"
import user2 from "@/assets/images/workspace/user2.png"
import user3 from "@/assets/images/workspace/user3.png"
import user4 from "@/assets/images/workspace/user4.png"
import user5 from "@/assets/images/workspace/user5.png"
import user6 from "@/assets/images/workspace/user6.png"

export default {
  data() {
    return {
      user1: user1,
      user2: user2,
      user3: user3,
      user4: user4,
      user5: user5,
      user6: user6,
    }
  },
  props: {
    custom: {
      type: String,
      default: "",
    },
    userData: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  computed: {
    ...mapGetters(["user"]),
    accountName() {
      if (this.userData.userType == "00") {
        return "管理员"
      } else if (this.userData.userType == "01") {
        return "主账号"
      } else {
        return "子账号"
      }
    },
  },
  methods: {
    handleLogOut() {
      this.$store.dispatch("LogOut").then(() => {
        if (this.userData.userType == "00") {
          location.href = "/login_admin"
        } else {
          location.href = "/login"
        }
      })
    },
    handleJump(path) {
      this.$router.push({ path: path })
    },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
.common-topbar-user-info-dropdown {
  position: absolute;
  box-sizing: border-box;
  min-width: 120px;
  font-size: 12px;
  transition: all 300ms ease-in-out 0s;
  color: var(--cb-color-text-primary, #333);
  border: 1px solid var(--cb-color-border-tertiary, #ebebeb);
  background-color: var(--cb-color-bg-primary, #fff);
  box-shadow: 0 4px 8px 0 var(--cb-color-shadow, rgba(0, 0, 0, 0.16));
  z-index: 10;

  right: 0px;
  min-width: 320px;
  max-width: 420px;
  top: 50px;
  display: none;
}
.email {
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: 600;
  color: var(--cb-color-text-primary, #333);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.5;
}
.account {
  font-size: 13px;
  line-height: 1.5;
  color: var(--cb-color-text-tertiary, #999);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.account-button {
  cursor: default;
  color: #ffffff;
  background-color: #4934b2;
  border: 1px solid var(--cb-color-border-accent, #4934b2);
  margin: 0 8px 0px 5px;
  padding: 0px 8px;
  border-radius: 100px;
  line-height: 1.7em;
  font-size: 0.95em;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
  width: auto;
}
.menu-button {
  padding: 8px 0 8px 0;
  background-color: var(--cb-color-bg-primary, #fff);
  border-top: 1px solid var(--cb-color-border-tertiary, #ebebeb);
  border-bottom: 1px solid var(--cb-color-border-tertiary, #ebebeb);
}
.CB-haYvkv:first-child {
  border-left: 0px;
}

.CB-haYvkv {
  flex: 1 1 0%;
  overflow: hidden;
  border-left: 1px solid var(--cb-color-border-tertiary, #ebebeb);
  border-bottom: 1px solid var(--cb-color-border-tertiary, #ebebeb);
}
.CB-jbHzuH {
  vertical-align: middle;
  border-width: initial;
  border-style: none;
  border-image: initial;
  border-radius: 0px;
  text-align: center;
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 32px;
  line-height: 30px;
  font-size: 13px;
  color: var(--cb-color-button-menu-text, #666);
  background-color: var(--cb-color-button-menu-bg, transparent);
  border-color: var(--cb-color-button-menu-border, transparent);
  cursor: pointer;
}
.CB-jbHzuH:link,
.CB-jbHzuH:visited {
  color: var(--cb-color-button-menu-text, #666);
}
.CB-eVWrXW {
  display: block;
  position: absolute;
  box-sizing: border-box;
  min-width: 120px;
  font-size: 12px;
  transition: all 300ms ease-in-out 0s;
  color: var(--cb-color-text-primary, #333);
  border: 1px solid var(--cb-color-border-tertiary, #ebebeb);
  background-color: var(--cb-color-bg-primary, #fff);
  box-shadow: 0 4px 8px 0 var(--cb-color-shadow, rgba(0, 0, 0, 0.16));
  line-height: 1.5;
}
.CB-kXOHJN {
  margin: 8px 0px;
}
.CB-fUEgaX:hover,
.CB-fUEgaX:focus {
  color: var(--cb-color-button-menu-text-hover, #666);
  background-color: var(--cb-color-button-menu-bg-hover, #f4f6f7);
  border-color: var(--cb-color-button-menu-border-hover, transparent);
}
.CB-gauNDS:hover {
  text-decoration: none;
}
.CB-toNyL {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.CB-jHNKiw:first-child {
  margin-left: 0px;
}
.CB-jHNKiw {
  margin: 0px 8px 0px 4px;
  font-size: 1.1em;
}
.CB-fRjyTt {
  flex: 1 1 0%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--cb-color-button-menu-text-hover, #333);
}
.CB-eyiNhl {
  height: 0px;
  border-top: 1px solid var(--cb-color-border-tertiary, #ebebeb);
}
.CB-fUEgaX {
  vertical-align: middle;
  border-width: initial;
  border-style: none;
  border-image: initial;
  border-radius: 0px;
  text-align: left;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px 12px;
  height: 32px;
  line-height: 30px;
  font-size: 12px;
  color: var(--cb-color-button-menu-text, #666);
  background-color: var(--cb-color-button-menu-bg, transparent);
  border-color: var(--cb-color-button-menu-border, transparent);
  cursor: pointer;
}
.CB-eLAMyj {
  border-top: 1px solid var(--cb-color-border-tertiary, #ebebeb);
}
.CB-laomNT {
  display: flex;
  padding: 12px 6px;
}
.CB-fjEFxU {
  flex: 1 1 0%;
  padding: 0px 6px;
}
.CB-glpSCo:active {
  color: var(--cb-color-button-secondary-alt-text-active, #333);
  background-color: var(--cb-color-button-secondary-alt-bg-active, #f4f6f7);
  border-color: var(--cb-color-button-secondary-alt-border-active, #e3e4e6);
}
.CB-gauNDS:hover {
  text-decoration: none;
}
.CB-eLAMyj {
  border-top: 1px solid var(--cb-color-border-tertiary, #ebebeb);
}
.CB-glpSCo {
  vertical-align: middle;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-radius: 2px;
  text-align: center;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px 12px;
  height: 32px;
  line-height: 30px;
  font-size: 12px;
  color: var(--cb-color-button-secondary-alt-text, #666);
  background-color: var(--cb-color-button-secondary-alt-bg, transparent);
  border-color: var(--cb-color-button-secondary-alt-border, #e3e4e6);
  cursor: pointer;
  transition: all 0.3s ease-out 0s;
  text-decoration: none;
  color: var(--cb-color-button-secondary-alt-text, #666);
}
.CB-glpSCo:hover,
.CB-glpSCo:focus {
  color: var(--cb-color-button-secondary-alt-text-hover, #333);
  background-color: var(--cb-color-button-secondary-alt-bg-hover, #f4f6f7);
  border-color: var(--cb-color-button-secondary-alt-border-hover, #e3e4e6);
}

.link-button {
  width: 110px;
  height: 34px;
  background: #fff8e6;
  border-radius: 17px;
  display: inline-block;
  color: #ff7e00;
  line-height: 34px;
}

.link-button:hover {
  color: #ffffff;
  background-color: #ff7e00;
  cursor: pointer;
}

.menu-logo {
  display: inline-block;
  line-height: 30px;
  text-align: center;
  margin: 0 39px 0 0;
  cursor: pointer;
}
.menu-logo {
  img {
    width: 40px;
    height: 40px;
  }
  :hover img {
    opacity: 0.8;
  }
}
</style>
