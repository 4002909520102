<template>
  <div class="rno-action-panel">
    <img style="width:100%;" :src="addBar" />
  </div>
</template>

<script>
import addBar from "@/assets/images/default2/ad-bar1.png"

export default {
  data() {
    return {
      addBar: addBar,
    }
  },
}
</script>

<style scoped>
.rno-action-panel {
  padding: 0 !important;
}
</style>
