<template>
  <div class="body">
    <TopNav @showNotice="handleShowNotice" :showDetail="!showDetail"></TopNav>
    <Content></Content>
    <Service></Service>
    <Footer></Footer>
    <Feedback></Feedback>
    <el-dialog
      :close-on-click-modal="false"
      title="平台定制公告"
      @close="noticeDialogVisible = false"
      :visible.sync="noticeDialogVisible"
      width="1000px"
    >
      <div style="height: 550px">
        <div
          style="
            height: 40px;
            line-height: 40px;
            color: #434649;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
          "
        >
          {{ noticeDialogData.noticeTitle }}
        </div>
        <div style="color: #7d8289; font-size: 13px; text-align: center">
          <span style="margin: 0 16px 0 0"
            >类型：{{
              dictMap["notice_type"][noticeDialogData.noticeType]
            }}</span
          ><span>发布时间：{{ noticeDialogData.createTime }}</span>
        </div>
        <div
          style="
            margin: 20px 10px;
            height: 400px;
            overflow: auto;
            line-height: 24px;
            font-size: 16px;
          "
        >
          <span v-html="noticeDialogData.content"></span>
        </div>
        <div style="margin: 0px 10px; line-height: 24px; font-size: 16px">
          <a
            v-if="noticeDialogData.attachUrl"
            target="_blank"
            :href="noticeDialogData.attachUrl"
            >附件下载</a
          >
        </div>
      </div>
      <div slot="footer">
        <div
          @click="handleClickUp"
          :class="{ gray: current == 0 }"
          class="link"
        >
          < 上一篇
        </div>
        <div
          @click="handleClickDown"
          :class="{ gray: current == page.total - 1 }"
          class="link"
        >
          下一篇 >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TopNav from "./TopNav"
import Detail from "./Detail"
import Carousel from "./Carousel"
import SectionHot from "./SectionHot"
import Product from "./Product"
import Free from "./Free"
import Process from "./Process"
import Content from "./Content"
import Set from "./Set"
import Service from "../default2/Service"
import Footer from "../default2/Footer"
import Feedback from "../default2/Feedback"
import { mapGetters } from "vuex"
import { listNotice } from "@/api/system/notice"
export default {
  components: {
    Detail,
    TopNav,
    Carousel,
    SectionHot,
    Product,
    Free,
    Process,
    Service,
    Content,
    Footer,
    Set,
    Feedback,
  },
  computed: {
    ...mapGetters(["dictData", "dictMap", "nginx", "user", "token", "name"]),
  },
  data() {
    return {
      showDetail: false,
      noticeDialogVisible: false,
      noticeDialogData: {
        title: "",
      },
      current: 0,
      timer: null,
      page: {
        status: "0",
        pageNum: 1,
        pageSize: 10,
        total: 0,
        length: 0,
      },
      tableData: [],
    }
  },
  methods: {
    loadNoticeData() {
      listNotice(this.page)
        .then((res) => {
          if (res.code == 200) {
            res.rows.map((row) => {
              row.title = row.noticeTitle
            })

            this.tableData = res.rows
            this.page.total = res.total
            if (this.page.total > 10) {
              this.tableData.length = 10
              this.page.total = 10
            }
            this.page.length = res.rows.length
          }
        })
        .catch()
    },
    handleShowNotice(current) {
      this.noticeDialogData = this.tableData[current--]
      this.noticeDialogVisible = true
    },
    handleClickUp() {
      if (this.current > 0) {
        this.current--
        this.noticeDialogData = this.tableData[this.current]
      }
    },
    handleClickDown() {
      if (this.current < this.page.total - 1) {
        this.current++
        this.noticeDialogData = this.tableData[this.current]
      }
    },
  },
  mounted() {
    let _self = this
    this.loadNoticeData()
  },
}
</script>

<style>
@import "../../assets/css/default/top-nav.css";
@import "../../assets/css/default/main.css";
</style>

<style scoped>
.link {
  display: inline-block;
  cursor: pointer;
  margin: 0 16px;
  color: #4934b2;
}
</style>
