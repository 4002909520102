<template>
  <div>
    <div class="vip-wrap">
      <div class="banner"></div>
      <div class="index_cont cont_2">
        <div class="cont">
          <div class="item mobile_hide ">
            <div class="item_title">平台使用</div>
            <div class="item_cont">
              <div class="cont_title mobile_hide">
                <!-- <div class="lt">帮助文档</div> -->
                <div class="lt">视频教程</div>
              </div>
              <div class="cont_link">
                <!-- <a
                  class="line_ellipsis_single"
                  href="https://ec-shopmall-cn.oss-cn-hangzhou.aliyuncs.com/downloads/template/1.1.docx"
                  target="_blank"
                >
                  新手指南
                </a> -->
                <a
                  href="javascript:void(0)"
                  @click="showDialog('0', 'Temu授权指导')"
                  class="line_ellipsis_single"
                >
                  Temu授权指导
                </a>
                <a
                  href="javascript:void(0)"
                  @click="showDialog('1', 'Temu人工下单，支持备货单、样品等')"
                  class="line_ellipsis_single"
                >
                  Temu人工下单，支持备货单、样品等
                </a>
                <a
                  href="javascript:void(0)"
                  @click="showDialog('2', 'Temu下单流程')"
                  class="line_ellipsis_single"
                >
                  Temu下单流程
                </a>
                <a
                  href="javascript:void(0)"
                  @click="showDialog('3', '设计器批量设计同品技巧！')"
                  class="line_ellipsis_single"
                >
                  设计器批量设计同品技巧！
                </a>
                <a
                  href="javascript:void(0)"
                  @click="showDialog('4', 'Temu批量修改库存功能')"
                  class="line_ellipsis_single"
                >
                  Temu批量修改库存功能
                </a>
                <a
                  href="javascript:void(0)"
                  @click="showDialog('5', 'Temu产品模版批量上架')"
                  class="line_ellipsis_single"
                >
                  Temu产品模版批量上架
                </a>
              </div>
            </div>
          </div>

          <div class="item mobile_hide mt_0">
            <div class="item_title">授权刊登</div>
            <div class="item_cont">
              <div class="cont_title mobile_hide">
                <div class="lt">视频教程</div>
              </div>
              <div class="cont_link">
                <a
                  href="javascript:void(0)"
                  @click="showDialog('0', 'Temu授权指导')"
                  class="line_ellipsis_single"
                >
                  Temu授权指导
                </a>
              </div>
            </div>
          </div>

          <div class="item mobile_hide ">
            <div class="item_title">订单处理</div>
            <div class="item_cont">
              <div class="cont_title mobile_hide">
                <div class="lt">视频教程</div>
              </div>
              <div class="cont_link">
                <a
                  href="javascript:void(0)"
                  @click="showDialog('1', 'Temu人工下单，支持备货单、样品等')"
                  class="line_ellipsis_single"
                >
                  Temu人工下单，支持备货单、样品等
                </a>
                <a
                  href="javascript:void(0)"
                  @click="showDialog('2', 'Temu下单流程')"
                  class="line_ellipsis_single"
                >
                  Temu下单流程
                </a>
              </div>
            </div>
          </div>

          <div class="item mobile_hide mt_0">
            <div class="item_title">数据管理</div>
            <div class="item_cont">
              <div class="cont_title mobile_hide">
                <div class="lt">视频教程</div>
              </div>
              <div class="cont_link">
                <a
                  href="javascript:void(0)"
                  @click="showDialog('3', '设计器批量设计同品技巧！')"
                  class="line_ellipsis_single"
                >
                  设计器批量设计同品技巧！
                </a>
                <a
                  href="javascript:void(0)"
                  @click="showDialog('4', 'Temu批量修改库存功能')"
                  class="line_ellipsis_single"
                >
                  Temu批量修改库存功能
                </a>
              </div>
            </div>
          </div>

          <div class="item mobile_hide ">
            <div class="item_title">订单流程</div>
            <div class="item_cont">
              <div class="cont_title mobile_hide">
                <div class="lt">视频教程</div>
              </div>
              <div class="cont_link">
                <a
                  href="javascript:void(0)"
                  @click="showDialog('1', 'Temu人工下单，支持备货单、样品等')"
                  class="line_ellipsis_single"
                >
                  Temu人工下单，支持备货单、样品等
                </a>
                <a
                  href="javascript:void(0)"
                  @click="showDialog('2', 'Temu下单流程')"
                  class="line_ellipsis_single"
                >
                  Temu下单流程
                </a>
              </div>
            </div>
          </div>

          <div class="item mobile_hide mt_0">
            <div class="item_title">上架流程</div>
            <div class="item_cont">
              <div class="cont_title mobile_hide">
                <div class="lt">视频教程</div>
              </div>
              <div class="cont_link">
                <a
                  href="javascript:void(0)"
                  @click="showDialog('5', 'Temu产品模版批量上架')"
                  class="line_ellipsis_single"
                >
                  Temu产品模版批量上架
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="960px"
      :before-close="handleClose"
    >
      <div style="text-align:center;margin: 20px 0 20px 0;">
        <video id="video"  autoplay="" controls="" width="800">
          <source :src="videoUrl" type="video/mp4" />
          您的浏览器不支持 video 标签。
        </video>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="handleClose"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getUserProfile } from "@/api/system/user"
import {
  getSeriveList,
  getAddServiceList,
  getVipServiceList,
  buyVipService,
  buyAddService,
} from "@/api/mine/service"
import { mapGetters } from "vuex"
import banner from "@/assets/images/vip/banner.png"

export default {
  components: {},
  computed: {
    ...mapGetters(["dictData", "dictMap", "token", "user"]),
  },
  data() {
    return {
      contents: [],
      banner: banner,
      userExt: {},
      serviceList: [],
      serviceListAdd: [],
      dialogVisible: false,
      videoUrl: "",
      dialogTitle: "",
      array: [
        "https://ec-shopmall-temudiy.oss-cn-hangzhou.aliyuncs.com/sample/temu_grant.mp4",// Temu授权指导
        "https://ec-shopmall-temudiy.oss-cn-hangzhou.aliyuncs.com/sample/temu-Manualorder.mp4", //Temu人工下单，支持备货单、样品等
        "https://ec-shopmall-temudiy.oss-cn-hangzhou.aliyuncs.com/sample/temu_order.mp4", //Temu下单流程
        "https://ec-shopmall-temudiy.oss-cn-hangzhou.aliyuncs.com/sample/temu_jiqiao.mp4", //设计器批量设计同品技巧！
        "https://ec-shopmall-temudiy.oss-cn-hangzhou.aliyuncs.com/sample/temu_stock.mp4", //Temu批量修改库存功能
        "https://ec-shopmall-temudiy.oss-cn-hangzhou.aliyuncs.com/sample/temu_publish.mp4", // Temu产品模版批量上架
      ],
    }
  },

  methods: {
    getUserProfile() {
      getUserProfile().then((res) => {
        this.userExt = res.user
      })
    },
    handleJump(path) {
      this.$router.push({ path: path })
    },
    showDialog(index, title) {
      this.dialogVisible = true
      this.dialogTitle = title
      this.videoUrl = this.array[Number(index)]
      var videoElement = document.querySelector("#video")
      videoElement.load()
    },
    handleClose() {
      var videoElement = document.querySelector("#video")
      videoElement.pause()
      videoElement.currentTime = 0
      // videoElement.removeAttribute("src") // empty source
      // videoElement.load()
      this.dialogVisible = false
    },
  },
  mounted() {
    if (this.token) {
      this.getUserProfile()
    }
  },
}
</script>

<style></style>

<style lang="scss" scoped>
.vip-wrap {
  .banner {
    width: 100%;
    height: 9.36vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: url("../../assets/images/vip/help.png") no-repeat;
    background-size: 100% 100%;

    .title {
      font-size: 48px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      line-height: 38px;
      margin-bottom: 20px;
    }

    .sub {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      line-height: 38px;
    }
  }
}

.list {
  width: 1200px;
  margin: -60px auto 20px;
  border: 1px solid #dddddd;
  border-radius: 6px;
  background: #ffffff;

  .top {
    height: 280px;
    display: flex;

    .one {
      width: 320px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      border-bottom: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
    }

    .item {
      width: 220px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-bottom: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
      position: relative;

      .special {
        position: absolute;
        top: 50px;
        right: 32px;
        line-height: 24px;
        padding: 0 15px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #fff;
        height: 24px;
        background: linear-gradient(270deg, #fad126, #ff544f);
        border-radius: 12px 12px 12px 0px;
      }

      &:last-child {
        border-right: 0;
      }

      .tip {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-style: italic;
        color: #333333;
        margin-bottom: 10px;
      }

      .name {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #181b1c;
        margin-bottom: 20px;

        span {
          color: rgb(73, 52, 178);
          font-size: 26px;
          font-weight: bold;
        }
      }

      .btn1 {
        color: #fff;
        width: 120px;
        height: 48px;
        background: linear-gradient(270deg, rgb(73, 52, 178), #4892ff);
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .btn2 {
        color: rgb(73, 52, 178);
        width: 120px;
        height: 48px;
        background: #f2f4ff;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          color: #fff;
          width: 120px;
          height: 48px;
          background: rgb(73, 52, 178);
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .content {
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #dddddd;
      height: 60px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;

      .no {
        &::before {
          content: "";
          width: 15px;
          height: 15px;
          background: url("../../assets/images/vip/no.png") no-repeat;
          background-size: contain;
        }
      }

      .yes {
        &::before {
          content: "";
          width: 20px;
          height: 15px;
          background: url("../../assets/images/vip/yes.png") no-repeat;
          background-size: contain;
        }
      }

      &:last-child {
        border-bottom: 0;
      }

      .one {
        width: 320px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        height: 100%;
      }

      .two,
      .three,
      .four,
      .five {
        height: 100%;
        width: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-bottom: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
      }

      .five {
        border-right: 0;
      }
    }
  }
}

.tip {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 18px;
  margin: 30px auto;
  width: 100%;
  text-align: center;
}

.info-btn {
  width: 300px;
  height: 64px;
  background: rgb(73, 52, 178);
  border-radius: 6px;
  margin: 50px auto 90px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
}

.vip-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  margin: 60px auto 30px;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 270px;
    height: 336px;
    background: #ffffff;
    border: 1px solid #e9e9e9;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    position: relative;

    .tip2 {
      position: absolute;
      bottom: 175px;
      right: 32px;
      line-height: 24px;
      padding: 0 15px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #fff;
      height: 24px;
      background: linear-gradient(270deg, #fad126, #ff544f);
      border-radius: 12px 12px 12px 0px;
    }

    .name {
      width: 75%;
      text-align: center;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #363839;
    }

    .desc {
      width: 75%;
      margin: 45px auto 20px;
      font-size: 16px;
      color: #181b1c;
      text-align: center;

      span {
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: rgb(73, 52, 178);
      }
    }

    .btn {
      width: 120px;
      height: 48px;
      background: #f2f4ff;
      border-radius: 6px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgb(73, 52, 178);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        background: rgb(73, 52, 178);
        color: #ffffff;
      }
    }
  }
}
.top-banner {
  width: 100%;
}
.index_cont {
  margin: 80px 0 80px 0;
}

/* 模块1 */
.index_cont.cont_1 > .cont > .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*box-shadow: 0 8px 20px 0 rgba(51, 51, 51, .25);*/
  box-shadow: 0 0 10px 0 rgba(54, 22, 158, 0.2);
  border-radius: 4px;
  width: 270px;
  max-width: 270px;
  height: 153px;
  color: #313033;
  background: #fff;
}
.index_cont.cont_1 > .cont > .item > .icon {
  margin-bottom: 5px;
  width: 64px;
  height: 64px;
}
.index_cont.cont_1 > .cont > .item > .icon[data-type="started"] {
  background-position: -32px -19px;
}
.index_cont.cont_1 > .cont > .item > .icon[data-type="video"] {
  background-position: -272px -19px;
}
.index_cont.cont_1 > .cont > .item > .icon[data-type="help"] {
  background-position: -153px -19px;
}
.index_cont.cont_1 > .cont > .item > .icon[data-type="blog"] {
  background-position: -392px -19px;
}
.index_cont.cont_1 > .cont > .item > .text {
  font-size: 18px;
  text-align: center;
}
/* 模块2 -- Help & FAQs */
.index_cont.cont_2 > .cont > .item {
  border-radius: 4px;
  box-shadow: 1px 1px 10px 0 rgba(31, 31, 31, 0.1);
  padding: 20px 10px;
  background: #fff;
  width: calc((100% - 40px) / 2);
}
.index_cont.cont_2 > .cont > .item:nth-child(n + 3) {
  margin-top: 40px;
}
.index_cont.cont_2 > .cont > .item > .item_title {
  height: 70px;
  line-height: 70px;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 1px;
}
.index_cont.cont_2 > .cont > .item > .item_cont {
  min-height: 240px;
}
.index_cont.cont_2 > .cont > .item > .item_cont a {
  display: block;
  border-bottom: 1px dashed #e6e6e6;
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  color: #313033;
}
.index_cont.cont_2 > .cont > .item > .item_cont a:hover {
  color: #4934b2;
}
.index_cont.cont_2 > .cont > .item > .item_footer > a.view_all {
  display: block;
  margin-top: 10px;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #f6f5fc;
}

.index_cont > .cont {
  width: 1240px;
  margin: 0 auto;
  height: 100%;
}
.index_cont.cont_1 > .cont,
.index_cont.cont_2 > .cont,
.index_cont.cont_3 > .cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.index_cont > .title {
  margin-bottom: 34px;
  font-size: 28px;
  font-weight: 400;
}
.index_cont.cont_1 {
  padding-top: 95px;
}

/* 模块1 */
.index_cont.cont_1 > .cont > .item {
  position: relative;
  box-shadow: 0 0 6px 0 rgba(36, 36, 36, 0.1);
  width: 220px;
  max-width: 220px;
  height: 100px;
}
.index_cont.cont_1 > .cont > .item:hover {
  box-shadow: 0 0 10px 0 rgba(36, 36, 36, 0.2);
}
.index_cont.cont_1 > .cont > .item > .icon {
  position: absolute;
  bottom: 53px;
  width: 100%;
  height: 105px;
}
.index_cont.cont_1 > .cont > .item > .text {
  margin-top: 30px;
  font-size: 16px;
}
.index_cont.cont_2 > .cont > .item {
  padding: 0 0 20px;
}
.index_cont.cont_2 > .cont > .item > .item_title {
  border-bottom: 1px solid #e8e8e8;
  padding: 0 20px;
  height: 58px;
  line-height: 58px;
  font-size: 20px;
  font-weight: 400;
}
.index_cont.cont_2 > .cont > .item > .item_cont {
  margin: 0 20px;
  min-height: initial;
}
.index_cont.cont_2 > .cont > .item > .item_cont.item_question {
  margin-top: 16px;
  border-top: 1px solid #e8e8e8;
}
.index_cont.cont_2 > .cont > .item > .item_cont > .cont_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
}
.item_cont > .cont_title > .lt {
  font-size: 16px;
  color: #999999;
}
.item_cont > .cont_title > .rt a {
  color: #4934b2 !important;
}
.index_cont.cont_2 > .cont > .item > .item_cont > .cont_link {
  min-height: 90px;
}
.index_cont.cont_2 > .cont > .item > .item_cont a {
  display: block;
  border-bottom: none;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #333333;
}
.index_cont.cont_2 > .cont > .item > .item_cont a:hover {
  color: #4934b2;
}
.index_cont.cont_3 > .cont > .item > .view_all {
  margin-left: 10px;
  font-size: 14px;
}
.index_cont.cont_4 .tabs_module .tab_pane > .item_title {
  font-size: 16px;
}
</style>
