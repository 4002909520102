<template>
  <div>
    <div class="rno-2-section-inner">
      <div class="rno-2-section-hd">
        <h3 class="rno-2-section-title">新品持续更新 紧跟潮流热卖</h3>
      </div>
      <div class="rno-2-section-bd">
        <!-- 选项卡 s -->
        <div class="rno-2-tabs">
          <div class="rno-2-tabs-bar">
            <ul class="rno-2-tabs-list">
              <li
                :class="[
                  'rno-2-tabs-item',
                  active === 'hot' ? 'rno-2-tabs-active' : '',
                  'J-homeProductTab',
                ]"
                @click="changeActive('hot')"
                data-target="hot"
              >
                <a href="javascript:;" class="rno-2-tabs-item-con">
                  近期热卖
                </a>
              </li>
              <li
                :class="[
                  'rno-2-tabs-item',
                  active === 'essential' ? 'rno-2-tabs-active' : '',
                  'J-homeProductTab',
                ]"
                @click="changeActive('essential')"
                data-target="essential"
              >
                <a href="javascript:;" class="rno-2-tabs-item-con">
                  新品速递
                </a>
              </li>
            </ul>
          </div>
          <div class="rno-2-tabs-content">
            <div class="flex-row flex-wrap index-goods-list flex-x-sbetween">
              <div
                @mouseout="handleMouseOut(record)"
                @mouseover="handleMouseOver(record)"
                v-for="(record, index) in productList"
                :key="index"
                class="good-item"
              >
                <a
                  href="/frontend/create/index?blankProId=Y9I9U8"
                  style="display: none"
                ></a>

                <div class="index-goods-vip"></div>

                <a @click="goProduct(record)">
                  <img
                    class="index-goods-img"
                    :src="record.picUrl"
                    style="transform: scale(1)"
                  />
                </a>
                <div style="height: 101px">
                  <div class="index-goods-detail pt13">
                    <div class="index-goods-tit shop_tit_style">
                      {{ record.proName }}
                    </div>
                    <div class="index-goods-price">
                      <span class="zc">￥{{ record.price }}</span> 起
                    </div>
                    <div class="mt5 shop_newTime_style">
                      上新时间：{{ record.createTime }}
                    </div>
                  </div>
                  <div class="index-goods-order">
                    <div class="index-goods-active">
                      <div :title="record.pdMaterial" class="text-ellipsis">
                        材质：{{ record.pdMaterial }}
                      </div>
                      <div>重量：{{ record.weight }}g</div>
                      <div
                        class="shop_factory_style"
                        title=""
                        style="height: 2px"
                      ></div>
                    </div>
                    <div @click="goProduct(record)" class="mt12">
                      <a class="shop_start_style">开始定制</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listNew, listSale } from "@/api/customProduct/product"
import { mapGetters } from "vuex"

export default {
  data() {
    return {
      active: "hot",
      productList: [],
      dataRecent: [],
      dataHSales: [],
    }
  },
  computed: {
    ...mapGetters(["user", "token"]),
  },
  methods: {
    changeActive(type) {
      this.productList = []
      this.active = type
      if (type == "hot") {
        this.productList = this.dataHSales
      } else {
        this.productList = this.dataRecent
      }
    },

    handleMouseOver(record) {
      if (record.picUrlHover) {
        record.picUrl = record.picUrlHover
      }
    },

    handleMouseOut(record) {
      record.picUrl = record.picUrlBak
    },
    async getListNew() {
      let result = await listNew({ pageNum: 1, pageSize: 15 })
      let dataRecent = []
      for (let record of result.rows) {
        for (let image of record.mainList) {
          if (image.isMain == "2") {
            record.picUrlHover = image.picUrl
          }

          if (image.isMain == "1" && !record.picUrlBak) {
            record.picUrl = image.picUrl
            record.picUrlBak = image.picUrl
          }
        }
        dataRecent.push({
          picUrl: `${record.picUrl}?x-oss-process=image/resize,m_fill,h_500,w_500/quality,q_100`,
          picUrlBak: `${record.picUrlBak}?x-oss-process=image/resize,m_fill,h_500,w_500/quality,q_100`,
          picUrlHover: `${record.picUrlHover}?x-oss-process=image/resize,m_fill,h_500,w_500/quality,q_100`,
          displayUrl: `${record.skuList[0].mainPic.picUrl}?x-oss-process=image/resize,m_fill,h_500,w_500/quality,q_100`,
          originUrl: `${record.skuList[0].mainPic.picUrl}?x-oss-process=image/resize,m_fill,h_500,w_500/quality,q_100`,
          imageUrl: `${record.skuList[0].mainPic.picUrl}?x-oss-process=image/resize,m_fill,h_500,w_500/quality,q_100`,
          isHover: false,
          price: record.skuList[0].lowPrice,
          proName: record.proName,
          createTime: record.createTime
            ? record.createTime.substring(0, 10)
            : "",
          pdMaterial: record.pdMaterial,
          weight: record.skuList[0].weight,
          pId: record.pId,
          manufacturer: record.manufacturer,
        })
      }
      this.dataRecent = dataRecent
    },
    async getListSale() {
      let result = await listSale({ pageNum: 1, pageSize: 15 })
      let dataHSales = []
      for (let record of result.rows) {
        for (let image of record.mainList) {
          if (image.isMain == "2") {
            record.picUrlHover = image.picUrl
          }

          if (image.isMain == "1" && !record.picUrlBak) {
            record.picUrl = image.picUrl
            record.picUrlBak = image.picUrl
          }
        }
        dataHSales.push({
          picUrl: `${record.picUrl}?x-oss-process=image/resize,m_fill,h_500,w_500/quality,q_100`,
          picUrlBak: `${record.picUrlBak}?x-oss-process=image/resize,m_fill,h_500,w_500/quality,q_100`,
          picUrlHover: `${record.picUrlHover}?x-oss-process=image/resize,m_fill,h_500,w_500/quality,q_100`,
          displayUrl: `${record.skuList[0].mainPic.picUrl}?x-oss-process=image/resize,m_fill,h_500,w_500/quality,q_100`,
          originUrl: `${record.skuList[0].mainPic.picUrl}?x-oss-process=image/resize,m_fill,h_500,w_500/quality,q_100`,
          imageUrl: `${record.skuList[0].mainPic.picUrl}?x-oss-process=image/resize,m_fill,h_500,w_500/quality,q_100`,
          isHover: false,
          price: record.skuList[0].lowPrice,
          proName: record.proName,
          createTime: record.createTime
            ? record.createTime.substring(0, 10)
            : "",
          pdMaterial: record.pdMaterial,
          weight: record.skuList[0].weight,
          pId: record.pId,
          manufacturer: record.manufacturer,
        })
      }
      this.dataHSales = dataHSales
    },
    goProduct(row) {
      if (this.token) {
        let routeUrl = this.$router.resolve({
          path: "/select/custom/product",
          query: { pId: row.pId },
        })
        window.open(routeUrl.href, "_blank")
      } else {
        let routeUrl = this.$router.resolve({
          path: "/product",
          query: { pId: row.pId },
        })
        window.open(routeUrl.href, "_blank")
      }
    },
  },
  async mounted() {
    await this.getListNew()
    await this.getListSale()
    this.changeActive("hot")
  },
}
</script>

<style lang="scss" scoped>
.flex-x-sbetween {
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.flex-row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.index-goods-list {
  font-size: 14px;
}
.index-goods-order {
  display: none;
}
.index-goods-list .good-item {
  position: relative;
  display: block;
  text-align: center;
  background: #fff;
  border: 1px solid #e9e9e9;
  width: 220px;
  margin-bottom: 30px;
}
.index-goods-list .index-goods-img {
  display: inline-block;
  width: 200px;
  height: 200px;
  margin: 10px;
  background: center no-repeat;
  background-size: 100%;
}
.shop_tit_style {
  font-size: 14px;
  font-weight: bold;
  color: rgba(51, 51, 51, 1);
}
.index-goods-tit {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #181b1c;
}
.index-goods-price {
  margin: 5px 0;
}
.shop_newTime_style {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.shop_factory_style {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: bottom;
}
.index-goods-list a {
  cursor: pointer;
  text-decoration: none;
  color: rgb(89, 90, 90);
}
.zc {
  color: #4934b2;
  border-color: #4934b2;
  fill: #4934b2;
}
.index-goods-price > span {
  font-size: 16px;
  font-weight: bold;
}
.index-goods-list .good-item:hover {
  cursor: auto;
  color: #000 !important;
  box-shadow: 0 0px 13px 0 rgb(64 147 243 / 35%);
  transition: all 0.35s;

  .index-goods-detail {
    display: none;
  }

  .index-goods-order {
    display: block;
  }
}
.shop_start_style {
  display: inline-block;
  height: 30px;
  line-height: 28px;
  font-size: 12px;
  width: 100%;
  background: rgba(64, 147, 243, 1);
  color: #ffffff !important;
}
.index-goods-active {
  /* display: none; */
  padding: 10px 15px;
  line-height: 25px;
  color: #333;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}
</style>
