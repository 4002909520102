<template>
  <div class="feedback-wrap" style="margin-bottom: 0px; opacity: 1;">
    <ul class="flex-col">
      <li class="slider_top weixin">
        <a class="menu " href="javascript:void(0)"
          ><img class="icon" :src="weixin" alt=""
        /></a>
        <img class="qr-code weixin-logo" :src="weixin_code" />
      </li>
      <li class="slider_top phone">
        <a class="menu">
          <img class="icon" :src="help_center" alt="" />
        </a>
        <div class="qr-code phone-logo" :src="phone" >{{ phoneNumber }}</div>
      </li>
      <li class="slider_top help">
        <a class="menu " href="javascript:void(0)"
          ><img class="icon" :src="add_order" alt=""
        /></a>
        <img class="qr-code help-logo" :src="help_image" />
        <div class="help-form">
          <div>您的称呼</div>
          <div>
            <el-input
              v-model="form.name"
              style="width: 200px;"
              size="small"
            ></el-input>
          </div>
          <div>联系方式</div>
          <div>
            <el-input
              v-model="form.phone"
              style="width: 200px;"
              size="small"
            ></el-input>
          </div>
          <div>您的需求或者问题描述</div>
          <div>
            <el-input
              v-model="form.description"
              style="width: 260px;"
              type="textarea"
              size="small"
            ></el-input>
            <button @click="sure" class="submit-button">发 送</button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import douyin from "@/assets/images/index/douyin.png"
import weixin from "@/assets/images/default2/weixin.png"
import help_center from "@/assets/images/default2/help_center.png"
import add_order from "@/assets/images/default2/add_order.png"
import douyin_code from "@/assets/images/default2/wechat_company.png"
import weixin_code from "@/assets/images/default2/wechat_company.png"
import phone from "@/assets/images/default2/phone.png"
import help_image from "@/assets/images/index/help_image.png"
import submitButton from "@/assets/images/index/submit_button.png"
import { mapGetters } from "vuex"
import { addQuestion } from "@/api/sysSupport/question"
export default {
  components: {},
  data() {
    return {
      douyin: douyin,
      weixin: weixin,
      help_center: help_center,
      add_order: add_order,
      douyin_code: douyin_code,
      helpVisible: false,
      help_image: help_image,
      submitButton: submitButton,
      form: {
        name: "",
        phone: "",
        description: "",
      },
      phone: phone,
    }
  },
  computed: {
    ...mapGetters(["user", "token"]),
    weixin_code() {
      return this.$store.state.setting.siteData.enterpriseQrUrl
    },
    phoneNumber() {
      return this.$store.state.setting.siteData.phoneNumber
    },
  },
  methods: {
    handleJump(path) {
      this.$router.push({
        path: path,
      })
    },
    goProduct() {
      if (this.token) {
        let routeUrl = this.$router.resolve({
          path: "/select/selection/list",
        })
        window.open(routeUrl.href, "_blank")
      } else {
        let routeUrl = this.$router.resolve({
          path: "/product_type",
        })
        window.open(routeUrl.href, "_blank")
      }
    },
    handleClose() {
      this.helpVisible = false
    },
    handleOpenHelp() {
      this.helpVisible = true
    },
    sure() {
      if (this.form.name == "") {
        this.msgError("请输入称呼")
        return
      }
      if (this.form.phone == "") {
        this.msgError("请输入联系方式")
        return
      }
      if (this.form.description == "") {
        this.msgError("请输入问题描述")
        return
      }
      addQuestion({
        questionTitle: this.form.name + "，" + this.form.phone,
        questionContent: this.form.description,
      }).then((response) => {
        if (response.code == "200") {
          ;(this.form = {
            name: "",
            phone: "",
            description: "",
          }),
            this.$message.success("提交成功！")
        } else {
          this.$message.error("提交失败")
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.feedback-wrap {
  position: fixed;
display:none;
  bottom: 8%;
  right: -8px;
  z-index: 999;
  text-align: center;
  color: #fff;
  transition: 0.3s;
  background-size: contain;
  padding: 24px 0 0 11px;
  height: 300px;
}
.feedback-wrap > ul {
  position: relative;
  width: 100px;
  height: auto;
  border-radius: 5px;
  padding-top: 6px;
  /* overflow: hidden; */
}
.feedback-wrap > ul.second-last {
  padding-bottom: 0.1rem;
}
.menu {
  width: 70px;
  display: block;
  img.icon {
    width: 70px;
  }
}
.slider_top {
  margin: 8px 0 0 8px;
  position: relative;
}
.flex-col {
  padding: 8px 0 0 0;
}
.qr-code {
  width: 169px;
}

.weixin-logo {
  position: absolute;
  left: -178px;
  top: -76px;
  display: none;
}
.weixin:hover {
  .weixin-logo {
    display: block;
  }
}

.phone:hover {
  .phone-logo {
    display: block;
    color: rgb(73, 52, 178);
    font-weight: bold;
  }
}

.phone-logo {
  position: absolute;
  left: -160px;
  top: 19px;
  display: none;
}

.douyin-logo {
  position: absolute;
  left: -287px;
  top: -76px;
  display: none;
}
.douyin:hover {
  .douyin-logo {
    display: block;
  }
}

.help-logo {
  position: absolute;
  left: -491px;
  top: -207px;
  display: none;
  width: 500px;
}
.help-form {
  position: absolute;
  left: -455px;
  top: -179px;
  display: none;
  width: 450px;
  text-align: left;
  line-height: 40px;
}
.help:hover {
  .help-logo {
    display: block;
  }
  .help-form {
    display: block;
  }
}
.help-form {
  color: #000000;
}
.submit-button {
  width: 100px;
  height: 28px;
  background: #4934b2;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
  font-family: Microsoft YaHei;
  border: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 90px;
  margin: 0 0 0 19px;
  position: absolute;
  bottom: 0;
  right: 70px;
}
</style>
