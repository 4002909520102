<template>
  <div class="main">
    <section class="article">
      <div class="cont">
        <div class="title t_c">
          <h2>Privacy Policy</h2>
          <i>Last Edited on 01 Aug, 2019</i>
        </div>
        <p>
          Your privacy is important to us. This privacy policy has been compiled
          to better serve those who are concerned with how their 'Personally
          Identifiable Information' (PII) is being used online. PII, is
          information that can be used on its own or with other information to
          identify, contact, or locate a single person, or to identify an
          individual in context. Please read our privacy policy carefully to get
          a clear understanding of how we collect, use, protect or otherwise
          handle your Personally Identifiable Information in accordance with our
          website.
        </p>
        <h4>Information Collected</h4>
        <p>
          When ordering or registering on our site, as appropriate, you may be
          asked to enter your name, email address, mailing address, phone
          number, credit card information, customer product catalog information,
          Customer information relating to invoices, payments, packages and
          shipments or other details to help you with your experience.<br />We
          collect information from you when you register on our site, place an
          order, subscribe to a newsletter, open a Support Ticket or enter
          information on our site. Provide us with feedback on our products or
          services.
        </p>
        <h4>Use of Personal Information</h4>
        <p class="mb_0">
          We may use the information we collect from you when you register, make
          a purchase, sign up for our newsletter, respond to a survey or
          marketing communication, surf the website, or use certain other site
          features in the following ways:
        </p>
        <ul>
          <li>
            To personalize your experience and to allow us to deliver the type
            of content and product offerings in which you are most interested.
          </li>
          <li>
            To allow us to better service you in responding to your customer
            service requests.
          </li>
          <li>To quickly process your transactions.</li>
          <li>
            To follow up with them after correspondence (live chat, email or
            phone inquiries)
          </li>
        </ul>
        <h4>Network and Information Security</h4>
        <p>
          Our website is scanned on a regular basis for security holes and known
          vulnerabilities in order to make your visit to our site as safe as
          possible.<br />Your personal information is contained behind secured
          networks and is only accessible by a limited number of persons who
          have special access rights to such systems, and are required to keep
          the information confidential. In addition, all sensitive/credit
          information you supply is encrypted via Secure Socket Layer (SSL)
          technology.<br />We implement a variety of security measures when a
          user places an order enters, submits, or accesses their information to
          maintain the safety of your personal information.
        </p>
        <h4>Cookies</h4>
        <p class="mb_20">
          Cookies are small files that a site or its service provider transfers
          to your computer's hard drive through your Web browser (if you allow)
          that enables the site's or service provider's systems to recognize
          your browser and capture and remember certain information. For
          instance, we use cookies to help us remember and process the items in
          your shopping cart. They are also used to help us understand your
          preferences based on previous or current site activity, which enables
          us to provide you with improved services. We also use cookies to help
          us compile aggregate data about site traffic and site interaction so
          that we can offer better site experiences and tools in the future.
        </p>
        <p class="mb_0">We use cookies to:</p>
        <ul class="mb_20">
          <li>Understand and save user's preferences for future visits.</li>
          <li>
            Compile aggregate data about site traffic and site interactions in
            order to offer better site experiences and tools in the future. We
            may also use trusted third-party services that track this
            information on our behalf.
          </li>
        </ul>
        <p class="mb_20">
          You can choose to have your computer warn you each time a cookie is
          being sent, or you can choose to turn off all cookies. You do this
          through your browser settings. Since browser is a little different,
          look at your browser's Help Menu to learn the correct way to modify
          your cookies.
        </p>
        <p>
          If users disable cookies in their browser:<br />If you turn cookies
          off, some features will be disabled. Some of the features that make
          your site experience more efficient and may not function properly.
          However, you will still be able to place orders, but may not be able
          to sign in and access certain Web page features or services.
        </p>
        <h4>Third-Party Disclosure</h4>
        <p>
          We do not sell, trade, or otherwise transfer to outside parties your
          Personally Identifiable Information unless we provide users with
          advance notice. This does not include website hosting partners and
          other parties who assist us in operating our website, conducting our
          business, or serving our users, so long as those parties agree to keep
          this information confidential. We may also release information when
          it's release is appropriate to comply with the law, enforce our site
          policies, or protect ours or others' rights, property or safety.<br />However,
          non-personally identifiable visitor information may be provided to
          other parties for marketing, advertising, or other uses.<br />Occasionally,
          at our discretion, we may include or offer third-party products or
          services on our website. These third-party sites have separate and
          independent privacy policies. We therefore have no responsibility or
          liability for the content and activities of these linked sites.
          Nonetheless, we seek to protect the integrity of our site and welcome
          any feedback about these sites.<br />We use Google Analytics on our
          website. Google, as a third-party vendor, uses cookies to serve ads on
          our site. Google's use of the DART cookie enables it to serve ads to
          our users based on previous visits to our site and other sites on the
          Internet. Users may opt-out of the use of the DART cookie by visiting
          the Google Ad and Content Network privacy policy.<br />We, along with
          third-party vendors such as Google use first-party cookies (such as
          the Google Analytics cookies) and third-party cookies (such as the
          DoubleClick cookie) or other third-party identifiers together to
          compile data regarding user interactions with ad impressions and other
          ad service functions as they relate to our website.
        </p>
        <h4>Accessing or Correcting Personal Information</h4>
        <p>
          You can access all your private information that we collect online via
          the tool, admin and account pages of your account. You can correct
          factual errors in your private information by sending us a request
          that credibly shows error. To protect your privacy and security, we
          will also take reasonable steps to verify your identity before
          granting access or making corrections.
        </p>
        <h4>Contacting Us</h4>
        <p>
          If there are any questions regarding this privacy policy, you may
          contact us via email to:<a :href="`mailto:${mail }`"
            >{{ mail }}</a
          >
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import { getUserProfile } from "@/api/system/user"
import {
  getSeriveList,
  getAddServiceList,
  getVipServiceList,
  buyVipService,
  buyAddService,
} from "@/api/mine/service"
import { mapGetters } from "vuex"
import banner from "@/assets/images/vip/banner.png"

export default {
  components: {},
  computed: {
    ...mapGetters(["dictData", "dictMap", "token", "user"]),
    mail() {
      return this.$store.state.setting.siteData.email
    },
  },
  data() {
    return {
      contents: [],
      banner: banner,
      userExt: {},
      serviceList: [],
      serviceListAdd: [],
    }
  },

  methods: {
    getUserProfile() {
      getUserProfile().then((res) => {
        this.userExt = res.user
      })
    },
    handleJump(path) {
      this.$router.push({ path: path })
    },
  },
  mounted() {
    if (this.token) {
      this.getUserProfile()
    }
  },
}
</script>

<style></style>

<style lang="scss" scoped>
.vip-wrap {
  .banner {
    width: 100%;
    height: 9.36vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: url("../../assets/images/vip/help.png") no-repeat;
    background-size: 100% 100%;

    .title {
      font-size: 48px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      line-height: 38px;
      margin-bottom: 20px;
    }

    .sub {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      line-height: 38px;
    }
  }
}

.list {
  width: 1200px;
  margin: -60px auto 20px;
  border: 1px solid #dddddd;
  border-radius: 6px;
  background: #ffffff;

  .top {
    height: 280px;
    display: flex;

    .one {
      width: 320px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      border-bottom: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
    }

    .item {
      width: 220px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-bottom: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
      position: relative;

      .special {
        position: absolute;
        top: 50px;
        right: 32px;
        line-height: 24px;
        padding: 0 15px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #fff;
        height: 24px;
        background: linear-gradient(270deg, #fad126, #ff544f);
        border-radius: 12px 12px 12px 0px;
      }

      &:last-child {
        border-right: 0;
      }

      .tip {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-style: italic;
        color: #333333;
        margin-bottom: 10px;
      }

      .name {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #181b1c;
        margin-bottom: 20px;

        span {
          color: rgb(73, 52, 178);
          font-size: 26px;
          font-weight: bold;
        }
      }

      .btn1 {
        color: #fff;
        width: 120px;
        height: 48px;
        background: linear-gradient(270deg, rgb(73, 52, 178), #4892ff);
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .btn2 {
        color: rgb(73, 52, 178);
        width: 120px;
        height: 48px;
        background: #f2f4ff;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          color: #fff;
          width: 120px;
          height: 48px;
          background: rgb(73, 52, 178);
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .content {
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #dddddd;
      height: 60px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;

      .no {
        &::before {
          content: "";
          width: 15px;
          height: 15px;
          background: url("../../assets/images/vip/no.png") no-repeat;
          background-size: contain;
        }
      }

      .yes {
        &::before {
          content: "";
          width: 20px;
          height: 15px;
          background: url("../../assets/images/vip/yes.png") no-repeat;
          background-size: contain;
        }
      }

      &:last-child {
        border-bottom: 0;
      }

      .one {
        width: 320px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        height: 100%;
      }

      .two,
      .three,
      .four,
      .five {
        height: 100%;
        width: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-bottom: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
      }

      .five {
        border-right: 0;
      }
    }
  }
}

section.article {
  margin-top: 2px;
  border: 1px solid transparent;
  background: #fff;
  padding-bottom: 40px;
}
section.article > .cont {
  width: 1240px;
}
section.article h2 {
  margin-top: 40px;
  font-size: 28px;
  font-weight: 600;
}
section.article h2 + i {
  color: #919099;
}
section.article h4 {
  margin: 40px 0 20px;
  font-size: 16px;
  font-weight: 600;
}
section.article p {
  line-height: 28px;
  margin-bottom: 40px;
}
section.article ul {
  list-style: initial;
  margin: 0 40px;
  line-height: 28px;
  margin-bottom: 40px;
}
section.article .title + p {
  margin-top: 60px;
}

.main .cont {
  position: relative;
  width: 1240px;
  margin: 0 auto;
  height: 100%;
  background: transparent;
  font-size: 14px;
}
.t_c {
  text-align: center !important;
}

section.article h2 + i {
  color: #919099;
}
body:not([data-lang="zh"]) * {
  word-break: keep-all;
}
i {
  font-style: italic;
}
</style>
