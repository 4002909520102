<template>
  <div class="body">
    <Main></Main>
  </div>
</template>

<script>
import Main from "./Main"

export default {
  components: {
    Main,
  },
}
</script>

<style>
body {
  min-width: initial !important;
  width: 100%;
}
</style>
