<template>
  <div class="main">
    <section class="article">
      <div class="cont">
        <div class="title t_c">
          <h2>Terms Of Use</h2>
          <i>Last Edited on 01 Aug, 2019</i>
        </div>
        <h4>Overview</h4>
        <p class="mb_20">
          Welcome to {{ siteDomain }}. Throughout the site, the terms “we”, “us”
          and “our” refer to {{ siteName }}. {{ siteName }} offers this website, including
          all information, tools and services available from this site to you,
          the user, conditioned upon your acceptance of all terms, conditions,
          policies and notices stated here.
        </p>
        <p class="mb_20">
          By visiting our site and / or purchasing something from us, you engage
          in our “Service” and agree to be bound by the following terms and
          conditions (“Terms of Use”, “Terms”), including those additional terms
          and conditions and policies referenced herein and/or available by
          hyperlink. These Terms of Service apply to all users of the site,
          including without limitation users who are browsers, vendors,
          customers, merchants, and/ or contributors of content.
        </p>
        <p class="mb_20">
          Please read these Terms of Use carefully before accessing or using our
          website. By accessing or using any part of the site, you agree to be
          bound by these Terms of Use. If you do not agree to all the terms and
          conditions of this agreement, then you may not access the website or
          use any services. If these Terms of Use are considered an offer,
          acceptance is expressly limited to these Terms of Use.
        </p>
        <p>
          Any new features or tools which are added to the current site shall
          also be subject to the Terms of Use. You can review the most current
          version of the Terms of Use at any time on this page. We reserve the
          right to update, change or replace any part of these Terms of Use by
          posting updates and/or changes to our website. It is your
          responsibility to check this page periodically for changes. Your
          continued use of or access to the website following the posting of any
          changes constitutes acceptance of those changes.
        </p>

        <h4>Section 1 - Online Stores Terms</h4>
        <p class="mb_20">
          By agreeing to these Terms of Use, you represent that you are at least
          the age of majority in your state or province of residence, or that
          you are the age of majority in your state or province of residence and
          you have given us your consent to allow any of your minor dependents
          to use this site.
        </p>
        <p class="mb_20">
          You may not use our service for any illegal or unauthorized purpose
          nor may you, in the use of the Service, violate any laws in your
          jurisdiction (including but not limited to copyright laws).<br />You
          must not transmit any worms or viruses or any code of a destructive
          nature.
        </p>
        <p>
          A breach or violation of any of the Terms will result in an immediate
          termination of your Services.
        </p>

        <h4>Section 2 - General Condition</h4>
        <p class="mb_20">
          We reserve the right to refuse service to anyone for any reason at any
          time.
        </p>
        <p class="mb_20">
          You understand that your content (not including credit card
          information), may be transferred unencrypted and involve (a)
          transmissions over various networks; and (b) changes to conform and
          adapt to technical requirements of connecting networks or devices.
          Credit card information is always encrypted during transfer over
          networks.
        </p>
        <p class="mb_20">
          You agree not to reproduce, duplicate, copy, sell, resell or exploit
          any portion of the Service, use of the Service, or access to the
          Service or any contact on the website through which the service is
          provided, without express written permission by us.
        </p>
        <p>
          The headings used in this agreement are included for convenience only
          and will not limit or otherwise affect these Terms.
        </p>

        <h4>Section 3- Accuracy, Completeness And Timeliness Of Information</h4>
        <p>
          We are not responsible if information made available on this site is
          not accurate, complete or current. The material on this site is
          provided for general information only and should not be relied upon or
          used as the sole basis for making decisions without consulting
          primary, more accurate, more complete or more timely sources of
          information. Any reliance on the material on this site is at your own
          risk.<br />This site may contain certain historical information.
          Historical information, necessarily, is not current and is provided
          for your reference only. We reserve the right to modify the contents
          of this site at any time, but we have no obligation to update any
          information on our site. You agree that it is your responsibility to
          monitor changes to our site.
        </p>

        <h4>Section 4 - Modifications To The Service And Prices</h4>
        <p>
          Prices for our service are subject to change without notice. We
          reserve the right at any time to modify or discontinue the Service (or
          any part or content thereof) without notice at any time.We shall not
          be liable to you or to any third-party for any modification, price
          change, suspension or discontinuance of the Service.
        </p>

        <h4>Section 5 - Services</h4>
        <p class="mb_20">
          We reserve the right, but are not obligated, to limit the sales of our
          Services to any person, geographic region or jurisdiction. We may
          exercise this right on a case-by-case basis. We reserve the right to
          limit the quantities of any services that we offer. All descriptions
          of service or service pricing are subject to change at anytime without
          notice, at the sole discretion of us. We reserve the right to
          discontinue any service at any time. Any offer for any service made on
          this site is void where prohibited.
        </p>
        <p>
          We do not warrant that the quality of any services, information, or
          other material purchased or obtained by you will meet your
          expectations, or that any errors in the Service will be corrected.
        </p>

        <h4>Section 6 - Accuracy Of Billing And Account Information</h4>
        <p class="mb_20">
          We reserve the right to refuse any order you place with us. We may, in
          our sole discretion, limit or cancel quantities purchased per person,
          per household or per order. These restrictions may include orders
          placed by or under the same customer account, the same credit card,
          and/or orders that use the same billing and/or shipping address. In
          the event that we make a change to or cancel an order, we may attempt
          to notify you by contacting the e-mail and/or billing address/phone
          number provided at the time the order was made. We reserve the right
          to limit or prohibit orders that, in our sole judgment, appear to be
          placed by dealers, resellers or distributors.
        </p>
        <p>
          You agree to provide current, complete and accurate purchase and
          account information for all purchases made at our store. You agree to
          promptly update your account and other information, including your
          email address and credit card numbers and expiration dates, so that we
          can complete your transactions and contact you as needed.
        </p>

        <h4>Section 7 - Payment Of Fees, Automatic Renewal</h4>
        <p class="mb_20">
          The fees applicable for {{ siteName }} services are available at
          {{ siteDomain }} and as published within the Services. {{ siteName }}
          reserves the right to change the Fees or applicable charges and to
          institute new charges at any time, upon thirty (30) days prior notice
          to you (which may be sent by email to the address you have most
          recently provided us).
        </p>
        <p class="mb_20">
          Unless specifically agreed upon in writing between User and {{ siteName }}
          at the commencement of a subscription term, at the conclusion of any
          service term, whether monthly or otherwise, {{ siteName }} will
          automatically renew the service for the same term and will charge the
          user's credit card or PayPal account on the first day of the renewal
          term.
        </p>
        <p class="mb_20">
          Payments for all single-seat accounts are due within 30 days of
          billing date.
        </p>
        <p>
          If payment is not received by the end of the given 30 day time period,
          user's account will be frozen and inaccessible until all outstanding
          payments have been processed by {{ siteName }}. Users retain the
          responsibility for settling all outstanding balances in a timely
          manner and maintaining updated billing information. If not complied
          with, at the end of 180 days, user's account will be deactivated and
          all data will no longer be retrievable.
        </p>

        <h4>Section 8 - Refund Of Charges</h4>
        <p class="mb_20">
          Except as specifically set forth in this section, all {{ siteName }}
          services are prepaid for the period selected (monthly or otherwise)
          and are non-refundable. This includes accounts that are renewed in
          accordance with the previous section.
        </p>
        <p class="mb_20">
          When paying users cancel their account, they will not be issued
          refunds for their most recent (or any previous) billing. Other
          requests for refunds may be processed at the sole discretion of a
          {{ siteName }} staff member.
        </p>
        <p class="mb_20">
          To cancel or downgrade, please click the link or button that says
          “Cancel” or Change" and follow the instructions."
        </p>
        <p>
          If you have a question while canceling your account, you must give our
          email staff adequate time to respond to your message. Cancellation
          requests sent via email to {{ mail }} will not be processed and will
          be replied to with an automatic response. You must use our form to
          process your cancellation.
        </p>

        <h4>Section 9 - Optional Tools</h4>
        <p class="mb_20">
          We may provide you with access to third-party tools over which we
          neither monitor nor have any control nor input.
        </p>
        <p class="mb_20">
          You acknowledge and agree that we provide access to such tools “as is”
          and “as available” without any warranties, representations or
          conditions of any kind and without any endorsement. We shall have no
          liability whatsoever arising from or relating to your use of optional
          third-party tools.
        </p>
        <p class="mb_20">
          Any use by you of optional tools offered through the site is entirely
          at your own risk and discretion and you should ensure that you are
          familiar with and approve of the terms on which tools are provided by
          the relevant third-party provider(s).
        </p>
        <p>
          We may also, in the future, offer new services and/or features through
          the website (including, the release of new tools and resources). Such
          new features and/or services shall also be subject to these Terms of
          Use.
        </p>

        <h4>Section 10 - Third-Party Links</h4>
        <p class="mb_20">
          Certain content, products and services available via our Service may
          include materials from third-parties.
        </p>
        <p class="mb_20">
          Third-party links on this site may direct you to third-party websites
          that are not affiliated with us. We are not responsible for examining
          or evaluating the content or accuracy and we do not warrant and will
          not have any liability or responsibility for any third-party materials
          or websites, or for any other materials, products, or services of
          third-parties.
        </p>
        <p class="mb_20">
          We are not liable for any harm or damages related to the purchase or
          use of goods, services, resources, content, or any other transactions
          made in connection with any third-party websites. Please review
          carefully the third-party's policies and practices and make sure you
          understand them before you engage in any transaction. Complaints,
          claims, concerns, or questions regarding third-party products should
          be directed to the third-party.
        </p>
        <p>
          Amazon Afiiliate Disclaimer We are a participant in the Amazon
          Services LLC Associates Program, an affiliate advertising program
          designed to provide a means for us to earn fees by linking to
          Amazon.com and affiliated sites.
        </p>

        <h4>Section 11 - User Comments, Feedback And Other Submissions</h4>
        <p class="mb_20">
          If, at our request, you send certain specific submissions (for example
          contest entries) or without a request from us you send creative ideas,
          suggestions, proposals, plans, or other materials, whether online, by
          email, by postal mail, or otherwise (collectively, 'comments'), you
          agree that we may, at any time, without restriction, edit, copy,
          publish, distribute, translate and otherwise use in any medium any
          comments that you forward to us. We are and shall be under no
          obligation (1) to maintain any comments in confidence; (2) to pay
          compensation for any comments; or (3) to respond to any comments.We
          may, but have no obligation to, monitor, edit or remove content that
          we determine in our sole discretion are unlawful, offensive,
          threatening, libelous, defamatory, pornographic, obscene or otherwise
          objectionable or violates any party's intellectual property or these
          Terms of Use.
        </p>
        <p>
          You agree that your comments will not violate any right of any
          third-party, including copyright, trademark, privacy, personality or
          other personal or proprietary right. You further agree that your
          comments will not contain libelous or otherwise unlawful, abusive or
          obscene material, or contain any computer virus or other malware that
          could in any way affect the operation of the Service or any related
          website. You may not use a false e-mail address, pretend to be someone
          other than yourself, or otherwise mislead us or third-parties as to
          the origin of any comments. You are solely responsible for any
          comments you make and their accuracy. We take no responsibility and
          assume no liability for any comments posted by you or any third-party.
        </p>

        <h4>Section 12 - Personal Information</h4>
        <p>
          Your submission of personal information through the site is governed
          by our Privacy Policy. To view our Privacy Policy.
        </p>

        <h4>Section 13 - Errors, Inaccuracies And Omissions</h4>
        <p class="mb_20">
          Occasionally there may be information on our site or in the Service
          that contains typographical errors, inaccuracies or omissions that may
          relate to service descriptions, pricing, promotions, offers and
          availability. We reserve the right to correct any errors, inaccuracies
          or omissions, and to change or update information or cancel orders if
          any information in the Service or on any related website is inaccurate
          at any time without prior notice (including after you have submitted
          your order).
        </p>
        <p>
          We undertake no obligation to update, amend or clarify information in
          the Service or on any related website, including without limitation,
          pricing information, except as required by law. No specified update or
          refresh date applied in the Service or on any related website, should
          be taken to indicate that all information in the Service or on any
          related website has been modified or updated.
        </p>

        <h4>Sections 14 - Prohibited Uses</h4>
        <p>
          In addition to other prohibitions as set forth in the Terms of Use,
          you are prohibited from using the site or its content: (a) for any
          unlawful purpose; (b) to solicit others to perform or participate in
          any unlawful acts; (c) to violate any international, federal,
          provincial or state regulations, rules, laws, or local ordinances; (d)
          to infringe upon or violate our intellectual property rights or the
          intellectual property rights of others; (e) to harass, abuse, insult,
          harm, defame, slander, disparage, intimidate, or discriminate based on
          gender, sexual orientation, religion, ethnicity, race, age, national
          origin, or disability; (f) to submit false or misleading information;
          (g) to upload or transmit viruses or any other type of malicious code
          that will or may be used in any way that will affect the functionality
          or operation of the Service or of any related website, other websites,
          or the Internet; (h) to collect or track the personal information of
          others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape;
          (j) for any obscene or immoral purpose; or (k) to interfere with or
          circumvent the security features of the Service or any related
          website, other websites, or the Internet. We reserve the right to
          terminate your use of the Service or any related website for violating
          any of the prohibited uses.
        </p>

        <h4>Section 15 - Disclaimer Warranties; Limitation Of Liability</h4>
        <p class="mb_20">
          We do not guarantee, represent or warrant that your use of our service
          will be uninterrupted, timely, secure or error-free.
        </p>
        <p class="mb_20">
          We do not warrant that the results that may be obtained from the use
          of the service will be accurate or reliable.
        </p>
        <p class="mb_20">
          You agree that from time to time we may remove the service for
          indefinite periods of time or cancel the service at any time, without
          notice to you.You expressly agree that your use of, or inability to
          use, the service is at your sole risk. The service and all products
          and services delivered to you through the service are (except as
          expressly stated by us) provided 'as is' and 'as available' for your
          use, without any representation, warranties or conditions of any kind,
          either express or implied, including all implied warranties or
          conditions of merchantability, merchantable quality, fitness for a
          particular purpose, durability, title, and non-infringement.
        </p>
        <p>
          In no case shall {{ siteName }}, our directors, officers, employees,
          affiliates, agents, contractors, interns, suppliers, service providers
          or licensors be liable for any injury, loss, claim, or any direct,
          indirect, incidental, punitive, special, or consequential damages of
          any kind, including, without limitation lost profits, lost revenue,
          lost savings, loss of data, replacement costs, or any similar damages,
          whether based in contract, tort (including negligence), strict
          liability or otherwise, arising from your use of any of the service or
          any products procured using the service, or for any other claim
          related in any way to your use of the service or any product,
          including, but not limited to, any errors or omissions in any content,
          or any loss or damage of any kind incurred as a result of the use of
          the service or any content (or product) posted, transmitted, or
          otherwise made available via the service, even if advised of their
          possibility. Because some states or jurisdictions do not allow the
          exclusion or the limitation of liability for consequential or
          incidental damages, in such states or jurisdictions, our liability
          shall be limited to the maximum extent permitted by law.
        </p>

        <h4>Section 16 - Indemnifcation</h4>
        <p>
          You agree to indemnify, defend and hold harmless {{ siteName }} and our
          parent, subsidiaries, affiliates, partners, officers, directors,
          agents, contractors, licensors, service providers, subcontractors,
          suppliers, interns and employees, harmless from any claim or demand,
          including reasonable attorneys' fees, made by any third-party due to
          or arising out of your breach of these Terms of Use or the documents
          they incorporate by reference, or your violation of any law or the
          rights of a third-party.
        </p>

        <h4>Section 17 - Severability</h4>
        <p>
          In the event that any provision of these Terms of Use is determined to
          be unlawful, void or unenforceable, such provision shall nonetheless
          be enforceable to the fullest extent permitted by applicable law, and
          the unenforceable portion shall be deemed to be severed from these
          Terms of Use, such determination shall not affect the validity and
          enforceability of any other remaining provisions.
        </p>

        <h4>Section 18 - Termination</h4>
        <p class="mb_20">
          The obligations and liabilities of the parties incurred prior to the
          termination date shall survive the termination of this agreement for
          all purposes.
        </p>
        <p class="mb_20">
          These Terms of Use are effective unless and until terminated by either
          you or us. You may terminate these Terms of Use at any time by
          notifying us that you no longer wish to use our Services, or when you
          cease using our site.
        </p>
        <p>
          If in our sole judgment you fail, or we suspect that you have failed,
          to comply with any term or provision of these Terms of Use, we also
          may terminate this agreement at any time without notice and you will
          remain liable for all amounts due up to and including the date of
          termination; and/or accordingly may deny you access to our Services
          (or any part thereof).
        </p>

        <h4>Section 19 - Entire Agreement</h4>
        <p class="mb_20">
          The failure of us to exercise or enforce any right or provision of
          these Terms of Use shall not constitute a waiver of such right or
          provision.These Terms of Use and any policies or operating rules
          posted by us on this site or in respect to The Service constitutes the
          entire agreement and understanding between you and us and govern your
          use of the Service, superseding any prior or contemporaneous
          agreements, communications and proposals, whether oral or written,
          between you and us (including, but not limited to, any prior versions
          of the Terms of Use).
        </p>
        <p>
          Any ambiguities in the interpretation of these Terms of Use shall not
          be construed against the drafting party.
        </p>

        <h4>Section 20 - Governing Law</h4>
        <p>
          These Terms of Use and any separate agreements whereby we provide you
          Services shall be governed by and construed in accordance with the
          laws of United Kingdom.
        </p>

        <h4>Section 21 - Changes To Terms Of Use</h4>
        <p class="mb_20">
          You can review the most current version of the Terms of Use at any
          time at this page.
        </p>
        <p>
          We reserve the right, at our sole discretion, to update, change or
          replace any part of these Terms of Use by posting updates and changes
          to our website. It is your responsibility to check our website
          periodically for changes. Your continued use of or access to our
          website or the Service following the posting of any changes to these
          Terms of Use constitutes acceptance of those changes.
        </p>

        <h4>Section 22 - Contact Information</h4>
        <p>
          Questions about the Terms of Use should be sent to us at
          {{ mail }}
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import { getUserProfile } from "@/api/system/user"
import {
  getSeriveList,
  getAddServiceList,
  getVipServiceList,
  buyVipService,
  buyAddService,
} from "@/api/mine/service"
import { mapGetters } from "vuex"
import banner from "@/assets/images/vip/banner.png"

export default {
  components: {},
  computed: {
    ...mapGetters(["dictData", "dictMap", "token", "user"]),
    mail() {
      return this.$store.state.setting.siteData.email
    },
    siteDomain() {
      return this.$store.state.setting.siteData.siteDomain
    },
    siteName() {
      return this.$store.state.setting.siteData.siteName
    },
  },
  data() {
    return {
      contents: [],
      banner: banner,
      userExt: {},
      serviceList: [],
      serviceListAdd: [],
    }
  },

  methods: {
    getUserProfile() {
      getUserProfile().then((res) => {
        this.userExt = res.user
      })
    },
    handleJump(path) {
      this.$router.push({ path: path })
    },
  },
  mounted() {
    if (this.token) {
      this.getUserProfile()
    }
  },
}
</script>

<style></style>

<style lang="scss" scoped>
.vip-wrap {
  .banner {
    width: 100%;
    height: 9.36vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: url("../../assets/images/vip/help.png") no-repeat;
    background-size: 100% 100%;

    .title {
      font-size: 48px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      line-height: 38px;
      margin-bottom: 20px;
    }

    .sub {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      line-height: 38px;
    }
  }
}

.list {
  width: 1200px;
  margin: -60px auto 20px;
  border: 1px solid #dddddd;
  border-radius: 6px;
  background: #ffffff;

  .top {
    height: 280px;
    display: flex;

    .one {
      width: 320px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      border-bottom: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
    }

    .item {
      width: 220px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-bottom: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
      position: relative;

      .special {
        position: absolute;
        top: 50px;
        right: 32px;
        line-height: 24px;
        padding: 0 15px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #fff;
        height: 24px;
        background: linear-gradient(270deg, #fad126, #ff544f);
        border-radius: 12px 12px 12px 0px;
      }

      &:last-child {
        border-right: 0;
      }

      .tip {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-style: italic;
        color: #333333;
        margin-bottom: 10px;
      }

      .name {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #181b1c;
        margin-bottom: 20px;

        span {
          color: rgb(73, 52, 178);
          font-size: 26px;
          font-weight: bold;
        }
      }

      .btn1 {
        color: #fff;
        width: 120px;
        height: 48px;
        background: linear-gradient(270deg, rgb(73, 52, 178), #4892ff);
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .btn2 {
        color: rgb(73, 52, 178);
        width: 120px;
        height: 48px;
        background: #f2f4ff;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          color: #fff;
          width: 120px;
          height: 48px;
          background: rgb(73, 52, 178);
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .content {
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #dddddd;
      height: 60px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;

      .no {
        &::before {
          content: "";
          width: 15px;
          height: 15px;
          background: url("../../assets/images/vip/no.png") no-repeat;
          background-size: contain;
        }
      }

      .yes {
        &::before {
          content: "";
          width: 20px;
          height: 15px;
          background: url("../../assets/images/vip/yes.png") no-repeat;
          background-size: contain;
        }
      }

      &:last-child {
        border-bottom: 0;
      }

      .one {
        width: 320px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        height: 100%;
      }

      .two,
      .three,
      .four,
      .five {
        height: 100%;
        width: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-bottom: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
      }

      .five {
        border-right: 0;
      }
    }
  }
}

section.article {
  margin-top: 2px;
  border: 1px solid transparent;
  background: #fff;
  padding-bottom: 40px;
}
section.article > .cont {
  width: 1240px;
}
section.article h2 {
  margin-top: 40px;
  font-size: 28px;
  font-weight: 600;
}
section.article h2 + i {
  color: #919099;
}
section.article h4 {
  margin: 40px 0 20px;
  font-size: 16px;
  font-weight: 600;
}
section.article p {
  line-height: 28px;
  margin-bottom: 40px;
}
section.article ul {
  list-style: initial;
  margin: 0 40px;
  line-height: 28px;
  margin-bottom: 40px;
}
section.article .title + p {
  margin-top: 60px;
}

.main .cont {
  position: relative;
  width: 1240px;
  margin: 0 auto;
  height: 100%;
  background: transparent;
  font-size: 14px;
}
.t_c {
  text-align: center !important;
}

section.article h2 + i {
  color: #919099;
}
body:not([data-lang="zh"]) * {
  word-break: keep-all;
}
i {
  font-style: italic;
}
</style>
