import request from '@/utils/request'

// 查询公告管理列表
export function getQuestionList(data) {
  return request({
      url: '/server/question/list',
      method: 'get',
      params: data
  })
}

// 导出公告管理列表
export function exportQuestionList(data) {
  return request({
      url: '/server/question/export',
      method: 'get',
      params: data
  })
} 

// 获取公告管理详细信息
export function getQuestionInfo(data) {
  return request({
      url: '/server/question/getInfo/'+data.noticeId,
      method: 'get',
      data: data
  });
}

// 新增公告管理
export function addQuestion(data) {
  return request({
      url: '/server/question/add',
      method: 'post',
      data: data
  });
}

// 修改公告管理
export function updateQuestion(data) {
  return request({
      url: '/server/question/edit',
      method: 'post',
      data: data
  });
}

// 删除公告管理
export function deleteQuestion(data) {
  return request({
      url: '/server/question/delete/' + data,
      method: 'post',
      data: data
  });
}

// 获取公告管理详细信息
export function getLastLoginInfo(data) {
  return request({
      url: '/getLastLoginInfo',
      method: 'get',
      data: data
  });
}