<template>
  <el-aside width="200px" style="background-color: rgb(238, 241, 246)">
    <el-menu
      width="200px"
      default-active="2"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
    >
      <el-menu-item v-if="showIndex" @click="handleJumpIndex" index="2">
        <i class="el-icon-menu"></i>
        <span slot="title">首页</span>
      </el-menu-item>
      <el-submenu v-for="menu in menus" :key="menu.name" :index="menu.name">
        <template slot="title">
          <i class="el-icon-menu"></i>
          <span>{{ menu.meta.title }}</span>
        </template>
        <el-menu-item-group
          v-if="menu.children"
          v-for="submenu in menu.children"
          :index="submenu.name"
          :key="submenu.name"
        >
          <template v-if="false" slot="title">{{
            submenu.meta.title
          }}</template>
          <el-menu-item
            @click="handleJump(menu.path, submenu.path, record.path)"
            v-if="submenu.children && !record.hidden"
            :index="record.name"
            v-for="record in submenu.children"
            :key="record.name"
            >{{ record.meta.title }}</el-menu-item
          >
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </el-aside>
</template>

<script>
import { mapGetters } from "vuex"
import path from "path"
import { getUserProfile } from "@/api/system/user"

export default {
  computed: {
    ...mapGetters(["name", "nickName", "avatar", "permission_routes", "stat"]),
  },
  data() {
    return {
      menus: [],
      user: {},
      showIndex: true,
      indexUrl: ''
    }
  },
  methods: {
    getMenus() {
      let menus = this._.clone(this.permission_routes[0].children)
      this.showIndex = false
      if (menus) {
        menus.splice(0, 1)

        for (var i = 0; i < menus.length; i++) {
          if (menus[i].meta.title == "选品" && this.user.userType == "00") {
            menus.splice(i, 1)
            break
          }
        }
        for (var i = 0; i < menus.length; i++) {
          if (menus[i].meta.title == "管理员首页" && menus[i].children) {
            this.showIndex = true
            this.indexUrl = '/' + menus[i].children[0].path
            menus.splice(i, 1)
            break
          }
        }
        for (var i = 0; i < menus.length; i++) {
          if (menus[i].meta.title == "用户首页" && menus[i].children) {
            this.showIndex = true
            this.indexUrl = '/' + menus[i].children[0].path
            menus.splice(i, 1)
            break
          }
        }
      }
      this.menus = menus
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    handleJump(firstPath, secondPath, thirdPath) {
      let path = firstPath
      if (secondPath) {
        path += "/" + secondPath
      }
      if (thirdPath) {
        path += "/" + thirdPath
      }
      this.$router.push({ path: path })
    },
    handleJumpIndex() {
      let path = this.indexUrl
      this.$router.push({ path: path })
    },
    resolvePath(firstPath, secondPath, thirdPath) {
      return path.resolve(firstPath, secondPath, thirdPath)
    },
    async getUserProfile() {
      let res = await getUserProfile()
      this.user = res.user
    },
  },
  async mounted() {
    await this.getUserProfile()
    setTimeout(() => {
      this.getMenus()
    }, 1000)
  },
}
</script>

<style scoped>
.el-menu-vertical-demo ::v-deep .el-submenu__title {
  text-align: left;
}
.el-menu-vertical-demo ::v-deep .el-menu-item-group__title {
  text-align: left;
  display: none;
}
.el-menu-vertical-demo ::v-deep .el-menu-item {
  text-align: left;
}
</style>
