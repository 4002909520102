<template>
  <section class="rno-2-action-panel" id="section-free">
    <div class="rno-2-action-panel-inner">
      <div class="label">
        <div class="title1">
          内容600万+
        </div>
        <div class="title2">年包裹出口总量</div>
      </div>
      <div class="label">
        <div class="title1">
          200+
        </div>
        <div class="title2">覆盖国家和地区</div>
      </div>
      <div class="label">
        <div class="title1">
          6000+
        </div>
        <div class="title2">年度活跃客户</div>
      </div>
      <div class="label">
        <div class="title1">
          3亿+
        </div>
        <div class="title2">年交易总额</div>
      </div>
      <div class="label">
        <div class="title1">
          60+
        </div>
        <div class="title2">支持全球站点</div>
      </div>
      <div class="label">
        <div class="title1">
          20+
        </div>
        <div class="title2">支持全球电商平台</div>
      </div>
    </div>
  </section>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.rno-2-action-panel {
  padding: 0;
}
.rno-2-action-panel-inner {
  display: flex;
  margin: 0 auto;
  width: 1280px;
  padding: 24px 10px;
  box-sizing: border-box;
}
.label {
  flex: 1;
  text-align: center;
}
.title1 {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFFFFF;
  margin-bottom: 10px;
}
.title2 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fefefe;
  opacity: 0.8;
}
</style>
