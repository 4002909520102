<template>
  <div class="register-2017-body">
    <img @click="handleJump('/app_register')" style="width: 100%" :src="registerImage" />
    <div style="position: absolute; right: 0px; top: 0px;padding: 1.5vh 5vw 0 0;">
      <div @click="handleJump('/app_login')" style="display: inline-block;margin: 0 5vw 0 5vw;">登录</div>
      <div @click="handleJump('/app_register?channel=01')" style="display: inline-block;background: #4934b2;border-radius: 5px;padding: 3px 12px;color: #ffffff;">注册</div>
    </div>
  </div>
</template>

<script>
import register from "@/assets/images/register.png"
import { mapGetters } from "vuex"

export default {
  name: "register",
  data() {
    return {
      registerImage: register,
    }
  },
  computed: {
    ...mapGetters(["dictData", "dictMap", "token", "user"]),
  },

  created() {
    this.getCode()
  },
  methods: {
    handleJump(path) {
      this.$router.push({
        path: path,
      })
    },
  },
  mounted() {},
}
</script>

<style>
.body {
  height: 100%;
}
</style>

<style lang="scss" scoped>
.register-2017-body {
}
</style>
