import request from "@/utils/request"

// 查询参数列表
export function listConfig(query) {
  return request({
    url: "/system/config/list",
    method: "get",
    params: query,
  })
}

// 查询参数详细
export function getConfig(configId) {
  return request({
    url: "/system/config/" + configId,
    method: "get",
  })
}



// 根据参数键名查询参数值
export function getConfigKey(configKey) {
  return request({
    url: "/system/config/configKey/" + configKey,
    method: "get",
  })
}

// 新增参数配置
export function addConfig(data) {
  return request({
    url: "/system/config",
    method: "post",
    data: data,
  })
}

// 修改参数配置
export function updateConfig(data) {
  return request({
    url: "/system/config",
    method: "put",
    data: data,
  })
}

// 删除参数配置
export function delConfig(configId) {
  return request({
    url: "/system/config/" + configId,
    method: "delete",
  })
}

// 清理参数缓存
export function clearCache() {
  return request({
    url: "/system/config/clearCache",
    method: "delete",
  })
}

// 导出参数
export function exportConfig(query) {
  return request({
    url: "/system/config/export",
    method: "get",
    params: query,
  })
}

// 导出参数
export function getBDToken() {
  return request({
    url: "/comm/bd/token",
    method: "post",
  })
}
// 导出参数
export function getBDAddress(data, access_token) {
  return request({
    url:
      "https://aip.baidubce.com/rpc/2.0/nlp/v1/address?access_token=" +
      access_token,
    method: "post",
    data: data,
  })
}

// 导出参数
export function getSiteConfig() {
  return request({
    url: "/getConfig",
    method: "get",
  })
}

// 获取类目接口
export function getCommonGoodsCats(data) {
  return request({
    url: "/product/temu/getGoodsCats",
    method: "get",
    params: data,
  })
}

// 获取类目属性接口
export function getTemuGoodsAttrs(data) {
  return request({
    url: "/product/temu/getGoodsAttrs",
    method: "get",
    params: data,
  })
}
