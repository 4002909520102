<template>
  <div class="portalFooter">
    <div class="hdWrap__PortalLayout-16BGU8">
      <div class="hd">
        <div @click="handleJump('/')">首页</div>
        <div class="br"></div>
        <div @click="handleJump('/contact')">关于我们</div>
        <div class="br"></div>
        <div @click="handleJump('/help')">帮助中心</div>
        <div class="br"></div>
        <div @click="handleJump('/policy')">隐私条款</div>
        <div class="br"></div>
        <div @click="handleJump('/terms')">使用条款</div>
      </div>
    </div>
    <div class="hdWrap__PortalLayout-16BGU8">
      <img style="width: 160px;" :src="homeLogoUrl" />
    </div>
    <div class="copyright__PortalLayout-3W9Nm3">
      Copyright @平台定制<a
        href="https://beian.miit.gov.cn/#/Integrated/index"
        target="blank"
        style="color:#ffffff;margin: 0px 10px"
        >{{ caseNo }}</a
      >
      <a
        v-if="false"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35020302035083"
        target="blank"
        style="color:#ffffff; margin: 0px 10px"
        >闽公网安备35020302035083号</a
      >

      All Rights Reserved.
    </div>
  </div>
</template>

<script>
import logoBlue from "@/assets/images/default2/logo_blue.png"

export default {
  data() {
    return {
      logoBlue: logoBlue,
    }
  },
  computed: {
    caseNo() {
      return this.$store.state.setting.siteData.caseNo
    },
    homeLogoUrl() {
      return this.$store.state.setting.siteData.homeLogoUrl
    },
  },
  methods: {
    handleJump(path) {
      this.$router.push({ path: path })
    },
  },
}
</script>

<style lang="scss" scoped>
.portalFooter {
  background: #ffffff;
  color: #333333;
  margin: 20px 0 0 0;
}
.br {
  border-right: 1px solid #555555;
  width: 1px;
  height: 10px;
  display: inline-block;
}

.portalFooter .hdWrap__PortalLayout-16BGU8 {
  background: #ffffff;
  text-align: center;
}

.portalFooter .hd {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 30px;
}

.portalFooter .hd > div {
  font-size: 14px;
  line-height: 20px;
  color: #555555;
  padding-left: 30px;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
}

.portalFooter .bd {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
}

.portalFooter .logo {
  background: url("../../assets/images/default/logo_blue.png") no-repeat;
  background-size: cover;
  width: 195px;
  height: 58px;
  margin: 21px 0 0px -29px;
}

.portalFooter .contact {
  width: 219px;
  height: 58px;
  margin: 30px 0 0px -29px;
  font-size: 14px;
  line-height: 29px;
}

.portalFooter .linkCol__PortalLayout-1EV_-b {
  margin-left: 55px;
}

.portalFooter .linkCol__PortalLayout-1EV_-b > h3 {
  font-size: 16px;
  color: #333333;
  font-weight: bold;
  margin-bottom: 25px;
}

.portalFooter .linkCol__PortalLayout-1EV_-b > a {
  display: block;
  margin-bottom: 15px;
  font-size: 14px;
  color: #333333;
  opacity: 0.6;
}

.portalFooter .linkCol__PortalLayout-1EV_-b > a:last-child {
  margin-bottom: 0;
}

.portalFooter .linkCol__PortalLayout-1EV_-b > a:hover {
  color: #333333;
  opacity: 0.6;
}

.portalFooter .copyright__PortalLayout-3W9Nm3 {
  line-height: 39px;
  text-align: center;
  font-size: 12px;
  color: #ffffff;
  background: #605c70;
  margin: 16px 0 0 0;
}

.portalFooter .contact1 {
  margin-left: 80px;
  margin-top: -52px;
}

.portalFooter .contact1 .tel,
.portalFooter .contact1 .qq {
  margin-bottom: 20px;
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  padding-left: 55px;
  padding-left: 80px;
  overflow: hidden;
  img {
    width: 117px;
    height: 117px;
    margin: 0 0 0 10px;
  }
  color: #333333;
}

.portalFooter .contact1 .qq {
  font-size: 14px;
  text-align: center;
  font-weight: normal;
}
.portalFooter .contact1 .tel {
  margin-top: 40px;
  background: url("../../assets/images/default/bottom-tel.png") no-repeat left
    center/32px;
}

.portalFooter .contact1 .qq {
}

.portalFooter .contact1 .time__PortalLayout-30tB3L {
  font-size: 14px;
  color: #ffffff;
}

.portalFooter .qrcode {
  width: 110px;
  height: 110px;
  margin-left: 25px;
}

.portalFooter .qrcode > img {
  width: 100%;
  margin: 0 0 0 5px;
}
</style>
