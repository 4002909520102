<template>
  <div class="main">
    <div class="top">即刻开启电商创业之旅，零基础也能成为销售大咖</div>
    <div class="body">
      <div class="module module1">
        <a href="http://china.onecust.com" target="_blank"
          ><img :src="module1"
        /></a>
      </div>
      <div class="module module2">
        <a href="/erp" target="_blank"
          ><img :src="module2"
        /></a>
      </div>
      <div class="module module3">
        <a href="http://international.onecust.com" target="_blank"
          ><img :src="module3"
        /></a>
      </div>
    </div>
  </div>
</template>
<script>
import module1 from "@/assets/images/module1.jpg"
import module2 from "@/assets/images/module2.jpg"
import module3 from "@/assets/images/module3.jpg"
export default {
  data() {
    return {
      hotGoods: [
        [
          {
            prodTitle: "百变头巾",
            price: 5.98,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/S3ERDP-1-30-1-1000-2482c250c850be424903b7c835d85b1c.jpg",
          },
          {
            prodTitle: "抱枕套",
            price: 6.68,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/PB93HR-1-30-1-1000-ea5d178a752bc7f9ed8eaacbf7a675a8.jpg",
          },
          {
            prodTitle: "PVC编织餐垫",
            price: 9.72,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/MAKB6P-1-29-1-1000-7040247304756f561ae8fe93ce4beb3b.jpg",
          },
          {
            prodTitle: "挂毯",
            price: 35.58,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/PXTDDO-1-29-1-1000-4106416dde5fff850459a85cd4661c6a.jpg",
          },
          {
            prodTitle: "IPAD皮套壳",
            price: 26.86,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/2OM7D2-2-163-1-1000-6fc2cc073242e13e3e2cb5b8c676e8c8.jpg",
          },
          {
            prodTitle: "方形三合一数据线",
            price: 22.91,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/JYSVU2-9-30-1-200-dacede865bcc7602a836c76b57e90418.jpg",
          },
        ],
        [
          {
            prodTitle: "毛毯盖毯（横）",
            price: 19.84,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet2/MVU7MU-1-29-1-1000-1b055116789bee166c4ac7dd00aa2a6a.jpg",
          },
          {
            prodTitle: "拼图500片（竖板）",
            price: 18.69,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/IQ3IEF-1-30-1-1000-b4117bad684c5489cb585fb6e7d3b4bd.jpg",
          },
          {
            prodTitle: "拼图500片（横板）",
            price: 18.69,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/YZEK2X-2-30-1-1000-7efca32956c8a8f1b2c25cc953e7a5be.jpg",
          },
          {
            prodTitle: "咖啡杯",
            price: 17.4,
            picUrl:
              "https://nimg5.hicustom.com/static/product/LVUXWE-1200-1-29-873fcff2a8bb7d5d23eaa1fb69ac85a9.jpg",
          },
          {
            prodTitle: "咖啡杯",
            price: 16.04,
            picUrl:
              "https://nimg5.hicustom.com/static/product/9JGD55-1200-1-29-d185418f1793533c1f93f1aeb2e8b2a2.jpg",
          },
          {
            prodTitle: "圣诞树裙（加厚款）",
            price: 17.68,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet2/VPMFI3-10-29-1-1000-61aa555dc567c497abcdea1687c19aa0.jpg",
          },
        ],
        [
          {
            prodTitle: "遮阳罩（加硬款）",
            price: 21.41,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/PTN8UW-1-29-1-1000-8b2c10d8b00664a6bb9c5964f5f7e6ad.jpg",
          },
          {
            prodTitle: "羊羔毯",
            price: 46.39,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/XE45E3-1-29-1-1000-41eaca048ef564007f462d641f1f191b.jpg",
          },
          {
            prodTitle: "折叠胸前包",
            price: 23.39,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/NBJNSN-1-29-1-1000-5f06f7ebb3de2ab2415f34420c5f81fc.jpg",
          },
          {
            prodTitle: "防水布料信箱套",
            price: 16.74,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet2/P9WWGO-6-29-1-1000-d1b74e402920f589356bd9749f902974.jpg",
          },
          {
            prodTitle: "手提斜挎餐包",
            price: 29.49,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet2/7MBXWP-1-29-1-1000-70e29a5cdd195a28ecc9e9ddf671801f.jpg",
          },
          {
            prodTitle: "圆形三合一数据线",
            price: 27.17,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/RD9R6F-8-30-1-1000-0cfaae5409fa904840651cb59f2d17c4.jpg",
          },
        ],
        [
          {
            prodTitle: "无框装饰画",
            price: 34.43,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet2/69F3IQ-3-30-1-1000-0e984697835da1a4b89b304b0d356a8b.jpg",
          },
          {
            prodTitle: "加绒棒球服(双面)",
            price: 42.52,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/FA5DYL-3-29-1-1000-e4ed66db1c6656b699421a0786ebf3e2.jpg",
          },
          {
            prodTitle: "男款带帽无口袋短袖卫衣",
            price: 35.9,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/B7BIRT-1-29-1-1000-36edb15e16e8a5c1e0a4f989efaaa252.jpg",
          },
        ],
      ],
      newGoods: [
        [
          {
            prodTitle: "火影文具袋",
            price: 15.0,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/28CEKL-1-29-1-500-2a546e3701b294223db90ea2f212dc06.jpg",
          },
          {
            prodTitle: "束口背包",
            price: 18.52,
            picUrl:
              "https://nimg5.hicustom.com/static/product/TDZXII-500-1-30-19a34b23379f12dac2bd4430fbc01f69.jpg",
          },
          {
            prodTitle: "双肩背包",
            price: 60.5,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/2A688C-1-29-1-500-2a1fa0230a676d290bdc58f2e436cb36.jpg",
          },
          {
            prodTitle: "汽车反向伞",
            price: 25.98,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/Q4RKXR-1-29-1-500-843d90fc357119fa2db346d8953f9971.jpg",
          },
          {
            prodTitle: "皮革行李牌",
            price: 5.68,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/HG22CS-1-29-1-500-fed59218c569dbdea072f006960df089.jpg",
          },
          {
            prodTitle: "防护帽",
            price: 13.7,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/4LV429-6-29-1-500-a97cd13d6fa4e30347878bfc935f446b.jpg",
          },
        ],
        [
          {
            prodTitle: "防尘口罩（多个滤片装）",
            price: 18.17,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/OWZ7ZS-1-166-1-500-31bb5679d1d1d6271ffb7cf353e80b25.jpg",
          },
          {
            prodTitle: "儿童装饰防尘口罩（多个装）",
            price: 6.51,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/GLQ7FK-1-29-1-500-c207f927bf2747e29906c1949e130ebf.jpg",
          },
          {
            prodTitle: "门帘34*56（两片拼接）",
            price: 29.75,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/3VAATK-1-29-1-500-d231531df3b46b26b97e03f5d0e6bc3f.jpg",
          },
          {
            prodTitle: "门帘34*56（两片一致）",
            price: 29.75,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/D8LFLZ-1-29-1-500-ef0786eea674bec00538cd9d35747582.jpg",
          },
          {
            prodTitle: "瑜伽砖",
            price: 13.91,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/NUAB66-1-101-1-500-36d50435f0a9a0e9befe514b3a8295ca.jpg",
          },
          {
            prodTitle: "PVC编织餐垫",
            price: 9.74,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/MAKB6P-1-29-1-500-4f42566af768b181722bdab53330547e.jpg",
          },
        ],
        [
          {
            prodTitle: "单肩防震手提电脑包",
            price: 32.98,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/2YUBM6-1-29-1-500-c07475222e3be61082162711595be3ec.jpg",
          },
          {
            prodTitle: "挂毯90*60inch（竖版）",
            price: 41.28,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/EVM52T-1-29-1-500-8c7f21236a1d65bc252b9a91d20cb2bb.jpg",
          },
          {
            prodTitle: "挂毯90*60inch（横版）",
            price: 41.28,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/T5MF25-1-29-1-500-26fb4c09f631a493304fd18c4108d002.jpg",
          },
          {
            prodTitle: "圆形药盒",
            price: 9.82,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/GYI27R-1-29-1-500-886a8bcb9824822440a78b857789d78d.jpg",
          },
          {
            prodTitle: "超大号宠物垫",
            price: 45.52,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/CM555S-1-29-1-500-84ee1d6228eb5abb0224bc10d6484a4a.jpg",
          },
          {
            prodTitle: "万圣节全副帽子",
            price: 13.98,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/O33AKD-1-29-1-500-3d6b95a7faa31d5089726e6cde0c3380.jpg",
          },
        ],
        [
          {
            prodTitle: "露营折叠凳",
            price: 49.68,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/LE7LCY-1-29-1-500-9195c77527db7f41098c2f182e56c5a3.jpg",
          },
          {
            prodTitle: "方形毛巾",
            price: 15.98,
            picUrl:
              "https://nimg5.hicustom.com/static/productDet/IQ4Y46-1-29-1-500-436d7275e7604994d10f549e1be1d394.jpg",
          },
        ],
      ],
      module1: module1,
      module2: module2,
      module3: module3,
    }
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
}
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.top {
  text-align: 100%;
  width: 100%;
  text-align: center;
  color: #ffffff;
  font-size: 29px;
  position: absolute;
  top: 60px;
}
.body {
  width: 100%;
}
.module {
  width: 30%;
  top: 150px;
  position: absolute;
  img {
    width: 100%;
  }
  transition: all 0.6s ease 0s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 0px 13px 0 rgb(64 147 243 / 35%);
  }
}
.module1 {
  left: 2%;
}
.module2 {
  left: 35%;
}
.module3 {
  left: 68%;
}
</style>
