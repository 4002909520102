<template>
  <div class="body">
    <TopNav :showDetail="!showDetail"></TopNav>
    <Content></Content>
    <Service></Service>
    <Footer></Footer>
    <Feedback></Feedback> 
  </div>
</template>

<script>
import TopNav from "../advantage/TopNav"
import Detail from "./Detail"
import Carousel from "./Carousel"
import SectionHot from "./SectionHot"
import Product from "./Product"
import Free from "./Free"
import Process from "./Process"
import Content from "./Content"
import Set from "./Set"
import Service from "./Service"
import Footer from "../default2/Footer"
import Feedback from "../default2/Feedback"


export default {
  components: {
    Detail,
    TopNav,
    Carousel,
    SectionHot,
    Product,
    Free,
    Process,
    Service,
    Content,
    Footer,
    Set,
    Feedback,
  },
  data() {
    return {
      showDetail: false,
    }
  },
  methods: {},
  mounted() {
    let _self = this
  },
}
</script>

<style>
@import "../../assets/css/default/top-nav.css";
@import "../../assets/css/default/main.css";
</style>
