import request from "@/utils/request"
import { praseStrEmpty } from "@/utils/jn"

// 查询用户列表
export function listUser(query) {
  return request({
    url: "/system/user/list",
    method: "get",
    params: query,
  })
}

// 查询用户详细
export function getUser(userId) {
  return request({
    url: "/system/user/" + praseStrEmpty(userId),
    method: "get",
  })
}

// 新增用户
export function addUser(data) {
  return request({
    url: "/system/user",
    method: "post",
    data: data,
  })
}

// 新增成员
export function addMember(data) {
  return request({
    url: "/system/user/add",
    method: "post",
    data: data,
  })
}

// 修改用户
export function updateUser(data) {
  return request({
    url: "/sys/user/update",
    method: "put",
    data: data,
  })
}

// 删除用户
export function delUser(userId) {
  return request({
    url: "/sys/user/delete/" + userId,
    method: "delete",
  })
}

// 导出用户
export function exportUser(query) {
  return request({
    url: "/system/user/export",
    method: "get",
    params: query,
  })
}

// 用户密码重置
export function resetUserPwd(userId, password) {
  const data = {
    userId,
    password,
  }
  return request({
    url: "/system/user/resetPwd",
    method: "put",
    data: data,
  })
}

// 用户状态修改
export function changeUserStatus(userId, status) {
  const data = {
    userId,
    status,
  }
  return request({
    url: "/system/user/changeStatus",
    method: "put",
    data: data,
  })
}

// 查询用户个人信息
export function getUserProfile() {
  return request({
    url: "/system/user/profile",
    method: "get",
  })
}

// 修改用户个人信息
export function updateUserProfile(data) {
  return request({
    url: "/system/user/updateProfile",
    method: "post",
    data: data,
  })
}

// 用户密码重置
export function updateUserPwd(oldPassword, newPassword) {
  const data = {
    oldPassword,
    newPassword,
  }
  return request({
    url: "/system/user/updatePwd",
    method: "post",
    params: data,
  })
}

// 用户头像上传
export function uploadAvatar(data) {
  return request({
    url: "/system/user/profile/avatar",
    method: "post",
    data: data,
  })
}

// 下载用户导入模板
export function importTemplate() {
  return request({
    url: "/system/user/importTemplate",
    method: "get",
  })
}

// 查询子账号列表
export function listSubAccount(query) {
  return request({
    url: "/system/user/listChild",
    method: "get",
    params: query,
  })
}

// 查询子账号列表
export function listMainChild(query) {
  return request({
    url: "/system/user/listMainChild",
    method: "get",
    params: query,
  })
}

// 设置读取账号数据
export function addReceive(data) {
  return request({
    url: "/system/user/addReceive",
    method: "POST",
    data: data,
  })
}

// 设置共享账号数据
export function addShare(data) {
  return request({
    url: "/system/user/addShare",
    method: "POST",
    data: data,
  })
}

// 设置共享账号数据
export function listShareChild(data) {
  return request({
    url: "/system/user/listShareChild",
    method: "GET",
    data: data,
  })
}

// 个人信息修改手机号
export function updateMobile(data) {
  return request({
    url: "/system/user/updateMobile",
    method: "post",
    data: data,
  })
}

// 个人信息修改手机号
export function sendUserCodeSms(data) {
  return request({
    url: "/system/user/sendUserCodeSms",
    method: "POST",
    data: data,
  })
}

// 个人信息修改手机号
export function sendUserAuthCodeSms(data) {
  return request({
    url: "/system/user/sendUserAuthCodeSms",
    method: "POST",
    data: data,
  })
}

// 个人信息修改手机号
export function addAuth(data) {
  return request({
    url: "/system/user/addAuth",
    method: "POST",
    data: data,
  })
}

// 设置共享账号数据
export function listInvite(data) {
  return request({
    url: "/system/user/listInvite",
    method: "GET",
    params: data,
  })
}

// 设置共享账号数据
export function setHotKey(data) {
  return request({
    url: "/system/user/setHotKey",
    method: "POST",
    data: data,
  })
}