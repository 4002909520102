export default {
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit("pick", [start, end])
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
              end.setTime(end.getTime() - 3600 * 1000 * 24 * 1)
              picker.$emit("pick", [start, end])
            },
          },
          {
            text: "本周",
            onClick(picker) {
              //起止日期数组
              var startStop = new Array()
              //获取当前时间
              var currentDate = new Date()
              //返回date是一周中的某一天
              var week = currentDate.getDay()
              //返回date是一个月中的某一天
              var month = currentDate.getDate()

              //一天的毫秒数
              var millisecond = 1000 * 60 * 60 * 24
              //减去的天数
              var minusDay = week != 0 ? week - 1 : 6
              //alert(minusDay);
              //本周 周一
              var monday = new Date(
                currentDate.getTime() - minusDay * millisecond
              )
              //本周 周日
              var sunday = new Date(monday.getTime() + 6 * millisecond)
              picker.$emit("pick", [monday, sunday])
            },
          },
          {
            text: "本月",
            onClick(picker) {
              //起止日期数组
              var startStop = new Array()
              //获取当前时间
              var currentDate = new Date()
              //获得当前月份0-11
              var currentMonth = currentDate.getMonth()
              //获得当前年份4位年
              var currentYear = currentDate.getFullYear()
              //求出本月第一天
              var firstDay = new Date(currentYear, currentMonth, 1)

              //当为12月的时候年份需要加1
              //月份需要更新为0 也就是下一年的第一个月
              if (currentMonth == 11) {
                currentYear++
                currentMonth = 0 //就为
              } else {
                //否则只是月份增加,以便求的下一月的第一天
                currentMonth++
              }

              //一天的毫秒数
              var millisecond = 1000 * 60 * 60 * 24
              //下月的第一天
              var nextMonthDayOne = new Date(currentYear, currentMonth, 1)
              //求出上月的最后一天
              var lastDay = new Date(nextMonthDayOne.getTime() - millisecond)
              picker.$emit("pick", [firstDay, lastDay])
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit("pick", [start, end])
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit("pick", [start, end])
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit("pick", [start, end])
            },
          },
        ],
      },
    }
  },
  methods: {
    getCache(formType) {
      let cache = localStorage.getItem("form")
      if (cache) {
        cache = JSON.parse(cache)
        if (cache[this.$route.path]) {
          if (!formType) {
            this.form = JSON.parse(cache[this.$route.path])
          } else {
            this.queryParams = JSON.parse(cache[this.$route.path])
          }
        }
      }
    },
    setCache(formType) {
      let form = this.form
      if (formType) {
        form = this.queryParams
      }
      let cache = localStorage.getItem("form")
      if (cache) {
        cache = JSON.parse(cache)
        cache[this.$route.path] = JSON.stringify(form)
      } else {
        cache = { [this.$route.path]: JSON.stringify(form) }
      }
      localStorage.setItem("form", JSON.stringify(cache))
    },
  },
}
