<template>
  <div class="rno-action-panel">
    <img style="width:100%;" :src="addBar" />
    <button
      style="cursor:pointer;"
      @click.prevent="handleJump('/register')"
      class="register"
    >
      免费注册
    </button>
  </div>
</template>

<script>
import addBar from "@/assets/images/default2/ad-bar3.jpg"

export default {
  data() {
    return {
      addBar: addBar,
    }
  },
  methods: {
    handleJump(path) {
      this.$router.push({
        path: path,
      })
    },
  },
}
</script>

<style scoped>
.rno-action-panel {
  padding: 0 !important;
  margin: 100px 0 0 0;
  position: relative;
  text-align: center;
}
.register {
  background: #ffffff;
  color: rgb(73, 52, 178);
  border-radius: 6px;
  width: 200px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  left: calc(50% - 100px);
  top: calc(6vw);
  border: 0;
  font-weight: bold;
}
.register:hover{
  opacity: 0.9;
}
</style>
