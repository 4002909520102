import request from '@/utils/request'

// 消息列表
export function getMsgList(data) {
    return request({
        url: '/server/msg/list',
        method: 'get',
        params: data
    })
}

// 单个消息详情
export function getMsgDetail(id) {
    return request({
        url: '/server/msg/'+ id,
        method: 'get'
    })
}