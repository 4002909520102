<template>
  <div :class="{ hidden: hidden }" class="pagination-container">
    <el-pagination
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :total="total"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :pager-count="pageCount"
    >
      <slot></slot>
    </el-pagination>
  </div>
</template>

<script>
import { scrollTo } from "@/utils/scroll-to"

export default {
  name: "Pagination",
  props: {
    total: {
      required: true,
      type: Number,
    },
    page: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 20,
    },
    pageCount: {
      type: Number,
      default: 5,
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50, 100]
      },
    },
    layout: {
      type: String,
      default: "total, sizes, prev, pager, next, jumper",
    },
    background: {
      type: Boolean,
      default: true,
    },
    autoScroll: {
      type: Boolean,
      default: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    langType: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit("update:page", val)
      },
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit("update:limit", val)
      },
    },
  },
  methods: {
    handleSizeChange(val) {
      this.$emit("pagination", { page: this.currentPage, limit: val })
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    },
    handleCurrentChange(val) {
      this.$emit("pagination", { page: val, limit: this.pageSize })
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    },
  },
}
</script>

<style scoped>
.pagination-container {
  padding: 0px 0px 4px 0px;
  text-align: right;
}
.pagination-container.hidden {
  display: none;
}

::v-deep .el-button {
  border: 1px solid;
}
::v-deep .el-button--warning {
  background-color: #e6a23c;
  border-color: #e6a23c;
}
::v-deep .el-button--success {
  background-color: #00b78d;
  border-color: #00b78d;
}
::v-deep .el-button--danger {
  background-color: #f56c6c;
  border-color: #f56c6c;
}
::v-deep .el-button--primary {
  background-color: #4934b2;
  border-color: #4934b2;
}
</style>
