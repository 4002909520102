<template>
  <div class="body">
    <TopNav></TopNav>
    <Carousel></Carousel>
    <SectionHot></SectionHot>
    <Product></Product>
    <Free></Free>
    <Process></Process>
    <Service></Service>
    <Parnter></Parnter>
    <!-- <Cert></Cert> -->
    <Set></Set>
    <Footer></Footer>
  </div>
</template>

<script>
import TopNav from "./TopNav"
import Carousel from "./Carousel"
import SectionHot from "./SectionHot"
import Product from "./Product"
import Free from "./Free"
import Process from "./Process"
import Service from "./Service"
import Parnter from "./Partner"
import Footer from "./Footer"
import Set from "./Set"
// import Cert from "./Cert"

export default {
  components: {
    TopNav,
    Carousel,
    SectionHot,
    Product,
    Free,
    Process,
    Service,
    Parnter,
    Footer,
    Set,
    // Cert,
  },
}
</script>

<style>
@import "../../assets/css/default/top-nav.css";
@import "../../assets/css/default/main.css";
</style>
